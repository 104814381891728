import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatRadioChange, MatSlideToggleChange } from "@angular/material";

import { ImportacaoAutomaticaConfiguracao, ImportacaoAutomaticaTipo } from "../../../common/model/importacao-automatica";
import * as moment from "moment";
import { ListaService } from "../../../common/service/lista.service";
import { CustomAlertService } from "../../../common/service/custom-alert.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
	selector: "app-importacao-automatica",
	templateUrl: "./importacao-automatica.component.html",
	styleUrls: ["./importacao-automatica.component.css"],
	providers: [
		{ useClass: ListaService, provide: ListaService },
		{ useClass: CustomAlertService, provide: CustomAlertService }
	]
})
export class ImportacaoAutomaticaComponent {
	importacaoAutomaticaConfiguracaoId: number;
	importacaoAutomatica: ImportacaoAutomaticaConfiguracao;
	horaInicioFrequencia: Date;

	descricao: string;

	tipoRecorrencia: string;
	dirty: boolean;

	tipoCollection: any = [{ 'tipo': '1', 'descricao': 'Arquivo' }, { 'tipo': '2', 'descricao': 'FTP' }/*, { 'tipo': '3', 'descricao': 'SFTP' }*/];
	frequenciaCollection: any = [{ 'frequencia': '1', 'descricao': 'Minuto' }, { 'frequencia': '2', 'descricao': 'Hora' }, { 'frequencia': '3', 'descricao': 'Dia' }, { 'frequencia': '4', 'descricao': 'Semana' }];
	diaSemanaCompleto: any = { 'SUN': 'domingo', 'MON': 'segunda', 'TUE': 'terca', 'WED': 'quarta', 'THU': 'quinta', 'FRI': 'sexta', 'SAT': 'sabado' };
	horarioCollection: Array<any>;

	_errosAsync: any;

	public get erros() {
		if (!this.dirty) return;
		return this.importacaoAutomatica.validar();
	}

	public get errosAsync() {
		return this._errosAsync;
	}

	constructor(private listaService: ListaService,
		private customAlertService: CustomAlertService,
		private translate: TranslateService,
		public dialogRef: MatDialogRef<ImportacaoAutomaticaComponent>,
		@Inject(MAT_DIALOG_DATA)
		public data: { importacaoAutomaticaConfiguracaoId: number, importacaoAutomaticaConfiguracao: ImportacaoAutomaticaConfiguracao }
	) {
		this.importacaoAutomatica = new ImportacaoAutomaticaConfiguracao();
		this.dirty = false;

		this.importacaoAutomaticaConfiguracaoId = data.importacaoAutomaticaConfiguracaoId;

		this.listaService.getImportacaoAutomaticaPorId(this.importacaoAutomaticaConfiguracaoId)
			.subscribe((result: ImportacaoAutomaticaConfiguracao) => {
				this.importacaoAutomatica = result;
				if (this.importacaoAutomatica.horaExecucao) {
					let h = this.importacaoAutomatica.horaExecucao.split(":");
					this.importacaoAutomatica.horaExecucao = `${h[0]}:${h[1]}`;
				}
				this.gerarDescricao();
			});
	}

	validarDiretorioExiste() {

		if(this.importacaoAutomatica.tipo != ImportacaoAutomaticaTipo.arquivo) return;

		this.importacaoAutomatica
			.validarDiretorioExisteAsync(this.listaService)
			.then((res: any) => { this._errosAsync = undefined; }, (err: any) => { this._errosAsync = err; });
	}

	isNew() {
		return this.vazio(this.importacaoAutomatica.importacaoAutomaticaConfiguracaoId);
	}

	salvar() {
		this.dirty = true;

		this.validarDiretorioExiste();
		
		if (this.importacaoAutomatica.validar()) {
			this.customAlertService.show("telaImportacaoAutomaticaConfiguracao.importacaoAutomatica", "telaPadrao.camposInvalidos", "error");
			return;
		}

		if (this.importacaoAutomatica.tipo == ImportacaoAutomaticaTipo.arquivo) {
			let then = (res: any) => {
				this._errosAsync = undefined;
				this.gravar();
			};
			let error = (err: any) => {
				this._errosAsync = err;
				this.customAlertService.show("telaImportacaoAutomaticaConfiguracao.importacaoAutomatica", "telaPadrao.camposInvalidos", "error");
			};
			this.importacaoAutomatica.validarDiretorioExisteAsync(this.listaService).then(then, error);
		} else {
			this.gravar();
		}
	}

	gravar() {		
		this.listaService.atualizarImportacaoAutomatica(this.importacaoAutomatica)
			.subscribe(() => {
				this.customAlertService.show("telaImportacaoAutomaticaConfiguracao.importacaoAutomatica", "telaImportacaoAutomaticaConfiguracao.salvarConfiguracao", "success");
				this.dialogRef.close(this.importacaoAutomatica);
			}, (error: any) => {
				this.customAlertService.show("telaImportacaoAutomaticaConfiguracao.importacaoAutomatica", error.error[0], "error");
			});
	}

	gerarDescricao() {
		this.descricao = '';

		if (!this.vazio(this.importacaoAutomatica.dataInicio)) {
			let dataInicio = moment(this.importacaoAutomatica.dataInicio).format("DD/MM/YYYY");
			let horaInicio: string;

			if (!this.vazio(this.importacaoAutomatica.horaExecucao))
				horaInicio = (typeof (this.importacaoAutomatica.horaExecucao) == 'string') ? this.importacaoAutomatica.horaExecucao : moment(this.importacaoAutomatica.horaExecucao).format("HH:mm");

			horaInicio += ':00';

			if (this.importacaoAutomatica.frequencia == '1') {
				let s = (this.importacaoAutomatica.intervalo > 1) ? this.translate.instant("telaImportacaoAutomaticaConfiguracao.s") : '';
				this.descricao = (!this.vazio(this.importacaoAutomatica.intervalo))
					? this.translate.instant("telaImportacaoAutomaticaConfiguracao.todosDiasMinuto", {
						'intervalo': this.importacaoAutomatica.intervalo,
						's': s,
						'dataInicio': dataInicio
					})
					: '';

			} else if (this.importacaoAutomatica.frequencia == '2') {
				let s = (this.importacaoAutomatica.intervalo > 1) ? this.translate.instant("telaImportacaoAutomaticaConfiguracao.s") : '';
				let aPartir = (!this.vazio(horaInicio)) ? this.translate.instant("telaImportacaoAutomaticaConfiguracao.aPartir", { 'horaInicio': horaInicio }) : '';

				this.descricao = (!this.vazio(this.importacaoAutomatica.intervalo))
					? this.translate.instant("telaImportacaoAutomaticaConfiguracao.todosDiasHora", {
						'intervalo': this.importacaoAutomatica.intervalo,
						's': s,
						'aPartir': aPartir,
						'dataInicio': dataInicio
					})
					: '';

			} else if (this.importacaoAutomatica.frequencia == '3') {
				this.descricao = (!this.vazio(horaInicio))
					? this.translate.instant("telaImportacaoAutomaticaConfiguracao.todosDiasDia", { 'horaInicio': horaInicio, 'dataInicio': dataInicio })
					: '';

			} else if (this.importacaoAutomatica.frequencia == '4') {
				if (this.vazio(this.importacaoAutomatica.diaSemana)) {
					this.descricao = (!this.vazio(horaInicio))
						? this.translate.instant("telaImportacaoAutomaticaConfiguracao.todosDiasDia", { 'horaInicio': horaInicio, 'dataInicio': dataInicio })
						: '';

				} else {
					let diasOrdenados = this.acertaOrdemDiasSemana();

					if (diasOrdenados.length == 1) {
						this.descricao = this.translate.instant("telaImportacaoAutomaticaConfiguracao.algunsDiasSemana", {
							'diaSemana': this.translate.instant(`telaImportacaoAutomaticaConfiguracao.${this.diaSemanaCompleto[this.importacaoAutomatica.diaSemana]}`),
							'horaInicio': horaInicio,
							'dataInicio': dataInicio
						});

					} else if (diasOrdenados.length == 7) {
						this.descricao = (!this.vazio(horaInicio))
							? this.translate.instant("telaImportacaoAutomaticaConfiguracao.todosDiasDia", { 'horaInicio': horaInicio, 'dataInicio': dataInicio })
							: '';

					} else {
						let diaSemana = [];
						diasOrdenados.forEach(function (v: string) { diaSemana.push(this.translate.instant(`telaImportacaoAutomaticaConfiguracao.${this.diaSemanaCompleto[v]}`)); }.bind(this));
						this.descricao = (!this.vazio(horaInicio))
							? this.translate.instant("telaImportacaoAutomaticaConfiguracao.algunsDiasSemana", {
								'diaSemana': this.replaceUltimoDia(diaSemana.join(', ')),
								'horaInicio': horaInicio,
								'dataInicio': dataInicio
							})
							: '';
					}
				}
			}

			if (this.importacaoAutomatica.comDataFim && !this.vazio(this.importacaoAutomatica.dataTermino)) {
				this.descricao = (!this.vazio(this.descricao))
					? `${this.descricao.substring(0, this.descricao.length - 1)} ${this.translate.instant("telaImportacaoAutomaticaConfiguracao.ate")} ${moment(this.importacaoAutomatica.dataTermino).format("DD/MM/YYYY")} 23:59:59.`
					: '';
			}
		}
	}

	acertaOrdemDiasSemana() {
		let dias = this.importacaoAutomatica.diaSemana.split(',');
		let diasOrdenados = [];

		if (dias.some((i) => { return i == 'SUN'; })) diasOrdenados.push('SUN');
		if (dias.some((i) => { return i == 'MON'; })) diasOrdenados.push('MON');
		if (dias.some((i) => { return i == 'TUE'; })) diasOrdenados.push('TUE');
		if (dias.some((i) => { return i == 'WED'; })) diasOrdenados.push('WED');
		if (dias.some((i) => { return i == 'THU'; })) diasOrdenados.push('THU');
		if (dias.some((i) => { return i == 'FRI'; })) diasOrdenados.push('FRI');
		if (dias.some((i) => { return i == 'SAT'; })) diasOrdenados.push('SAT');

		this.importacaoAutomatica.diaSemana = diasOrdenados.join(',');

		return diasOrdenados;
	}

	replaceUltimoDia(str: string) {
		return str.split(' ').reverse().join(' ').replace(new RegExp(/,/), '*e').split(' ').reverse().join(' ').replace('*e', ' e');
	};

	tipoChange(event: any) {
		this.importacaoAutomatica.diretorio = undefined;
		this.importacaoAutomatica.host = undefined;
		this.importacaoAutomatica.username = undefined;
		this.importacaoAutomatica.password = undefined;
		this.importacaoAutomatica.dominio = undefined;
	}

	frequenciaChange(event: any) {
		if (this.isNew() && this.dirty === undefined) {
			let dataAtual = new Date();
			this.importacaoAutomatica.dataInicio = dataAtual;

			if (this.importacaoAutomatica.frequencia == '1')
				this.importacaoAutomatica.intervalo = 30;

			else if (this.importacaoAutomatica.frequencia == '2')
				this.importacaoAutomatica.intervalo = 1;
		}

		if (this.importacaoAutomatica.frequencia == '3')
			this.importacaoAutomatica.diaSemana = null;

		this.gerarDescricao();
	}

	dadosChange(event: any) {
		this.gerarDescricao();
	}

	comDataFimChange(event: MatSlideToggleChange) {
		if (event.checked) {
			let dataAtual = new Date();
			dataAtual.setDate(dataAtual.getDate() + 1);
			dataAtual.setHours(23, 59, 59, 999);
			this.importacaoAutomatica.dataTermino = dataAtual;
		} else {
			this.importacaoAutomatica.dataTermino = null;
		}

		this.gerarDescricao();
	}

	vazio(valor: any) {
		return (valor == undefined || valor == null || valor == '');
	}

	checkTerminoDesabilitado() {
		if (!this.importacaoAutomatica.comDataFim)
			return true;

		return false;
	}

	fechar() {
		this.importacaoAutomatica.validar();
		this.dialogRef.close();
	}
}
