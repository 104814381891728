import { ESBuilderRulesConditions } from "./rules-conditions.model";

export class ESBuilderRules {
	public condition: ESBuilderRulesConditions;

	public field: string;
	public fieldType: string;
	public fieldNestedType: string;
	public fieldNestedList: boolean;

	public filterType: string;
	public filterParameters: Array<any>;
	public filterValues: any;

	public nestedType: string;
	public nestedContent: string;
	public nestedList: boolean;


	public order: number;
	public rules: Array<ESBuilderRules>;

	// Propriedades usadas para a criação da query reversa
	public reverse: boolean = false;
	public notReverseCondition: boolean = false;
	public reverseNegation: boolean = false;

	public constructor(init?: Partial<ESBuilderRules>) {
		Object.assign(this, init);
	}

	static fromRaw(raw: any) {
		let data = new ESBuilderRules();
		Object.assign(data, raw);
		return data;
	}
}
