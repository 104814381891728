export function isNullOrEmpty(valor: any) {
    return (valor == undefined || valor == null || valor == '');
}

export function isNull(valor: any) {
    return (valor == undefined || valor == null || valor == '');
}

export function isNullOrZero(valor: any) {
    return (valor == undefined || valor == null || valor == '' || valor == 0 || valor == '0');
}

export function isObjectEmpty(object: any) {
    return Object.keys(object).length === 0;
}