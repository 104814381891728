import { Component, OnInit, ViewChild, Query, OnDestroy, HostBinding } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material";
import { Subject, Operator, Subscription, forkJoin, Observable } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import swal from "sweetalert2";
import { isNull, isNullOrEmpty, isNullOrZero, isObjectEmpty } from "../../common/utils";

import { Edge, Node, Layout, Graph, NodeDimension, GraphComponent, ClusterNode, MiniMapPosition } from '@swimlane/ngx-graph';
import * as shape from 'd3-shape';
import { DagreNodesOnlyLayout } from "../custom-layout-flow";

import { CustomAlertService } from "../../common/service/custom-alert.service";
import { GuidService } from "../../common/service/guid.service";
import { FluxoService } from "../../common/service/fluxo.service";
import { ListaService } from "../../common/service/lista.service";

import { FiltroLista } from "../../common/model/filtro-lista";
import { Lista } from "../../common/model/lista";
import { Fluxo, FluxoTipo } from "../../common/model/fluxo";
import { FluxoProcesso, FluxoProcessoTipo } from "../../common/model/fluxo-processo";
import { Estrategia, EstrategiaAcaoCampanhaTipo } from "../../common/model/estrategia";
import { FluxoQuery } from "../../common/model/fluxo-query";

import { Provider } from "../../common/model/provider";
import { ProviderService } from "../../common/service/provider.service";
import { Template, TipoCategoria } from "../../common/model/template";
import { TemplateService } from "../../common/service/template.service";
import { CampanhaService } from "../../common/service/campanha.service";
import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';
import { execucaoEstrategiaDescricao } from '../libs/utils/execucaoEstrategiaDescricao';
import { EstrategiaService } from "../../common/service/estrategia.service";
import { ModelToModelReverse, ModelToQuery } from "../../esbuilder/libs/utils";
import { ESBuilderData, ESBuilderRules, ESBuilderRulesConditions } from "../../esbuilder/models";
import { environment } from "../../../../environments/environment";
import {
    FluxoAgendamentoEstrategiaComponent,
    FluxoQueryModalComponent,
    FluxoVisualizarFiltroModalComponent,
    FluxoVisualizarFiltroSimplesModalComponent
} from "../fluxo-modais";

import * as jquery from 'jquery';
import { PreviewDadosEstrategiaComponent } from "../../estrategia/form-estrategia/preview-dados-estrategia/preview-dados-estrategia.component";
import { IntegracaoTipo } from "../../common/model/provedor-configuracao";
import { is } from "core-js/fn/object";
const $ = jquery;

@Component({
    selector: "app-form-fluxo-estrategia",
    templateUrl: "./form-fluxo-estrategia.component.html",
    styleUrls: ["./form-fluxo-estrategia.component.scss", "./fluxo-nos.scss", "./fluxo-cores.scss"],
    providers: [
        { useClass: FluxoService, provide: FluxoService },
        { useClass: ListaService, provide: ListaService },
        { useClass: EstrategiaService, provide: EstrategiaService },
        { useClass: TemplateService, provide: TemplateService },
        { useClass: ProviderService, provide: ProviderService },
        { useClass: CampanhaService, provide: CampanhaService },
        { useClass: CustomAlertService, provide: CustomAlertService }
    ],
    animations: [
        trigger('slideInOut', [
            transition(':enter', [
                style({ transform: 'translateX(-100%)' }),
                animate('500ms ease-in-out', style({ transform: 'translateX(0%)' }))
            ]),
            transition(':leave', [
                animate('500ms ease-in-out', style({ transform: 'translateX(-100%)' }))
            ])
        ])
    ]
})
export class FormFluxoEstrategiaComponent implements OnDestroy {

    //#region [ Properts ] 	

    dirty: boolean;
    fullScreen: boolean;
    fluxo: Fluxo;
    noSelecionado: FluxoProcesso;
    guidNoSelecionado: string;
    exibeMenuLateral: boolean = false;
    AppConfig: any;

    // Combos 
    listas: Lista[];
    tipos: Array<any>;
    tiposNos: Array<any>;
    cores: Array<string> = ['branco', 'azul', 'azul-escuro', 'verde', 'verde-escuro', 'amarelo', 'laranja', 'vermelho', 'vermelho-escuro', 'roxo', 'cinza', 'preto'];

    querys: FluxoQuery[];
    querysCombo: Array<any> = [];
    estrategias: Array<any> = [];
    estrategiasCombo: Array<any> = [];
    providers: Array<Provider>;
    providersCombo: Array<Provider>;
    listaTemplateExportacao: Array<Template>;
    templatesExportacaoCombo: Array<any>;
    campanhasCombo: Array<any>;
    filtroVazio: string = '{"size":5,"_source":{"includes":[]},"query":{"bool":{"must":[],"must_not":[]}}}';

    // Flow 
    nos: Node[] = [];
    nodes: Node[] = [];
    links: Edge[] = [];
    clusters: ClusterNode[];
    layout: String | Layout = new DagreNodesOnlyLayout(); 	//'dagreCluster';
    layoutSettings: any = { orientation: 'TB' };
    curve: any = shape.curveLinear;							//shape.curveBundle.beta(1)
    draggingEnabled: boolean = true;
    panningEnabled: boolean = true;
    showMiniMap: boolean = true;
    miniMapPosition: MiniMapPosition = MiniMapPosition.UpperLeft;
    update$: Subject<boolean> = new Subject();
    center$: Subject<boolean> = new Subject();
    zoomToFit$: Subject<boolean> = new Subject();
    zoom: any = { 'current': 1, 'min': 0.6, 'max': 1.6 };
    miniMapMaxWidth: number = 130;
    miniMapMaxHeight: number = 130;

    // Subscriptions
    subTipos: Subscription;
    subTiposNos: Subscription;
    subListas: Subscription;
    subFluxo: Subscription;
    subDados: Subscription;
    subQuerys: Subscription;
    subCampanhas: Subscription;

    //#endregion

    //#region [ GETS/SETS ]

    public get erros() {
        if (!this.dirty) return;
        return this.fluxo.validar();
    }

    public get isEdit(): boolean {
        return this.fluxo != undefined && this.fluxo.fluxoId != undefined;
    }

    public get isFluxoProcessoEdit(): boolean {
        return this.noSelecionado != undefined && this.noSelecionado.fluxoProcessoId != undefined && this.noSelecionado.fluxoProcessoId > 0;
    }

    public get isFluxoQuerySelected(): boolean {
        return this.noSelecionado != undefined && this.noSelecionado.fluxoQueryId != undefined && this.noSelecionado.fluxoQueryId != '' && this.noSelecionado.fluxoQueryId != '0';
    }

    public get enviarReutilizar() {
        return !this.noSelecionado.estrategia.marcarDistribuido;
    }

    public set enviarReutilizar(reutilizar) {
        this.noSelecionado.estrategia.marcarDistribuido = !reutilizar;
    }

    public liveErrorNode(id: string, property: string = null, value: string = null) {
        if (this.dirty) {
            var validationResult = this.fluxo.validar();

            if (!validationResult)
                return null;

            let noValidacao = validationResult['noValidacao'];

            if (!noValidacao)
                return null;

            let validateErrors = validationResult["noValidacao"][0].errors;
            let isError: any;



            if (isNullOrEmpty(property) && isNullOrEmpty(value)) {
                isError = Object.keys(validateErrors)
                    .map(f => ({ 'id': validateErrors[f].fluxoProcessoGuid, 'error': validateErrors[f].error }))
                    .find(f => f.id == id);
            } else {

                isError = Object.keys(validateErrors)
                    .map(f => ({ 'id': validateErrors[f].fluxoProcessoGuid, 'error': validateErrors[f].error }))
                    .find((f: any) => {
                        if (property.indexOf('.') === -1) {
                            return f.id == id && f.error[property] == value;
                        } else {
                            let p = property.split('.');
                            return f.id == id && f.error[p[0]][p[1]] == value;
                        }
                    });
            }

            if (isError)
                return true;

            return null;
        }
    }

    public existsExportTemplate() {
        if (!this.listaTemplateExportacao || isNullOrZero(this.noSelecionado.estrategia.providerId))
            return false;

        return this.listaTemplateExportacao.some((i: any) => (i.providerId == this.noSelecionado.estrategia.providerId));
    }

    //#endregion

    @ViewChild('myGraph', { static: false }) public myGraph: GraphComponent; //Graph;
    constructor(
        private route: ActivatedRoute,
        private fluxoService: FluxoService,
        private listaService: ListaService,
        private estrategiaService: EstrategiaService,
        private templateService: TemplateService,
        private providerService: ProviderService,
        private router: Router,
        private customAlertService: CustomAlertService,
        private translate: TranslateService,
        private dialog: MatDialog
    ) {
        this.AppConfig = environment;
        this.fluxo = new Fluxo();
        this.route.params.subscribe(params => this.carregar(params));
    }

    //#region [ Eventos do componente ]

    ngOnDestroy() {
        if (this.subTipos) this.subTipos.unsubscribe();
        if (this.subTiposNos) this.subTiposNos.unsubscribe();
        if (this.subListas) this.subListas.unsubscribe();
        if (this.subDados) this.subDados.unsubscribe();
        if (this.subQuerys) this.subQuerys.unsubscribe();
        if (this.subCampanhas) this.subCampanhas.unsubscribe();
    }

    //#endregion

    //#region [ Dados ]	

    carregar(params: any) {
        this.obterTipos();

        if (!params.id) {
            this.obterListas();
            this.fluxo.fluxoProcesso = [new FluxoProcesso(this.fluxo.fluxoId, null, FluxoProcessoTipo.database, 'azul')];
            return;
        }

        this.subFluxo = this.fluxoService.obterPorId(params.id).subscribe((fluxo: Fluxo) => {
            this.fluxo = fluxo;

            this.obterTiposNos();

            if (this.fluxo.fluxoTipoId == FluxoTipo.circular) {
                this.layout = 'dagreCluster';
                this.layoutSettings = null;
                this.curve = shape.curveBundle.beta(1);
                this.draggingEnabled = false;
                this.miniMapMaxWidth = 260;
                this.miniMapMaxHeight = 50;
            } else {
                this.layout = new DagreNodesOnlyLayout();
                this.layoutSettings = { orientation: 'TB' };
                this.curve = shape.curveLinear;
                this.draggingEnabled = true;
                this.miniMapMaxWidth = 130;
                this.miniMapMaxHeight = 130;
            }

            this.obterListas(this.fluxo.listaId);

            let p1 = this.fluxoService.obterEstrategias(this.fluxo.fluxoId, this.fluxo.listaId);
            let p2 = this.fluxoService.obterFluxosQuerys({ lista: this.fluxo.listaId });
            let p3 = this.providerService.obterProvedorDisponivelPorLista(this.fluxo.listaId);
            let p4 = this.templateService.obterTemplates({ listaId: this.fluxo.listaId, filtroEstrategia: true });
            let p5 = this.fluxoService.obterQueryQuantidadeRegistrosPost(this.fluxo.listaId, this.filtroVazio);


            // Uso o FORKJOIN pois antes de transformar os fluxos em nos já é preciso estar com todos os dados carregados
            this.subDados = forkJoin([p1, p2, p3, p4, p5]).subscribe((results: any) => {
                this.guardarEstrategiasSemFluxo(results[0]);
                this.guardarQuerys(results[1]);
                this.guardarProvedores(results[2]);
                this.guardarTemplateExportacao(results[3]);
                this.guardarQuantidadeDatabase(results[4]);

                this.fluxo.fluxoProcesso.forEach((f: FluxoProcesso) => {
                    if (f.fluxoProcessoTipoId == FluxoProcessoTipo.criacaoEstrategia && f.estrategia) {
                        let fluxoProcessoPai = this.fluxo.fluxoProcesso.find((p: FluxoProcesso) => p.fluxoProcessoGuid == f.fluxoProcessoPaiGuid);
                        let query = this.querys.find((q: FluxoQuery) => q.fluxoQueryId.toString() == fluxoProcessoPai.fluxoQueryId);
                        this.enviarQueryEstrategias(query.fluxoQueryId);
                    }
                });
                this.transformarFluxoNos(this.fluxo.fluxoProcesso, true);
                this.update$.next(true);
                this.zoom.current = 1;
            });
        });
    }

    obterTipos() {
        this.subTipos = this.fluxoService.obterFluxosTipos().subscribe((tipos: any) => (this.tipos = tipos));
    }

    obterTiposNos() {
        this.subTiposNos = this.fluxoService.obterFluxosProcessosTipos(this.fluxo.fluxoTipoId).subscribe((tiposNos: any) => (this.tiposNos = tiposNos));
    }

    obterListas(listaId: number = null) {
        let filtro: FiltroLista = { ignorarErroPesquisa: true } as FiltroLista;
        if (!isNullOrZero(listaId)) filtro.lista = listaId;
        this.subListas = this.listaService.obterLista(filtro).subscribe((listas: Lista[]) => (this.listas = listas));
    }

    guardarEstrategiasSemFluxo(estrategias: Array<any>) {

        this.estrategias = estrategias;

        // Popula o combo com as estratégias que não estão vinculadas a nenhum item
        let vinculadas = this.fluxo.fluxoProcesso.filter((f: FluxoProcesso) => f.ativo).map((m: FluxoProcesso) => m.listaEstrategiaId);
        this.estrategiasCombo = estrategias
            .filter((f: Estrategia) => !vinculadas.some((s: number) => s == f.listaEstrategiaId))
            .map((m: Estrategia) => { return { 'estrategiaId': m.listaEstrategiaId.toString(), 'nome': m.nome } });

        if (!isNullOrEmpty(this.guidNoSelecionado)) {
            let selecionadas = this.fluxo.fluxoProcesso
                .filter((f: FluxoProcesso) => {
                    return (f.fluxoProcessoTipoId == FluxoProcessoTipo.selecaoEstrategia
                        && !isNullOrEmpty(f.listaEstrategiaId)
                        && f.ativo
                        && f.listaEstrategiaId != this.noSelecionado.listaEstrategiaId);
                })
                .map((m: FluxoProcesso) => (m.listaEstrategiaId));

            this.estrategiasCombo = this.estrategias.filter((f: any) => (!selecionadas.some(s => s == f.estrategiaId)));
        }
    }

    guardarQuerys(querys: Array<FluxoQuery>) {
        this.querys = querys;
        this.querysCombo = querys.map((m: FluxoQuery) => { return { 'value': m.fluxoQueryId.toString(), 'label': `${m.nome} (${m.quantidadeRegistros})` } });

        this.fluxo.fluxoProcesso.forEach((fp: FluxoProcesso) => {

            if (fp.fluxoProcessoTipoId == FluxoProcessoTipo.quantidade || fp.fluxoProcessoTipoId == FluxoProcessoTipo.filtro) {
                let q = this.querys.find((q: FluxoQuery) => (q.fluxoQueryId.toString() == fp.fluxoQueryId));
                fp.queryQtdRegistros = q.quantidadeRegistros;
            }
        })
    }

    guardarProvedores(providers: Provider[]) {
        this.providers = providers.map(p => this.providerService.convertObject(p));
        this.providersCombo = this.providers;
    }

    guardarTemplateExportacao(templates: any) {
        this.listaTemplateExportacao = templates.filter((f: any) => (f.listaTemplateCategoriaId == TipoCategoria.exportacao));
    }

    guardarQuantidadeDatabase(quantidade: number) {
        this.fluxo.fluxoProcesso.forEach((f: FluxoProcesso) => {
            if (f.fluxoProcessoTipoId == FluxoProcessoTipo.database)
                f.queryQtdRegistros = (quantidade) ? quantidade : 0;
        });
    }

    populaTemplateCombo(providerId: any) {
        if (!providerId) return;

        this.templatesExportacaoCombo = this.listaTemplateExportacao.filter((f: Template) => (f.providerId == providerId));

        if (this.templatesExportacaoCombo.length === 1) {
            this.noSelecionado.estrategia.listaTemplateExportacaoId = parseInt(this.templatesExportacaoCombo[0].listaTemplateId);

            let provedor = this.providers.find((f: Provider) => f.providerId == this.noSelecionado.estrategia.providerId);

            if (this.noSelecionado.estrategia.listaTemplateExportacaoId && provedor.configuracaoPorCampanha) {
                if (!isNull(provedor.campanha) && provedor.campanha.layoutPorCampanha) {
                    let templateSelecionado = this.listaTemplateExportacao.find(t => t.listaTemplateId == this.noSelecionado.estrategia.listaTemplateExportacaoId.toString());

                    if (!isNullOrZero(templateSelecionado.campanhaId)) {
                        this.noSelecionado.estrategia.campanhaId = templateSelecionado.campanhaId.toString();
                    }
                }
            }
        }
    }

    populaCampanhaCombo() {
        this.subCampanhas = this.providerService
            .obterCampanhasConfiguradas(this.fluxo.listaId, this.noSelecionado.estrategia.providerId)
            .subscribe(campanhas => {

                if (!isNull(campanhas)) {
                    this.campanhasCombo = campanhas.map((m: any) => {
                        m.key = `${m.value} - ${m.key}`;
                        return m;
                    });
                }
            });
    }

    filtrarQuerysSelecionadas() {
        let selecionadas = this.fluxo.fluxoProcesso
            .filter((f: FluxoProcesso) => f.fluxoProcessoGuid != this.noSelecionado.fluxoProcessoGuid && f.fluxoProcessoTipoId == FluxoProcessoTipo.filtro && f.ativo)
            .map(m => m.fluxoQueryId);

        this.querysCombo = this.querys
            .filter((f: FluxoQuery) => !selecionadas.some(s => s == f.fluxoQueryId.toString()))
            .map((m: FluxoQuery) => { return { 'value': m.fluxoQueryId.toString(), 'label': `${m.nome} (${m.quantidadeRegistros})` } });
    }

    //#endregion

    //#region [ Eventos Change ]

    changePadrao(event: any) {
        this.transformarFluxoNos(this.fluxo.fluxoProcesso);
    }

    estrategiaChange(event: any) {
        if (isNullOrEmpty(this.noSelecionado.listaEstrategiaId))
            this.noSelecionado.estrategia = new Estrategia();

        this.transformarFluxoNos(this.fluxo.fluxoProcesso);
    }

    tipoChange(event: any) {

        if (this.fluxo.fluxoTipoId == FluxoTipo.processo || this.fluxo.fluxoTipoId == FluxoTipo.circular) {
            if (this.noSelecionado.fluxoProcessoTipoId == FluxoProcessoTipo.decisao) {
                this.noSelecionado.listaEstrategiaId = null;
                this.noSelecionado.fluxoQueryId = '';
                this.transformarFluxoNos(this.fluxo.fluxoProcesso);
            }

            if (this.noSelecionado.fluxoProcessoTipoId == FluxoProcessoTipo.selecaoEstrategia) {
                this.noSelecionado.descricao = null;
                this.noSelecionado.fluxoQueryId = '';
                this.transformarFluxoNos(this.fluxo.fluxoProcesso);
            }

            if (this.noSelecionado.fluxoProcessoTipoId == FluxoProcessoTipo.quantidade) {
                this.noSelecionado.listaEstrategiaId = null;
                this.noSelecionado.descricao = null;

                this.fluxoService.obterQueryQuantidadeRegistrosPost(this.fluxo.listaId, this.filtroVazio).subscribe((result: number) => {
                    this.noSelecionado.queryQtdRegistros = result;
                    this.transformarFluxoNos(this.fluxo.fluxoProcesso);
                });
            }

            if (this.noSelecionado.fluxoProcessoTipoId == FluxoProcessoTipo.informativo) {
                this.noSelecionado.listaEstrategiaId = null;
                this.noSelecionado.fluxoQueryId = '';
                this.transformarFluxoNos(this.fluxo.fluxoProcesso);
            }
        } else if (this.fluxo.fluxoTipoId == FluxoTipo.cascata) {

            let fluxoProcessoPai = this.fluxo.fluxoProcesso.find((fp: FluxoProcesso) => fp.fluxoProcessoGuid == this.noSelecionado.fluxoProcessoPaiGuid);

            if (this.noSelecionado.fluxoProcessoTipoId == FluxoProcessoTipo.criacaoEstrategia) {
                let provedor = this.providers.find((f: Provider) => f.providerId == this.noSelecionado.estrategia.providerId);

                this.noSelecionado.estrategia.listaEstrategiaTipoId = (!isNull(provedor) && !isNullOrEmpty(!provedor.envio)) ? ((provedor.envio.integracaoTipoId == IntegracaoTipo.Arquivo) ? 2 : 1) : 2;
                this.noSelecionado.estrategia.listaId = this.fluxo.listaId;
                this.noSelecionado.estrategia.estrategiaAcaoCampanhaTipoId = EstrategiaAcaoCampanhaTipo.FluxoImportarCampanha;
                this.noSelecionado.estrategia.quantidadeMaximaExportacaoPct = 100;
                this.noSelecionado.estrategia.medidaMaximaExportacaoPct = true;
                this.noSelecionado.estrategia.envioImediato = true;

                // Habilitar Controle de Retorno
                this.noSelecionado.estrategia.enviarEstrategiaIdExportacao = false;

                if (fluxoProcessoPai.fluxoProcessoTipoId == FluxoProcessoTipo.filtro && !isNullOrZero(fluxoProcessoPai.fluxoQueryId)) {
                    this.noSelecionado.estrategia.filtro = this.noSelecionado.fluxoInformativo.filtro;
                    this.noSelecionado.estrategia.filtroRegras = this.noSelecionado.fluxoInformativo.filtroRegras;
                    this.noSelecionado.estrategia.quantidadeMaximaExportacao = this.noSelecionado.fluxoInformativo.quantidadeMaximaExportacao;
                }
            }

            if (this.noSelecionado.fluxoProcessoTipoId == FluxoProcessoTipo.informativoEstrategia) {
                this.noSelecionado.fluxoInformativo.filtro = '';
                this.noSelecionado.fluxoInformativo.filtroRegras = '';
                this.noSelecionado.fluxoInformativo.quantidadeMaximaExportacao = 0;
                this.noSelecionado.fluxoInformativo.quantidadeMaximaExportacaoPct = 100;

                if (fluxoProcessoPai.fluxoProcessoTipoId == FluxoProcessoTipo.filtro && !isNullOrZero(fluxoProcessoPai.fluxoQueryId)) {
                    this.noSelecionado.fluxoInformativo.filtro = this.noSelecionado.estrategia.filtro;
                    this.noSelecionado.fluxoInformativo.filtroRegras = this.noSelecionado.estrategia.filtroRegras;
                    this.noSelecionado.fluxoInformativo.quantidadeMaximaExportacao = this.noSelecionado.estrategia.quantidadeMaximaExportacao;
                }
            }

            this.transformarFluxoNos(this.fluxo.fluxoProcesso);

        } else {
            this.transformarFluxoNos(this.fluxo.fluxoProcesso);
        }


    }

    filtroChange(event: any) {
        let fluxoQueryId = 0;

        if (!isNullOrEmpty(event.value))
            fluxoQueryId = Number(event.value);

        this.enviarQueryEstrategias(fluxoQueryId);
    }

    providerDeSelect(providerId: any) {
        this.noSelecionado.estrategia.providerId = null;
        this.noSelecionado.estrategia.listaTemplateExportacaoId = null;
        this.noSelecionado.estrategia.campanhaId = null;
    }

    providerChange(providerId: any) {

        if (!providerId) return;
        let provedor = this.providers.find((f: Provider) => f.providerId == this.noSelecionado.estrategia.providerId);

        this.noSelecionado.estrategia.listaEstrategiaTipoId = (provedor.envio.integracaoTipoId == IntegracaoTipo.Arquivo) ? 2 : 1;
        this.noSelecionado.estrategia.listaTemplateExportacaoId = null;
        this.noSelecionado.estrategia.campanhaId = null;
        this.populaTemplateCombo(providerId);

        if (!isNull(provedor.campanha)) {
            if (provedor.configuracaoPorCampanha)
                this.populaCampanhaCombo();
        }
    }

    provedorConfiguracaoPorCampanha() {
        if (!this.noSelecionado.estrategia.providerId) return false;
        let provedor = this.providers.find((f: Provider) => f.providerId == this.noSelecionado.estrategia.providerId);
        return provedor.configuracaoPorCampanha;
    }

    templateExportacaoChange(templateExportacaoId: any) {

        if (isNullOrEmpty(templateExportacaoId)) {
            this.noSelecionado.estrategia.listaTemplateExportacaoId = null;
            this.campanhasCombo = [];
        } else {

            if (!this.noSelecionado.estrategia.providerId) return;
            let provedor = this.providers.find((f: Provider) => f.providerId == this.noSelecionado.estrategia.providerId);
            this.noSelecionado.estrategia.listaEstrategiaTipoId = (provedor.envio.integracaoTipoId == IntegracaoTipo.Arquivo) ? 2 : 1;
            this.noSelecionado.estrategia.campanhaId = null;

            if (templateExportacaoId && provedor.configuracaoPorCampanha) {

                this.populaCampanhaCombo();

                // Se LayoutVariavelPorCampanha deve selecionar automaticamente a campanha no combo campanha e desabilita-lo (this.desabilitarCampoCampanha())
                if (provedor.campanha.layoutPorCampanha) {
                    let templateSelecionado = this.listaTemplateExportacao.find(t => t.listaTemplateId == templateExportacaoId.toString());
                    this.noSelecionado.estrategia.campanhaId = templateSelecionado.campanhaId.toString();
                }
            }
        }
    }

    desabilitarCampoCampanha() {
        if (isNull(this.noSelecionado) || this.noSelecionado.fluxoProcessoTipoId != FluxoProcessoTipo.criacaoEstrategia)
            return false;

        if (isNullOrZero(this.noSelecionado.estrategia.providerId))
            return false;

        let provedor = this.providers.find((f: Provider) => f.providerId == this.noSelecionado.estrategia.providerId);

        return provedor.campanha.layoutPorCampanha;
    }

    //#endregion

    //#region [ Transformar Fluxo em Nos ]

    transformarFluxoNos(fluxoProcessos: FluxoProcesso[], carregamento: boolean = false) {

        this.nodes = new Array<Node>();
        this.links = new Array<Edge>();

        fluxoProcessos.filter((f: FluxoProcesso) => (f.ativo)).forEach((fp: FluxoProcesso) => {

            let data: any;
            let nodeDimension: NodeDimension;

            // FIX: Trocar o tipo do campo que é alterado pelo componente APP-SELECT
            if (typeof (fp.fluxoProcessoTipoId) == "string")
                fp.fluxoProcessoTipoId = parseInt(fp.fluxoProcessoTipoId) as FluxoProcessoTipo;

            switch (fp.fluxoProcessoTipoId) {
                case FluxoProcessoTipo.database:
                    data = this.transformaFluxoNosDatabase(fp, carregamento);
                    data.btnAdd = !fluxoProcessos.some((s: FluxoProcesso) => (s.fluxoProcessoPaiGuid == fp.fluxoProcessoGuid && s.ativo));
                    data.btnRemove = false;
                    nodeDimension = { width: 260, height: 130 };
                    break;
                case FluxoProcessoTipo.decisao:
                    data = this.transformaFluxoDescricao(fp, carregamento);
                    data.btnAdd = true;
                    data.btnRemove = true;
                    nodeDimension = { width: 260, height: 130 };
                    break;
                case FluxoProcessoTipo.selecaoEstrategia:
                    data = this.transformaFluxoSelecaoEstrategia(fp, carregamento);
                    data.btnAdd = true;
                    data.btnRemove = true;

                    if (this.fluxo.fluxoTipoId == FluxoTipo.circular) {
                        let ultimos = this.fluxo.fluxoProcesso.filter((f: FluxoProcesso) => f.fluxoProcessoTipoId == FluxoProcessoTipo.selecaoEstrategia && f.ativo);
                        data.btnAdd = (isNull(ultimos)) ? true : (ultimos.pop().fluxoProcessoGuid == fp.fluxoProcessoGuid);
                    }

                    nodeDimension = { width: 300, height: 160 };
                    break;
                case FluxoProcessoTipo.quantidade:
                    data = this.transformaFluxoQuantidade(fp, carregamento);
                    data.btnAdd = true;
                    data.btnRemove = true;
                    nodeDimension = { width: 180, height: 160 };
                    break;
                case FluxoProcessoTipo.filtro:
                    data = this.transformaFluxoFiltro(fp, carregamento);
                    data.btnAdd = false;
                    data.btnRemove = true;
                    nodeDimension = { width: 260, height: 90 };
                    break;
                case FluxoProcessoTipo.criacaoEstrategia:

                    if (!isNullOrZero(this.AppConfig.estrategiaAgendamentoMinimoMinutos))
                        fp.estrategia.estrategiaAgendamentoMinimoMinutos = this.AppConfig.estrategiaAgendamentoMinimoMinutos;

                    data = this.transformaFluxoCriacaoEstrategia(fp, carregamento);

                    if (this.fluxo.fluxoProcesso.some(s => s.fluxoProcessoPaiGuid == fp.fluxoProcessoGuid && s.ativo)) {
                        data.btnAdd = false;
                    } else {
                        let i = this.fluxo.fluxoProcesso.find(f => f.fluxoProcessoPaiGuid == data.fluxoProcessoPaiGuid && f.ativo);
                        let pai = this.fluxo.fluxoProcesso.find(f => f.fluxoProcessoGuid == fp.fluxoProcessoPaiGuid && f.ativo);
                        data.btnAdd = (i.fluxoProcessoGuid == fp.fluxoProcessoGuid && !isNullOrEmpty(pai.fluxoQueryId));
                    }

                    data.btnRemove = false;
                    nodeDimension = { width: 300, height: 160 };
                    break;

                case FluxoProcessoTipo.informativoEstrategia:
                    data = this.transformaFluxoInformativoEstrategia(fp, carregamento);
                    data.btnAdd = false;
                    data.btnRemove = false;
                    nodeDimension = { width: 300, height: 160 };
                    break;

                case FluxoProcessoTipo.informativo:
                    data = this.transformaFluxoDescricao(fp, carregamento);
                    data.btnAdd = true;
                    data.btnRemove = true;
                    nodeDimension = { width: 300, height: 130 };
                    break;

            }

            // Nodes
            let node: Node = {
                id: fp.fluxoProcessoGuid,
                label: '',
                data: Object.assign({ 'type': fp.fluxoProcessoTipoId, 'cssClass': fp.cor }, data),
                dimension: nodeDimension
            };

            this.nodes.push(node);

            // Links
            if (fp.fluxoProcessoPaiGuid) {
                const edge: Edge = { source: fp.fluxoProcessoPaiGuid, target: fp.fluxoProcessoGuid, label: '', data: {} };
                this.links.push(edge);
            }
        });
    }

    transformaFluxoNosDatabase(fluxoProcesso: FluxoProcesso, carregamento: boolean): any {
        let data: any = { 'listaNome': '', 'listaQtdRegistros': 0 };

        if (this.isEdit) {
            data.listaNome = this.fluxo.lista.nome;
            data.listaQtdRegistros = fluxoProcesso.queryQtdRegistros;
        }

        return data;
    }

    transformaFluxoDescricao(fluxoProcesso: FluxoProcesso, carregamento: boolean): any {
        let data: any = { 'descricao': fluxoProcesso.descricao };
        return data
    }

    transformaFluxoSelecaoEstrategia(fluxoProcesso: FluxoProcesso, carregamento: boolean): any {
        let data: any = {
            'listaEstrategiaId': 0,
            'estrategiaNome': '',
            'estrategiaQtdRegistros': 0,
            'estrategiaQtdMaximaExportacaoRegistros': 0,
            'estrategiaQtdMaximaExportacaoRegistrosPct': 0,
            'provedorNome': '',
            'fluxoProcessoPaiGuid': fluxoProcesso.fluxoProcessoPaiGuid,
            'descricaoExecucao': '',
            'visualizarPendenteExecucao': false,
            'visualizarForcarExecucao': false,
            'erros': false
        };

        if (!isNullOrEmpty(fluxoProcesso.listaEstrategiaId)) {
            if (isNull(fluxoProcesso.estrategia) || isNullOrEmpty(fluxoProcesso.estrategia.nome))
                fluxoProcesso.estrategia = this.estrategias.find((f: any) => f.listaEstrategiaId == fluxoProcesso.listaEstrategiaId);

            data.listaEstrategiaId = fluxoProcesso.listaEstrategiaId;
            data.estrategiaNome = fluxoProcesso.estrategia.nome;
            data.estrategiaQtdRegistros = (isNull(fluxoProcesso.estrategia.quantidadeRegistros)) ? 0 : fluxoProcesso.estrategia.quantidadeRegistros;
            data.estrategiaQtdMaximaExportacaoRegistros = (isNull(fluxoProcesso.estrategia.quantidadeMaximaExportacao)) ? 0 : fluxoProcesso.estrategia.quantidadeMaximaExportacao;
            data.estrategiaQtdMaximaExportacaoRegistrosPct = (isNull(fluxoProcesso.estrategia.quantidadeMaximaExportacaoPct)) ? 0 : fluxoProcesso.estrategia.quantidadeMaximaExportacaoPct;
            data.provedorNome = this.getProviderNome(fluxoProcesso);
            data.descricaoExecucao = execucaoEstrategiaDescricao(fluxoProcesso.estrategia, this.translate);
            data.visualizarPendenteExecucao = (fluxoProcesso.estrategia.pendenteExecucao == false && fluxoProcesso.estrategia.recorrente == false);
            data.visualizarForcarExecucao = (fluxoProcesso.estrategia.forcarExecucao == false && fluxoProcesso.estrategia.recorrente);
        }

        data.erros = (this.liveErrorNode(fluxoProcesso.fluxoProcessoGuid) === true);

        return data
    }

    transformaFluxoQuantidade(fluxoProcesso: FluxoProcesso, carregamento: boolean): any {
        let data: any = { 'queryQtdRegistros': 0 };

        if (!isNullOrEmpty(fluxoProcesso.fluxoQueryId) && fluxoProcesso.fluxoQueryId != '0') {
            let query = this.querys.find((f: FluxoQuery) => f.fluxoQueryId.toString() == fluxoProcesso.fluxoQueryId);
            data.queryQtdRegistros = (isNullOrEmpty(fluxoProcesso.fluxoQueryId)) ? 0 : query.quantidadeRegistros;
        } else {
            data.queryQtdRegistros = fluxoProcesso.queryQtdRegistros;
        }

        return data;
    }

    transformaFluxoFiltro(fluxoProcesso: FluxoProcesso, carregamento: boolean): any {
        let data: any = { 'queryNome': '' };

        if (!isNullOrEmpty(fluxoProcesso.fluxoQueryId) && fluxoProcesso.fluxoQueryId != '0') {
            let query = this.querys.find((f: FluxoQuery) => f.fluxoQueryId.toString() == fluxoProcesso.fluxoQueryId);
            data.queryNome = (isNullOrEmpty(fluxoProcesso.fluxoQueryId)) ? '' : `${query.nome} (${query.quantidadeRegistros})`;
        }

        return data;
    }

    transformaFluxoCriacaoEstrategia(fluxoProcesso: FluxoProcesso, carregamento: boolean): any {

        let data: any = {
            'listaEstrategiaId': 0,
            'estrategiaNome': '',
            'estrategiaQtdRegistros': 0,
            'estrategiaQtdRegistrosPct': 0,
            'provedorNome': '',
            'fluxoProcessoPaiGuid': fluxoProcesso.fluxoProcessoPaiGuid,
            'descricaoExecucao': '',
            'visualizarPendenteExecucao': false,
            'visualizarForcarExecucao': false,
            'erros': false
        };

        data.listaEstrategiaId = fluxoProcesso.listaEstrategiaId;
        data.estrategiaNome = fluxoProcesso.estrategia.nome;
        data.estrategiaQtdRegistros = (isNull(fluxoProcesso.estrategia.quantidadeMaximaExportacao)) ? 0 : fluxoProcesso.estrategia.quantidadeMaximaExportacao;

        if (isNull(fluxoProcesso.estrategia.quantidadeMaximaExportacaoPct))
            fluxoProcesso.estrategia.quantidadeMaximaExportacaoPct = 100;

        data.estrategiaQtdRegistrosPct = fluxoProcesso.estrategia.quantidadeMaximaExportacaoPct;
        data.provedorNome = this.getProviderNome(fluxoProcesso);
        data.descricaoExecucao = execucaoEstrategiaDescricao(fluxoProcesso.estrategia, this.translate);
        data.visualizarPendenteExecucao = (fluxoProcesso.estrategia.pendenteExecucao == false && fluxoProcesso.estrategia.recorrente == false);
        data.visualizarForcarExecucao = (fluxoProcesso.estrategia.forcarExecucao == false && fluxoProcesso.estrategia.recorrente);
        data.erros = (this.liveErrorNode(fluxoProcesso.fluxoProcessoGuid) === true);
        return data
    }

    transformaFluxoInformativoEstrategia(fluxoProcesso: FluxoProcesso, carregamento: boolean): any {

        let data: any = {
            'fluxoInformativoId': 0,
            'informativoQtdRegistros': 0,
            'informativoQtdRegistrosPct': 0,
            'fluxoProcessoPaiGuid': fluxoProcesso.fluxoProcessoPaiGuid,
            'erros': false
        };

        data.informativoQtdRegistros = (isNull(fluxoProcesso.fluxoInformativo.quantidadeMaximaExportacao)) ? 0 : fluxoProcesso.fluxoInformativo.quantidadeMaximaExportacao;

        if (isNull(fluxoProcesso.fluxoInformativo.quantidadeMaximaExportacaoPct))
            fluxoProcesso.fluxoInformativo.quantidadeMaximaExportacaoPct = 100;

        data.informativoQtdRegistrosPct = fluxoProcesso.fluxoInformativo.quantidadeMaximaExportacaoPct;
        data.erros = (this.liveErrorNode(fluxoProcesso.fluxoProcessoGuid) === true);
        return data
    }

    getProviderNome(fluxoProcesso: FluxoProcesso) {
        if (isNullOrZero(fluxoProcesso.estrategia.providerId)) return '';
        let p = this.providers.find((f: Provider) => (f.providerId == fluxoProcesso.estrategia.providerId));
        return (!isNull(p)) ? p.descricao : '';
    };

    visualizarPendenteExecucao(estrategia: Estrategia) {
        if (isNullOrEmpty(estrategia))
            return false;

        return estrategia.pendenteExecucao == false && estrategia.recorrente == false;
    }

    visualizarForcarExecucao(estrategia: Estrategia) {
        if (isNullOrEmpty(estrategia))
            return false;

        return estrategia.forcarExecucao == false && estrategia.recorrente;
    }

    //#endregion

    //#region [ Flow ]

    toggleMenuLateral() {
        this.exibeMenuLateral = (this.exibeMenuLateral === false) ? true : false;
    }

    zoomIn() {
        let z = (Math.round(this.zoom.current * 10) / 10);
        if (z >= this.zoom.max) return;
        this.zoom.current = z + 0.2;
    }

    zoomOut() {
        let z = (Math.round(this.zoom.current * 10) / 10);
        if (z <= this.zoom.min) return;
        this.zoom.current = z - 0.2;
    }

    centerGraph() {
        this.center$.next(true);
    }

    setFullScreen() {
        this.fullScreen = !this.fullScreen;

        setTimeout(() => {
            let divFluxo: HTMLElement = window.document.querySelector('.div-fluxo');

            this.myGraph.width = divFluxo.offsetWidth;
            this.myGraph.height = divFluxo.offsetHeight;
        }, 500);
    }

    selectNodeGraph(fluxoProcessoGuid: string) {
        this.guidNoSelecionado = fluxoProcessoGuid;

        if (!isNullOrEmpty(this.guidNoSelecionado)) {

            this.exibeMenuLateral = true;
            this.noSelecionado = this.fluxo.fluxoProcesso.find((f: FluxoProcesso) => (f.fluxoProcessoGuid == fluxoProcessoGuid));
            this.noSelecionado.fluxoQueryId = (this.noSelecionado.fluxoQueryId) ? this.noSelecionado.fluxoQueryId.toString() : '';

            if (this.noSelecionado.fluxoProcessoTipoId == FluxoProcessoTipo.selecaoEstrategia) {
                let selecionadas = this.fluxo.fluxoProcesso
                    .filter((f: FluxoProcesso) => {
                        return (f.fluxoProcessoTipoId == FluxoProcessoTipo.selecaoEstrategia
                            && !isNullOrEmpty(f.listaEstrategiaId)
                            && f.ativo
                            && f.listaEstrategiaId != this.noSelecionado.listaEstrategiaId);
                    })
                    .map((m: FluxoProcesso) => (m.listaEstrategiaId));

                this.estrategiasCombo = this.estrategias.filter((f: any) => (!selecionadas.some(s => s == f.estrategiaId)));
            }

            if (this.noSelecionado.fluxoProcessoTipoId == FluxoProcessoTipo.filtro) {
                this.filtrarQuerysSelecionadas();
            }

            if (this.noSelecionado.fluxoProcessoTipoId == FluxoProcessoTipo.criacaoEstrategia) {

                if (!isNullOrZero(this.noSelecionado.estrategia.providerId)) {
                    this.populaTemplateCombo(this.noSelecionado.estrategia.providerId);
                    this.populaCampanhaCombo();
                }
            }
        }
    }

    addNodeGraph(fluxoProcessoPaiGuid: string) {
        if (this.fluxo.fluxoTipoId == FluxoTipo.cascata) {
            this.addNodeCascate(fluxoProcessoPaiGuid);

        } else if (this.fluxo.fluxoTipoId == FluxoTipo.circular) {
            this.addNodeCircular(fluxoProcessoPaiGuid);

        } else {
            let fluxoProcesso = new FluxoProcesso(this.fluxo.fluxoId, fluxoProcessoPaiGuid, FluxoProcessoTipo.selecaoEstrategia);
            this.fluxo.fluxoProcesso.push(fluxoProcesso);
        }

        this.transformarFluxoNos(this.fluxo.fluxoProcesso);

        if (this.fluxo.fluxoTipoId != FluxoTipo.circular)
            setTimeout(() => { this.center$.next(true); }, 300);
    }

    addNodeCascate(fluxoProcessoPaiGuid: string) {
        let fluxoProcesso = new FluxoProcesso(this.fluxo.fluxoId, fluxoProcessoPaiGuid, FluxoProcessoTipo.filtro, 'azul');
        let fluxoProcessoE1 = new FluxoProcesso(this.fluxo.fluxoId, fluxoProcesso.fluxoProcessoGuid, FluxoProcessoTipo.criacaoEstrategia, 'azul');
        let fluxoProcessoE2 = new FluxoProcesso(this.fluxo.fluxoId, fluxoProcesso.fluxoProcessoGuid, FluxoProcessoTipo.informativoEstrategia, 'vermelho');

        let provedorE1 = this.providers.find((f: Provider) => f.providerId == fluxoProcessoE1.estrategia.providerId);
        //let provedorE2 = this.providers.find((f: Provider) => f.providerId == fluxoProcessoE2.estrategia.providerId);

        //#region [ Card I ]

        fluxoProcessoE1.estrategia.listaEstrategiaTipoId = (!isNull(provedorE1) && !isNullOrEmpty(!provedorE1.envio)) ? ((provedorE1.envio.integracaoTipoId == IntegracaoTipo.Arquivo) ? 2 : 1) : 2;
        fluxoProcessoE1.estrategia.listaId = this.fluxo.listaId;
        fluxoProcessoE1.estrategia.estrategiaAcaoCampanhaTipoId = EstrategiaAcaoCampanhaTipo.FluxoImportarCampanha;
        fluxoProcessoE1.estrategia.quantidadeMaximaExportacaoPct = 100;
        fluxoProcessoE1.estrategia.medidaMaximaExportacaoPct = true;
        fluxoProcessoE1.estrategia.envioImediato = true;

        //Habilitar Controle de Retorno
        fluxoProcessoE1.estrategia.enviarEstrategiaIdExportacao = false;

        //#endregion

        //#region [ Card II ]

        fluxoProcessoE2.fluxoInformativo.fluxoProcessoId = 0;
        fluxoProcessoE2.fluxoInformativo.filtro = '';
        fluxoProcessoE2.fluxoInformativo.filtroRegras = '';
        fluxoProcessoE2.fluxoInformativo.quantidadeMaximaExportacao = 0;
        fluxoProcessoE2.fluxoInformativo.quantidadeMaximaExportacaoPct = 100;

        // fluxoProcessoE2.estrategia.listaEstrategiaTipoId = (!isNull(provedorE2) && !isNullOrEmpty(!provedorE2.envio)) ? ((provedorE2.envio.integracaoTipoId == IntegracaoTipo.Arquivo) ? 2 : 1) : 2;
        // fluxoProcessoE2.estrategia.listaId = this.fluxo.listaId;
        // fluxoProcessoE2.estrategia.estrategiaAcaoCampanhaTipoId = EstrategiaAcaoCampanhaTipo.FluxoImportarCampanha;
        // fluxoProcessoE2.estrategia.quantidadeMaximaExportacaoPct = 100;
        // fluxoProcessoE2.estrategia.medidaMaximaExportacaoPct = true;
        // fluxoProcessoE2.estrategia.envioImediato = true;

        // // Distribuir e reutilizar
        // fluxoProcessoE2.estrategia.marcarDistribuido = false;

        // //Habilitar Controle de Retorno
        // fluxoProcessoE2.estrategia.enviarEstrategiaIdExportacao = false;

        //#endregion

        this.fluxo.fluxoProcesso.push(fluxoProcesso);
        this.fluxo.fluxoProcesso.push(fluxoProcessoE1);
        this.fluxo.fluxoProcesso.push(fluxoProcessoE2);
    }

    addNodeCircular(fluxoProcessoPaiGuid: string) {
        let fluxoProcesso = new FluxoProcesso(this.fluxo.fluxoId, fluxoProcessoPaiGuid, FluxoProcessoTipo.selecaoEstrategia, 'azul');
        this.fluxo.fluxoProcesso.push(fluxoProcesso);

        this.fluxo.fluxoProcesso.forEach((fp: FluxoProcesso) => {
            if (fp.fluxoProcessoTipoId == FluxoProcessoTipo.database && fp.ativo)
                fp.fluxoProcessoPaiGuid = fluxoProcesso.fluxoProcessoGuid;
        });
    }

    removeNodeGraph(fluxoProcessoGuid: string) {

        this.customAlertService
            .showWithConfiguration({
                title: "telaFluxo.fluxo",
                text: "telaFluxo.confirmacaoRemoverNo",
                icon: "success",
                showCancelButton: true,
                confirmButtonText: "telaPadrao.sim",
                cancelButtonText: "telaPadrao.nao"
            })
            .then(data => {

                if (data.dismiss == swal.DismissReason.cancel)
                    return;

                if (data) {

                    this.noSelecionado = null;
                    this.guidNoSelecionado = null;
                    this.removeNode(fluxoProcessoGuid);

                    if (this.fluxo.fluxoTipoId == FluxoTipo.circular) {
                        let pai = this.fluxo.fluxoProcesso.find(f => f.fluxoProcessoGuid == fluxoProcessoGuid).fluxoProcessoPaiGuid;
                        this.fluxo.fluxoProcesso.forEach((fp: FluxoProcesso) => {
                            if (fp.fluxoProcessoTipoId == FluxoProcessoTipo.database && fp.ativo)
                                fp.fluxoProcessoPaiGuid = pai;
                        });
                    }

                    for (var i = 0; i < this.fluxo.fluxoProcesso.length; i++) {
                        if (isNullOrZero(this.fluxo.fluxoProcesso[i].fluxoProcessoId) && this.fluxo.fluxoProcesso[i].ativo == false)
                            this.fluxo.fluxoProcesso.splice(i, 1);
                    };

                    this.transformarFluxoNos(this.fluxo.fluxoProcesso);
                }
            });
    }

    removeNode(fluxoProcessoGuid: string, paiExcluido: boolean = false) {
        this.fluxo.fluxoProcesso.forEach((f: FluxoProcesso) => {
            if (f.fluxoProcessoGuid == fluxoProcessoGuid) {
                f.ativo = false;

                // Verifica se tem filhos
                this.fluxo.fluxoProcesso.filter((c: FluxoProcesso) => (c.fluxoProcessoPaiGuid == f.fluxoProcessoGuid && c.fluxoProcessoTipoId != FluxoProcessoTipo.database))
                    .forEach((c: FluxoProcesso) => {
                        this.removeNode(c.fluxoProcessoGuid);
                    });
            }
        });
    }

    setColorNode(color: string) {
        this.fluxo.fluxoProcesso.forEach((f: FluxoProcesso) => {
            if (f.fluxoProcessoGuid == this.guidNoSelecionado)
                f.cor = color;
        });

        this.nodes.forEach((f: Node) => {
            if (f.id == this.guidNoSelecionado)
                f.data.cssClass = color;
        });
    }

    exibirPorTipoNo(tipos: FluxoProcessoTipo[]) {
        if (!this.noSelecionado)
            return false;

        return (tipos.some(s => s == this.noSelecionado.fluxoProcessoTipoId));
    }

    exibirPorTipoNoCampoTipoNo(tipos: FluxoProcessoTipo[]) {
        if (!this.noSelecionado)
            return false;

        if (this.fluxo.fluxoTipoId == FluxoTipo.cascata && this.noSelecionado.fluxoProcessoTipoId == FluxoProcessoTipo.criacaoEstrategia) {
            let filhos = this.fluxo.fluxoProcesso.filter((f: FluxoProcesso) => f.fluxoProcessoPaiGuid == this.noSelecionado.fluxoProcessoPaiGuid);
            return (filhos.length <= 0) ? false : (filhos[0].fluxoProcessoGuid == this.noSelecionado.fluxoProcessoGuid);
        }

        return (tipos.some(s => s == this.noSelecionado.fluxoProcessoTipoId));
    }

    tituloPorTipoNo(tipo: FluxoProcessoTipo): string {
        if (typeof (tipo) == "string")
            tipo = parseInt(tipo) as FluxoProcessoTipo
        //tipo = FluxoProcessoTipo[FluxoProcessoTipo[tipo]];

        switch (tipo) {
            case FluxoProcessoTipo.database:
                return 'telaFluxo.noDados';
            case FluxoProcessoTipo.decisao:
                return 'telaFluxo.noDecisao';
            case FluxoProcessoTipo.selecaoEstrategia:
                return 'telaFluxo.noSelecaoEstrategia';
            case FluxoProcessoTipo.quantidade:
                return 'telaFluxo.noQuantidade';
            case FluxoProcessoTipo.filtro:
                return 'telaFluxo.noFiltro';
            case FluxoProcessoTipo.criacaoEstrategia:
                return 'telaFluxo.noCriacaoEstrategia';
            case FluxoProcessoTipo.informativoEstrategia:
                return 'telaFluxo.noInformativoEstrategia';
        }
    }

    //#endregion

    //#region [ Estratégias ] 	

    redirecionarEstrategia() {
        if (isNullOrZero(this.noSelecionado.fluxoProcessoId)) return;
        this.router.navigate([`/app/fluxo/${this.fluxo.fluxoId}/${this.noSelecionado.fluxoProcessoId}/${this.fluxo.listaId}/estrategia/create`]);
    }

    ativarPendenteExecucao(event: any) {
        this.customAlertService.confirmationMessage("telaEstrategia.confirmarReativacao")
            .then(() => {
                this.estrategiaService.ativarPendenteExecucao(event.listaEstrategiaId)
                    .subscribe(() => {
                        // Muda a propriedade PendenteExecucao da estrategia para true
                        this.fluxo.fluxoProcesso.forEach((fp: FluxoProcesso) => {
                            if (fp.fluxoProcessoGuid == event.guidId)
                                fp.estrategia.pendenteExecucao = true;
                        });

                        this.transformarFluxoNos(this.fluxo.fluxoProcesso);
                    });
            });
    }

    forcarExecucao(event: any) {
        this.customAlertService.confirmationMessage("telaEstrategia.forcarExecucao")
            .then(() => {
                this.estrategiaService.forcarExecucao(event.listaEstrategiaId)
                    .subscribe(() => {
                        // Muda a propriedade ForcarExecucao da estrategia para true
                        this.fluxo.fluxoProcesso.forEach((fp: FluxoProcesso) => {
                            if (fp.fluxoProcessoGuid == event.guidId)
                                fp.estrategia.forcarExecucao = true;
                        });

                        this.transformarFluxoNos(this.fluxo.fluxoProcesso);
                    });
            });
    }

    enviarQueryEstrategias(fluxoQueryId: number) {
        let filtros = this.fluxo.fluxoProcesso.filter(f => { return f.fluxoProcessoTipoId == FluxoProcessoTipo.filtro });
        let filtrosRegras: ESBuilderData = new ESBuilderData();
        let dados: Array<any> = [];
        let consultas: Array<Observable<any>> = new Array<Observable<any>>();

        this.fluxo.fluxoProcesso.forEach((fp: FluxoProcesso) => {

            if (fp.fluxoProcessoTipoId == FluxoProcessoTipo.filtro && fp.ativo) {
                // Obtem a query do filtro
                let query = this.querys.find((f: FluxoQuery) => (f.fluxoQueryId.toString() == fp.fluxoQueryId));

                // Recupera as regras da query
                let regras = JSON.parse(query.filtroRegras) as ESBuilderData;

                // Muda os filtros ACIMA para NÃO reverter a query
                filtrosRegras.rules.forEach((i: ESBuilderRules) => {
                    i.reverse = false;
                    if (i.rules)
                        i.rules.forEach((i: ESBuilderRules) => (i.reverse = false));
                });

                filtrosRegras.includeIntegratedRegister = regras.includeIntegratedRegister;

                // Muda os filtros para reverter a query
                regras.rules.forEach((i: ESBuilderRules) => {
                    i.reverse = true;
                    if (i.rules)
                        i.rules.forEach((i: ESBuilderRules) => (i.reverse = true));
                });

                // Trato o primeiro item da nova regra para se AND
                if (!isObjectEmpty(filtrosRegras.rules)) {
                    regras.rules[0].notReverseCondition = true;
                    regras.rules[0].condition = ESBuilderRulesConditions.and;
                }

                //Guarda as regras no objeto filtrosRegras que será geral para ir cascateando as regras em todos os filtros
                filtrosRegras.rules.push(...regras.rules);

                // Converte as regras em filtro do ElasticSearch
                let filtro: any = ModelToQuery.convert(filtrosRegras);
                let regrasStr = JSON.stringify(filtrosRegras);
                let filtroStr = JSON.stringify(filtro);

                // Inverte as regras e gera o filtro Inverso do ElasticSearch
                let regrasInverso: any = ModelToModelReverse.convert(filtrosRegras);

                // Converte as regras invertidas em filtro do ElasticSearch
                let filtroInverso: any = ModelToQuery.convert(regrasInverso);
                let regrasInversoStr = JSON.stringify(regrasInverso);
                let filtroInversoStr = JSON.stringify(filtroInverso);

                // Prepara as consultas para saber a quantidade de itens em cada uma das duas estratégias filhas desse filtro
                dados.push({
                    'filtroGuid': fp.fluxoProcessoGuid,
                    'filtroStr': filtroStr,
                    'regrasStr': regrasStr,
                    'filtroInversoStr': filtroInversoStr,
                    'regrasInversoStr': regrasInversoStr
                });
                consultas.push(this.fluxoService.obterQueryQuantidadeRegistrosPost(this.fluxo.listaId, filtroStr));
                consultas.push(this.fluxoService.obterQueryQuantidadeRegistrosPost(this.fluxo.listaId, filtroInversoStr));
            }
        });

        forkJoin(consultas).subscribe((results: any) => {
            let idx = 0;
            let idxInverso = 1;
            dados.forEach((f: any) => {
                let c = 0;
                this.fluxo.fluxoProcesso.forEach((fp: FluxoProcesso) => {
                    if (fp.fluxoProcessoGuid == f.filtroGuid && fp.ativo)
                        fp.queryQtdRegistros = ((results[idx]) ? results[idx] : 0);

                    if (fp.fluxoProcessoPaiGuid == f.filtroGuid && fp.ativo) {
                        if (fp.fluxoProcessoTipoId == FluxoProcessoTipo.criacaoEstrategia) {
                            fp.estrategia.filtro = (c == 0) ? f.filtroStr : f.filtroInversoStr;
                            fp.estrategia.filtroRegras = (c == 0) ? f.regrasStr : f.regrasInversoStr;
                            fp.estrategia.quantidadeMaximaExportacao = (c == 0) ? ((results[idx]) ? results[idx] : 0) : ((results[idxInverso]) ? results[idxInverso] : 0);
                        }
                        if (fp.fluxoProcessoTipoId == FluxoProcessoTipo.informativoEstrategia) {
                            fp.fluxoInformativo.filtro = (c == 0) ? f.filtroStr : f.filtroInversoStr;
                            fp.fluxoInformativo.filtroRegras = (c == 0) ? f.regrasStr : f.regrasInversoStr;
                            fp.fluxoInformativo.quantidadeMaximaExportacao = (c == 0) ? ((results[idx]) ? results[idx] : 0) : ((results[idxInverso]) ? results[idxInverso] : 0);
                        }
                        c++;
                    }

                    if (c > 1) return;
                });

                idx = idx + 2;
                idxInverso = idxInverso + 2;
            });

            this.transformarFluxoNos(this.fluxo.fluxoProcesso);
        });
    }

    verificarTemplateCampoRetornoInvalido(estrategia: Estrategia): boolean {
        if (this.templatesExportacaoCombo == null)
            return false;

        var templateSelecionado = this.templatesExportacaoCombo.find(t => t.listaTemplateId == estrategia.listaTemplateExportacaoId);

        return templateSelecionado == null ? false : (templateSelecionado.possuiCampoRetornoInvalido == null ? false : templateSelecionado.possuiCampoRetornoInvalido);
    }

    //#endregion

    //#region [ Modais ]

    modalAgendamento() {

        // campo dos radios
        if (this.noSelecionado.estrategia.recorrente == false) {
            this.noSelecionado.estrategia.dataInicio = null;
            this.noSelecionado.estrategia.horaInicio = null;
            this.noSelecionado.estrategia.dataTermino = null;
            this.noSelecionado.estrategia.horaTermino = null;
            this.noSelecionado.estrategia.intervalo = null;
            this.noSelecionado.estrategia.diaSemana = null;
            return;
        }

        var agendamentoAnterior = {
            dataInicio: this.noSelecionado.estrategia.dataInicio,
            horaInicio: this.noSelecionado.estrategia.horaInicio,
            dataTermino: this.noSelecionado.estrategia.dataTermino,
            horaTermino: this.noSelecionado.estrategia.horaTermino,
            intervalo: this.noSelecionado.estrategia.intervalo,
            diaSemana: this.noSelecionado.estrategia.diaSemana
        };
        const agendamentoRef = this.dialog.open(FluxoAgendamentoEstrategiaComponent, {
            width: "800px",
            height: "720px",
            data: { estrategia: this.noSelecionado.estrategia, dirty: this.dirty }
        });

        agendamentoRef.afterClosed().subscribe((estrategia: Estrategia) => {
            this.dirty = true;
            if (!estrategia) return;

            Object.assign(this.noSelecionado.estrategia, estrategia);
            this.transformarFluxoNos(this.fluxo.fluxoProcesso);
        });
    }

    modalQuery(novaQuery: boolean) {
        let id = 0;

        if (!novaQuery) {
            id = (this.isFluxoQuerySelected) ? Number(this.noSelecionado.fluxoQueryId) : 0;

            if (id == 0) {
                this.customAlertService.show("telaFluxo.fluxo", "telaFluxo.informeFluxoEditar", "error");
                return;
            }
        }

        let lista: Lista = this.listas.find(f => f.listaId == this.fluxo.listaId);

        let dialogRef = this.dialog.open(FluxoQueryModalComponent, {
            hasBackdrop: true,
            width: "70%",
            height: "800px",
            data: { 'fluxoQueryId': id, 'listaId': this.fluxo.listaId, 'listaTemplateId': lista.listaTemplateId, 'listaAlias': lista.alias, }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!result) return;

            this.noSelecionado.fluxoQueryId = result.fluxoQueryId.toString();

            this.subQuerys = this.fluxoService
                .obterFluxosQuerys({ lista: this.fluxo.listaId })
                .subscribe((querys: Array<FluxoQuery>) => {
                    this.guardarQuerys(querys);
                    this.filtrarQuerysSelecionadas();

                    if (this.noSelecionado.fluxoProcessoTipoId == FluxoProcessoTipo.filtro) {
                        let fluxoQueryId = 0;

                        if (!isNullOrEmpty(this.noSelecionado.fluxoQueryId))
                            fluxoQueryId = Number(this.noSelecionado.fluxoQueryId)

                        this.enviarQueryEstrategias(fluxoQueryId);
                    } else {
                        this.transformarFluxoNos(this.fluxo.fluxoProcesso);
                    }
                });
        });
    }

    modalVisualizarFiltros(fluxoProcessoGuid: string) {

        let model: FluxoProcesso = this.fluxo.fluxoProcesso.find(f => f.fluxoProcessoGuid == fluxoProcessoGuid);
        let filtro: string = (model.fluxoProcessoTipoId == FluxoProcessoTipo.criacaoEstrategia) ? model.estrategia.filtro : model.fluxoInformativo.filtro;
        let filtroRegras: string = (model.fluxoProcessoTipoId == FluxoProcessoTipo.criacaoEstrategia) ? model.estrategia.filtroRegras : model.fluxoInformativo.filtroRegras;

        let lista: Lista = this.listas.find(f => f.listaId == this.fluxo.listaId);

        let dialogRef = this.dialog.open(FluxoVisualizarFiltroModalComponent, {
            hasBackdrop: true,
            width: "70%",
            height: "800px",
            data: { 'listaId': this.fluxo.listaId, 'listaTemplateId': lista.listaTemplateId, 'listaAlias': lista.alias, 'dataRules': filtroRegras, 'query': filtro }
        });

        dialogRef.afterClosed().subscribe(result => { });
    }

    modalVisualizarFiltrosSimples(event: any) {

        if (isNullOrEmpty(event.listaEstrategiaId)) {
            this.customAlertService.show("telaFluxo.fluxo", "telaFluxo.estrategiaNaoInformada", "success");
            return;
        }

        let dialogRef = this.dialog.open(FluxoVisualizarFiltroSimplesModalComponent, {
            hasBackdrop: true,
            //closeOnNavigation: true,
            width: "70%",
            height: "800px",
            data: { estrategiaId: event.listaEstrategiaId }
        });

        dialogRef.afterClosed().subscribe(result => { });
    }

    mostrarPreview(fluxoProcessoGuid: string) {
        let fluxoProcesso = this.fluxo.fluxoProcesso.find(f => f.fluxoProcessoGuid == fluxoProcessoGuid);
        let lista: Lista = this.listas.find(f => f.listaId == this.fluxo.listaId);
        let queryStr: string = this.filtroVazio;

        switch (fluxoProcesso.fluxoProcessoTipoId) {
            case FluxoProcessoTipo.selecaoEstrategia:
            case FluxoProcessoTipo.criacaoEstrategia:
            case FluxoProcessoTipo.informativoEstrategia:
                queryStr = fluxoProcesso.estrategia.filtro;
                break;
            case FluxoProcessoTipo.quantidade:
                let qq = this.querys.find(f => f.fluxoQueryId.toString() == fluxoProcesso.fluxoQueryId);
                if (!isNull(qq))
                    queryStr = qq.filtro;
                break;
            case FluxoProcessoTipo.filtro:
                let qf = this.querys.find(f => f.fluxoQueryId.toString() == fluxoProcesso.fluxoQueryId);
                queryStr = (!isNull(qf)) ? qf.filtro : '';
                break;
            default:
                break;
        }

        if (isNullOrEmpty(queryStr)) {
            this.customAlertService.show("telaFluxo.fluxo", "telaFluxo.filtroNaoInformado", "success");
            return;
        }

        let query: any = JSON.parse(queryStr);
        query["size"] = 10;

        this.dialog.open(PreviewDadosEstrategiaComponent, {
            height: "700px",
            width: "80%",
            hasBackdrop: true,
            data: {
                templateId: lista.listaTemplateId,
                lista: lista.alias,
                listaId: lista.listaId,
                query: query
            }
        });
    }

    //#endregion

    //#region [ Salvar ]

    salvar() {
        this.dirty = true;

        if (this.fluxo.validar()) {
            console.log(this.fluxo.validar());
            this.customAlertService.show("telaFluxo.fluxo", "telaPadrao.camposInvalidos", "error");
            this.transformarFluxoNos(this.fluxo.fluxoProcesso);
            return;
        }

        let sucesso = (response: any) => {
            if (this.isEdit) {
                this.customAlertService.show("telaFluxo.fluxo", "telaPadrao.sucessoSalvar", "success");
                this.router.navigate(["/app/fluxo/"]);
            } else {
                this.router.navigate(["/app/fluxo", response["data"]]);
            }
        };

        let erro = () => {
            this.customAlertService.show("telaFluxo.fluxo", "telaPadrao.erroSalvar", "error");
        };

        if (!this.fluxo.fluxoId)
            return this.fluxoService.criar(this.fluxo).subscribe(sucesso, erro);

        this.fluxoService.atualizar(this.fluxo).subscribe(sucesso, erro);
    }

    //#endregion
}