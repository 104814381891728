import { ListaTemplateItem } from "./lista-template-item";
import { CampoIntegracao } from "./campo-integracao";
import { ListaTemplateItemExportacao } from "./lista-template-item-exportacao";
import { TemplateService } from "../service/template.service";
import { Provider } from "./provider";
import { ListaTemplateExportacaoParametrosUrl } from "./lista-template-exportacao-parametros-url";
import { isNull, isNullOrEmpty } from "../utils";

const validate = require("validate.js");

export enum TipoTemplate {
	posicional = 1,
	delimitado = 2
}

export enum TipoCategoria {
	importacao = 1,
	exportacao = 2
}

export enum TipoConfiguracao {
	manual = 1,
	automatica = 2
}

export class Template {
	listaTemplateId: string;
	listaTemplateTipoId: TipoTemplate;
	//listaTemplateTipoId: TipoTemplate;

	public get listaTemplateTipo() {
		return this.listaTemplateTipoId.toString();
	}

	public set listaTemplateTipo(value: any) {
		this.listaTemplateTipoId = value;
	}

	listaTemplateCategoriaId: TipoCategoria;
	listaTemplateImportacaoId: number;
	nome: string;
	delimitador: string;
	configuracao: TipoConfiguracao;
	possuiHeader: boolean;
	listaTemplateItem: Array<ListaTemplateItem>;

	listaTemplateItemExportacao: Array<ListaTemplateItemExportacao>;
	identificadorAlias: string;
	identificadorTelefone1: string | null;
	identificadorTelefone2: string | null;
	identificadorTelefone: string;

	_ativarCaminhoDestino: boolean;
	caminhoDestino: string;
	providerId: number;
	providerNome: string;
	provider: Provider;
	campanhaId: number;
	carteiraId: number;
	updateCarteira: boolean;

	quebraLinhas: boolean;
	quebraQuantidadeLinhas: number;

	listaTemplateExportacaoParametrosUrl: Array<ListaTemplateExportacaoParametrosUrl>;

	possuiCampoRetornoInvalido: boolean;

	ignoraHeader: boolean;


	public get listaTemplateItemAtiva() {
		if (this.listaTemplateCategoriaId == TipoCategoria.importacao) {
			return this.listaTemplateItem
				.filter(listaTemplate => listaTemplate.ativo)
				.sort((a, b) => a.ordem - b.ordem)
				.map((templateItem, index) => {
					templateItem.ordem = index;
					return templateItem;
				});
		}
		else {
			return this.listaTemplateItemExportacao
				.filter(listaTemplate => listaTemplate.ativo)
				.sort((a, b) => a.ordem - b.ordem)
				.map((templateItem, index) => {
					templateItem.ordem = index;
					let campoIntegracao = new CampoIntegracao(templateItem.alias, templateItem.listaTemplateItemId);
					let item = new ListaTemplateItem(templateItem.alias, campoIntegracao, templateItem.ordem, templateItem.tamanho, false);
					item.virtualId = templateItem.virtualId;
					item.tipoCampoExportacao = templateItem.tipoCampoExportacao;
					if (templateItem.conteudo)
						item.conteudoCustomizado = templateItem.conteudo.length > 100 ? templateItem.conteudo.substring(0, 100) + '...' : templateItem.conteudo;
					return item;
				});
		}
	}

	public get ativarCaminhoDestino() {
		if (this._ativarCaminhoDestino == undefined || this._ativarCaminhoDestino == null) {
			let ativo: boolean = (this.caminhoDestino != undefined && this.caminhoDestino != null && this.caminhoDestino != '');
			this._ativarCaminhoDestino = ativo;
		}
		return this._ativarCaminhoDestino;
	}

	public set ativarCaminhoDestino(value: boolean) {
		if (!value)
			this.caminhoDestino = null;

		this._ativarCaminhoDestino = value;
	}


	constructor(nome?: string, id?) {
		this.listaTemplateId = id;
		this.nome = nome;
		this.listaTemplateItem = [];
		this.listaTemplateItemExportacao = [];
		this.listaTemplateTipoId = TipoTemplate.delimitado;
		this.listaTemplateCategoriaId = TipoCategoria.importacao;
		this.configuracao = TipoConfiguracao.manual;
		this.delimitador = ";";
		this.caminhoDestino = "";
		this.possuiHeader = true;
		this.quebraQuantidadeLinhas = 0;
		//this.templateColunaQuebra = [];
		this.ignoraHeader = false;



		validate.validators.diretorioVirtual = (value: any, options: any, key: any, attributes: any) => {
			var validRegex = /^(\\\\[^/\\\]\[":;|<>+=,?* _]+\\[^/\\\]\[":;|<>+=,?*]+)((?:\\[^\\/:*?"<>|]+)*\\?)$/i

			if (!validRegex.test(value))
				return "^caminhoInvalido";
		};

		validate.validators.diretorioExiste = (value: any, options: any, key: any, attributes: any) => {
			let service = options.service;

			return new validate.Promise(function (resolve, reject) {

				service.validarDiretorio({ 'path': value })
					.subscribe((diretorio: any) => {
						if (diretorio.exists == false)
							reject({ "diretorio": ["diretorioInexistente"] });

						resolve({});
					});
			});
		};
	}

	static fromRaw(rawTemplate): Template {
		let template = Object.assign(new Template(), rawTemplate);
		template.provider = Object.assign(new Provider(), template.provider);
		return template;
	}

	static fromRawSemProvider(rawTemplate): Template {
		let template = Object.assign(new Template(), rawTemplate);
		return template;
	}

	obterPosicaoCampo(campo) {
		if (this.listaTemplateCategoriaId == TipoCategoria.importacao)
			return this.listaTemplateItemAtiva.indexOf(campo);
		else {
			let campoExportacao = this.listaTemplateItemExportacao.find(f => f.listaTemplateItemId == campo.listaCampoTipoId);
			return campoExportacao.ordem;
		}
	}

	mudarCampoOrdem(campo: ListaTemplateItem, mudanca) {
		let posicao = campo.ordem + mudanca;

		if (this.listaTemplateCategoriaId == TipoCategoria.importacao) {
			this.listaTemplateItemAtiva.forEach(campoLista => {
				if (mudanca > 0 && campoLista.ordem >= posicao) campoLista.ordem--;
				if (mudanca < 0 && campoLista.ordem <= posicao) campoLista.ordem++;
			});
			campo.ordem = posicao;
		}
		else {
			this.listaTemplateItemExportacao.forEach(campoLista => {
				if (mudanca > 0 && campoLista.ordem >= posicao) campoLista.ordem--;
				if (mudanca < 0 && campoLista.ordem <= posicao) campoLista.ordem++;
			});
			let campoExportacao = this.listaTemplateItemExportacao.find(f => f.listaTemplateItemId == campo.listaCampoTipoId)
			campoExportacao.ordem = posicao;
		}
	}

	excluirCampo(campo) {
		if (this.listaTemplateCategoriaId == TipoCategoria.importacao)
			campo.ativo = false;
		else {
			let campoExportacao = campo.tipoCampoExportacao == 1
				? this.listaTemplateItemExportacao.find(f => f.alias == campo.alias && f.ordem == campo.ordem)
				: this.listaTemplateItemExportacao.find(f => f.listaTemplateItemId == campo.listaCampoTipoId);
			campoExportacao.ativo = false;
		}

	}

	validar(camposIntegracao: CampoIntegracao[], exibeIdenficadorExtra: boolean = false) {

		let templateImportacao = this.listaTemplateCategoriaId == TipoCategoria.importacao;

		let regras = {
			'nome': { presence: { allowEmpty: false } },
			'carteiraId': { presence: templateImportacao },
			'identificadorAlias': { presence: templateImportacao },
			'caminhoDestino': {}
		};

		if (this)

			if (this.quebraLinhas) {
				regras['identificadorTelefone1'] = { presence: { allowEmpty: false } };
				if (exibeIdenficadorExtra)
					regras['identificadorTelefone2'] = { presence: { allowEmpty: false } };
			}

		let validationResult = validate.validate(this, regras);

		let validationResultSetError = (prop: string, error: string) => {
			if (isNull(validationResult))
				validationResult = {};

			validationResult[prop] = [error];
		};

		if (this.listaTemplateTipoId == TipoTemplate.delimitado && !this.delimitador)
			validationResultSetError("delimitador", "Campo Obrigatório");

		if (!this.listaTemplateCategoriaId)
			validationResultSetError("listaTemplateCategoriaId", "Campo Obrigatório");



		if (this.listaTemplateCategoriaId == TipoCategoria.exportacao) {
			if (!this.providerId)
				validationResultSetError("providerId", "Campo Obrigatório");

			if (this.listaTemplateImportacaoId == 0 || !this.listaTemplateImportacaoId)
				validationResultSetError("listaTemplateImportacaoId", "Campo Obrigatório");

			if (this.providerId == 2 && this.ativarCaminhoDestino) {
				let caminhoDestino = validate.validate(this, {
					caminhoDestino: {
						presence: { allowEmpty: false, message: '^campoObrigatorio' },
						diretorioVirtual: {}
					}
				});

				if (caminhoDestino != null)
					validationResultSetError("caminhoDestino", caminhoDestino["caminhoDestino"]);
			}

			if (this.provider.envio.parametrosUrl) {
				if (this.listaTemplateExportacaoParametrosUrl.some(s => isNullOrEmpty(s.listaTemplateItemId) && s.ativo))
					validationResultSetError("listaTemplateExportacaoParametrosUrl", "Campo Obrigatório");
			}
		}



		return validationResult;
	}

	validarDiretorioExisteAsync(templateService: TemplateService) {

		if (this.providerId == 2 && this.ativarCaminhoDestino) {

			var validacao: any = {
				caminhoDestino: {
					diretorioExiste: { service: templateService }
				}
			};
		}
		return validate.async(this, validacao);
	}
}
