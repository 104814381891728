import { Component, OnInit, Inject } from "@angular/core";
import { TemplateService } from "../../common/service/template.service";
import { obterOperacaoAgregacaoMapeada } from "./operacao-agregacao";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { DefaultValuesService } from "../../common/service/default-values.service";
import { FormatacaoCampo } from "../../common/model/formatacao-campo";

@Component({
	selector: "app-card-configuravel",
	templateUrl: "./card-configuravel.component.html",
	providers: [
		{ provide: TemplateService, useClass: TemplateService },
		{ provide: DefaultValuesService, useClass: DefaultValuesService }
	]
})
export class CardConfiguravelComponent implements OnInit {
	camposMapeados: any[] = [];
	listaCamposAgrupadoresPossiveis: any[] = [];

	_campoSelecionado: string;
	_operacaoSelecionada: number;
	listaFormatacaoCampo: Array<any>;
	formatacaoCampo = FormatacaoCampo;

	public get campoSelecionado(): string {
		return this._campoSelecionado;
	}

	public set campoSelecionado(campo: string) {
		this._campoSelecionado = campo;
		this.mapearCamposPossiveis();
	}

	public get operacaoSelecionada(): number {
		return this._operacaoSelecionada;
	}

	public set operacaoSelecionada(operacao: number) {
		this._operacaoSelecionada = operacao;
		this.mapearCamposAgrupadoresPossiveis();
	}

	campoUnicoPorSelecionado: string;
	campoAgrupadorSomaUnicosSelecionado: string;
	formatacaoSelecionada: string;
	listaOperacaoAgregacao: { label: string; value: any }[] = [];
	constructor(
		@Inject(MAT_DIALOG_DATA) public data,
		private templateService: TemplateService,
		private defaultValuesService: DefaultValuesService,
		private ref: MatDialogRef<CardConfiguravelComponent>
	) { }

	mapearCamposPossiveis() {
		if (undefined == this.campoSelecionado)
			return;

		let tipoDado = this.camposMapeados.find(c => c.nome == this.campoSelecionado).tipoDado;

		let listaOperacaoAgregacao = obterOperacaoAgregacaoMapeada(tipoDado);
		this.defaultValuesService
			.obterOperacoesCardEstrategia()
			.then(listaAgregacao => {
				this.listaOperacaoAgregacao = listaAgregacao.filter(l => listaOperacaoAgregacao.some(a => l.value == a.value));
			});

		this.listaFormatacaoCampo = this.formatacaoCampo
			.filter(f => f.tipo == tipoDado)
			.map(m => ({
				value: m.valor,
				label: m.nome
			}));
	}

	mapearCamposAgrupadoresPossiveis() {
		this.listaCamposAgrupadoresPossiveis = this.camposMapeados.filter((f: any) => f.nome != this.campoSelecionado)
	}

	selecaoCampo() {
		this.operacaoSelecionada = null;
		this.formatacaoSelecionada = null;
	}

	validarCampo() {
		return !(this.campoSelecionado && this.operacaoSelecionada != null);
	}

	ngOnInit() {

		let tipoDadoHabilitado = ["Data", "Decimal", "Inteiro", "Lista", "Long", "Texto"];
		this.templateService
			.obterCamposMapeados(this.data.templateId, this.data.listaId)
			.subscribe(camposMapeados => {
				this.camposMapeados = camposMapeados.filter(
					f => tipoDadoHabilitado.indexOf(f.tipoDado) != -1
				);

				this.campoSelecionado = this.data.campoSelecionado;
				this.operacaoSelecionada = this.data.operacaoSelecionada;
				this.campoAgrupadorSomaUnicosSelecionado = this.data.campoAgrupadorSomaUnicosSelecionado;
				this.formatacaoSelecionada = this.data.formatacaoSelecionada;
				this.mapearCamposPossiveis();
			});
	}

	close() {
		this.ref.close();
	}

	isNullOrEmpty(valor: any) {
		return (valor == undefined || valor == null || valor == '');
	}

	save() {
		this.ref.close({
			campo: this.campoSelecionado,
			operacao: this.operacaoSelecionada,
			campoAgrupadorSomaUnicos: this.campoAgrupadorSomaUnicosSelecionado,
			formatacao: this.formatacaoSelecionada
		});
	}
}
