import * as validate from "validate.js";
import { ConfiguracaoConversaoProvedor } from "./configuracao-conversao-provedor";
import { TemplateItemListaValor } from "./template-item-lista-valor";

export enum ProvedorConfiguracaoTipo {
	Envio = 1,
	Retorno = 2,
	Campanha = 3
}

export enum CampoFormato {
	Form = 1,
	Raw = 2
}

let contadorCampo: number = 0;

export class ConfiguracaoCampoProvider {
	public configuracaoCampoProviderId: number;
	public nome: string;
	public providerId: number;
	public campoCampanha: boolean;
	public provedorConfiguracaoTipoId: ProvedorConfiguracaoTipo;
	public listaCampoTipoId: number;
	public campoFormatoId: CampoFormato;
	public campoJson: any;
	public ativo: boolean;
	public obrigatorio: boolean;
	public ordem: number;
	public campoSistemico: boolean = false;
	public possuiLookup: boolean;
	public templateItemListaValor: Array<TemplateItemListaValor>;
	public configuracaoConversaoProvedor: Array<ConfiguracaoConversaoProvedor>;

	constructor(provedorConfiguracaoTipoId?: ProvedorConfiguracaoTipo, campoFormatoId?: CampoFormato) {
		this.provedorConfiguracaoTipoId = provedorConfiguracaoTipoId;
		this.campoFormatoId = campoFormatoId;
		this.campoJson = null;
		this.ativo = true;
		this.configuracaoCampoProviderId = 0;
		this.templateItemListaValor = new Array<TemplateItemListaValor>();
	}

	//#region [ Validação ]

	validate(): any {
		let regras = {
			nome: {
				presence: { allowEmpty: false }
			}
		};

		if (this.provedorConfiguracaoTipoId == ProvedorConfiguracaoTipo.Retorno) {
			regras["listaCampoTipoId"] = { presence: { allowEmpty: false } };
		}

		return validate(this, regras);
	}

	validarJson() {
		validate.validators.jsonValido = function (value) {
			try {
				var json = JSON.parse(value);
				return null;
			} catch (error) {
				return "^invalido";
			}
		};

		return validate(this, { campoJson: { jsonValido: true } });
	}

	//#endregion

	//#region [ Formatação Json]

	formatarJson() {
		contadorCampo = 0;
		this.campoJson = typeof (this.campoJson) == 'object' ? this.campoJson : JSON.parse(this.campoJson);

		Object.keys(this.campoJson).forEach(key => {
			if (key.slice(-1) == ' ' || key.slice(0, 1) == ' ') {
				this.campoJson[key.trim()] = this.campoJson[key];
				delete this.campoJson[key];
			}
		});
		
		Object.keys(this.campoJson).forEach(key => {
			key = key.trim();
			var retorno = this.formatarChaveJson(key, this.campoJson[key]);
			this.campoJson[key] = retorno;
		});

		this.campoJson = JSON.stringify(this.campoJson);
	}

	formatarChaveJson(key: string, value: any) {

		if (new RegExp(/(\$\(CAMPO_[\d]*\)\&T=(STRING|NUMBER|BIGINT|BOOLEAN))/g).test(value))
			return value.replace(/(?<=\$\()(.*)(?=\)\&T\=)/g, "CAMPO_" + ++contadorCampo);

		if (this.isTipoBasico(value)) {
			return ("$(CAMPO_" + ++contadorCampo + ")&T=" + typeof (value)).toUpperCase();
		}

		if (this.isArray(value)) {
			return [this.formatarChaveJson(key, value[0])];
		}

		Object.keys(value).forEach(subKey => {
			value[subKey] = this.formatarChaveJson(subKey, value[subKey]);
		});

		return value;
	}

	isTipoBasico(value: any): boolean {
		var tipo = typeof (value);
		return typeof (value) == "string" || typeof (value) == "number" || typeof (value) == "bigint" || typeof (value) == "boolean";
	}

	isArray(value: any): boolean {
		return Array.isArray(value);
	}

	//#endregion
}