import { ReguaEstrategia } from './ReguaEstrategia';
const validate = require("validate.js");

export class Regua {
    reguaId: number;
    nome: string;
    listaId: number;
    dataCadastro: Date;
    usuarioCadastro: number;
    dataAlteracao: Date;
    usuarioAlteracao: number;
    ativo: boolean;
    reguaEstrategia: Array<ReguaEstrategia>;

    posicoes: number = 0;
    nomeUsuarioCadastro: string;
    
    constructor() {

    }

    validar() {
        var validacao: any = {
            nome: {
                presence: { allowEmpty: false, message: '^campoObrigatorio' }
            },
            listaId: {
                presence: { allowEmpty: false, message: '^campoObrigatorio' }
            },
            posicoes: {
                presence: { allowEmpty: false, message: '^campoObrigatorio' },
                numericality: { onlyInteger: true, greaterThan: 0, message: "^menorque0" }
            }
        };

        return validate.validate(this, validacao);
    }

    static fromRaw(rawRegua): Regua {
        return Object.assign(new Regua(), rawRegua);
    }
}