import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Notificacao, NotificacaoTipo } from "../../common/model/notificacao";
import { NotificacaoService } from "../../common/service/notificacao.service";
import { NotificationService } from "../../common/service/notification.service";

@Component({
    selector: "app-detalhe-notificacao",
    templateUrl: "./detalhe-notificacao.component.html",
    styleUrls: ["./detalhe-notificacao.component.scss"],
    providers: [

    ]
})
export class DetalheNotificacaoComponent {
    public notificacao: Notificacao;
    public dadosJson: any;
    public notificacaoTipo = NotificacaoTipo;
    public notificacaoTipoIcone: any = {"error": "cancel", "warning": "warning", "info": "info" };

    constructor(
        private ref: MatDialogRef<DetalheNotificacaoComponent>,
        private dialog: MatDialog,
        private notificacaoService: NotificacaoService,
        private notificationService: NotificationService,
        @Inject(MAT_DIALOG_DATA)
        public data: { notificacao: Notificacao, id: number }
    ) {
        if (this.data.id > 0)
            this.notificacaoService.obterNotificacaoPorId(this.data.id).subscribe(notificacao => {
                this.notificacao = notificacao;
            });
        else
            this.notificacao = this.data.notificacao;
    }

    close() {
        this.ref.close();
    }

    fechar() {
        this.ref.close();
    }

    notify(): void {
        this.notificationService.success("telaPadrao.sucesso", "telaPadrao.informacaoCopiada");
    }

    copyToClipboard(): string {
     return this.notificacao.detalhe;
    }
}