
import {from as observableFrom,  Observable } from 'rxjs';

import {concatMap, mergeMap, tap} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import "rxjs/add/operator/concatMap";

@Injectable()
export class EnqueueObservableService {
  static queue: Array<Observable<any>> = [];

  enqueue<T>(observable: Observable<T>): Observable<T> {
    if (EnqueueObservableService.queue.length == 0)
      return observable.pipe(tap(() => EnqueueObservableService.queue));

    const enqueuedObservable = observableFrom(EnqueueObservableService.queue).pipe(
      mergeMap(x => x),
      concatMap(() => observable),);

    EnqueueObservableService.queue.push(enqueuedObservable);
    return enqueuedObservable;
  }
}
