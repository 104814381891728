import { Component, OnInit, ViewChild } from "@angular/core";
import { FiltroDashPrincipal } from "../model/filtro-dash-principal";
import { ListaService } from "../../common/service/lista.service";
import { DashCardDisplayComponent } from "./dash-card-display/dash-card-display.component";

@Component({
    selector: "app-dash-principal",
    templateUrl: "./dash-principal.component.html",
    providers: [{ useClass: ListaService, provide: ListaService }]
})
export class DashPrincipalComponent implements OnInit {
    @ViewChild("cards", { static: true }) cards: DashCardDisplayComponent;
    constructor(private listaService: ListaService) {
        this.dadosGrafico;
    }
    dadosGrafico: any;

    formatarNumero(value) {
        if (value < 1000) return value;
        if (value >= 1000 && value < 1000000) return `${value / 1000}K`;
        return `${value / 1000000}M`;
    }

    chartOptions: any;
    setChartOptions(title, legend) {
        this.chartOptions = {
            title: {
                text: title
            },
            legend: {
                show: false
            },
            xAxis: {
                data: Object.keys(this.dadosGrafico.registrosPorDia).map(
                    data => new Date(data)
                ),
                axisLabel: {
                    formatter: function (value) {
                        var intl = new Intl.DateTimeFormat(navigator.language);
                        return intl.format(new Date(value));
                    }
                }
            },
            yAxis: {
                axisLabel: {
                    formatter: this.formatarNumero
                }
            },
            tooltip: {
                formatter: function (params) {
                    var intlDate = new Intl.DateTimeFormat(navigator.language);
                    var intlNumber = new Intl.NumberFormat(navigator.language);

                    return `${intlDate.format(
                        new Date(params.name)
                    )} - ${intlNumber.format(params.value)}`;
                }
            },
            series: [
                {
                    name: legend,
                    type: "line",
                    data: Object.keys(this.dadosGrafico.registrosPorDia).map(key =>
                        parseInt(this.dadosGrafico.registrosPorDia[key])
                    ),
                    symbolSize: 8,
                    lineStyle: {
                        width: 3
                    }
                }
            ]
        };
    }

    pieChart: any;
    setPieChart() {
        this.pieChart = {
            title: {
                text: "Registros por lista"
            },
            tooltip: {
                trigger: "item",
                formatter: "{a} <br/>{b} : {c} ({d}%)"
            },
            legend: {
                type: "scroll",
                orient: "vertical",
                right: 10,
                top: 20,
                bottom: 20,
                data: ["Livre", "Distribuido"]
            },
            series: [
                {
                    name: "Registros por lista",
                    type: "pie",
                    radius: "55%",
                    center: ["40%", "50%"],
                    data: [
                        {
                            name: "Livre",
                            value: this.dadosGrafico.graficoIndicadorStatusLista[0]
                                .quantidadeLivre
                        },
                        {
                            name: "Distribuido",
                            value: this.dadosGrafico.graficoIndicadorStatusLista[0]
                                .quantidadeDistribuido
                        }
                    ],
                    itemStyle: {
                        emphasis: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: "rgba(0, 0, 0, 0.5)"
                        }
                    }
                }
            ]
        };
    }

    stackedCharts;
    setStackCharts() {
        this.stackedCharts = {
            title: {
                text: "Registros por lista"
            },
            tooltip: {
                trigger: "axis"
            },
            legend: {
                data: ["Livre", "Distribuido"],
                right: 0
            },
            grid: {
                left: "3%",
                right: "4%",
                bottom: "3%",
                containLabel: true
            },
            xAxis: {
                type: "value",
                axisLabel: {
                    formatter: this.formatarNumero
                }
            },
            yAxis: {
                type: "category",
                data: this.dadosGrafico.graficoIndicadorStatusLista.map(
                    c => c.nomeLista
                ),
                axisLabel: {
                    formatter: value => {
                        const indexado = this.dadosGrafico.graficoIndicadorStatusLista.find(
                            c => c.nomeLista == value
                        ).indexado;
                        if (indexado) return value;
                        return `${value} - Não indexado`;
                    }
                }
            },
            series: [
                {
                    name: "Livre",
                    type: "bar",
                    stack: "Lista",
                    data: this.dadosGrafico.graficoIndicadorStatusLista.map(
                        c => c.quantidadeLivre
                    )
                },
                {
                    name: "Distribuido",
                    type: "bar",
                    stack: "Lista",
                    data: this.dadosGrafico.graficoIndicadorStatusLista.map(
                        c => c.quantidadeDistribuido
                    )
                }
            ]
        };
    }

    setStatusChart() {
        if (this.dadosGrafico.graficoIndicadorStatusLista.length == 1)
            return this.setPieChart();
        this.setStackCharts();
    }

    ngOnInit() { }

    search(filtro: FiltroDashPrincipal) {
        this.cards.updateData(filtro.listaSelecionada, filtro.carteiraId);

        this.listaService.obterListaGrafico(filtro).subscribe(dadosGrafico => {
            this.chartOptions = undefined;
            this.pieChart = undefined;
            this.stackedCharts = undefined;

            this.dadosGrafico = dadosGrafico;
            this.setChartOptions("Registros livres", "Qtd. Registros");
            this.setStatusChart();
        });
    }
}
