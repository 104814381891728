import { Component, Input, ComponentFactoryResolver, ViewChild, OnInit, ElementRef, Inject } from '@angular/core';
import { LayoutMenu } from '../../../common/model/layout-menu';
import { DefaultValuesService } from '../../../modules/common/service/default-values.service';
import { deepCopy } from '../../../modules/common/utils/deepCopy';
//import { LayoutMenu } from '../../../common/model/layout-menu';
import { environment } from "../../../../environments/environment";
import { PermissoesUsuario } from '../../../modules/common/model/permissoes-usuario';
import { LayoutSubMenu } from '../../../common/model/layout-submenu';
@Component({
	selector: 'my-app-sidenav-menu',
	styles: [],
	templateUrl: './sidenav-menu.component.html',
	providers: [
		{ provide: DefaultValuesService, useClass: DefaultValuesService }
	]
})

export class AppSidenavMenuComponent implements OnInit {
	public AppConfig: any;

	menus: Array<LayoutMenu> = [];
	menusCompleto: Array<LayoutMenu> = [];
	filtro: string = '';

	constructor(private elRef: ElementRef, private defaultValuesService: DefaultValuesService) {
		this.AppConfig = environment;
	}


	ngOnInit() {

		this.defaultValuesService.obterMenu().then(m => {

			this.menusCompleto = Array<LayoutMenu>();
			let permissoesRecursos = PermissoesUsuario.recursos;

			m.forEach((m: LayoutMenu) => {
				if (permissoesRecursos.find(l => l.acao == m.recursoAcao && m.recurso.some(s => s == l.modulo))) {

					if (m.hasSubMenu) {
						let subMenu = Array<LayoutSubMenu>();
						m.subMenus.forEach((s: LayoutSubMenu) => {
							if (permissoesRecursos.find(l => l.acao == s.recursoAcao && s.recurso.some(s => s == l.modulo)))
								subMenu.push(s);
						});
						m.subMenus = subMenu;
					}

					this.menusCompleto.push(m);
				}
			});

			this.adicionarMenus();
		});
	}

	adicionarMenus() {
		this.menus = [];
		this.menus = this.menus.concat(this.menusCompleto);
	}

	applyFilter(filter: string): any {

		if (filter.length < 1) {

			this.adicionarMenus();
		} else {

			let copy: Array<LayoutMenu> = deepCopy(this.menusCompleto);

			this.menus = copy.filter(item => {
				if (item.subMenus != null) {
					item.subMenus = item.subMenus.filter(submenu => {
						return (submenu.nome.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1);
					});
				}
				return ((item.nome.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1) ||
					(item.subMenus != null && item.subMenus.length > 0));
			});

			setTimeout(() => { this.expandirAll(); }, 400);


		}
	}

	clearFilter() {
		this.filtro = '';
		this.applyFilter(this.filtro);
	}

	expandirAll() {
		const target = this.elRef.nativeElement.querySelector('.nav');

		for (const children of target.children) {
			if (children.className.toString().includes("hasSubMenu")) {
				children.click();
				return;
			}
		}
	}
}