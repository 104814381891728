import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material";
import { FiltroMonitorRetorno } from "../../../common/model/filtro-monitor-retorno";
import { HistoricoRetornoService } from "../../../common/service/historico-retorno.service";
import { DetalheErroProcessamentoComponent } from "./detalhe-erro-processamento/detalhe-erro-processamento.component";

@Component({
	selector: "app-lista-erro-processamento",
	templateUrl: "./lista-erro-processamento.component.html",
	styleUrls: ["../style.scss"],
	providers: [
		{ useClass: HistoricoRetornoService, provide: HistoricoRetornoService }
	]
})
export class ListaErroProcessamentoComponent implements OnInit {
	public errosProcessamento: Array<any> = [];

	constructor(
		private historicoRetornoService: HistoricoRetornoService,
        private dialog: MatDialog		
	) {
	}

	ngOnInit() {
	}

	pesquisar(filtro: FiltroMonitorRetorno) {
		this.historicoRetornoService.obterErroProcessamentoRetorno(filtro).subscribe(erros => {
			this.errosProcessamento = erros;
		});
	}

	visualizarDetalhe(erro: any) {
		this.dialog.open(DetalheErroProcessamentoComponent, {
			hasBackdrop: true,
			width: "50%",
			data: {
				'erro': erro
			}
		});
	}
}