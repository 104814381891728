const validate = require("validate.js");

export class FiltroMonitorRetorno {
    providerId: number;
    listaId: number;
    listaEstrategiaId: number;
    dataInicio: Date;
    dataFim: Date;

    constructor() {
        this.providerId = null;
        this.listaId = null;
        this.listaEstrategiaId = null;
		this.dataInicio = new Date();
        this.dataFim = new Date();

        validate.validators.dataMenorQue = (value: any, options: any, key: any, attributes: any) => {
            if (value > attributes[options.campo])
                return "dataMaior";
        };
        validate.validators.dataMaiorQue = (value: any, options: any, key: any, attributes: any) => {
            if (value < attributes[options.campo])
                return "^dataFimInvalida";
        };        
    }

    validarPesquisaErroProcessamento() {
        var validacao: any = {
            dataInicio: { 
                presence: { allowEmpty: false, message: '^campoObrigatorio' },

            },
            dataFim: { 
                presence: { allowEmpty: false, message: '^campoObrigatorio' },
                dataMaiorQue: { campo: "dataInicio", message: '^dataFimInvalida' }
            }
        }

        return validate.validate(this, validacao);
    }

    validarPesquisaSemRetorno() {
        var validacao: any = {
            dataInicio: { 
                presence: { allowEmpty: false, message: '^campoObrigatorio' },

            },
            dataFim: { 
                presence: { allowEmpty: false, message: '^campoObrigatorio' },
                dataMaiorQue: { campo: "dataInicio", message: '^dataFimInvalida' }
            },
            listaId :{
                presence: { allowEmpty: false, message: '^campoObrigatorio' },
        
            }
        }

        return validate.validate(this, validacao);
    }    
}