import { Component, ViewChild, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from '@angular/material';
import { DataView } from 'primeng/dataview';

import { Regua } from "../../common/model/regua";
import { ReguaEstrategia } from "../../common/model/ReguaEstrategia";
import { Lista } from "../../common/model/lista";
import { FiltroLista } from '../../common/model/filtro-lista';

import { ReguaService } from "../../common/service/regua.service";
import { CustomAlertService } from "../../common/service/custom-alert.service";
import { ListaService } from "../../common/service/lista.service";

import { CarteiraService } from "../../common/service/carteira.service";
import { Carteira } from "../../common/model/carteira";

import { VisualizarReguaModalComponent } from "../visualizar-regua-modal/visualizar-regua-modal.component";
import { Edge, Node, Layout } from '@swimlane/ngx-graph';

import * as shape from 'd3-shape';
import { Subject } from "rxjs";
import { PreferenciasUsuarioService } from "../../common/service/preferencias-usuario.service";
import { PreferenciasUsuario } from "../../common/model/preferencias-usuario";

@Component({
	selector: "app-lista-regua-estrategia",
	templateUrl: "./lista-reguaestrategia.component.html",
	styleUrls: ["./lista-reguaestrategia.component.scss"],
	providers: [
		{ useClass: CarteiraService, provide: CarteiraService },
		{ useClass: PreferenciasUsuarioService, provide: PreferenciasUsuarioService },
		{ useClass: ReguaService, provide: ReguaService },
		{ useClass: ListaService, provide: ListaService },
		{ useClass: CustomAlertService, provide: CustomAlertService }
	]
})
export class ListaReguaEstrategiaComponent implements OnInit {
	preferenciasUsuario: PreferenciasUsuario;
	reguas: Array<Regua> = [];
	carteiras: Array<Carteira> = [];
	listas: Array<Lista>;
	filtro = { 'carteiraId': null, 'lista': '', 'nome': '' };

	public get disabledCarteira(): boolean {
		return (this.carteiras.length == 1);
	}

	@ViewChild(DataView, { static: false }) dataTable: DataView;
	constructor(
		private carteiraService: CarteiraService,
		private reguaService: ReguaService,
		private preferenciasService: PreferenciasUsuarioService,
		private listaService: ListaService,
		private route: ActivatedRoute,
		private customAlertService: CustomAlertService,
		public dialog: MatDialog
	) {
		// Preferencias do usuário
		this.preferenciasUsuario = this.preferenciasService.localStorageObterPreferenciasUsuario();

		//this.route.params.subscribe(params => this.filtrarReguas());
	}

	ngOnInit() {
		this.obterCarteiras();
		//this.obterListas();
	}

	filtrarReguas() {
		this.reguaService
			.obterReguas(this.filtro)
			.subscribe(data => {
				this.reguas = data;
				this.setCurrentPage(0);
			});
	}

	carteiraChange() {
		this.obterListas();
	}

	obterCarteiras() {
		this.carteiraService.obterCarteiras().subscribe(carteiras => {
			this.carteiras = carteiras;

			if (this.preferenciasUsuario.existePreferenciaCarteira) {
				this.filtro.carteiraId = this.preferenciasUsuario.carteiraId as number;
			} else {
				let padrao = this.carteiras.find(f => f.padrao) as Carteira;
				this.filtro.carteiraId = padrao.carteiraId;
			}

			this.obterListas();
		});
	}

	obterListas() {
		let filtroLista = new FiltroLista();
		filtroLista.ignorarErroPesquisa = true;
		filtroLista.carteiraId = this.filtro.carteiraId;

		if (this.preferenciasUsuario.existePreferenciaLista) {
			this.filtro.lista = `${this.preferenciasUsuario.listaId}`;
		}

		this.listaService.obterLista(filtroLista).subscribe(listas => {
			this.listas = listas;
			this.filtrarReguas();
		});
	}

	setCurrentPage(n: number) {
		if (this.dataTable)
			this.dataTable.first = n;
	}

	quantidadePosicoes(reguaEstrategia: ReguaEstrategia[]) {

		if (reguaEstrategia == null || reguaEstrategia == undefined)
			return 0;

		return reguaEstrategia.filter((f: ReguaEstrategia) => f.ativo).length;
	}

	limpar() {
		let padrao = this.carteiras.find(f => f.padrao);
		this.filtro = { 'carteiraId': padrao.carteiraId, 'lista': '', 'nome': '' };
	}

	excluir(reguaId: number) {
		this.customAlertService
			.confirmationMessage("telaRegua.confirmacaoDesativarRegua")
			.then(() => {
				this.reguaService
					.desativarRegua(reguaId)
					.subscribe((retorno: any) => {
						this.filtrarReguas();
					});
			});
	}

	mostrarPosicoes(regua: Regua) {
		this.dialog.open(VisualizarReguaModalComponent, {
			height: "650px",
			width: "80%",
			//panelClass: "theme-bradesco",
			data: {
				regua: regua
			}
		});
	}
}