import { Component, OnInit } from "@angular/core";

import { Template, TipoCategoria } from "../../common/model/template";
import { TemplateService } from "../../common/service/template.service";
import { CustomAlertService } from "../../common/service/custom-alert.service";
import { Router } from "@angular/router";

@Component({
	selector: "app-lista-template",
	templateUrl: "./lista-template.component.html",
	styleUrls: ["../style.css"],
	providers: [
		{ useClass: TemplateService, provide: TemplateService },
		{ useClass: CustomAlertService, provide: CustomAlertService }
	]
})
export class ListaTemplateComponent implements OnInit {
	templates: Array<Template>;

	constructor(
		private router: Router,
		private templateService: TemplateService,
		private customAlertService: CustomAlertService
	) {
		this.templates = [];
	}

	public get templatesImportacao() {
		return this.templates.filter(
			f => f.listaTemplateCategoriaId == TipoCategoria.importacao
		);
	}

	public get templatesExportacao() {
		return this.templates.filter(
			f => f.listaTemplateCategoriaId == TipoCategoria.exportacao
		);
	}

	ngOnInit() {
	}

	filtrarTemplate(filtro) {
		this.templateService
			.obterTemplates(filtro)
			.subscribe(templates => (this.templates = templates));
	}

	duplicar(template: any) {
		// listaTemplateId
		// TemplateImportacaoId
		this.customAlertService
			.confirmationMessage("telaTemplate.confirmacaoDuplicacao")
			.then(() => {
				if(template.listaTemplateCategoriaId == TipoCategoria.importacao)
					this.router.navigate(["/app/template/create", template.listaTemplateId ]);
				else
					this.router.navigate(["/app/template/" + template.templateImportacaoId + "/create/" + template.listaTemplateId]);					
			})
	}

	excluir(listaTemplateId) {
		this.customAlertService
			.confirmationMessage("telaTemplate.confirmacaoExclusao")
			.then(() =>
				this.templateService
					.desativar(listaTemplateId)
					.subscribe(
						() =>
							(this.templates = this.templates.filter(
								t => t.listaTemplateId != listaTemplateId
							))
					)
			);
	}
}
