const p = require("../../config.json");

function makeAppConfig() {
    const date = new Date();
    const year = date.getFullYear();

    const AppConfig = {
        brandIcon: "phone",
        brand: "TalkMiner",
        user: "Lisa",
        year,
        layoutBoxed: false, // true, false
        navCollapsed: false, // true, false
        navBehind: true, // true, false
        fixedHeader: true, // true, false
        sidebarWidth: "small", // small, middle, large
        theme: "light", // light, gray, dark, bradesco
        colorOption: "32", // 11,12,13,14,15,16; 21,22,23,24,25,26; 31,32,33,34,35,36
        AutoCloseMobileNav: true, // true, false. Automatically close sidenav on route change (Mobile only)
        productLink:
            "https://themeforest.net/item/material-design-angular-2-admin-web-app-with-bootstrap-4/19421267",
        client_id: "TalkMinerClientId",
        uploadLimit: 2147483647, 
        epbxManagerApiUrl: "http://localhost:62745",
        epbxManagerClientId: "9ecbdb4438c78962ed908b711fcf44",
        version: p.version,
        enableConditionOr: true,
        estrategiaAgendamentoMinimoMinutos: 2
    };
    return AppConfig;
}

export function applySharedConfig(config: any) {
    return Object.assign({}, config, makeAppConfig());
}
