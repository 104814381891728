export const convertToQueryString = (queryObject, usePrefix = true) => {
  if (!queryObject) return "";

  let formUrlEncoded = Object.keys(queryObject)
    .map(
      prop =>
        queryObject[prop] instanceof Date
          ? `${prop}=${encodeURIComponent(queryObject[prop].toISOString())}`
          : `${prop}=${encodeURIComponent(queryObject[prop])}`
    )
    .join("&");

  return usePrefix ? `?${formUrlEncoded}` : formUrlEncoded;
};
