import { Component, Input, EventEmitter, Output, SimpleChanges } from "@angular/core";

import { ListaService } from "../../../common/service/lista.service";
import { EnqueueObservableService } from "../../../common/service/enqueue-observable.service";
import { ElasticsearchRepositoryService } from "../../repository/elasticsearch-repository.service";
import { MatDialog } from "@angular/material";
import { CardConfiguravelComponent } from "../../card-configuravel/card-configuravel.component";
import { CardSomarUnicosComponent } from "../../card-somar-unicos/card-somar-unicos.component";
import { Estrategia, EstrategiaTotal } from "../../../common/model/estrategia";
import { FiltroStatusRegistroLista, Lista } from "../../../common/model/lista";

@Component({
	selector: "app-quantidades-lista",
	templateUrl: "./quantidades-lista.component.html",
	styleUrls: ["../../custom.css"],
	providers: [
		{ useClass: ListaService, provide: ListaService },
		{ useClass: ElasticsearchRepositoryService, provide: ElasticsearchRepositoryService },
		{ useClass: EnqueueObservableService, provide: EnqueueObservableService }
	]
})
export class QuantidadesListaComponent {
	public queryPadraoTotal: any = {
		size: 10,
		_source: {
			includes: []
		},
		query: {
			bool: {
				must: [],
				must_not: []
			}
		}
	};

	public queryPadraoNaoDisponivel: any = {
		size: 10,
		_source: {
			includes: []
		},
		query: {
			bool: {
				must: [],
				must_not: [
					{
						exists: {
							field: "_integrado"
						}
					}
				]
			}
		}
	};

	public queryPadraoUtilizado: any = {
		size: 10,
		_source: {
			includes: []
		},
		query: {
			bool: {
				must: [
					{
						exists: {
							field: "_integrado"
						}
					}
				],
				must_not: []
			}
		}
	};

	quantidadesLista: any = {
		quantidadeLivre: 0,
		quantidadeDistribuida: 0
	};

	@Input()
	dadosQuantidadeAlterada: EstrategiaTotal;

	public get dados() {
		return this.quantidadesLista;
	}

	@Input()
	quantidadeEstrategia = 0;
	@Input()
	nomeLista: string;
	@Input()
	query;
	@Input()
	templateId;

	@Input()
	reenviarDistribuido: FiltroStatusRegistroLista;

	@Input()
	origem: string;

	@Input()
	finishedLoading: boolean;

	@Input()
	exibirTotalEstrategia: boolean = true;

	@Input()
	exibirCampoCustomizadoLista: boolean = false;

	@Output()
	preview: EventEmitter<{}>;
	cardState: string = "in";

	@Output()
	eventCardCustomizado: EventEmitter<any>;

	@Output()
	eventQuantidadeListaAlterada: EventEmitter<EstrategiaTotal>;

	@Output()
	eventCardSomarUnicos: EventEmitter<any>;

	@Output()
	eventReenviarDistribuido: EventEmitter<{}>;


	constructor(
		private listaService: ListaService,
		private elasticsearchService: ElasticsearchRepositoryService,
		private enqueueObservableService: EnqueueObservableService,
		private dialog: MatDialog
	) {
		this.preview = new EventEmitter<{}>();
		this.eventCardCustomizado = new EventEmitter<any>();
		this.eventCardSomarUnicos = new EventEmitter<any>();
		this.eventReenviarDistribuido = new EventEmitter<{}>();
		this.eventQuantidadeListaAlterada = new EventEmitter<any>();
	}

	@Input()
	listaId;

	@Input()
	lista: Lista;

	@Input()
	estrategiaId;

	@Input()
	estrategia: Estrategia;

	statusRegistroLista = FiltroStatusRegistroLista;

	atualizarValores(statusFiltroLista: FiltroStatusRegistroLista) {
		if (this.query == undefined) {
			this.query = statusFiltroLista == FiltroStatusRegistroLista.naoUtilizado ? this.queryPadraoNaoDisponivel
				: statusFiltroLista == FiltroStatusRegistroLista.utilizado ? this.queryPadraoUtilizado : this.queryPadraoTotal;
		} else {
			this.query.query.bool.must = this.query.query.bool.must.filter(f => {
				if (f.exists != undefined)
					return f.exists.field != "_integrado";

				return true;
			})

			this.query.query.bool.must_not = this.query.query.bool.must_not.filter(f => {
				if (f.exists != undefined)
					return f.exists.field != "_integrado";

				return true;
			})

			if (statusFiltroLista == FiltroStatusRegistroLista.naoUtilizado) {
				this.query.query.bool.must_not.push({ exists: { field: "_integrado" } });
			}
			else if (statusFiltroLista == FiltroStatusRegistroLista.utilizado) {
				this.query.query.bool.must.push({ exists: { field: "_integrado" } });
			}
		}

		this.doRequest(true);
	}

	doRequest(disparaEventoQuantidadeAlterada: boolean = false) {

		if (undefined == this.listaId || this.finishedLoading == false) {
			this.quantidadesLista = {
				quantidadeLivre: 0,
				quantidadeDistribuida: 0
			};
			return;
		}

		let campo: string;
		let operacao: number;
		let campoAgrupadorSomaUnicos: string;
		let formatacao: string;
		let somaUnicosCardFormatacao: string;

		if (this.listaId && this.lista) {
			campo = (this.lista.campoCard) ? this.lista.campoCard : null;
			operacao = (this.lista.operacaoCampoCard) ? this.lista.operacaoCampoCard : null;
			campoAgrupadorSomaUnicos = (this.lista.campoAgrupadorSomaUnicosCard) ? this.lista.campoAgrupadorSomaUnicosCard : null;
			formatacao = (this.lista.formatacaoCard) ? this.lista.formatacaoCard : null;
			somaUnicosCardFormatacao = (this.lista.somaUnicosCardFormatacao) ? this.lista.somaUnicosCardFormatacao : null;
		}

		if (this.estrategiaId >= 0 && this.estrategia) {
			campo = (this.estrategia.campoCard) ? this.estrategia.campoCard : null;
			operacao = (this.estrategia.operacaoCampoCard) ? this.estrategia.operacaoCampoCard : null;
			campoAgrupadorSomaUnicos = (this.estrategia.campoAgrupadorSomaUnicosCard) ? this.estrategia.campoAgrupadorSomaUnicosCard : null;
			formatacao = (this.estrategia.formatacaoCard) ? this.estrategia.formatacaoCard : null;
		}

		this.elasticsearchService
			.getQuantidadeLista(this.listaId, (this.estrategiaId || 0), (this.query || {}), campo, operacao, campoAgrupadorSomaUnicos, formatacao, somaUnicosCardFormatacao)
			.subscribe(quantidadesLista => {
				this.quantidadesLista = quantidadesLista;


				this.dadosQuantidadeAlterada = new EstrategiaTotal()
				this.dadosQuantidadeAlterada.quantidadeLivre = this.quantidadesLista.quantidadeLivre;
				this.dadosQuantidadeAlterada.totalGeral = this.quantidadesLista.totalGeral;
				this.dadosQuantidadeAlterada.quantidadeEstrategia = this.quantidadesLista.quantidadeEstrategia;
				this.dadosQuantidadeAlterada.totalParaUtilizar = 0;
				this.dadosQuantidadeAlterada.reenviarDistribuido = this.reenviarDistribuido;

				// this.dadosQuantidadeAlterada = this.quantidadesLista;
				// this.dadosQuantidadeAlterada.reenviarDistribuido = this.reenviarDistribuido;
				// this.estrategia["dadosQuantidadeAlterada"] = this.dadosQuantidadeAlterada;

				if (disparaEventoQuantidadeAlterada) {
					this.eventQuantidadeListaAlterada.emit(this.dadosQuantidadeAlterada);
				}
			});

	}

	ngOnChanges(inputAlterado: SimpleChanges) {

		let disparaEventoQuantidadeAlterada: boolean = false;

		if (inputAlterado != undefined) {
			if (inputAlterado.reenviarDistribuido != undefined) {

				if (inputAlterado.reenviarDistribuido.previousValue != undefined) {
					return;
				}
			}
		}
		this.doRequest();
	}

	toggleCard() {
		this.cardState = this.cardState === "out" ? "in" : "out";
	}


	alterarReenviar(valor) {
		this.reenviarDistribuido = valor;
		this.eventReenviarDistribuido.emit(this.reenviarDistribuido);
		this.atualizarValores(valor);
	}

	abrirConfiguracao() {

		if (this.isNullOrEmpty(this.templateId))
			return;

		let ref = this.dialog.open(CardConfiguravelComponent, {
			width: "65%",
			data: {
				origem: this.origem,
				campoSelecionado: this.estrategia.campoCard,
				operacaoSelecionada: this.estrategia.operacaoCampoCard,
				campoAgrupadorSomaUnicosSelecionado: this.estrategia.campoAgrupadorSomaUnicosCard,
				templateId: this.templateId,
				formatacaoSelecionada: this.estrategia.formatacaoCard,
				listaId: this.listaId
			}
		});

		ref.afterClosed().subscribe(result => {
			if (!result) return;

			this.estrategia.campoCard = result.campo;
			this.estrategia.operacaoCampoCard = result.operacao;
			this.estrategia.campoAgrupadorSomaUnicosCard = result.campoAgrupadorSomaUnicos;
			this.estrategia.formatacaoCard = (result.formatacao) ? result.formatacao : 'N0';

			this.doRequest();
		});
	}

	abrirConfiguracaoLista() {
		let ref = this.dialog.open(CardConfiguravelComponent, {
			width: "65%",
			data: {
				origem: this.origem,
				campoSelecionado: this.lista.campoCard,
				operacaoSelecionada: this.lista.operacaoCampoCard,
				templateId: this.templateId,
				formatacaoSelecionada: this.lista.formatacaoCard,
				listaId: this.listaId
			}
		});

		ref.afterClosed().subscribe(result => {
			if (!result) return;
			this.lista.campoCard = result.campo;
			this.lista.operacaoCampoCard = result.operacao;
			this.lista.formatacaoCard = (result.formatacao) ? result.formatacao : 'N0';

			this.eventCardCustomizado.emit(this.lista);
			this.doRequest();
		});
	}

	abrirConfiguracaoSomarUnicosPorLista() {
		let ref = this.dialog.open(CardSomarUnicosComponent, {
			width: "65%",
			data: {
				origem: this.origem,
				templateId: this.templateId,
				somaUnicosCardCampoSelecionado: this.lista.somaUnicosCardCampo,
				somaUnicosCardAgrupadorSelecionado: this.lista.somaUnicosCardAgrupador,
				somaUnicosCardFormatacaoSelecionada: this.lista.somaUnicosCardFormatacao,
				somaUnicosCardValor: this.lista.somaUnicosCardValor,
				somaUnicosCardProcessamento: this.lista.somaUnicosCardProcessamento,
				somaUnicosCardAlterado: this.lista.somaUnicosCardAlterado,
				listaId: this.listaId
			}
		});

		ref.afterClosed().subscribe(result => {
			if (!result) return;
			this.lista.somaUnicosCardAgrupador = result.somaUnicosCardAgrupador;
			this.lista.somaUnicosCardAlterado = result.somaUnicosCardAlterado;
			this.lista.somaUnicosCardCampo = result.somaUnicosCardCampo;
			this.lista.somaUnicosCardFormatacao = result.somaUnicosCardFormatacao;
			this.lista.somaUnicosCardProcessamento = result.somaUnicosCardProcessamento;
			this.lista.somaUnicosCardValor = result.somaUnicosCardValor;

			this.eventCardSomarUnicos.emit(this.lista);
			this.doRequest();
		});
	}

	isNullOrEmpty(valor: any) {
		return (valor == undefined || valor == null || valor == '');
	}

	emitPreview() {
		this.preview.emit();
	}
}