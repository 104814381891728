import { Component, OnInit, OnDestroy } from "@angular/core";
import { ListaService } from "../../common/service/lista.service";
import { MatDialog } from '@angular/material';
import { FiltroLista } from "../../common/model/filtro-lista";
import { Tarefa } from "../../common/model/tarefa";
import { CustomAlertService } from "../../common/service/custom-alert.service";
import { ExcelService } from "../../common/service/excel.service";
import { TarefaService } from "../../common/service/tarefa.service";
import { EstrategiaService } from "../../common/service/estrategia.service";
import { Estrategia } from "../../common/model/estrategia";
import { HubConnectionBuilder, HubConnection } from "@aspnet/signalr";
import { environment } from "../../../../environments/environment";
import * as signalR from "@aspnet/signalr";
import { FiltroTarefa } from "../../common/model/filtro-tarefa";
import { downloadFile } from "../../common/service/download-file";
import { InfoExecucaoEstrategiaComponent } from "../../estrategia/lista-estrategia/info-execucao-estrategia/info-execucao-estrategia.component";
import { isNullOrEmpty, isNull } from "../../common/utils";
import { Observable, Subscription } from "rxjs";
import { SituacaoExecucao } from "../../common/model/situacao-execucao";
import { FluxoService } from "../../common/service/fluxo.service";


@Component({
	selector: "app-lista-tarefa",
	templateUrl: "./lista-tarefa.component.html",
	styleUrls: ["./lista-tarefa.scss"],
	providers: [
		{ useClass: CustomAlertService, provide: CustomAlertService },
		{ useClass: TarefaService, provide: TarefaService },
		{ useClass: ExcelService, provide: ExcelService },
		{ useClass: EstrategiaService, provide: EstrategiaService },
		{ useClass: FluxoService, provide: FluxoService }
	]
})
export class ListaTarefaComponent implements OnInit, OnDestroy {
	listaTarefa: Array<Tarefa>;
	listaTarefaHistorico: Array<Tarefa>;
	listaTarefaExecucao: Array<Tarefa>;
	listaEstrategia: Array<Estrategia>;
	filtro: FiltroTarefa;
	signalRStatus: boolean;

	private _hubConnection: HubConnection | undefined;

	constructor(private tarefaService: TarefaService,
		private excelService: ExcelService,
		private estrategiaService: EstrategiaService,
		private customAlertService: CustomAlertService,
		private fluxoService: FluxoService,
		
		public dialog: MatDialog
	) {
		this.filtro = new FiltroTarefa();
	}

	ngOnInit() {
		//this.carregarLista();

		this.conectarSignalR();
		this.escutarSignalR();
		this.reconectarSignalR();
	}


	ngOnDestroy() {
		this._hubConnection.stop();
	}

	conectarSignalR() {
		this._hubConnection = new HubConnectionBuilder()
			.withUrl(`${environment.serverUrl}/notificacao`)
			.configureLogging(signalR.LogLevel.Information)
			.build();
	}

	reconectarSignalR() {
		this._hubConnection.onclose(() => {
			this.signalRStatus = false;
			this.conectarSignalR();
			this.escutarSignalR();
			this.reconectarSignalR();
		});
	}

	escutarSignalR() {
		this._hubConnection.start().then(() => {
			this.signalRStatus = true;
			this._hubConnection.on('AtualizarTarefa', (conteudo: any) => {
				if (!conteudo) return;

				if (conteudo.atualizacaoStatus)
					this.carregarLista(true);

				if (conteudo.atualizacaoPorcentagem && !isNull(this.listaTarefaExecucao)) {
					this.listaTarefaExecucao.forEach((f: Tarefa) => {
						if (f.tarefaId == conteudo.tarefaId)
							f.porcentagemExecucao = conteudo.porcentagem;
					});
				}
			});

			this._hubConnection.on('AtualizarInfoEstrategiasParaExecutar', (conteudo: any) => {
				if (!conteudo) return;
				//this.carregarLista(true);
				this.obterEstrategiasParaExecutar(true);
			});
		}).catch(err => console.log(err));
	}

	carregarLista(blockLoading: boolean = false) {
		this.obterEstrategiasParaExecutar(blockLoading);
		this.obterTarefas(blockLoading);
	}

	obterEstrategiasParaExecutar(blockLoading: boolean = false) {
		this.tarefaService.obterEstrategiasParaExecutar(this.filtro, blockLoading).subscribe(estrategias => {
			this.listaEstrategia = estrategias.sort((a: any, b: any) => {
				let key1 = new Date(a.proximaExecucao);
				let key2 = new Date(b.proximaExecucao);
				return (key1 > key2) ? 1 : ((key1 == key2) ? 0 : -1);
			});
		});
	}

	obterTarefas(blockLoading: boolean = false) {
		this.tarefaService.obterTarefas(this.filtro, blockLoading).subscribe(tarefa => {
			this.listaTarefaExecucao = tarefa.filter(f => (f.status == 1 || f.status == 13) && f.situacaoExecucao != SituacaoExecucao.cancel).sort((a: any, b: any) => {
				let key1 = a.status;
				let key2 = b.status;
				return (key1 > key2) ? 1 : ((key1 == key2) ? 0 : -1);
			});
			this.listaTarefaHistorico = tarefa.filter(f => f.status != 1 && f.status != 13);
		});
	}

	filtrarTarefa(filtroTarefa: FiltroTarefa) {
		this.filtro = filtroTarefa;

		if (this.signalRStatus == false) {
			this.conectarSignalR();
			this.escutarSignalR();
			this.reconectarSignalR();
		}

		this.carregarLista();
	}

	existeCampanha(campanhaNome: string): boolean {
		return (!isNullOrEmpty(campanhaNome));
	}

	ativarPendenteExecucao(estrategia) {
		this.fluxoService
            .obterQueryQuantidadeRegistrosPost(estrategia.listaId, estrategia.estrategiaFiltro)
            .subscribe((result: any) => {
            let quantidade = (result) ? result : 0;

            if(quantidade > 0){
                this.customAlertService
                    .confirmationMessage("telaEstrategia.confirmarReativacao")
                    .then(() =>
                        this.estrategiaService.ativarPendenteExecucao(estrategia.listaEstrategiaId).subscribe(() => this.carregarLista())
                );
            }else{        
                this.customAlertService
                    .confirmationMessage("telaEstrategia.confirmarReativacaoSemRegistro")
                    .then(() =>
					this.estrategiaService.ativarPendenteExecucao(estrategia.listaEstrategiaId).subscribe(() => this.carregarLista())
                );
            }
        });
	}

	forcarExecucao(estrategia) {
        this.fluxoService
            .obterQueryQuantidadeRegistrosPost(estrategia.listaId, estrategia.estrategiaFiltro)
            .subscribe((result: any) => {
            let quantidade = (result) ? result : 0;

            if(quantidade > 0){
                this.customAlertService
                    .confirmationMessage("telaEstrategia.forcarExecucao")
                    .then(() =>
					this.estrategiaService.forcarExecucao(estrategia.estrategiaId).subscribe(() => this.carregarLista())
                );
            }else{
        
                this.customAlertService
                    .confirmationMessage("telaEstrategia.forcarExecucaoSemRegistro")
                    .then(() =>
					this.estrategiaService.forcarExecucao(estrategia.estrategiaId).subscribe(() => this.carregarLista())
                );
            }
        });	
	}

	playExecucao(listaEstrategiaId: number) {
		this.confirmacao("telaTarefa.playExecucaoConfirm", () => this.estrategiaService.atualizarSituacaoExecucao(listaEstrategiaId, SituacaoExecucao.play).subscribe(() => this.obterEstrategiasParaExecutar(true)));
	}

	pauseExecucao(listaEstrategiaId: number) {
		this.confirmacao("telaTarefa.pauseExecucaoConfirm", () => this.estrategiaService.atualizarSituacaoExecucao(listaEstrategiaId, SituacaoExecucao.pause).subscribe(() => this.obterEstrategiasParaExecutar(true)));
	}

	cancelExecucao(listaEstrategiaId: number) {
		this.confirmacao("telaTarefa.cancelExecucaoConfirm", () => this.estrategiaService.atualizarSituacaoExecucao(listaEstrategiaId, SituacaoExecucao.cancel).subscribe(() => this.carregarLista(true)));
	}

	exibirInfoExecucao(listaEstrategiaId: number): void {
		this.estrategiaService.obterEstrategiaPorId(listaEstrategiaId).subscribe(e => this.dialog.open(InfoExecucaoEstrategiaComponent, { width: '500px', data: e }));
	}

	confirmacao(tranalateMsg: string, execute: () => {}) {
		return this.customAlertService.confirmationMessage(tranalateMsg).then(execute);
	}

	limparTarefasNaoExecutadas() {
		this.tarefaService.limparTarefasNaoExecutadas().subscribe(r => this.carregarLista());
	}

	exportarExcel() {
		this.excelService.exportAsExcelFile(this.listaTarefaHistorico, 'Execuções de estratégias');
	}

	baixarUltimoHistorico(lista: any) {
		this.estrategiaService.baixarUltimoHistorico(lista.listaEstrategiaId, lista.tarefaId).subscribe(
			(res) => {
				let extensao = res["_body"].type == "application/text" ? "txt" : "zip";
				let nomeArquivo = `${lista.estrategiaNome.replace(/[.*+\-?^${}()|[\]\\]/g, '').replace(/\s\s+/g, ' ').replace(/\s/g, '_')}_${lista.tarefaId}.${extensao}`;
				(downloadFile(res, nomeArquivo))
			},
			() => (this.customAlertService.show("telaEstrategia.download", "telaEstrategia.expurgoArquivo", "info")));
	}
}