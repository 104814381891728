
import {map} from 'rxjs/operators';
import "rxjs/add/operator/map";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Usuario } from "../model/usuario";
import { convertToQueryString } from "./query-string";
import { environment } from "../../../../environments/environment";
import { Senha } from '../../autenticacao/model/senha';

@Injectable()
export class UsuarioService {
	constructor(private httpClient: HttpClient) { }

	obterUsuario(id) {
		return this.httpClient
			.get<Usuario>(`${environment.serverUrl}/api/usuarios/${id}`).pipe(
			map(usuario => Usuario.fromRaw(usuario)));
	}

	obterUsuarios(filtro?) {
		return this.httpClient.get<Array<Usuario>>(
			`${environment.serverUrl}/api/usuarios${convertToQueryString(filtro)}`
		);
	}

	verificaUsuarioExiste(login: string) {
		return this.httpClient.get<Array<Usuario>>(
			`${environment.serverUrl}/api/usuarios/verificaUsuarioExiste?login=${login}`
		);
	}

	obterApikeys(filtro?) {
		return this.httpClient.get<Array<Usuario>>(
			`${environment.serverUrl}/api/usuarios/apikey${convertToQueryString(
				filtro
			)}`
		);
	}

	recuperarSenha(usuario: Usuario) {
		return this.httpClient.post<any>(
			`${environment.serverUrl}/api/usuarios/recuperarSenha`,
			usuario
		);
	}

	alterarSenha(senha : Senha) {
		return this.httpClient.post<any>(
			`${environment.serverUrl}/api/usuarios/alterarSenha`,
			senha
		);
	}

	verificarChaveAlteracao(chave: string) {
		let objChave = {
			chave: chave
		}
		return this.httpClient.post<any>(
			`${environment.serverUrl}/api/usuarios/validarChaveAlteracaoSenha`,
			objChave
		);
	}

	criarApiKey(usuario: Usuario) {
		return this.httpClient.post(
			`${environment.serverUrl}/api/usuarios/apikey`,
			usuario
		);
	}

	desativar(id) {
		return this.httpClient.patch(
			`${environment.serverUrl}/api/usuarios/${id}/desativar`,
			null
		);
	}

	criar(usuario: Usuario) {
		return this.httpClient.post(
			`${environment.serverUrl}/api/usuarios`,
			usuario
		);
	}

	atualizar(usuario: Usuario) {
		return this.httpClient.put(
			`${environment.serverUrl}/api/usuarios`,
			usuario
		);
	}
}
