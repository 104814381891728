import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";

import { ListaService } from "../../common/service/lista.service";
import { MatDialog } from '@angular/material';
import { Lista } from "../../common/model/lista";
import { CustomAlertService } from "../../common/service/custom-alert.service";
import { Table } from "primeng/table";
import { ActivatedRoute } from "@angular/router";
import * as moment from "moment";
import { PreviewDadosHistoricoComponent } from "./preview-dados-historico/preview-dados-historico.component";

@Component({
	selector: "app-historico-indexacao",
	templateUrl: "./historico-indexacao.component.html",
	styleUrls: ["../style.css"],
	providers: [
		{
			useClass: ListaService,
			provide: ListaService
		},
		{ useClass: CustomAlertService, provide: CustomAlertService }
	]
})
export class HistoricoIndexacaoComponent implements OnInit, OnDestroy {
	listaId: number;
	listNome: string;
	historicos: any;
	filtro: any = {};
	erros: any = {};

	@ViewChild(Table, {static: false}) dataTable: Table;
	constructor(private listaService: ListaService, private route: ActivatedRoute, public dialog: MatDialog) {

		let dataFim = new Date();
		let dataInicio = new Date(dataFim.getFullYear(), dataFim.getMonth(), dataFim.getDate() - 5);

		this.filtro.dataInicio = dataInicio;
		this.filtro.dataFim = dataFim;
		this.route.params.subscribe(params => {
			this.listaId = parseInt(params.id);
		});
		this.listaService.obterListaPorId(this.listaId).subscribe(result => {
			this.listNome = result.nome;
		})
	}

	ngOnInit() {
		this.carregarLista();
	}


	carregarLista() {
		this.listaService.obterHistoricoIndexacao(this.listaId, moment(this.filtro.dataInicio).format("YYYY/MM/DD") + " 00:00:00", moment(this.filtro.dataFim).format("YYYY/MM/DD") + " 23:59:59").subscribe(result => {
			this.historicos = result;
		});
	}

	mostrarPreview (historico:any){

		this.dialog.open(PreviewDadosHistoricoComponent, {
			height: "650px",
			width: "80%",
			data: {
				historicoIndexacaoId: historico.historicoIndexacaoId
			}
		});
	}

	ngOnDestroy() {

	}

	limpar(){
		this.filtro = {};
	}

	setCurrentPage(n: number) {
		if (this.dataTable)
			this.dataTable.first = n;
	}
}
