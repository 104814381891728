import { Component, OnInit, EventEmitter, Output, Input } from "@angular/core";
import * as moment from "moment";

@Component({
    selector: "app-date-filter",
    styleUrls: ["./date-filter.component.scss"],
    templateUrl: "./date-filter.component.html"
})
export class DateFilterComponent implements OnInit {
    @Input("model")
    model;
    @Output("modelChange")
    modelChange: EventEmitter<any>;

    tipoData: string = null;
    _dataFiltro: Date;

    public get dataFiltro() {
        return this._dataFiltro;
    }

    public set dataFiltro(val) {
        this._dataFiltro = val;
        this.emitChange();
    }

    public get elasticSearchData() {
        this.model = moment(this._dataFiltro).format('YYYY-MM-DD HH:mm:ss');
        return this.model;
    }

    constructor() {
        this.modelChange = new EventEmitter();
    }

    emitChange() {
        this.modelChange.emit(this.elasticSearchData);
    }

    ngOnInit() {
        if (this.model != null) {
            this._dataFiltro = moment(this.model).toDate();
        }
    }

    ngOnChanges() {

    }
}