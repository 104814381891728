import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { environment } from "../../../../environments/environment";
import { convertToQueryString } from "../../common/service/query-string";
import { Carteira } from "../model/carteira";
import { CarteiraUsuario } from "../model/carteira-usuario";
import { isNull } from "../utils/";
import { IndicadorProvedor } from "../model/indicador-provedor";

@Injectable()
export class IndicadorProvedorService {
    constructor(private httpClient: HttpClient) { }

    public obterIndicadores(filtro?: any) {
        var queryString = convertToQueryString(filtro);
		return this.httpClient.get<Array<IndicadorProvedor>>(
			`${environment.serverUrl}/api/indicadorprovedor${queryString}`
		);
        

    }

    // public obterCarteiras(filtro?: any) {
    //     let url = `${environment.serverUrl}/api/carteiras`;

    //     if (filtro)
    //         url += "?" + Object.keys(filtro).map(prop => `${prop}=${filtro[prop]}`).join("&");

    //     return this.httpClient.get<Array<Carteira>>(url);
    // }

    // public obterPorId(id: number) {
    //     // return this.httpClient.get<Carteira>(`${environment.serverUrl}/api/carteiras/${id}`)
    //     //     .pipe(map(f => Carteira.fromRaw(f)));

    //     return this.httpClient.get<Carteira>(`${environment.serverUrl}/api/carteiras/${id}`)
    //         .pipe(map(f => {
    //             let carteira = Carteira.fromRaw(f);

    //             if (!isNull(carteira.carteiraUsuario))
    //                 carteira.carteiraUsuario = carteira.carteiraUsuario.map((m: CarteiraUsuario) => CarteiraUsuario.fromRaw(m));

    //             return carteira;
    //         }));
    // }

    // public criar(carteira: Carteira) {
    //     return this.httpClient.post<number>(`${environment.serverUrl}/api/carteiras/`, carteira);
    // }

    // public atualizar(carteira: Carteira) {
    //     return this.httpClient.put<number>(`${environment.serverUrl}/api/carteiras/${carteira.carteiraId}`, carteira);
    // }

    // public desativarCarteira(carteiraId: number) {
    //     return this.httpClient.patch<any>(`${environment.serverUrl}/api/carteiras/${carteiraId}/desativar-carteira`, null);
    // }

    // public obterCarteiraPadrao() {
    //     return this.httpClient.get<any>(`${environment.serverUrl}/api/carteiras/carteira-padrao`);
    // }
}
