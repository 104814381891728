import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { LayoutComponent } from "./layout/layout.component";
import { AlterarSenhaExpiradaComponent } from "./modules/autenticacao/alterar-senha-expirada/alterar-senha-expirada.component";
import { LoginComponent } from "./modules/autenticacao/login/login.component";

// Page Layouts
export const AppRoutes: Routes = [
  { path: "", redirectTo: "/app/home", pathMatch: "full" },
  { path: "login", component: LoginComponent },
	{ path: "alterarsenhaexpirada", component: AlterarSenhaExpiradaComponent },
  { path: "app", component: LayoutComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(AppRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
