import { isNull, isNullOrEmpty, isNullOrZero } from './isNull';
import { esbFormatFieldName } from './esbFormatFieldName';
import { ESBuilderData, ESBuilderRules, ESBuilderRulesConditions, ESBuilderFilterType } from "../../models";

export class QueryToModel {

    public static convert(queryString: string, mappedFields: Array<any>): ESBuilderData {

        if (isNullOrEmpty(queryString))
            return;

        let queryObject = JSON.parse(queryString);

        let model = new ESBuilderData();

        // Type
        let queryTypes = Object.keys(queryObject.query);
        model.type = queryTypes[0];

        // Rules
        model.rules = new Array<ESBuilderRules>();

        // Condições dentro do bool (should/must/must_not)
        let conditions = Object.keys(queryObject.query[model.type]);
        let o = 0;

        conditions.forEach((condition: string) => {

            queryObject.query.bool[condition].forEach((filter: any) => {

                let negation = (condition == 'must_not');
                let queryValues = this.mapFromElasticFilter(null, filter, mappedFields, negation);

                let rule = new ESBuilderRules({
                    "condition": ESBuilderRulesConditions.and,
                    "field": queryValues.field.nome,
                    "fieldType": queryValues.field.tipoDado,
                    "filterType": queryValues.filterType.filterType,
                    "filterParameters": queryValues.filterType.parameters,
                    "filterValues": queryValues.filterValues,
                    "order": o
                });

                model.rules.push(rule);
                o++;
            });
        });

        return model;
    }

    //#region [ Métodos de Map Elastic Filter ]

    // var filterPossibleType = {
    //     query_string: QueryStringElasticsearchFilter,
    //     terms: TermsElasticsearchFilter,
    //     term: TermElasticsearchFilter,
    //     not_terms: NotTermsElasticsearchFilter,
    //     exists: FieldQuery,
    //     bool: NotExistsQuery,
    //     script: EmptyQuery
    // };

    protected static mapFromElasticFilter(notInCollection: any, filter: any, mappedFields: Array<any>, negation: boolean) {
        const type = this.getQueryType(filter);

        switch (type) {
            case 'query_string':
                return this.mapFromElasticFilterQueryString(filter, mappedFields, negation);
            case 'terms':
                return this.mapFromElasticFilterTerms(filter, mappedFields, negation);
            case 'exists':
                return this.mapFromElasticFilterFieldQuery(filter, mappedFields, negation);
            case 'script':
                return this.mapFromElasticFilterEmptyQuery(filter, mappedFields, negation);
            // case 'regexp':
            //     return this.mapFromElasticFilterRegex(filter, mappedFields, negation);
            case 'term':
                return this.mapFromElasticFilterTerm(filter, mappedFields, negation);
            default:
                return this.mapFromElasticFilterBase(filter, mappedFields, negation);
        }
    }

    protected static mapFromElasticFilterBase(filter: any, mappedFields: Array<any>, negation: boolean) {

        // Funcoes auxiliares
        let getParameters = (filter: any, queryType: any, propertyName: any) => {
            if (queryType == 'regexp')
                return { 'query': filter[queryType][propertyName] };

            return filter[queryType][propertyName];
        }

        let getFilterType = (queryTypes: Array<any>, queryType: string, parameters: any) => {
            let parameterKeys = (queryType == 'regexp') ? '["query"]' : JSON.stringify(Object.keys(parameters).sort());
            let filterType = this.getFilterType(queryTypes, queryType, parameters, parameterKeys);
            return filterType;
        }

        let queryType = this.getQueryType(filter);                                                  // range
        let propertyName = Object.keys(filter[queryType])[0];                                       // inteiro
        let parameters = getParameters(filter, queryType, propertyName);                            // {gt:10}

        let mappedField = mappedFields.find(cm => esbFormatFieldName(cm.nome) == propertyName);
        let filterType = getFilterType(ESBuilderFilterType[mappedField.tipoDado], queryType, parameters);

        return {
            "queryType": queryType,
            "propertyName": propertyName,
            "field": mappedField,
            "filterType": filterType,
            "filterValues": parameters
        };
    }

    protected static mapFromElasticFilterQueryString(filter: any, mappedFields: Array<any>, negation: boolean) {

        let getFilterType = (queryTypes: Array<any>, queryType: string, parameters: any, negation: boolean) => {
            let parameterKeys = JSON.stringify(Object.keys(parameters).filter((f: string) => (f != 'default_field')).sort());

            let filterType = queryTypes.find((f: any) => {
                if (f.content == queryType && f.negation == negation && JSON.stringify(f.parameters.map((m: any) => (m.name)).sort()) == parameterKeys)
                    return f;
            });

            return filterType;
        }

        let getFilterValues = (filterValues: any) => {
            let values = {};
            Object.keys(filterValues).forEach((key: string) => {
                if (key != 'default_field')
                    values[key] = this.clearAsterisk(filterValues[key]);
            });
            return values;
        }

        let queryType = this.getQueryType(filter);
        let propertyName = filter[queryType].default_field;
        let mappedField = mappedFields.find(cm => esbFormatFieldName(cm.nome) == propertyName);

        let filterType = getFilterType(ESBuilderFilterType[mappedField.tipoDado], queryType, filter[queryType], negation);
        let filterValues = getFilterValues(filter[queryType]);

        return {
            "queryType": queryType,
            "propertyName": propertyName,
            "field": mappedField,
            "filterType": filterType,
            "filterValues": filterValues
        };
    }

    protected static mapFromElasticFilterTerms(filter: any, mappedFields: Array<any>, negation: boolean) {
        // Funcoes auxiliares
        let getFilterType = (queryTypes: Array<any>, queryType: string) => {
            let filterType = queryTypes.find((f: any) => (f.content == queryType));
            return filterType;
        }

        let queryType = this.getQueryType(filter);
        let propertyName = Object.keys(filter[queryType])[0];
        let parameters = filter[queryType][propertyName];

        let mappedField = mappedFields.find(cm => esbFormatFieldName(cm.nome) == propertyName);
        let filterType = getFilterType(ESBuilderFilterType[mappedField.tipoDado], queryType);

        return {
            "queryType": queryType,
            "propertyName": propertyName,
            "field": mappedField,
            "filterType": filterType,
            "filterValues": { [filterType.parameters[0].name.toString()]: parameters.join('') }
        };
    }

    protected static mapFromElasticFilterFieldQuery(filter: any, mappedFields: Array<any>, negation: boolean) {
        // Funcoes auxiliares
        let getFilterType = (queryTypes: Array<any>, queryType: string, parameters: any) => {
            let parameterKeys = JSON.stringify(Object.keys(parameters).sort());
            let filterType = this.getFilterType(queryTypes, queryType, parameters, parameterKeys);
            return filterType;
        }

        let queryType = this.getQueryType(filter);
        let propertyName = filter[queryType][Object.keys(filter[queryType])[0]];
        let parameters = filter[queryType];

        let mappedField = mappedFields.find(cm => esbFormatFieldName(cm.nome) == propertyName);
        let filterType = getFilterType(ESBuilderFilterType[mappedField.tipoDado], queryType, parameters);

        return {
            "queryType": queryType,
            "propertyName": propertyName,
            "field": mappedField,
            "filterType": filterType,
            "filterValues": {}
        };
    }

    protected static mapFromElasticFilterEmptyQuery(filter: any, mappedFields: Array<any>, negation: boolean) {

        let getFilterType = (queryTypes: Array<any>, queryType: string) => {
            let filterType = queryTypes.find((f: any) => (f.content == queryType));
            return filterType;
        }

        let getPropertName = (filterValue: string) => {
            let result = filterValue
                .match(/doc\[\'(.*?)\'\].empty/g)
                .map((value: string) => (value.replace(/doc\[\'/g, '').replace(/\'\].empty/g, '')));
            return (result.length > 0) ? result[0] : '';
        }

        let queryType = this.getQueryType(filter);
        let propertyName = getPropertName(filter[queryType].script.inline);

        let mappedField = mappedFields.find(cm => esbFormatFieldName(cm.nome) == propertyName);
        let filterType = getFilterType(ESBuilderFilterType[mappedField.tipoDado], queryType);

        return {
            "queryType": queryType,
            "propertyName": propertyName,
            "field": mappedField,
            "filterType": filterType,
            "filterValues": {}
        };
    }

    protected static mapFromElasticFilterTerm(filter: any, mappedFields: Array<any>, negation: boolean) {
        // Funcoes auxiliares
        let getParameters = (filter: any, queryType: any, propertyName: any, fieldType: string) => {
            if (fieldType == 'Boolean')
                return { 'query': filter[queryType][propertyName] };

            return filter[queryType][propertyName];
        }

        let getFilterType = (queryTypes: Array<any>, queryType: string, parameters: any) => {
            let parameterKeys = (queryType == 'regexp') ? '["query"]' : JSON.stringify(Object.keys(parameters).sort());
            let filterType = this.getFilterType(queryTypes, queryType, parameters, parameterKeys);
            return filterType;
        }

        let queryType = this.getQueryType(filter);
        let propertyName = Object.keys(filter[queryType])[0];

        let mappedField = mappedFields.find(cm => esbFormatFieldName(cm.nome) == propertyName);
        let parameters = getParameters(filter, queryType, propertyName, mappedField.tipoDado);
        let filterType = getFilterType(ESBuilderFilterType[mappedField.tipoDado], queryType, parameters);

        return {
            "queryType": queryType,
            "propertyName": propertyName,
            "field": mappedField,
            "filterType": filterType,
            "filterValues": parameters
        };
    }

    //#endregion

    //#region [ Métodos auxiliares ]

    private static getQueryType = (filter: any) => (Object.keys(filter)[0]);

    private static getFilterType(queryTypes: Array<any>, queryType: string, parameters: any, queryParametersKeys: string) {
        let filterType = queryTypes.find((f: any) => {
            if (f.content == queryType && JSON.stringify(f.parameters.map((m: any) => (m.name)).sort()) == queryParametersKeys)
                return f;
        });

        return filterType;
    }

    private static clearAsterisk(text: string) {
        if (text.startsWith("*"))
            text = text.substring(1);

        if (text.endsWith("*"))
            text = text.substring(0, text.length - 1);

        return text;
    }

    //#endregion
}



