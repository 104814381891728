import { Directive, ElementRef, Input, HostListener } from "@angular/core";

@Directive({
  selector: "[appPostForm]"
})
export class PostFormDirective {
  _botao;
  public get botao(): string {
    return this._botao;
  }

  @Input()
  public set botao(botao: string) {
    this._botao = botao;
  }

  @HostListener('keypress', ['$event']) onKeyDown(e) {
    if (e.keyCode == 13) 
      this.eventFire(document.getElementById(this._botao), 'click');
  }

  eventFire(el, etype){
    if (el.fireEvent) {
      el.fireEvent('on' + etype);
    } else {
      var evObj = document.createEvent('Events');
      evObj.initEvent(etype, true, false);
      el.dispatchEvent(evObj);
    }
  }

  constructor(private el: ElementRef) {}
}
