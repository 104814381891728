import { ElementRef, HostBinding, Component, Input, OnChanges, ViewChild, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-ftn-quantidade',
    template: `<div class="quantityNodeCard {{node.data.cssClass}}" (click)="selectNodeGraph(node.id)">
                   <label class="value">{{node.data.queryQtdRegistros | numberFormat}}</label>
                   <mat-icon class="actions clickable" [matTooltip]="'telaFluxo.visualizarRegistros' | translate" (click)="mostrarPreview(node.id)" >
                       search
                   </mat-icon>
               </div>`,
    styles: []
})
export class FluxoTemplateNoQuantidadeComponent {

    @Input()
    guidSelecionado: string;

    @Input()
    node: any;

    @Output()
    selectNode = new EventEmitter<string>();

    @Output()
    mostraPreview = new EventEmitter<string>();

    constructor() {
        this.selectNode = new EventEmitter<string>();
        this.mostraPreview = new EventEmitter<string>();
    }

    selectNodeGraph(guidId: string) {
        this.selectNode.emit(guidId);
    }

    mostrarPreview(guidId: string) {
        this.mostraPreview.emit(guidId);
    }
}
