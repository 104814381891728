import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import {
  MatInputModule,
  MatRadioModule,
  MatButtonModule,
  MatListModule,
  MatDialogModule,
  MatIconModule,
  MatDividerModule,
  MatSelectModule,
  MatCardModule,
  MatCheckboxModule,
  MatTabsModule
} from "@angular/material";
import { AppCommonModule } from "../common/common.module";
import { TableModule } from "primeng/table";
import { PickListModule } from 'primeng/picklist';
import { SelectModule } from "ng-select";
import { TabsUsuarioComponent } from "./tabs-usuario/tabs-usuario.component";
import { FormUsuarioComponent } from "./form-usuario/form-usuario.component";
import { ListaUsuarioComponent } from "./lista-usuario/lista-usuario.component";
import { FiltroUsuarioComponent } from "./filtro-usuario/filtro-usuario.component";
import { FormApiKeyComponent } from "./form-apikey/form-apikey.component";
import { FiltroApiKeyComponent } from "./filtro-apikey/filtro-apikey.component";
import { ListaApiKeyComponent } from "./lista-apikey/lista-apikey.component";
import { FormPermissoesComponent } from "./form-permissoes/form-permissoes.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,

    MatCardModule,
    MatTabsModule,
    MatInputModule,
    MatCheckboxModule,
    MatRadioModule,
    MatButtonModule,
    MatListModule,
    MatDialogModule,
    MatIconModule,
    MatDividerModule,
    MatSelectModule,
    AppCommonModule,
    TableModule,
    PickListModule,
    SelectModule
  ],
  declarations: [
    TabsUsuarioComponent,
    FormUsuarioComponent,
    FiltroUsuarioComponent,
    ListaUsuarioComponent,
    FormApiKeyComponent,
    FiltroApiKeyComponent,
    ListaApiKeyComponent,
    FormPermissoesComponent,
  ]
})

export class UsuarioModule { }
