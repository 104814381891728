import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-card-quantidade",
  templateUrl: "./card-quantidade.component.html"
})
export class CardQuantidadeComponent implements OnInit {
  @Input()
  icon: string;
  @Input()
  text: string;
  @Input()
  value: number;

  constructor() {}

  ngOnInit() {}
}
