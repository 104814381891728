import { Component } from '@angular/core';
import { FabMenuModel } from './fab-menu/fab-menu.model';

@Component({
  selector: 'my-app-layout',
  templateUrl: './layout.component.html',
})
export class LayoutComponent {

  public fabMenuLista: FabMenuModel[] = [
    { icone: "person", descricao: "Novo Usuário", rota: ['/app/usuario/create'] },
    { icone: "storage", descricao: "Nova Lista", rota: ['/app/lista/create'] },
    { icone: "business", descricao: "Nova Estratégia", rota: ['/app/estrategia/create'] }
  ];
}
