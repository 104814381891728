const validate = require("validate.js");

export class FluxoInformativo {
    fluxoInformativoId: number;
    fluxoProcessoId: number;
    filtro: string;
    filtroRegras: string;
	quantidadeMaximaExportacao: number;
	quantidadeMaximaExportacaoPct: number;

    constructor() { }

    validar() {
        var validacao: any = {
            fluxoProcessoId: {
                presence: { allowEmpty: false, message: '^campoObrigatorio' }
            }
        };

        return validate.validate(this, validacao);
    }

    static fromRaw(rawFluxo): FluxoInformativo {
        return Object.assign(new FluxoInformativo(), rawFluxo);
    }
}
