import { Component, Input, OnInit, Output, OnDestroy, EventEmitter, AfterContentInit, AfterViewInit, } from "@angular/core";
import { ExpressaoRegex } from "../../common/model/expressao-regex";
import { isNull, isNullOrEmpty, esbFormatFieldName, isNullOrZero } from "../libs/utils";
import { ESBuilderRules, ESBuilderFilterType, ESBuilderRulesChange, ESBuilderRulesChangeType } from "../models";
import { ElasticsearchRepositoryService } from "../../elasticsearch/repository/elasticsearch-repository.service";
import { EsBuilderService } from "../libs/services/esbuilder.service";
import { Subscription } from "rxjs";

@Component({
    selector: "app-esbuilder-item",
    templateUrl: "./esbuilder-item.component.html",
    styleUrls: ["./esbuilder-item.component.scss"],
    providers: [
        { useClass: ElasticsearchRepositoryService, provide: ElasticsearchRepositoryService },
        //{ useClass: EsBuilderService, provide: EsBuilderService }
    ]
})
export class EsBuilderItemComponent implements OnInit, OnDestroy {

    //#region [ Inputs/Outputs ]

    @Input()
    isFirst: boolean;

    @Input()
    isLast: boolean;

    @Input()
    listaNome: string;

    @Input()
    templateId: number;

    @Input()
    listaId: number;

    @Output()
    dataRuleChange = new EventEmitter<ESBuilderRulesChange>();

    @Input()
    enableConditionOr: boolean;

    @Input()
    readOnly: boolean = false;

    //#endregion

    //#region   

    listaExpressaoExpressaoSelecao: any;
    listaOpcoes: Array<any>;

    //#region [ GET/SET - CamposMapeados ]

    _camposMapeados: any;

    public get camposMapeados() {
        return this._camposMapeados;
    }

    @Input()
    public set camposMapeados(cm: any) {
        if (!cm) return;
        this._camposMapeados = cm;
    }

    //#endregion 

    //#region [ GET/SET - FiltrosMapeados ] 

    _filtrosMapeados: Array<any>;

    public get filtrosMapeados() {

        return this._filtrosMapeados;
    }

    public set filtrosMapeados(fm: Array<any>) {
        if (!fm) return;
        this._filtrosMapeados = fm;
    }

    //#endregion 

    //#region [ GET/SET - CampoSelecionado ]

    public get campoSelecionado() {
        return this.dataRule.field;
    }

    public set campoSelecionado(cs: string) {
        if (isNull(cs)) {
            this.dataRule.field = null;
            this.dataRule.fieldType = null;
            this.dataRule.fieldNestedType = null;
            this.dataRule.fieldNestedList = null;

            //Limpando os outros campos
            this.filtrosMapeados = [];
            this.filtroSelecionado = undefined;
            return;
        }

        if (Array.isArray(this.dataRule.filterValues))
            this.dataRule.filterValues = {};

        let campo = this.camposMapeados.find((f: any) => (f.nome == cs));
        this.dataRule.field = cs;
        this.dataRule.fieldType = campo.tipoDado;

        this.dataRule.fieldNestedType = campo.tipoDadoNested;
        this.dataRule.fieldNestedList = campo.listaNested;

        // Populando os tipos de filtros
        this.filtrosMapeados = (this.dataRule.fieldType == "Nested")
            ? ESBuilderFilterType[this.dataRule.fieldType].filter((f: any) => f.nestedType == campo.tipoDadoNested)
            : ESBuilderFilterType[this.dataRule.fieldType];

        //Limpando os outros campos
        this.filtroSelecionado = undefined;

        // Verificando se existem exporessões regulares para aquele tipo
        if (this.listaExpressaoRegex.some((f: ExpressaoRegex) => (f.tipoDado == campo.tipoDado)))
            this.adicionarOpcaoRegex();

        if (this.isDataTypeBoolean)
            this.dataRule.filterValues = {};
    }

    //#endregion

    //#region [ GET/SET - FiltroSelecionado ]

    public get filtroSelecionado() {
        return this.dataRule.filterType;
    }

    public set filtroSelecionado(fs: string) {

        if (fs == undefined) {
            this.dataRule.filterType = null;
            this.dataRule.filterParameters = [];
            this.dataRule.filterValues = {};
            return;
        }

        this.dataRule.filterType = fs;

        let param = (this.dataRule.fieldType == 'Nested')
            ? ESBuilderFilterType[this.dataRule.fieldType].find((f: any) => (f.nestedType == this.dataRule.fieldNestedType && f.filterType == fs))
            : ESBuilderFilterType[this.dataRule.fieldType].find((f: any) => (f.filterType == fs));


        //let param = ESBuilderFilterType[this.dataRule.fieldType].find((f: any) => (f.filterType == fs));
        this.dataRule.filterParameters = param.parameters;
        this.dataRule.filterValues = {};

        this.dataRule.nestedType = param.nestedType;
        this.dataRule.nestedContent = param.nestedContent;
        this.dataRule.nestedList = param.nestedList;

        if (this.isDataTypeBoolean && (fs == "true" || fs == "false"))
            this.dataRule.filterValues[this.dataRule.filterParameters[0].name] = (fs == "true");

        // Verificando se existem expressões regulares para aquele tipo
        if (this.listaExpressaoRegex.some((f: ExpressaoRegex) => (f.tipoDado == this.dataRule.fieldType)))
            this.adicionarOpcaoRegex();


        if (this.isDataTypeList || (this.isDataTypeNested && this.isDataTypeNested_FieldNestedList)) {
            if (Array.isArray(this.dataRule.filterValues) == false)
                this.dataRule.filterValues = [];

            this.obterListaOpcoes();
        }
    }

    //#endregion

    //#region [ GET/SET - Lista Expressao Regex ]  

    _listaExpressaoRegex = new Array<ExpressaoRegex>();

    public get listaExpressaoRegex() {
        return this._listaExpressaoRegex;
    }

    @Input()
    public set listaExpressaoRegex(rg: Array<ExpressaoRegex>) {
        if (!rg) return;
        this._listaExpressaoRegex = rg;
    }

    //#endregion

    //#region [ GETS - IsDataType ]

    public get isDataTypeList(): boolean {
        return this.campoSelecionado && this.dataRule.fieldType == "Lista";
    }

    public get isDataTypeBoolean(): boolean {
        return this.campoSelecionado && this.dataRule.fieldType == "Boolean";
    }

    public get isDataTypeNumeric(): boolean {
        let num = ["Inteiro", "Long", "Decimal"];
        return this.campoSelecionado && num.some((s: any) => (s == this.dataRule.fieldType));
    }

    public get isDataTypeNested(): boolean {
        return this.campoSelecionado && this.dataRule.fieldType == "Nested";
    }

    public get isDataTypeNested_Date(): boolean {
        return this.campoSelecionado && this.dataRule.fieldType == "Nested"// && this.dataRule.tipoDadoNested == "Data";
    }

    public get isDataTypeNested_FieldNestedList(): boolean {
        return this.campoSelecionado && this.dataRule.fieldType == "Nested" && this.dataRule.fieldNestedList === true;
    }

    public get isFilterValueRegex(): boolean {
        if (!this.filtroSelecionado)
            return false;

        let filter = ESBuilderFilterType[this.dataRule.fieldType].find((f: any) => (f.filterType == this.filtroSelecionado))
        return filter && filter.regex;
    }

    //#endregion

    //#region [ GETS - Outros ]

    private get isFilterSelected(): boolean {
        return (!isNullOrEmpty(this.filtroSelecionado));
    }

    private get displayValueFields(): boolean {
        if (!this.filtroSelecionado)
            return false;

        return !(this.filtroSelecionado == "vazio" || this.filtroSelecionado == "nao_vazio" ||
            this.filtroSelecionado == "existe" || this.filtroSelecionado == "nao_existe");
    }

    //#endregion

    //#region [ GET/SET - DataRule ]

    _dataRule: ESBuilderRules;

    public get dataRule() {
        return this._dataRule;
    }

    @Input()
    public set dataRule(dr: ESBuilderRules) {
        if (!dr) return;
        this._dataRule = dr;

        this.filtrosMapeados = ESBuilderFilterType[this.dataRule.fieldType];

        // Verificando se existem expressões regulares para aquele tipo
        if (this.listaExpressaoRegex.some((f: ExpressaoRegex) => (f.tipoDado == this.dataRule.fieldType)))
            this.adicionarOpcaoRegex();
    }

    //#endregion

    //#region [ GET/SET - ListaOpcoesSelecionadas ]  

    public get listaOpcoesSelecionadas(): Array<any> {
        if (Array.isArray(this.dataRule.filterValues) == false)
            this.dataRule.filterValues = [];

        return this.dataRule.filterValues;
    }

    public set listaOpcoesSelecionadas(values: Array<any>) {
        this.dataRule.filterValues = values.sort();
    }

    //#endregion

    subDadosListaCarregados: Subscription;
    subDadosListaExpressaoCarregados: Subscription;

    constructor(
        private elasticsearchRepositoryService: ElasticsearchRepositoryService,
        private esBuilderService: EsBuilderService) {
        this.dataRuleChange = new EventEmitter<ESBuilderRulesChange>();
    }

    ngOnInit() {
        this.subDadosListaCarregados = this.esBuilderService.onDadosListaCarregados.subscribe(data => {
            this.listaNome = data.listaNome;
            this.templateId = data.templateId;

            if (this.isDataTypeList || (this.isDataTypeNested && this.isDataTypeNested_FieldNestedList)) {
                if (Array.isArray(this.dataRule.filterValues) == false)
                    this.dataRule.filterValues = [];
       
                this.obterListaOpcoes();
            }
        });

        this.subDadosListaExpressaoCarregados = this.esBuilderService.onDadosListaExpressaoCarregados.subscribe(data => {
            this.listaExpressaoRegex = data;
            // Verificando se existem expressões regulares para aquele tipo
            if (this.listaExpressaoRegex.some((f: ExpressaoRegex) => (f.tipoDado == this.dataRule.fieldType)))
                this.adicionarOpcaoRegex();
        });


    }

    obterListaOpcoes(): any {
        var format = new Intl.NumberFormat(navigator.language);

        let index: string = this.listaNome;
        let templateId: number = this.templateId;
        let fieldName: string = esbFormatFieldName(this.campoSelecionado)
        let query: any = JSON.parse('{"bool":{"must":[],"must_not":[]}}');

        let negation: boolean = (this.dataRule.filterType == "nao_contem");

        if (isNullOrEmpty(index) || isNullOrZero(templateId) || isNullOrEmpty(fieldName))
            return;

        this.elasticsearchRepositoryService
            .getOptionForField(index, this.listaId, this.templateId, fieldName, query, negation)
            .subscribe(collection => {
                this.listaOpcoes = Object.keys(collection).map(label => ({
                    label: label.replace(/_/g, " "),
                    value: label,
                    suffix: format.format(collection[label])
                }));
            });
    }

    adicionarOpcaoRegex() {
        this.listaExpressaoExpressaoSelecao = this.listaExpressaoRegex
            .filter((f: ExpressaoRegex) => f.tipoDado == this.dataRule.fieldType)
            .map((m: ExpressaoRegex) => ({ value: m.expressao, label: m.nome }));
    }

    conditionChanged(event: any) {
        this.dataRuleChange.emit(new ESBuilderRulesChange(ESBuilderRulesChangeType.condition));
    }

    fieldChanged(event: any) {
        this.dataRuleChange.emit(new ESBuilderRulesChange(ESBuilderRulesChangeType.field));
    }

    filterChanged(event: any) {
        this.dataRuleChange.emit(new ESBuilderRulesChange(ESBuilderRulesChangeType.filter));
    }

    filterValueChanged(event: any) {
        this.dataRuleChange.emit(new ESBuilderRulesChange(ESBuilderRulesChangeType.filterValue));
    }

    getFilterTypeName(value: string) {
        if (isNullOrEmpty(value)) return '';
        return this.filtrosMapeados.find((f: any) => (f.filterType == value)).name;
    }

    getFilterExpressaoSelecao(value: string) {
        if (isNullOrEmpty(value) || !this.listaExpressaoRegex.some((f: ExpressaoRegex) => (f.tipoDado == this.dataRule.fieldType && f.expressao == value)))
            return '';

        let expressao = this.listaExpressaoRegex.find((f: ExpressaoRegex) => (f.tipoDado == this.dataRule.fieldType && f.expressao == value));
        return expressao.nome;
    }

    getOpcaoSelecionada(values: any) {
        if (this.listaOpcoes) {
            let retorno: Array<any> = [];
            this.listaOpcoes.forEach(f => {
                if ((this.dataRule.filterValues as Array<any>).some(s => s == f.value))
                    retorno.push(f.value);
            });
            return retorno.join(', ');
        }
        return '';
    }

    ngOnDestroy() {
        if (this.subDadosListaCarregados)
            this.subDadosListaCarregados.unsubscribe();

        if (this.subDadosListaExpressaoCarregados)
            this.subDadosListaExpressaoCarregados.unsubscribe();

    }
}
