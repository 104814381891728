export class PesquisaLista {
    listaId: number;
    nomeLista: string;
    listaTemplateId: number;
    quantidadeEncontrada: number;
    query: any;

    constructor(listaId: number, nomeLista: string, listaTemplateId: number, quantidadeEncontrada: number, query: any) {
        this.listaId = listaId;
        this.nomeLista = nomeLista;
        this.listaTemplateId = listaTemplateId;
        this.quantidadeEncontrada = quantidadeEncontrada;
        this.query = query;
    }
}

export class FiltroPesquisaLista {
    carteiraId: number;
    template: number;
    nomeCampo: string;
    valorCampo: string;
    campoAgrupado: CampoTemplateAgrupadoNomeTipo;
}

export class CampoTemplateAgrupadoNomeTipo {
    public nome: string;
    public listaCampoTipoId: number;
    public listaCampoTipoNome: string;
    public camposListas: Array<CamposListas>;
}

export class CamposListas {
    public nomeCampo: string;
    public listas: Array<ListaCampo>;
}

export class ListaCampo {
    public listaId: number;
    public nomeLista: string;
    public listaTemplateId: number;
}