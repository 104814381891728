import { Component, Inject, ViewChild, OnDestroy } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Subscription } from "rxjs";
import { FluxoService } from "../../../common/service/fluxo.service";
import { isNullOrEmpty } from '../../../common/utils';
import { ESBuilderData } from '../../../esbuilder/models'
import { PreviewDadosEstrategiaComponent } from "../../../estrategia/form-estrategia/preview-dados-estrategia/preview-dados-estrategia.component";

@Component({
    selector: "app-fluxo-visualizar-filtro-modal",
    templateUrl: "./fluxo-visualizar-filtro-modal.component.html",
    styleUrls: ["./fluxo-visualizar-filtro-modal.component.scss"],
    providers: [
        { useClass: FluxoService, provide: FluxoService }
    ]
})
export class FluxoVisualizarFiltroModalComponent implements OnDestroy {

    quantidade: number = 0;
    listaId: number;
    listaTemplateId: number;
    listaAlias: string;
    query: any = JSON.parse('{"size":5,"_source":{"includes":[]},"query":{"bool":{"must":[],"must_not":[{"exists":{"field":"_integrado"}}]}}}');
    dataRules: ESBuilderData = new ESBuilderData();
    subFluxo: Subscription;

    constructor(
        private fluxoService: FluxoService,
        private ref: MatDialogRef<FluxoVisualizarFiltroModalComponent>,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA)
        public data: { listaId: number, listaTemplateId: number, listaAlias: string, dataRules: string, query: string }
    ) {
        this.quantidade = 0;
        this.listaId = this.data.listaId;
        this.listaTemplateId = this.data.listaTemplateId;
        this.listaAlias = this.data.listaAlias;

        if (!isNullOrEmpty(this.data.dataRules))
            this.dataRules = JSON.parse(this.data.dataRules);

        if (!isNullOrEmpty(this.data.query)) {
            this.query = this.data.query;

            this.fluxoService
                .obterQueryQuantidadeRegistrosPost(this.data.listaId, this.data.query)
                .subscribe((result: any) => {
                    this.quantidade = (result) ? result : 0;
                });
        }

    }

    updateQuery(event: any) {
        this.fluxoService
            .obterQueryQuantidadeRegistrosPost(this.data.listaId, JSON.stringify(event.query))
            .subscribe((result: any) => {
                this.quantidade = (result) ? result : 0;
            });
    }

    mostrarPreview() {
        let query: any = JSON.parse(this.query);
        query["size"] = 10;

        this.dialog.open(PreviewDadosEstrategiaComponent, {
            height: "700px",
            width: "80%",
            hasBackdrop: true,
            data: {
                templateId: this.listaTemplateId,
                lista: this.listaAlias,
                listaId: this.listaId,
                query: query
            }
        });
    }

    close() {
        this.ref.close();
    }

    fechar() {
        this.ref.close();
    }

    ngOnDestroy() {
        if (this.subFluxo)
            this.subFluxo.unsubscribe();
    }
}
