import {
	Component,
	OnInit,
	Input,
	Output,
	Inject,
	EventEmitter
} from "@angular/core";
import * as moment from "moment";

@Component({
	selector: "app-elasticsearch-edit-item",
	templateUrl: "./elasticsearch-edit-item.component.html"
})
export class ElasticsearchEditItemComponent implements OnInit {
	@Input()
	valor: any;

	@Input()
	campo: string;

	@Input()
	tipoDado: string;

	@Output()
	alterarDado: EventEmitter<{}>;

	public get tipoDadoDescricao(): string {
		switch (this.tipoDado) {
			case '8': return 'Texto';
			case '9': return 'Decimal';
			case '10': return 'Data';
			case '11': return 'Lista';
			case '12': return 'Sim/Não';
			case '16': return 'Inteiro';
			case '17': return 'Long';
			default: return '';
		}
	}



	item: any;
	constructor() {
		this.alterarDado = new EventEmitter<{}>();
	}

	campoEhData() {
		if (this.tipoDado != "10") return false;

		if (!this.valor) return true;
		let dado = moment(this.valor).toDate();

		return Object.prototype.toString.call(dado) === "[object Date]";
	}

	campoEhNumerico() {
		return (
			this.tipoDado &&
			this.tipoDado == "9" &&
			!Number.isNaN(Number.parseFloat(this.valor))
		);
	}

	campoEhBoolean() {
		return (
			this.tipoDado &&
			this.tipoDado == "12" &&
			Object.prototype.toString.call(this.valor) == "[object Boolean]"
		);
	}

	ngOnInit() { }

	dadoAlterado() {
		let valor = this.valor;
		if (this.campoEhData()) {
			try {
				valor = moment(this.valor).format("DD/MM/YYYY HH:mm:ss");
			} catch {
				return;
			}
		}

		this.alterarDado.emit({ campo: this.campo, valor: this.valor });
	}
}
