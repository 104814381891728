import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { environment } from "../../../../environments/environment";
import { PlanoTarifaProvedor } from "../model/plano-tarifa-provedor";
import { Observable } from "rxjs";

@Injectable()
export class PLanoTarifaProvedorService {
    constructor(private httpClient: HttpClient) { }

    public obterPlanos(provedorId: number): Observable<Array<PlanoTarifaProvedor>> {
        let url = `${environment.serverUrl}/api/planotarifaprovedor/${provedorId}`;
        return this.httpClient.get<Array<PlanoTarifaProvedor>>(url);
    }

    public salvar(planos: Array<PlanoTarifaProvedor>) {
        return this.httpClient.post<number>(`${environment.serverUrl}/api/planotarifaprovedor/`, planos);
    }
}
