
import {map} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import "rxjs/add/operator/do";
import { environment } from "../../../../environments/environment";

@Injectable()
export class CampanhaService {
  constructor(private _httpClient: HttpClient) {}

  obterCampanhas() {
    return this._httpClient
      .get<any>(`${environment.serverUrl}/api/campanhas`).pipe(
      map(campanhas =>
        Object.keys(campanhas).map(id => ({
          id: parseInt(id),
          nome: campanhas[id]
        }))
      ));
  }
}
