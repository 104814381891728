import { ESBuilderRules } from "./rules.model";

export enum ESBFiltroStatusRegistroLista {
	naoUtilizado = 0,
	total = 1,
	utilizado = 2
}

export class ESBuilderData {
    rules: Array<ESBuilderRules>;
    type: string;
    includeIntegratedRegister: ESBFiltroStatusRegistroLista;

    constructor() {
        this.rules = new Array<ESBuilderRules>();
        this.type = 'bool';
        this.includeIntegratedRegister = ESBFiltroStatusRegistroLista.naoUtilizado;
    }

    static fromRaw(raw: any) {
        let data = new ESBuilderData();
        Object.assign(data, raw);
        return data;
    }
}