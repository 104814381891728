export class ListaTipoCampoFormatacao {
	listaTipoCampoFormatacaoId: number;
	listaCampoTipoId: number;
	formato: string;
	precisao: number;
	exemplo: string;
	personalizada: boolean
	local: ListaTipoCampoFormatacaoLocal
	label : string;
}

export enum ListaTipoCampoFormatacaoLocal {
	Ambos = 0,
	Importacao = 1,
	Exportacao = 2
}
