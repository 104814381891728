import "rxjs/add/operator/do";
import "rxjs/add/operator/first";
import "rxjs/add/operator/mergeMap";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import swal from "sweetalert2";
import { Regua } from "../../common/model/regua";
import { ReguaService } from "../../common/service/regua.service";
import { CustomAlertService } from "../../common/service/custom-alert.service";
import { FiltroLista } from "../../common/model/filtro-lista";
import { ListaService } from "../../common/service/lista.service";
import { Lista } from "../../common/model/lista";
import { ReguaEstrategia } from "../../common/model/ReguaEstrategia";

@Component({
	selector: "app-form-regua-estrategia",
	templateUrl: "./form-regua-estrategia.component.html",
	styleUrls: ["./form-regua-estrategia.component.scss"],
	providers: [
		{ useClass: ReguaService, provide: ReguaService },
		{ useClass: ListaService, provide: ListaService },
		{ useClass: CustomAlertService, provide: CustomAlertService }
	]
})
export class FormReguaEstrategiaComponent implements OnInit {
	dirty: boolean;
	regua: Regua;
	listas: Lista[];

	public get erros() {
		if (!this.dirty) return;
		return this.regua.validar();
	}

	public get isEdit(): boolean {
		return this.regua != undefined && this.regua.reguaId != undefined;
	}

	constructor(
		private route: ActivatedRoute,
		private reguaService: ReguaService,
		private listaService: ListaService,
		private router: Router,
		private customAlertService: CustomAlertService
	) {
		this.regua = new Regua();
		this.route.params.subscribe(params => this.carregar(params));
	}

	ngOnInit() {
		this.obterListas();
	}

	obterListas() {
		let filtroLista = new FiltroLista();
		filtroLista.ignorarErroPesquisa = true;
		this.listaService.obterLista(filtroLista).subscribe(listas => (this.listas = listas));
	}

	carregar(params: any) {
		if (!params.id) return;
		this.reguaService.obterPorId(params.id).subscribe(regua => {
			this.regua = regua;
		});
	}

	updateRegua(event: any){
		this.reguaService.obterPorId(event.reguaId).subscribe(regua => {
			this.regua = regua;
		});
	}

	liveError(property: string) {
		if (this.dirty) {
			var validationResult = this.regua.validar();
			if (!validationResult) return null;
			return validationResult[property] ? validationResult[property][0] : null;
		}
	}

	salvar() {
		this.dirty = true;

		if (this.regua.validar()) {
			this.customAlertService.show("telaRegua.regua", "telaPadrao.camposInvalidos", "error");
			return;
		}

		let sucesso = (response: any) => {

			if (this.isEdit) {
				this.customAlertService.show("telaRegua.regua", "telaPadrao.sucessoSalvar", "success");
				this.router.navigate(["/app/regua/"]);
			} else {
				this.customAlertService
					.showWithConfiguration({
						title: "telaRegua.regua",
						text: "telaRegua.reguaSalva",
						icon: "success",
						showCancelButton: true,
						confirmButtonText: "telaPadrao.sim",
						cancelButtonText: "telaPadrao.nao"
					})
					.then(data => {
						if (data.dismiss == swal.DismissReason.cancel)
							return this.router.navigate(["/app/regua"]);

						this.router.navigate(["/app/regua", response["data"]]);
					});
			}
		};

		let erro = () => { this.customAlertService.show("telaRegua.regua", "telaPadrao.erroSalvar", "error") };

		if (!this.regua.reguaId)
			return this.reguaService.criar(this.regua).subscribe(sucesso, erro);

		this.reguaService.atualizar(this.regua).subscribe(sucesso, erro);
	}
}
