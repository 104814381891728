import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Regua } from "../../common/model/regua";
import { ReguaService } from "../../common/service/regua.service";

@Component({
    selector: "app-visualizar-regua-modal",
    templateUrl: "./visualizar-regua-modal.component.html",
    providers: [
        { useClass: ReguaService, provide: ReguaService }
    ]    
})
export class VisualizarReguaModalComponent {
    regua: Regua;
        
    constructor(
        private reguaService: ReguaService,        
		public dialogRef: MatDialogRef<VisualizarReguaModalComponent>,   
        @Inject(MAT_DIALOG_DATA) public data: { regua: Regua }        
    ) {
        this.regua = new Regua();
        this.carregar(data.regua.reguaId);
    }

	carregar(reguaId: any) {
		this.reguaService.obterPorId(reguaId).subscribe(regua => {
			this.regua = regua;
		});
    }

    updateRegua(event: any){
		this.reguaService.obterPorId(event.reguaId).subscribe(regua => {
			this.regua = regua;
		});
	}
    
	fechar() {
		this.dialogRef.close();
	}    
}