import { Estrategia } from "./estrategia";

export class ReguaEstrategia {
    reguaEstrategiaId: number;
    reguaId: number;
    listaEstrategiaId: number;
    ordem: number;
    dataCadastro: Date;
    usuarioCadastro: number;
    dataAlteracao: Date;
    usuarioAlteracao: number;
    ativo: boolean;
    estrategia: Estrategia;

    constructor(reguaId: number, listaEstrategiaId: number, ordem: number) {
        this.reguaId = reguaId;
        this.listaEstrategiaId = listaEstrategiaId;
        this.ordem = ordem;
    }
}

export class ReguaEstrategiaEstrategia {

}