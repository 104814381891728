import { map } from 'rxjs/operators';
import "rxjs/add/operator/map";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { DownloadRegistro } from "../model/download-registro";
import { FiltroDownloadRegistro } from "../model/filtro-download-registro";
import { AutenticacaoService } from "./autenticacao.service";
import { downloadFile } from "./download-file";
import { convertToQueryString } from "./query-string";
import { ResponseContentType, Http, Headers } from "@angular/http";

@Injectable()
export class DownloadRegistroService {
    constructor(private httpClient: HttpClient,
        private authService: AutenticacaoService,
        private http: Http
    ) {

    }

    public criarDownloadTotal(model: DownloadRegistro) {
        return this.httpClient.post(`${environment.serverUrl}/api/downloadregistro`,
            model
        );
    }

    public obterDownloadRegistro(filtro: FiltroDownloadRegistro) {
        return this.httpClient.get<Array<DownloadRegistro>>(
            `${environment.serverUrl}/api/downloadregistro${convertToQueryString(filtro)}`
        );
    }


    public baixarArquivoRegistro(downloadregistro: DownloadRegistro) {
        this.http
            .get(
                `${environment.serverUrl}/api/downloadregistro/${downloadregistro.downloadRegistroId}/download-arquivo`,
                {
                    responseType: ResponseContentType.Blob,
                    headers: new Headers({
                        Authorization: `Bearer ${this.authService.obterTokenAutenticado()}`
                    })
                }
            )
            .subscribe(res => downloadFile(res, `${downloadregistro.nomeArquivo}`));
    }
}