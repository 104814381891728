import { ElementRef, HostBinding, Component, Input, OnChanges, ViewChild, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-ftn-informativo',
    template: `<div class="informationNodeCard {{node.data.cssClass}}" (click)="selectNodeGraph(node.id)">
                   <label class="value">{{node.data.descricao}}</label>
               </div>`,
    styles: []
})
export class FluxoTemplateNoInformativoComponent {

    @Input()
    guidSelecionado: string;

    @Input()
    node: any;

    @Output()
    selectNode = new EventEmitter<string>();

    constructor() {
        this.selectNode = new EventEmitter<string>();
    }

    selectNodeGraph(guidId: string) {
        this.selectNode.emit(guidId);
    }
}