import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import { ListaService } from "../../../common/service/lista.service";
import { ItemTipo } from "../../../common/model/lista-template-item";

@Component({
	selector: "app-preview-dados-arquivo",
	templateUrl: "./preview-dados-arquivo.component.html",
	styleUrls: ["./preview-dados-arquivo.component.scss", "../../style.css"],
	providers: [{ useClass: ListaService, provide: ListaService }]
})
export class PreviewDadosArquivoComponent {

	listaConsistente: any[];
	totalConsistente: any;
	listaInconsistente: any[];
	totalInconsistente: any;

	header: string[] = [];
	headerInvalido: string[] = [];
	abaSelecionada: number = 0;

	constructor(
		private ref: MatDialogRef<PreviewDadosArquivoComponent>,
		private translate: TranslateService,
		@Inject(MAT_DIALOG_DATA) public data,
		private listaService: ListaService
	) {
		this.header = data.template.listaTemplateItem.filter(f => f.ativo && (f.itemTipo == ItemTipo.Padrao || f.itemTipo == ItemTipo.Coluna || f.itemTipo == ItemTipo.Validacao)).sort(compare).map(m => m.nome);
		this.abaSelecionada = data.abaSelecionada;
		this.previewConsistente(this.data.listaId, this.data.listaArquivoId, 1);
		this.previewInconsistente(this.data.listaId, this.data.listaArquivoId, 1);
	}

	previewConsistente(listaId: number, listaArquivoId: number, pagina: number) {

		this.listaService
			.obterPreviewArquivoImportacao(listaId, listaArquivoId, true, pagina)
			.subscribe(dados => {
				this.listaConsistente = dados.collection;
				this.totalConsistente = dados.total;

				if (dados.collection.length == 0) return;
			});
	}

	changePageConsistente(event: any) {
		const pagina = (event.pageIndex * event.pageSize) / event.pageSize + 1;
		if (isNaN(pagina)) return;
		this.previewConsistente(this.data.listaId, this.data.listaArquivoId, pagina);
	}

	previewInconsistente(listaId: number, listaArquivoId: number, pagina: number) {
		this.listaService
			.obterPreviewArquivoImportacao(listaId, listaArquivoId, false, pagina)
			.subscribe(dados => {
				this.listaInconsistente = dados.collection;
				this.totalInconsistente = dados.total;
				if (dados.collection.length == 0) return;
			});
	}

	changePageInconsistente(event: any) {
		const pagina = (event.pageIndex * event.pageSize) / event.pageSize + 1;
		if (isNaN(pagina)) return;
		this.previewInconsistente(this.data.listaId, this.data.listaArquivoId, pagina);
	}

	getValorPreview(item: any, propriedade: string, index: number) {
		let key = Object.keys(item).find(prop => prop.toLocaleLowerCase() == propriedade.toLocaleLowerCase());

		if (key == undefined)
			key = `item${index}`;

		if (item[key] != undefined)
			return item[key].valor;

		return "";
	}

	getErroPreview(item: any, propriedade: string, index: number) {
		let key = Object.keys(item).find(prop => prop.toLocaleLowerCase() == propriedade.toLocaleLowerCase());

		if (key == undefined)
			key = `item${index}`;

		if (item[key] != undefined)
			return item[key].erro;

		return false;
	}

	getErroDescricaoPreview(item: any, propriedade: string, index: number) {
		
		let key = Object.keys(item).find(prop => prop.toLocaleLowerCase() == propriedade.toLocaleLowerCase());

		if (key == undefined)
			key = `item${index}`;

		if (item[key] != undefined)
			return item[key].descricao;

		return false;
	}

	getErroLinhaDescricaoPreview(item: any) {
		
		let key = Object.keys(item).find(prop => prop.toLocaleLowerCase() == "descricao");

		if (item[key] != undefined)
			return item[key];

		return "";
	}

	fechar() {
		this.ref.close();
	}
}

function compare(a: any, b: any) {
	if (a.ordem < b.ordem) return -1;
	if (a.ordem > b.ordem) return 1;
	return 0;
}
