import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { HistoricoRetorno } from "../../../common/model/historico-retorno";

@Component({
    selector: "app-preview-retorno-modal",
    templateUrl: "./preview-retorno-modal.component.html",
    styleUrls: ["./preview-retorno-modal.component.scss"],
    providers: [
    ]
})
export class PreviewRetornoModalComponent {
    public retorno: HistoricoRetorno;
    public dadosJson: any;

    constructor(
        private ref: MatDialogRef<PreviewRetornoModalComponent>,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA)
        public data: { retorno: HistoricoRetorno }         
    ) {
        this.retorno = this.data.retorno;
        this.dadosJson = JSON.parse(this.retorno.valorRetorno);
    }

    close() {
        this.ref.close();
    }

    fechar() {
        this.ref.close();
    }
}