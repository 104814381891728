import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

import { ExpressaoRegex } from "../model/expressao-regex";
import { environment } from "../../../../environments/environment";

@Injectable()
export class ListaExpressaoService {
  constructor(private httpClient: HttpClient) {}

  obterExpressao(): Observable<Array<ExpressaoRegex>> {
    return this.httpClient
      .get<Array<ExpressaoRegex>>(`${environment.serverUrl}/api/listaexpressao`);
  }
}
