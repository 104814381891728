import { Component, ViewChild, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from '@angular/material';
import { DataView } from 'primeng/dataview';

import { Fluxo, FluxoTipo } from "../../common/model/fluxo";
import { Lista } from "../../common/model/lista";
import { FiltroLista } from '../../common/model/filtro-lista';

import { FluxoService } from "../../common/service/fluxo.service";
import { CustomAlertService } from "../../common/service/custom-alert.service";
import { ListaService } from "../../common/service/lista.service";
import { FiltroQueryService } from "../../common/service/filtro-query.service";
import { FiltroQuery } from "../../common/model/filtro-query";


@Component({
	selector: "app-lista-filtro-query-padrao",
	templateUrl: "./lista-filtro-query-padrao.component.html",
	styleUrls: ["./lista-filtro-query-padrao.component.scss"],
	providers: [
		{ useClass: FiltroQueryService, provide: FiltroQueryService },
		{ useClass: ListaService, provide: ListaService },
		{ useClass: CustomAlertService, provide: CustomAlertService }
	]
})
export class ListaFiltroQueryPadraoComponent implements OnInit {
	listaFiltrosQuerys: Array<FiltroQuery> = [];
	listas: Array<Lista>;
	tipos: Array<any>;
	filtro = { 'nome': '', 'listaId': '' };

	@ViewChild(DataView, { static: false }) dataTable: DataView;
	constructor(
		private filtroQueryService: FiltroQueryService,
		private listaService: ListaService,
		private route: ActivatedRoute,
		private customAlertService: CustomAlertService,
		public dialog: MatDialog
	) { }

	ngOnInit() {
		this.obterListas();
		this.filtrar();
	}

	filtrar() {
		this.filtroQueryService.obterFiltros(this.filtro).subscribe(data => this.listaFiltrosQuerys = data);
	}

	obterListas() {
		let filtroLista = new FiltroLista();
		filtroLista.ignorarErroPesquisa = true;
		this.listaService.obterListaSemQuantidade(filtroLista).subscribe(listas => (this.listas = listas));
	}

	limpar() {
		this.filtro = { 'nome': '', 'listaId': '' };
	}

	excluir(filtroQueryId: number) {
		this.customAlertService
			.confirmationMessage("telaFluxo.confirmacaoDesativarFluxo")
			.then(() => {
				this.filtroQueryService.desativar(filtroQueryId).subscribe((retorno: any) => {
						this.filtrar();
				});
			});
	}
}