import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { LogHistoricoAlteracao } from "../model/log-historico-alteracao";
import { environment } from "../../../../environments/environment";

@Injectable()
export class LogHitoricoAlteracaoService {
  constructor(private httpClient: HttpClient) {}

  obterListaLog(id, tipoEntidade) {
    return this.httpClient.get<Array<LogHistoricoAlteracao>>(
      `${environment.serverUrl}/api/logHistoricoAlteracao/${id}/${tipoEntidade}/lista`
    );
  }
}
