import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material";
import { FiltroMonitorRetorno } from "../../../common/model/filtro-monitor-retorno";
import { HistoricoRetornoService } from "../../../common/service/historico-retorno.service";
import { DetalheSemRetornoComponent } from "./detalhe-sem-retorno/detalhe-sem-retorno.component";

@Component({
	selector: "app-lista-sem-retorno",
	templateUrl: "./lista-sem-retorno.component.html",
	styleUrls: ["../style.scss"],
	providers: [
		{ useClass: HistoricoRetornoService, provide: HistoricoRetornoService }
	]
})

export class ListaSemRetornoComponent implements OnInit {
	public enviosSemRetorno: Array<any> = [];

	constructor(
		private historicoRetornoService: HistoricoRetornoService,
		private dialog: MatDialog
	) {
	}

	ngOnInit() {
	}

	pesquisar(filtro: FiltroMonitorRetorno) {
		this.historicoRetornoService.obterEnvioSemRetorno(filtro).subscribe(enviosSemRetorno => {
			this.enviosSemRetorno = enviosSemRetorno;
		});
	}

	visualizarDetalhe(envioSemRetorno: any) {
		this.dialog.open(DetalheSemRetornoComponent, {
			hasBackdrop: true,
			width: "50%",
			data: {
				'envioSemRetorno': envioSemRetorno
			}
		});
	}	
}