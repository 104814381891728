import { lPad } from "./lPad";

export function generateHours(min: number = 1) {
    let hours = [];

    for (let i = 0; i < 23; i = i + min)
        hours.push((i < 10) ? lPad(i.toString(), 2) : i.toString());

    return hours;
}
