import { Component, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MatDialog } from '@angular/material';

import { Estrategia, EstrategiaAcaoCampanhaTipo } from "../../common/model/estrategia";
import { InfoExecucaoEstrategiaComponent } from "./info-execucao-estrategia/info-execucao-estrategia.component";
import { LogHistoricoComponent } from "./../../common/components/log-historico/log-historico.component";
import { EstrategiaService } from "../../common/service/estrategia.service";
import { FluxoService } from "../../common/service/fluxo.service";
import { CustomAlertService } from "../../common/service/custom-alert.service";
import { Router } from "@angular/router";
import { PreviewDadosEstrategiaComponent } from "../../estrategia/form-estrategia/preview-dados-estrategia/preview-dados-estrategia.component";
import * as primeng from 'primeng/primeng';
import { Table } from 'primeng/table';
import { Lista } from "../../common/model/lista";
import { VisualizarProvedorModalComponent } from "../visualizar-provedor-modal/visualizar-provedor-modal.component";
import { TemplateService } from "../../common/service/template.service";
import { QueryToModel } from "../../esbuilder/libs/utils";
import { ESBuilderData } from "../../esbuilder/models";

import { EstrategiaTipo } from "../../common/model/estrategia-tipo";
import { CarteiraService } from "../../common/service/carteira.service";
import { ListaService } from "../../common/service/lista.service";
import { FiltroLista } from "../../common/model/filtro-lista";
import { Carteira } from "../../common/model/carteira";
import { DefaultValuesService } from "../../common/service/default-values.service";
import { PreferenciasUsuarioService } from "../../common/service/preferencias-usuario.service";
import { PreferenciasUsuario } from "../../common/model/preferencias-usuario";



@Component({
    selector: "app-lista-estrategia",
    templateUrl: "./lista-estrategia.component.html",
    styleUrls: ["./lista-estrategia.component.scss"],
    providers: [
        { provide: CarteiraService, useClass: CarteiraService },
        { provide: ListaService, useClass: ListaService },
        { provide: DefaultValuesService, useClass: DefaultValuesService },
        { useClass: EstrategiaService, provide: EstrategiaService },
        { useClass: TemplateService, provide: TemplateService },
        { useClass: FluxoService, provide: FluxoService },
        { useClass: CustomAlertService, provide: CustomAlertService },
        { useClass: DefaultValuesService, provide: DefaultValuesService },
        { useClass: PreferenciasUsuarioService, provide: PreferenciasUsuarioService },

    ]
})
export class ListaEstrategiaComponent {
    preferenciasUsuario: PreferenciasUsuario;
    filtro: any = {};

    carteiras: Array<Carteira> = [];
    listas: Array<Lista>;
    estrategiaAcaoCampanhaTipos: Array<any>;
    recorrencias: Array<any>;

    estrategias: Array<Estrategia> = [];

    travarEscolhaLista: boolean = false;
    pesquisou: boolean = false;
    isRotaLista: boolean = false;
    size: number;
    sortField: string = 'ListarEstrategiaId';
    sortOrder: number = -1;
    totalRecords: number;
    paginaAtual: any = 0;
    tamanhoAtual: any = 10;

    public get disabledCarteira(): boolean {
        return (this.carteiras.length == 1);
    }

    queryBaseTotal: any = {
        size: 10,
        _source: {
            includes: []
        },
        query: {
            bool: {
                must: [],
                must_not: []
            }
        }
    }

    public get carteirasCarregadas(): boolean {
        return (this.carteiras.length > 0);
    }


    @ViewChild(Table, { static: false }) dataTable: Table;
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public dialog: MatDialog,
        private carteiraService: CarteiraService,
        private customAlertService: CustomAlertService,
        private defaultValuesService: DefaultValuesService,
        private estrategiaService: EstrategiaService,
        private fluxoService: FluxoService,
        private listaService: ListaService,
        private templateService: TemplateService,
        private preferenciasService: PreferenciasUsuarioService
    ) {
        //let filtroLista = new FiltroLista(true);

        // Preferencias do usuário
		this.preferenciasUsuario = this.preferenciasService.localStorageObterPreferenciasUsuario();

        this.route.params.subscribe(params => {
            this.filtro = {};

            if (params.listaId != undefined) {
                this.filtro = { 'lista': parseInt(params.listaId) };
                this.travarEscolhaLista = true;
                this.isRotaLista = true;

                this.listaService.obterListaSemQuantidade().subscribe((listas: Array<Lista>) => {
                    let lista = listas.find(f => f.listaId == params.listaId);
                    this.filtro.carteiraId = (lista != null) ? lista.carteiraId : 1;
                    this.obterCarteiras(false);
                });
            } else {
                this.obterCarteiras();
            }

            this.obterEstrategiaRecorrencia();
        });
    }

    ngOnInit() {
        this.populateFiltro();
        this.populate(false);
    }

    populateFiltro() {
        this.filtro.paginar = true;
        this.filtro.paginaAtual = this.paginaAtual;
        this.filtro.tamanhoAtual = this.tamanhoAtual;
        this.filtro.sortField = this.sortField;
        this.filtro.sortOrder = this.sortOrder;
    }

    populate(pageZero: boolean) {

        if (this.carteirasCarregadas == false)
            return;

        this.estrategiaService.obterEstrategiasPaginadas(this.filtro).subscribe(result => {
            this.estrategias = result.data;
            this.totalRecords = result.total;
            if (pageZero)
                this.setCurrentPage(0);
        });
    }

    obterCarteiras(setCarteiraInicial: boolean = true) {
        this.carteiraService.obterCarteiras().subscribe(carteiras => {
            this.carteiras = carteiras;
            
            if (this.preferenciasUsuario.existePreferenciaCarteira) {
                this.filtro.carteiraId = this.preferenciasUsuario.carteiraId as number;
            } else {
                let padrao = this.carteiras.find(f => f.padrao) as Carteira;
                this.filtro.carteiraId = padrao.carteiraId;
            }

            this.filtrarEstrategias();
            this.obterListas();
        });
    }

    obterListas() {
        this.listaService.obterListaSemQuantidade(this.filtro).subscribe(listas => (this.listas = listas));
    }

    obterEstrategiaRecorrencia() {
        this.recorrencias = this.listaService.obterEstrategiaRecorrencia();
    }

    carteiraChange() {
        this.filtro.lista = null;
        this.obterListas();
    }

    //#region [ Filtrar ]

    limpar() {
        this.filtro = {};
        let padrao = this.carteiras.find(f => f.padrao) as Carteira;
        this.filtro.carteiraId = padrao.carteiraId;
        this.filtro.ignorarErroPesquisa = true;
        this.travarEscolhaLista = false;
    }

    keyDownFunction($event: any) {
        this.filtrarEstrategias();
    }

    lazyPaginate(event: any) {
        if (!this.pesquisou) {
            this.sortField = event.sortField;
            this.sortOrder = event.sortOrder;
            this.paginaAtual = event.first;
            this.tamanhoAtual = event.rows;
            this.populateFiltro();
            this.populate(false); 1
        }
        this.pesquisou = false;
    }

    paginate(event: any) {
        this.paginaAtual = event.first;
        this.tamanhoAtual = event.rows;

        this.populateFiltro();
        this.populate(false);
    }

    setCurrentPage(n: number) {
        if (this.dataTable)
            this.dataTable.first = n * this.tamanhoAtual;
    }

    filtrarEstrategias(filtro: any = null) {
        this.pesquisou = true;
        this.paginaAtual = 0;

        this.populateFiltro();
        this.populate(true);
    }

    //#endregion

    //#region [ Outros ]

    ativarEnvioImediato(id) {
        this.customAlertService
            .confirmationMessage("telaEstrategia.confirmarAtivacaoEnvioImediato")
            .then(() =>
                this.estrategiaService
                    .ativarEnvioImediato(id)
                    .subscribe(() => this.filtrarEstrategias(this.filtro))
            );
    }

    ativarPendenteExecucao(estrategia) {
        this.fluxoService
            .obterQueryQuantidadeRegistrosPost(estrategia.lista.listaId, estrategia.filtro)
            .subscribe((result: any) => {
                let quantidade = (result) ? result : 0;

                if (quantidade > 0) {
                    this.customAlertService
                        .confirmationMessage("telaEstrategia.confirmarReativacao")
                        .then(() =>
                            this.estrategiaService.ativarPendenteExecucao(estrategia.listaEstrategiaId).subscribe(() => this.filtrarEstrategias(this.filtro))
                        );
                } else {

                    this.customAlertService
                        .confirmationMessage("telaEstrategia.confirmarReativacaoSemRegistro")
                        .then(() =>
                            this.estrategiaService.ativarPendenteExecucao(estrategia.listaEstrategiaId).subscribe(() => this.filtrarEstrategias(this.filtro))
                        );
                }
            });
    }

    exibirInfoExecucao(estrategia): void {
        const dialogRef = this.dialog.open(InfoExecucaoEstrategiaComponent, {
            width: '500px',
            data: estrategia
        });

        dialogRef.afterClosed().subscribe(result => {
        });
    }

    forcarExecucao(estrategia) {
        this.fluxoService
            .obterQueryQuantidadeRegistrosPost(estrategia.lista.listaId, estrategia.filtro)
            .subscribe((result: any) => {
                let quantidade = (result) ? result : 0;

                if (quantidade > 0) {
                    this.customAlertService
                        .confirmationMessage("telaEstrategia.forcarExecucao")
                        .then(() =>
                            this.estrategiaService.forcarExecucao(estrategia.listaEstrategiaId).subscribe(() => this.filtrarEstrategias(this.filtro))
                        );
                } else {

                    this.customAlertService
                        .confirmationMessage("telaEstrategia.forcarExecucaoSemRegistro")
                        .then(() =>
                            this.estrategiaService.forcarExecucao(estrategia.listaEstrategiaId).subscribe(() => this.filtrarEstrategias(this.filtro))
                        );
                }
            });
    }

    excluirEstrategias(id) {
        this.customAlertService
            .confirmationMessage("telaEstrategia.confirmarExclusao")
            .then(() =>
                this.estrategiaService
                    .excluir(id)
                    .subscribe(() => this.filtrarEstrategias(this.filtro))
            );
    }

    duplicarEstrategia(id) {
        this.customAlertService
            .confirmationMessage("telaEstrategia.confirmarDuplicarEstrategia")
            .then(() =>
                this.estrategiaService
                    .duplicarEstrategia(id)
                    .subscribe(model =>
                        this.router.navigate([`/app/estrategia/${model.listaEstrategiaId}`])
                    )
            );
    }

    mostrarPreview(filtro: any, lista: Lista, title: string) {
        this.dialog.open(PreviewDadosEstrategiaComponent, {
            height: "700px",
            width: "80%",
            hasBackdrop: true,
            data: {
                templateId: lista.listaTemplateId,
                lista: lista.nome,
                listaId: lista.listaId,
                title: `Encontrados na Estratégia: ${title}`,
                query: JSON.parse(filtro)
            }
        });
    }

    mostrarPreviewTotal(lista: Lista, title: string) {
        this.dialog.open(PreviewDadosEstrategiaComponent, {
            height: "700px",
            width: "80%",
            hasBackdrop: true,
            data: {
                templateId: lista.listaTemplateId,
                lista: lista.nome,
                listaId: lista.listaId,
                title: `Total da Lista: ${title}`,
                query: this.queryBaseTotal
            }
        });
    }

    abrirLogHistorico(id, descricao) {

        let data = {
            id: id,
            entidade: 0,
            titulo: "Estrategia "+ descricao
        };

        this.dialog.open(LogHistoricoComponent, {
            height: "500px",
            width: "40%",
            data
        });
    }

    visualizarProvedor(estrategia: Estrategia) {
        this.dialog.open(VisualizarProvedorModalComponent, {
            height: "450px",
            width: "50%",
            data: {
                listaEstrategiaId: estrategia.listaEstrategiaId
            }
        });
    }


    //#endregion


    baixarArquivo(estrategiaNome: string, listaEstrategiaTracking: any) {
        if (listaEstrategiaTracking.arquivoNome)
            this.estrategiaService.baixarHistorico(listaEstrategiaTracking.listaEstrategiaId, listaEstrategiaTracking.listaEstrategiaTrackingId, estrategiaNome);
        else
            this.customAlertService.show("telaEstrategia.download", "telaEstrategia.expurgoArquivo", "info");
    }


}