import { FiltroStatusRegistroLista } from "./lista";

const validate = require("validate.js");

export class FiltroQuery {
    filtroQueryId: number;
    listaId: number;
    nome: string;
    filtro: string;
    filtroRegras: string;
    dataCadastro: Date;
    usuarioCadastro: number;
    dataAlteracao: Date;
    usuarioAlteracao: number;
    ativo: boolean;

    listaNome: string;
    listaTemplateId: number;
    nomeUsuarioCadastro: string;
    estrategiasVinculadas: boolean;

    constructor() { }

    validar() {
        var validacao: any = {
            nome: {
                presence: { allowEmpty: false, message: '^campoObrigatorio' }
            },
            listaId: {
                presence: { allowEmpty: false, message: '^campoObrigatorio' }
            },
            filtro: {
                presence: { allowEmpty: false, message: '^campoObrigatorio' }
            },
            filtroRegras: {
                presence: { allowEmpty: false, message: '^campoObrigatorio' }
            }
        };

        return validate.validate(this, validacao);
    }

    static fromRaw(rawFluxo): FiltroQuery {
        return Object.assign(new FiltroQuery(), rawFluxo);
    }
}
