import { Component, OnInit, AfterViewInit, OnDestroy, NgZone } from "@angular/core";
import { MatDialog } from "@angular/material";
import { FiltroDashIndividual } from "../model/filtro-dash-individual";
import { DashIndividualService } from '../../common/service/dash-individual.service';
import { TemplateService } from "../../common/service/template.service";
import { DashIndividualRegistro } from "../model/dash-individual-registro";
import { DashIndividualLinhaTempo, DashIndividualLinhaTempoConsolidada } from "../model/dash-individual-linha-tempo";
import { CarteiraService } from "../../common/service/carteira.service";
import { Carteira } from "../../common/model/carteira";


@Component({
    selector: "app-dash-individual",
    templateUrl: "./dash-individual.component.html",
    styleUrls: ["./dash-individual.component.scss"],
    providers: [
        { useClass: DashIndividualService, provide: DashIndividualService },
        { useClass: TemplateService, provide: TemplateService },
        { useClass: CarteiraService, provide: CarteiraService }
    ]
})

export class DashIndividualComponent implements OnInit, OnDestroy {
    filtro: FiltroDashIndividual;
    dirty: boolean;
    listaTemplateItensCollectionOriginal: Array<any> = [];
    listaTemplateItensCollection: Array<any> = [];
    registrosEncontrados: Array<DashIndividualRegistro> = [];
    linhaTempo: Array<DashIndividualLinhaTempo> = [];
    linhaTempoConsolidada: DashIndividualLinhaTempoConsolidada;
    carteiras: Array<Carteira> = [];

    constructor(
        private dashIndividualService: DashIndividualService,
        private templateService: TemplateService,
        private carteiraService: CarteiraService,
        private zone: NgZone,
        public dialog: MatDialog
    ) {
        this.filtro = new FiltroDashIndividual();
    }

    public get erros() {
        if (!this.dirty) return;
        return this.filtro.validar();
    }

    public get disabledCarteira(): boolean {
		return (this.carteiras.length == 1);
	}

    ngOnInit() {
        this.pesquisarCamposMapeados();
        this.obterCarteiras();
    }

    ngOnDestroy() { }

    //#region [ Carregamento dos dados ]

    pesquisar() {
        this.dirty = true;
        this.filtro.listaItemId = null;
        this.linhaTempoConsolidada = null;

        if (this.filtro.validar())
            return;

        this.obterDadosRegistros();
    }

    exibirLinhaDoTempo(listaId: number, listaItemId: number) {
        this.filtro.listaItemId = listaItemId;
        this.filtro.listaId = listaId;
        this.linhaTempoConsolidada = null;        
        this.obterLinhaTempo();
    }

    obterDadosRegistros() {
        this.dashIndividualService.obterRegistros(this.filtro).subscribe(retorno => {
            this.registrosEncontrados = retorno;
        });
    }

    obterLinhaTempo() {
        this.dashIndividualService.obterLinhaTempo(this.filtro).subscribe(retorno => {
            this.linhaTempo = retorno;

            if (this.linhaTempo.length > 0) {
                this.linhaTempoConsolidada = new DashIndividualLinhaTempoConsolidada(this.linhaTempo);
            }
        });
    }

    obterCarteiras() {
		this.carteiraService.obterCarteiras().subscribe(carteiras => {
				this.carteiras = carteiras;

				let padrao = this.carteiras.find(f => f.padrao);
				this.filtro.carteiraId = padrao.carteiraId;

				this.pesquisarCamposMapeados();
			});
	}

    limpar() {
        this.filtro = new FiltroDashIndividual();
        let padrao = this.carteiras.find(f => f.padrao);
        this.filtro.carteiraId = padrao.carteiraId;
        this.registrosEncontrados = [];
        this.linhaTempo = [];
        this.linhaTempoConsolidada = null;
    }

    //#endregion

    //#region [ Filtro Campo ]

    pesquisarCamposMapeados() {
        this.templateService.obterTodosCamposMapeados({ 'carteiraId': this.filtro.carteiraId }).subscribe(result => {
            this.listaTemplateItensCollectionOriginal = result;
            this.listaTemplateItensCollection = result.map(m => ({ 'value': m.listaTemplateItemId, 'label': m.nome }));
        });
    }

    obterTemplateItem(listaTemplateItemId) {
        return this.listaTemplateItensCollectionOriginal.find(f => f.listaTemplateItemId == listaTemplateItemId).templateNome;
    }

    obterTipoItem(listaTemplateItemId) {
        return this.listaTemplateItensCollectionOriginal.find(f => f.listaTemplateItemId == listaTemplateItemId).tipoDado;
    }

    //#endregion

}