import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-acesso-negado",
  templateUrl: "./acesso-negado.component.html"
})
export class AcessoNegadoComponent implements OnInit {
  rota: string;
  constructor(private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      this.rota = params["rota"];
    });
  }

  ngOnInit() {}
}
