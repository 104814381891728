import { LOCALE_ID, NgModule } from "@angular/core";
import { MatButtonModule, 
         MatCardModule,
         MatCheckboxModule,
         MatDatepickerModule,
         MatDialogModule,
         MatDividerModule,
         MatExpansionModule,
         MatIconModule,
         MatInputModule,
         MatListModule,
         MatPaginatorModule,
         MatRadioModule,
         MatSelectModule,
         MatSlideToggleModule,
         MatTooltipModule } from "@angular/material";
import { AppCommonModule } from "../common/common.module";
import { CommonModule } from "@angular/common";
import { FormBuilder, FormsModule } from "@angular/forms";
import { TableModule } from "primeng/table";
import { ListaNotificacaoComponent } from "./lista-notificacao/lista-notificacao.component";
import { DetalheNotificacaoComponent } from "./detalhe-notificacao/detalhe-notificacao.component";
import { NotificacaoEventoPipe } from "./libs/pipes/notificacao-evento-pipe";
import { NotificacaoService } from "../common/service/notificacao.service";
import { SelectModule } from "ng-select";

@NgModule({
    imports: [
        AppCommonModule,
        CommonModule,        
        MatButtonModule,
        MatInputModule,
        MatIconModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatCardModule,
        MatRadioModule,
        MatDividerModule,
        MatSlideToggleModule,
        MatExpansionModule,
        MatListModule,
        MatPaginatorModule,
        MatTooltipModule,
        MatDialogModule,
        MatSelectModule,
        SelectModule,
        FormsModule,
        TableModule
    ],
    providers: [
        { provide: FormBuilder, useClass: FormBuilder },
        { provide: LOCALE_ID, useValue: "pt-BR" },        
        { useClass: NotificacaoService, provide: NotificacaoService }
    ],
    declarations: [
        ListaNotificacaoComponent,
        DetalheNotificacaoComponent,
        NotificacaoEventoPipe
    ],
    entryComponents: [
        DetalheNotificacaoComponent
    ],
     exports: [
        NotificacaoEventoPipe
     ]
})

export class NotificacaoModule { }