
import { map } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Estrategia } from "../model/estrategia";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../../environments/environment";
import { convertToQueryString } from "./query-string";
import { EstrategiaCard } from "../../dash/model/estrategia-card";
import { Http, ResponseContentType, Headers } from "@angular/http";
import { AutenticacaoService } from "./autenticacao.service";
import { downloadFile } from "./download-file";
import { SituacaoExecucao } from '../model/situacao-execucao';

@Injectable()
export class EstrategiaService {
	constructor(
		private _httpClient: HttpClient,
		private http: Http,
		private authService: AutenticacaoService
	) { }

	obterEstrategias(filtro?) {
		var queryString = convertToQueryString(filtro);
		return this._httpClient.get<Array<Estrategia>>(
			`${environment.serverUrl}/api/estrategias${queryString}`
		);
	}

	obterEstrategiasPaginadas(filtro?) {
		var queryString = convertToQueryString(filtro);
		return this._httpClient.get<any>(
			`${environment.serverUrl}/api/estrategias${queryString}`
		);
	}

	obterCard(listaId, carteiraId) {
		return this._httpClient.get<EstrategiaCard>(
			`${environment.serverUrl
			}/api/dashboard/card-estrategia?listaId=${listaId}&carteiraid=${carteiraId}`
		);
	}

	excluir(id: any) {
		return this._httpClient.delete(
			`${environment.serverUrl}/api/estrategias/${id}`
		);
	}

	obterEstrategiaPorId(id) {
		return this._httpClient
			.get<Estrategia>(`${environment.serverUrl}/api/estrategias/${id}`).pipe(
				map(Estrategia.fromRaw));
	}

	obterHistoricoEstrategia(id, filtro?) {
		return this._httpClient.get<Array<any>>(
			`${environment.serverUrl
			}/api/estrategias/${id}/historico${convertToQueryString(filtro)}`
		);
	}

	criarEstrategia(estrategia: Estrategia) {
		return this._httpClient.post<Estrategia>(
			`${environment.serverUrl}/api/estrategias`,
			estrategia
		);
	}

	duplicarEstrategia(id) {
		return this._httpClient.post<Estrategia>(
			`${environment.serverUrl}/api/estrategias/${id}/duplicar`,
			null
		).pipe(map(Estrategia.fromRaw));
	}

	atualizarEstrategia(estrategia: Estrategia) {
		return this._httpClient.put<Estrategia>(
			`${environment.serverUrl}/api/estrategias/${estrategia.listaEstrategiaId
			}`,
			estrategia
		);
	}


	ativarEnvioImediato(id) {
		return this._httpClient.patch<Estrategia>(
			`${environment.serverUrl}/api/estrategias/${id}/ativar-envio-imediato`,
			{}
		);
	}

	ativarPendenteExecucao(id) {
		return this._httpClient.patch<Estrategia>(
			`${environment.serverUrl}/api/estrategias/${id}/pendente-execucao`,
			{}
		);
	}

	forcarExecucao(id) {
		return this._httpClient.patch<Estrategia>(
			`${environment.serverUrl}/api/estrategias/${id}/forcar-execucao`,
			{}
		);
	}

	atualizarSituacaoExecucao(id: number, situacao: SituacaoExecucao) {
		return this._httpClient.patch<Estrategia>(`${environment.serverUrl}/api/estrategias/${id}/${situacao}/atualizar-situacao-execucao`, {});
	}

	baixarHistorico(estrategiaId, historicoId, estrategiaNome) {
		this.http
			.get(
				`${environment.serverUrl
				}/api/estrategias/${estrategiaId}/historico/${historicoId}`,
				{
					responseType: ResponseContentType.Blob,
					headers: new Headers({
						Authorization: `Bearer ${this.authService.obterTokenAutenticado()}`
					})
				}
			)
			.subscribe(res => {
				let nomeArquivo = `${estrategiaNome.replace(/[.*+\-?^${}()|[\]\\]/g, '').replace(/\s\s+/g, ' ').replace(/\s/g, '_')}.txt`;
				downloadFile(res, nomeArquivo)
			});
	}

	baixarUltimoHistorico(estrategiaId, tarefaId) {
		return this.http
			.get(
				`${environment.serverUrl
				}/api/estrategias/${estrategiaId}/ultimoArquivoHistorico/${tarefaId}`,
				{
					responseType: ResponseContentType.Blob,
					headers: new Headers({
						Authorization: `Bearer ${this.authService.obterTokenAutenticado()}`
					})
				}
			);
	}

	obterExecucoesTrackingEstrategias(filtro?) {
		var queryString = convertToQueryString(filtro);
		return this._httpClient.get<Array<any>>(
			`${environment.serverUrl}/api/estrategias/execucoestrackingestrategias${queryString}`
		);
	}

	verificarHabilitaControleRetorno() {
		return this._httpClient.get<boolean>(
			`${environment.serverUrl}/api/estrategias/verificarHabilitaControleRetorno`
		);
	}

	obterEstrategiaDetalheCampanha(id) {
		return this._httpClient
			.get<Estrategia>(`${environment.serverUrl}/api/estrategias/${id}/detalheCampanha`).pipe(
				map(Estrategia.fromRaw));
	}
}