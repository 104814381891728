import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
  selector: "app-dash-individual-detalhe",
  templateUrl: "./dash-individual-detalhe-modal.component.html"
})
export class DashIndividualDetalheModalComponent implements OnInit {
  mensagem: any;
  titulo: any;
  tipoDescricao: any;
  estrategiaDescricao: any;
  grupoDescricao: any;
  subGrupoDescricao: any;
  data: any;
  constructor(
    public dialogRef: MatDialogRef<DashIndividualDetalheModalComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    let object;

    try {
      object = JSON.stringify(JSON.parse(data.valor), undefined, 4);
    }
    catch{
      object = data.valor;
    }    
    this.mensagem = object;
    this.titulo = data.mensagem;
    this.data = data.data;
    this.tipoDescricao = data.tipoDescricao;
    this.estrategiaDescricao = data.estrategiaDescricao;
    this.grupoDescricao = data.grupoDescricao;
    this.subGrupoDescricao = data.subGrupoDescricao;
  }

  ngOnInit() { }

  close() {
    this.dialogRef.close();
  }
}