import { Estrategia } from "./estrategia";
import { FluxoInformativo } from "./fluxo-informativo";
import { GuidService } from "../service/guid.service";
import { from } from "core-js/fn/array";

export class FluxoProcesso {
    fluxoProcessoId: number;
    fluxoId: number;

    fluxoProcessoGuid: string;
    fluxoProcessoPaiGuid: string;
    fluxoProcessoTipoId: FluxoProcessoTipo;
    listaEstrategiaId: number;
    fluxoQueryId: string;

    descricao: string;
    ordem: number;
    cor: string;
    dataCadastro: Date;
    usuarioCadastro: number;
    dataAlteracao: Date;
    usuarioAlteracao: number;
    ativo: boolean;
    estrategia: Estrategia;
    fluxoInformativo: FluxoInformativo;

    // Usado para exibição na tela não vai para o backend
    queryQtdRegistros: number = 0;

    constructor(fluxoId: number, fluxoProcessoPaiGuid: string, fluxoProcessoTipoId: FluxoProcessoTipo, cor: string = 'branco') {
        this.fluxoId = fluxoId;
        this.listaEstrategiaId = null;
        this.ordem = 0;
        this.cor = cor;
        this.fluxoProcessoGuid = GuidService.newGuid();
        this.fluxoProcessoPaiGuid = fluxoProcessoPaiGuid;
        this.fluxoProcessoTipoId = fluxoProcessoTipoId;
        this.ativo = true;
        this.estrategia = new Estrategia();
        this.estrategia.listaEstrategiaId = 0;

        this.fluxoInformativo = new FluxoInformativo();
        this.fluxoInformativo.fluxoInformativoId = 0;
    }

    static fromRaw(raw): FluxoProcesso {
        return Object.assign(new FluxoProcesso(raw.fluxoId, raw.fluxoProcessoPaiGuid, raw.fluxoProcessoTipoId, raw.cor), raw);
    }
}

export enum FluxoProcessoTipo {
    database = 1,
    decisao = 2,
    selecaoEstrategia = 3,
    quantidade = 4,
    filtro = 5,
    criacaoEstrategia = 6,
    informativoEstrategia = 7,
    informativo = 8
}
