import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { FiltroHistoricoRetorno } from "../../../common/model/filtro-historico-retorno";
import { HistoricoRetornoService } from "../../../common/service/historico-retorno.service";
import { HubConnection, HubConnectionBuilder } from "@aspnet/signalr";
import * as signalR from "@aspnet/signalr";
import { environment } from "../../../../../environments/environment";
import { isNull, isNullOrZero } from "../../../common/utils";

@Component({
    selector: "app-visualizar-execucoes-modal",
    templateUrl: "./visualizar-execucoes-modal.component.html",
    styleUrls: ["./visualizar-execucoes-modal.component.scss"],
    providers: [
        { useClass: HistoricoRetornoService, provide: HistoricoRetornoService }
    ]
})
export class VisualizarExecucoesModalComponent implements OnInit {
    provedoresLista: Array<any>;
    dataHoraProximaExecucao: Date;
    dataHoraInicioExecucao: Date;
    atualizandoExecucao: boolean;
    filtro: FiltroHistoricoRetorno = new FiltroHistoricoRetorno();

    mostrar: boolean = false;

    private _hubConnection: HubConnection | undefined;

    toggle() {
        this.mostrar = !this.mostrar;
    }

    constructor(
        private historicoRetornoService: HistoricoRetornoService,
        private ref: MatDialogRef<VisualizarExecucoesModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: { filtro: FiltroHistoricoRetorno }        
    ) {
        this._hubConnection = new HubConnectionBuilder()
             .withUrl(`${environment.serverUrl}/notificacao`)
             .configureLogging(signalR.LogLevel.Information)
             .build();
        this.filtro = this.data.filtro;
        this.obterProximaExecucao();
        this.obterProverdorLista();
    }

    ngOnInit() {
        this._hubConnection.start().then(() => {
            this._hubConnection.on("AtualizarHistoricoRetorno", (dados: any) => {
                if (dados.providerId == this.filtro.providerId) {
                    if (dados.status == 0) {
                        this.atualizandoExecucao = true;
                        this.dataHoraProximaExecucao = dados.dataHoraProximaExecucao;
                        this.dataHoraInicioExecucao = dados.dataHoraInicioExecucao;
                    } else {
                        this.dataHoraInicioExecucao = null;
                    }
                    setTimeout(() => {
                        this.atualizandoExecucao = false;
                    }, 1000);
                }
            });
        });
    }
 
    obterProverdorLista() {
        let carteiraId = this.filtro.carteiraId == undefined ? 0 : this.filtro.carteiraId;
        this.historicoRetornoService
            .obterProvedoresListaRetorno({ 'carteiraId': carteiraId })
            .subscribe(provedores => (this.provedoresLista = provedores));
    }

    obterProximaExecucao(){
        if (!isNullOrZero(this.filtro.providerId)) {
            this.historicoRetornoService
                .obterProximaExecucao(this.filtro.providerId)
                .subscribe(agendamento => (this.dataHoraProximaExecucao = agendamento.proximaExecucao));
        }
    }

    close() {
        this.ref.close();
    }
}
