import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";

import { ListaService } from "../../common/service/lista.service";
import { MatDialog } from '@angular/material';
import { FiltroLista } from "../../common/model/filtro-lista";
import { Lista } from "../../common/model/lista";
import { CustomAlertService } from "../../common/service/custom-alert.service";
import { LogHistoricoComponent } from "./../../common/components/log-historico/log-historico.component";
import { PreviewDadosEstrategiaComponent } from "../../estrategia/form-estrategia/preview-dados-estrategia/preview-dados-estrategia.component";
import { Table } from "primeng/table";
import { Router } from "@angular/router";
import { LimpezaAutomaticaComponent } from "../limpeza-automatica/limpeza-automatica.component";

@Component({
    selector: "app-lista",
    templateUrl: "./lista.component.html",
    styleUrls: ["../style.css", "lista.component.scss"],
    providers: [
        {
            useClass: ListaService,
            provide: ListaService
        },
        { useClass: CustomAlertService, provide: CustomAlertService }
    ]
})
export class ListaComponent implements OnInit, OnDestroy {
    listaLista: Array<Lista>;
    menuSuspenso: Array<boolean> = [];
    filtroLista: FiltroLista;

    queryBaseDisponivel: any = {
        size: 10,
        _source: {
            includes: []
        },
        query: {
            bool: {
                must: [],
                must_not: [
                    {
                        exists: {
                            field: "_integrado"
                        }
                    }
                ]
            }
        }
    }

    queryBaseTotal: any = {
        size: 10,
        _source: {
            includes: []
        },
        query: {
            bool: {
                must: [],
                must_not: []
            }
        }
    }

    @ViewChild(Table, { static: false }) dataTable: Table;
    constructor(
        private listaService: ListaService,
        private customAlertService: CustomAlertService,
        public dialog: MatDialog,
        private router: Router,
    ) { }

    ngOnInit() {
        //this.carregarLista();
    }

    ngOnDestroy() {
        this.listaService.paraEscutarProgressoLista();
    }

    carregarLista() {
        this.listaService.paraEscutarProgressoLista();

        this.listaService.obterLista(this.filtroLista).subscribe(listaLista => {
            this.listaLista = listaLista;
            this.listaService.escutarProgressoListas(this.listaLista);
        });
    }

    filtrarLista(filtroLista: FiltroLista) {
        this.filtroLista = filtroLista;
        this.listaService.paraEscutarProgressoLista();
        this.listaService
            .obterLista(filtroLista)
            .subscribe(listaLista => {
                this.listaLista = listaLista;
                this.setCurrentPage(0);
                this.listaService.escutarProgressoListas(this.listaLista);
            });
    }

    ativarLista(lista: Lista) {
        this.listaService
            .ativarLista(lista.listaId)
            .subscribe(() => lista.ativarLista());
    }

    cancelarLista(lista: Lista) {
        this.customAlertService
            .confirmationMessage("telaLista.confirmacaoCancelamento")
            .then(() =>
                this.listaService
                    .cancelarLista(lista.listaId)
                    .subscribe(
                        () => { this.carregarLista() },
                        (error: any) => {
                            this.customAlertService.show("telaLista.lista", error.error[0], "error");
                        })
            );
    }

    limparLista(lista: Lista) {
        this.customAlertService
            .confirmationMessage("telaLista.confirmacaoLimpar")
            .then(() =>
                this.listaService
                    .limparLista(lista.listaId)
                    .subscribe(() => this.carregarLista())
            );
    }

    zerarListaConfiguracao(lista: Lista) {
        const modalRef = this.dialog.open(LimpezaAutomaticaComponent, {
            hasBackdrop: true,
            width: '50%',
            data: { 'listaId': lista.listaId }
        });

        modalRef.afterClosed().subscribe((result: any) => {
            if (!result) return;
            this.filtrarLista(new FiltroLista());
        });
    }

    limparDistribuidoLista(lista: Lista) {
        this.customAlertService
            .confirmationMessage("telaLista.confirmacaoLimparDistribuidos")
            .then(() =>
                this.listaService
                    .limparDistribuidosLista(lista.listaId)
                    .subscribe(() => this.filtrarLista(new FiltroLista()))
            );
    }

    pausarLista(lista: Lista) {
        this.customAlertService
            .confirmationMessage("telaLista.confirmacaoPausar")
            .then(() =>
                this.listaService
                    .pausarLista(lista.listaId)
                    .subscribe(() => lista.pausarLista())
            );
    }

    pararLista(lista: Lista) {
        this.customAlertService
            .confirmationMessage("telaLista.confirmacaoParar")
            .then(() =>
                this.listaService
                    .pararLista(lista.listaId)
                    .subscribe(() => this.carregarLista())
            );
    }

    mostrarPreview(lista: Lista) {
        this.dialog.open(PreviewDadosEstrategiaComponent, {
            hasBackdrop: true,
            height: "700px",
            width: "80%",
            data: {
                templateId: lista.listaTemplateId,
                lista: lista.nome,
                listaId: lista.listaId,
                title: `Lista: ${lista.nome}`,
                query: this.queryBaseDisponivel
            }
        });
    }

    mostrarPreviewTotal(lista: Lista) {
        this.dialog.open(PreviewDadosEstrategiaComponent, {
            hasBackdrop: true,
            height: "700px",
            width: "80%",
            data: {
                templateId: lista.listaTemplateId,
                lista: lista.nome,
                listaId: lista.listaId,
                title: `Lista: ${lista.nome}`,
                query: this.queryBaseTotal
            }
        });
    }

    abrirLogHistorico(id, descricao) {
        let data = { id: id, entidade: 1,titulo: "Lista "+ descricao };

        this.dialog.open(LogHistoricoComponent, {
            hasBackdrop: true,
            height: "500px",
            width: "40%",
            data
        });
    }

    abrirLogLimpeza(id, descricao) {
        let data = { id: id, entidade: 2 , titulo: "Limpeza Lista "+ descricao};

        this.dialog.open(LogHistoricoComponent, {
            hasBackdrop: true,
            height: "500px",
            width: "40%",
            data
        });
    }

    abrirHistoricoIndexacao(id) {
        this.router.navigate([`/app/lista/dashboard/${id}`])
    }

    setCurrentPage(n: number) {
        if (this.dataTable)
            this.dataTable.first = n;
    }
}