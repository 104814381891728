export enum ListaStatus {
  AgIndexacao = 1,
  EmProcessamento,
  Online,
  Inativa,
  Cancelada,
  AgMediacao,
  Offline,
  ErroIndexacao,
  Pausado,
  TemplateAlterado
}
