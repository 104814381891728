
import { combineLatest as observableCombineLatest, Observable, empty } from 'rxjs';

import { map } from 'rxjs/operators';
import { MatDialog } from "@angular/material";
import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";

import {
	mapToElasticSearchFilter,
	mapToElasticSearchFilterRules,
	getFilterTypeByName
} from "../../model/elasticsearch-filter";
import { FiltroPorTipo } from "../../model/filtros";
import { Estrategia } from "../../../common/model/estrategia";
import { BaseElasticsearchFilter } from "../../model/elasticsearch-filter/base";

import { ListaService } from "../../../common/service/lista.service";
import { TemplateService } from "../../../common/service/template.service";
import { EnqueueObservableService } from "../../../common/service/enqueue-observable.service";
import { ElasticsearchRepositoryService } from "../../repository/elasticsearch-repository.service";

import { ElasticsearchAddFilterComponent } from "../elasticsearch-add-filter/elasticsearch-add-filter.component";
import { ExpressaoRegex } from "../../../common/model/expressao-regex";
import { RegexElasticsearchFilter } from "../../model/elasticsearch-filter/regex";
import { ListaExpressaoService } from "../../../common/service/lista-expressao.service";
import { changeOrder } from "../../../common/model/change-collection-item-order";
import { isNull, isNullOrEmpty, isNullOrZero } from '../../../common/utils';
import { deepCopy } from '../../../common/utils/deepCopy';
import { FiltroStatusRegistroLista } from '../../../common/model/lista';
import { QueryStringElasticsearchFilter } from '../../model/elasticsearch-filter/query_string';
import { TermsElasticsearchFilter } from '../../model/elasticsearch-filter/terms';
import { WildcardElasticsearchFilter } from '../../model/elasticsearch-filter/wildcard';
import { TermElasticsearchFilter } from '../../model/elasticsearch-filter/term';
import { NotTermsElasticsearchFilter } from '../../model/elasticsearch-filter/not_terms';
import { FieldQuery } from '../../model/elasticsearch-filter/field_query';
import { NotExistsQuery } from '../../model/elasticsearch-filter/not';
import { EmptyQuery } from '../../model/elasticsearch-filter/empty';
import { NestedElasticsearchFilter } from '../../model/elasticsearch-filter/nested-filter';
import { ChangeDetectionStrategy } from "@angular/core";
import { FiltroQuery } from '../../../common/model/filtro-query';
import swal from "sweetalert2";

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: "app-elasticsearch-filter",
	templateUrl: "./elasticsearch-filter.component.html",
	providers: [
		{ useClass: TemplateService, provide: TemplateService },
		{ useClass: ListaService, provide: ListaService },

		{
			useClass: ElasticsearchRepositoryService,
			provide: ElasticsearchRepositoryService
		},
		{
			useClass: EnqueueObservableService,
			provide: EnqueueObservableService
		},
		{
			useClass: ListaExpressaoService,
			provide: ListaExpressaoService
		}
	]
})
export class ElasticsearchFilterComponent implements OnInit {
	camposMapeados: any[];
	quantidades: Array<number> = [];
	campoSelecionado;
	parameters = {};
	quantidadesLista: any;

	@Input()
	titleComponent: string = 'Dados dos Filtros';

	@Output()
	abrirModalFiltroPadraoEvent: EventEmitter<any>;
	@Output()
	desvincularFiltroPadraoEvent: EventEmitter<any>;

	@Input()
	finishedLoading: boolean;
	@Output()
	showPreview: EventEmitter<any>;
	@Input()
	showButtonPreview: boolean = true;
	@Output()
	updateQuantidades: EventEmitter<any>;
	@Output()
	updateQuery: EventEmitter<any>;
	@Input()
	pageSize: number = 10;

	@Input()
	readOnly: boolean;
	fields: Array<BaseElasticsearchFilter>;
	fieldsHidden: Array<BaseElasticsearchFilter>;

	notInCollection: { field: string; collection: string[] }[];
	must: Array<BaseElasticsearchFilter> = [];
	filtroSelecionado;
	listaExpressaoRegex = new Array<ExpressaoRegex>();
	static campoArrastado: any;

	_estrategia: Estrategia;
	_listaId: any;
	_templateId;
	_lista;
	_reenviarDistribuidos: FiltroStatusRegistroLista;
	//_showButtonPreview: any = false;

	_exibirAplicarESalvar: boolean = false;

	@Input()
	public set exibirAplicarESalvar(valor: boolean) {
		this._exibirAplicarESalvar = valor;
	}

	constructor(
		private templateService: TemplateService,
		private listaService: ListaService,
		private elasticsearchService: ElasticsearchRepositoryService,
		public dialog: MatDialog,
		private enqueueObservableService: EnqueueObservableService,
		private listaExpressaoService: ListaExpressaoService
	) {
		this.showPreview = new EventEmitter<any>();
		this.updateQuantidades = new EventEmitter<any>();
		this.updateQuery = new EventEmitter<any>();

		this.abrirModalFiltroPadraoEvent = new EventEmitter<any>();
		this.desvincularFiltroPadraoEvent = new EventEmitter<any>();
	}

	public get templateId() {
		return this._templateId;
	}

	@Input()
	public set templateId(id) {

		if (!id) return;

		this._templateId = id;

		this.CarregarCamposMapeados();
	}

	public get lista(): string {
		return this._lista;
	}

	@Input()
	public set lista(lista: string) {
		if (!lista) return;
		this._lista = lista;
		this.aplicarOpcoesNaoContemEstrategia(this.estrategia);
		this.CarregarCamposMapeados();
	}

	updatedReenviarDistribuidos: boolean = false;

	@Input()
	public set reenviarDistribuidos(reenviarDistribuidos) {
		if (undefined == reenviarDistribuidos) return;
		this._reenviarDistribuidos = reenviarDistribuidos;
		if (this.updatedReenviarDistribuidos) this.setarQuantidades("re-send dist");
		else this.updatedReenviarDistribuidos = true;
	}

	public get reenviarDistribuidos() {
		return this._reenviarDistribuidos;
	}

	public get disableActions(): boolean {
		return !this.lista || !this.templateId;
	}

	public get listaId() {
		return this._listaId;
	}

	@Input()
	public set listaId(id) {
		this._listaId = id;
		this.atualizarQuantidadesLista();
	}

	@Input()
	public set estrategia(estrategia: Estrategia) {
		if (!estrategia) return;
		this._estrategia = estrategia;
		this.atualizarQuantidadesLista();
		this.aplicarOpcoesNaoContemEstrategia(estrategia);
		this.CarregarCamposMapeados();
	}


	public CarregarCamposMapeados() {
		if (isNull(this.listaId) && !isNull(this.estrategia) && !isNull(this.estrategia.listaId)) {
			this.listaId = this.estrategia.listaId;
		}

		if (!isNullOrZero(this._templateId) && !isNullOrZero(this.listaId) && isNull(this.camposMapeados)) {

			this.templateService.obterCamposMapeados(this._templateId, this.listaId).subscribe(camposMapeados => {
				this.camposMapeados = camposMapeados;
				this.aplicarOpcoesNaoContemEstrategia(this.estrategia);
			});
		}
	}

	public get estrategia() {
		return this._estrategia;
	}

	get query() {
		this.must = this.MustSemIntegrado;
		let must = this.must.filter(f => !f.negation).map((f: any) => {
			return this.convertFilter(f).convertedFilter
		});

		let mustNot = this.must.filter(f => f.negation === true).map((f: any) => {
			return this.convertFilter(f).convertedFilter
		});

		return {
			size: this.pageSize,
			_source: {
				includes: []
			},
			query: {
				bool: {
					must: [...must, ...this.mustUtilizado],
					must_not: [...mustNot, ...this.mustNot]
				}
			}
		}
	}

	public get MustSemIntegrado() {

		return this.must.filter((f: any) => {
			if (f.queryType == 'exists' && f.property == "_integrado")
				return false;

			return true;
		});
	}

	public get mustNot() {
		if (this.reenviarDistribuidos == FiltroStatusRegistroLista.naoUtilizado)
			return [{ exists: { field: "_integrado" } }];

		return [];
	}

	public get mustUtilizado() {
		if (this.reenviarDistribuidos == FiltroStatusRegistroLista.utilizado)
			return [{ exists: { field: "_integrado" } }];

		return [];
	}

	public get filtros() {
		if (!this.campoSelecionado) return;
		return FiltroPorTipo[this.campoSelecionado.tipoDado];
	}

	obterListaExpressao(): any {
		this.listaExpressaoService.obterExpressao().subscribe(s => {
			this.listaExpressaoRegex = s;
		});
	}

	aplicarOpcoesNaoContemEstrategia(estrategia: Estrategia) {
		if (
			!estrategia ||
			estrategia.listaEstrategiaId === null ||
			estrategia.listaEstrategiaId === undefined ||
			!this.lista ||
			!this.templateId
		)
			return;

		if (this.estrategia.filtroNot.length == 0) {
			if (isNull(estrategia.filtro))
				estrategia.filtro = JSON.stringify(this.query);

			if (isNullOrEmpty(estrategia.filtroRegras)) {
				let json = JSON.parse(estrategia.filtro);
				this.must = json.query.bool.must.map((filter: any) => mapToElasticSearchFilter(null, filter));
				this.estrategia.filtroRegras = JSON.stringify(this.must);
			} else {
				let json = JSON.parse(estrategia.filtroRegras).map((filter: any) => {
					if (isNull(this.camposMapeados)) {
						return mapToElasticSearchFilterRules(filter, false);
					} else {
						let campo = this.camposMapeados.find((f: any) => f.nome.toLowerCase() == filter.property.replace('.keyword', '').toLowerCase());
						return mapToElasticSearchFilterRules(filter, isNull(campo.listaNested) ? false : campo.listaNested);
					}
				});

				this.must = json;
			}

			this.setarQuantidades("query with not in");
			return;
		} else {
			if (isNullOrEmpty(estrategia.filtroRegras)) {
				let json = JSON.parse(estrategia.filtro);
				this.must = json.query.bool.must.map((filter: any) => mapToElasticSearchFilter(null, filter));
				this.estrategia.filtroRegras = JSON.stringify(this.must);
			} else {
				let json = JSON.parse(estrategia.filtroRegras).map((filter: any) => {
					if (isNull(this.camposMapeados)) {
						return mapToElasticSearchFilterRules(filter, false);
					} else {
						let campo = this.camposMapeados.find((f: any) => f.nome.toLowerCase() == filter.property.replace('.keyword', '').toLowerCase());
						return mapToElasticSearchFilterRules(filter, isNull(campo.listaNested) ? false : campo.listaNested);
					}
				});

				this.must = json;
			}
		}

		observableCombineLatest(
			estrategia.filtroNot.map(field => {
				let filtro = this.must.find(f => f.property == field.replace('.keyword', '') || f.property == `${field.replace('.keyword', '')}.keyword`);
				let queryAnterior = (filtro != null) ? this.obterQueryAnterior(filtro) : this.query;
				return this.elasticsearchService.getOptionForField(this.lista, this.listaId, this.templateId, field, queryAnterior).pipe(map(collection => ({ field, collection })))
			})
		).subscribe((notInCollection: Array<any>) => {
			this.notInCollection = notInCollection;

			if (isNullOrEmpty(estrategia.filtroRegras)) {

				let json = JSON.parse(estrategia.filtro);

				if (!isNull(json.query.bool.must))
					this.must = json.query.bool.must.map(mapToElasticSearchFilter.bind(null, notInCollection));
			} else {
				let json = JSON.parse(estrategia.filtroRegras).map((filter: any) => {
					if (isNull(this.camposMapeados)) {
						return mapToElasticSearchFilterRules(filter, false, notInCollection);
					} else {
						let campo = this.camposMapeados.find((f: any) => f.nome.toLowerCase() == filter.property.replace('.keyword', '').toLowerCase());
						return mapToElasticSearchFilterRules(filter, (isNull(campo.listaNested) ? false : campo.listaNested), notInCollection);
					}
				});

				this.must = json;
			}

			//this.setarQuantidades("not in filter");
			this.setarQuantidades("close modal");
		});
	}

	ngOnInit() {
		this.obterListaExpressao();
	}

	atualizarQuantidadesLista() {
		if (undefined == this.listaId || this.estrategia == undefined) return;

		this.enqueueObservableService
			.enqueue(
				this.listaService.obterQuantidadeLista(
					this.listaId,
					this.estrategia.listaEstrategiaId
				)
			)
			.subscribe(
				quantidadesLista => (this.quantidadesLista = quantidadesLista)
			);
	}

	formatAlias(alias) {
		if (this.isUpperCase(alias)) return alias.toLowerCase();
		return `${alias[0].toLowerCase()}${alias.substring(1)}`;
	}

	isUpperCase(text) {
		return text.toUpperCase() == text;
	}

	obterCampoCorrespondente(filtro: BaseElasticsearchFilter) {
		let campo = this.camposMapeados.find(c => c.nome.toLowerCase() == filtro.getFieldName().toLowerCase());
		return campo;
	}

	obterTipoFiltro(filtro: BaseElasticsearchFilter) {
		if (!this.camposMapeados) return;
		var tipoDado = this.obterCampoCorrespondente(filtro).tipoDado;

		let item = (tipoDado == 'Nested')
			? FiltroPorTipo[tipoDado].filter(c => c.nestedType == filtro.nestedType && c.nestedContent == filtro.nestedContent && c.content == filtro.queryType && c.negation == filtro.negation)
			: FiltroPorTipo[tipoDado].filter(c => c.content == filtro.queryType && c.negation == filtro.negation);

		if (item.length > 1) {
			item = item.find(f => {
				let prm = Object.values(f.parameters.map((m: any) => m.name));

				return prm.every((e: any) => Object.keys(filtro.parameters).includes(e as string))
			});
		}

		return item ? (Array.isArray(item) ? item[0].name : item.name) : "";
	}

	obterNomeCampo(filtro: BaseElasticsearchFilter) {
		if (!this.camposMapeados) return;
		let campo = this.obterCampoCorrespondente(filtro);

		if (!campo) return "";
		return campo.nome;
	}

	obterDescricaoFiltro(filtro: any) {
		if (!this.camposMapeados) return;

		let filtroNew = this.convertFilter(filtro);

		var tipoDado = this.obterCampoCorrespondente(filtroNew).tipoDado;

		let filtroPorTipo = FiltroPorTipo[tipoDado];

		if (filtroNew.queryType == "regexp") {
			filtroNew.extra = this.listaExpressaoRegex.find(
				f => f.expressao == filtroNew.parameters && f.tipoDado == tipoDado
			).nome;
			if (!filtroPorTipo.find(f => f.regex))
				filtroPorTipo.push({
					name: "Aplicar validação",
					content: "regexp",
					regex: true,
					negation: false,
					filter: RegexElasticsearchFilter,
					parameters: [{ name: "query", alias: "Válidos" }]
				});
		}

		return filtroNew.friendlyParameters(tipoDado, filtroPorTipo, filtroNew.negation);
	}

	obterQuantidadeDisponivelAnterior(filtro) {
		var index = this.MustSemIntegrado.indexOf(filtro);
		if (index != 0) return this.quantidades[index - 1];
		if (!this.quantidadesLista) return 0;

		return this.reenviarDistribuidos == FiltroStatusRegistroLista.total ? this.quantidadesLista.totalGeral
			: this.reenviarDistribuidos == FiltroStatusRegistroLista.utilizado ? this.quantidadesLista.quantidadeDistribuida : this.quantidadesLista.quantidadeLivre;
	}

	obterPosicaoFiltro(filtro) {
		return this.must.indexOf(filtro) + 1;
	}

	obterPosicaoFiltroExibicao(filtro) {
		if (this.fieldsHidden != null)
			return (this.must.indexOf(filtro) - this.fieldsHidden.length) + 1;

		return this.must.indexOf(filtro) + 1;
	}

	obterQuantidade(filtro) {
		var posicao = this.must.indexOf(filtro);
		if (posicao < 0) return -1;
		return this.quantidades[posicao];
	}

	obterQueryParcial(filtro) {

		let must: any = [];
		let mustNot: any = [];
		let itens = this.must.slice(0, this.must.indexOf(filtro) + 1);



		itens.forEach((f: any) => {
			let item: any = this.convertFilter(f);

			if (!item.negation)
				must.push(item.convertedFilter);
			else
				mustNot.push(item.convertedFilter);
		});

		return {
			bool: {
				must: [...must, ...this.mustUtilizado],
				must_not: [...mustNot, ...this.mustNot]
			}
		}
	}

	obterQueryAnterior(filtro) {

		let must: any = [];
		let mustNot: any = [];
		let itens = this.must.slice(0, this.must.indexOf(filtro));

		itens.forEach((f: any) => {
			let item: any = this.convertFilter(f);

			if (!item.negation)
				must.push(item.convertedFilter);
			else
				mustNot.push(item.convertedFilter);
		});

		return {
			bool: {
				must: [...must, ...this.mustUtilizado],
				must_not: [...mustNot, ...this.mustNot]
			}
		}
	}

	convertFilter(f: any) {
		switch (f.constructor.name) {
			case 'BaseElasticsearchFilter':
				return (BaseElasticsearchFilter.fromRaw(deepCopy(f)));
			case 'QueryStringElasticsearchFilter':
				return (QueryStringElasticsearchFilter.fromRaw(deepCopy(f)));
			case 'TermsElasticsearchFilter':
				return (TermsElasticsearchFilter.fromRaw(deepCopy(f)));
			case 'WildcardElasticsearchFilter':
				return (WildcardElasticsearchFilter.fromRaw(deepCopy(f)));
			case 'TermElasticsearchFilter':
				return (TermElasticsearchFilter.fromRaw(deepCopy(f)));
			case 'NotTermsElasticsearchFilter':
				return (NotTermsElasticsearchFilter.fromRaw(deepCopy(f)));
			case 'FieldQuery':
				return (FieldQuery.fromRaw(deepCopy(f)));
			case 'NotExistsQuery':
				return (NotExistsQuery.fromRaw(deepCopy(f)));
			case 'EmptyQuery':
				return (EmptyQuery.fromRaw(deepCopy(f)));
			case 'RegexElasticsearchFilter':
				return (RegexElasticsearchFilter.fromRaw(deepCopy(f)));
			case 'NestedElasticsearchFilter':
				return (NestedElasticsearchFilter.fromRaw(deepCopy(f)));
		}
	}

	setarQuantidades(source: any, aplicarESalvar: boolean = false) {

		if (this.finishedLoading) {
			this.updateQuery.emit(this.query);
		}

		let must = this.must.map((f: any) => {
			return this.convertFilter(f)
		});

		let ordem = must.map((m: any) => {

			if (m.queryType == 'not_terms' && m.constructor.name == 'NotTermsElasticsearchFilter') {
				m.transformForNotContains();
			}

			return {
				"queryType": m.queryType,
				"queryStringValue": m.convertedFilter,
				"property": m.property,
				"keyword": m.keyword,
				"negation": m.negation
			};
		});

		this.elasticsearchService
			.getQuantidadeFiltrosOrdem(this.listaId, this.query, ordem)
			.subscribe(quantidades => {
				this.quantidades = quantidades;
				this.updateQuantidades.emit({ 'quantidades': quantidades, 'aplicarESalvar': aplicarESalvar });
			});
	}

	public adicionarFiltro() {
		this.abrirModalFiltro(null);
	}

	abrirModalFiltro(filtro: any) {

		let nomeCampoSelecionado = filtro ? this.obterNomeCampo(filtro) : "";
		let nomeFiltroSelecionado = filtro ? this.obterTipoFiltro(filtro) : "";
		let query = (filtro != null) ? this.obterQueryParcial(filtro) : this.query.query;
		let queryAnterior = (filtro != null) ? this.obterQueryAnterior(filtro) : this.query.query;
		let disponivel = (filtro != null) ? this.quantidadesLista.quantidadeLivre : this.obterQuantidadeDisponivelAnterior(filtro);
		let param: any = null;

		if (filtro) {
			param = {};
			Object.assign(param, filtro.parameters);
		}
		let data = {
			isEdit: filtro != null,
			nomeLista: this.lista,
			nomeCampoSelecionado: String(nomeCampoSelecionado),
			nomeFiltroSelecionado: String(nomeFiltroSelecionado),
			parameters: param,
			templateId: this.templateId,
			index: this.lista,
			posicaoFiltro: this.obterPosicaoFiltro(filtro),
			query: query,
			queryAnterior: queryAnterior,
			disponivel: disponivel,
			listaExpressaoRegex: this.listaExpressaoRegex,
			listaId: this.listaId,
			exibirAplicarESalvar: this._exibirAplicarESalvar
		};

		if (filtro) {
			disponivel = this.obterQuantidadeDisponivelAnterior(filtro);
		} else if (this.must.length == 0) {
			disponivel = this.reenviarDistribuidos == FiltroStatusRegistroLista.total ? this.quantidadesLista.totalGeral
				: this.reenviarDistribuidos == FiltroStatusRegistroLista.utilizado ? this.quantidadesLista.quantidadeDistribuida : this.quantidadesLista.quantidadeLivre;
		} else {
			disponivel = this.quantidades[this.quantidades.length - 1];
		}
		data.disponivel = disponivel;
		if (filtro && filtro.not_in) {
			nomeFiltroSelecionado = FiltroPorTipo.Lista.find(f => f.content == "not_terms").name;
			data.parameters = filtro.not_in;
			data.nomeFiltroSelecionado = String(nomeFiltroSelecionado);
		}

		let dialogRef = this.dialog.open(ElasticsearchAddFilterComponent, {
			hasBackdrop: true,
			width: "70%",
			data
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result) {

				if (result.cancelado === true)
					return;

				let aplicarESalvar = (result.aplicarESalvar === true);

				result.forEach(element => {

					if (filtro) {

						let index = this.must.indexOf(filtro);
						this.must[index] = this.getObjetoFiltro(element);

						if (!isNull(this.estrategia))
							this.estrategia.filtroRegras = JSON.stringify(this.must);

						this.setarQuantidades("close modal", aplicarESalvar);
					} else {
						this.adicionar(element, aplicarESalvar);
					}
				});
			}
		});
	}

	emitShowPreview(filtro) {

		const query = {
			size: 10,
			_source: {
				includes: []
			},
			query: this.obterQueryParcial(filtro)
		};

		this.showPreview.emit(query);
	}

	getObjetoFiltro(content) {
		var filterType = getFilterTypeByName(content.filtroSelecionado.content);
		let keyword = (content.campoSelecionado.tipoDado == "Lista" || content.filtroSelecionado.keyword);

		return new filterType(
			content.campoSelecionado.alias,
			content.filtroSelecionado.content,
			content.parameters,
			content.extra,
			keyword,
			content.filtroSelecionado.nestedContent,
			content.filtroSelecionado.nestedType,
			content.campoSelecionado.listaNested,
			content.filtroSelecionado.negation === true
		);
	}

	adicionar(content: any, aplicarESalvar: boolean = false) {
		this.must.push(this.getObjetoFiltro(content));

		if (!isNull(this.estrategia))
			this.estrategia.filtroRegras = JSON.stringify(this.must);

		this.setarQuantidades("add filter", aplicarESalvar);
	}

	remover(filtro) {
		this.must = this.must.filter(f => f != filtro);

		if (!isNull(this.estrategia))
			this.estrategia.filtroRegras = JSON.stringify(this.must);

		this.setarQuantidades("remove filter");
	}

	alterarOrdem(filtroOrigem: any, filtroDestino: any) {

		if (this.mostrarControles(filtroOrigem) == false || this.mostrarControles(filtroDestino) == false)
			return;

		var newOrderCollection = changeOrder(this.must, filtroOrigem, filtroDestino);
		this.must = newOrderCollection;

		if (!isNull(this.estrategia))
			this.estrategia.filtroRegras = JSON.stringify(this.must);

		this.setarQuantidades("");
	}

	moverCampo(filtro: any, descerPosicao: boolean) {
		let indexFiltro = this.obterPosicaoFiltro(filtro) - 1;
		let indexDestino = descerPosicao ? indexFiltro + 1 : indexFiltro - 1;
		let filtroDestino = this.must[indexDestino];

		this.alterarOrdem(filtro, filtroDestino);
	}

	handleDragStart(filtro: any) {
		if (this.readOnly || this.fieldsHidden != null) return;
		ElasticsearchFilterComponent.campoArrastado = filtro;
	}

	handleDragOver(event: Event) {

		if (this.readOnly || this.fieldsHidden != null) return;
		event.preventDefault();
		(<Element>event.target).parentElement.classList.add("droppable");
	}

	handleDragLeave(event: Event) {
		if (this.readOnly || this.fieldsHidden != null) return;
		event.preventDefault();
		(<Element>event.target).parentElement.classList.remove("droppable");
	}

	handleDrop(event: Event, filtro: any) {

		if (this.readOnly || this.fieldsHidden != null) return;
		(<Element>event.target).parentElement.classList.remove("droppable");
		this.alterarOrdem(ElasticsearchFilterComponent.campoArrastado, filtro);
	}

	atualizar(fields: string, fieldsHidden: string = '') {

		if (isNullOrEmpty(this.estrategia.filtro)) {
			this.must = new Array<BaseElasticsearchFilter>();
			return;
		}

		if (!isNullOrEmpty(fields))
			this.fields = JSON.parse(fields).query.bool.must.map((filter: any) => mapToElasticSearchFilter(null, filter));

		if (!isNullOrEmpty(fieldsHidden))
			this.fieldsHidden = JSON.parse(fieldsHidden).query.bool.must.map((filter: any) => mapToElasticSearchFilter(null, filter));

		if (this.estrategia.filtroNot.length == 0) {

			this.must = JSON.parse(this.estrategia.filtro).query.bool.must.map(filter =>
				mapToElasticSearchFilter(null, filter)
			);
			this.setarQuantidades("query with not in");
			return;
		}

		observableCombineLatest(
			this.estrategia.filtroNot.map(field =>
				this.elasticsearchService
					.getOptionForField(this.lista, this.listaId, this.templateId, field, this.query).pipe(
						map(collection => ({ field, collection })))
			)
		).subscribe((notInCollection: Array<any>) => {
			this.notInCollection = notInCollection;

			this.must = JSON.parse(this.estrategia.filtro).query.bool.must.map(
				mapToElasticSearchFilter.bind(null, notInCollection)
			);
			this.setarQuantidades("not in filter");
		});

	}

	visualizarCampo(item: BaseElasticsearchFilter) {

		if (this.fieldsHidden == null || this.fieldsHidden.length <= 0)
			return true;

		return !this.fieldsHidden.some((f: BaseElasticsearchFilter) => f.property == item.property && f.parameters['query'] == item.parameters['query']);
	}


	abrirModalFiltroPadrao() {
		this.abrirModalFiltroPadraoEvent.emit()
	}

	desvincularFiltroPadrao() {
		this.desvincularFiltroPadraoEvent.emit();
	}

	@Input()
	mostrarAcoesFiltrosPadroes: boolean = false;

	public get temFiltroPadrao() {
		return (!isNull(this.estrategia.filtroQueryId))
	}

	public get obterNomeFiltroPadrao() {
		if (isNull(this.estrategia.filtroQuery))
			return '---';

		return this.estrategia.filtroQuery.nome;
	}

	public mostrarControles(filtro: any): boolean {
		if (!this.temFiltroPadrao)
			return true;

		let json = JSON.parse(this.estrategia.filtroQuery.filtroRegras);

		return (json.some((s: any) => s.property == filtro.property && s.queryType == filtro.queryType && JSON.stringify(s.parameters) == JSON.stringify(filtro.parameters)) == false);

	}
}
