import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { CustomAlertService } from "../../../common/service/custom-alert.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "app-campo-campanha-info-modal",
    templateUrl: "./campo-campanha-info-modal.component.html",
    styleUrls: ["./campo-campanha-info-modal.component.scss", "../../style.css",]
})

export class CampoCampanhaInfoModalComponent {
    campos: Array<any>;

    constructor(
        private ref: MatDialogRef<CampoCampanhaInfoModalComponent>,
        private customAlertService: CustomAlertService,
        private translate: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data
    ) {
        this.campos = this.data.campos;
    }

    fechar() {
        this.ref.close();
    }
}