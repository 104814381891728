import { Component, Inject, OnDestroy } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Subscription } from "rxjs";

import { CustomAlertService } from "../../common/service/custom-alert.service";
import { FiltroQueryService } from "../../common/service/filtro-query.service";
import { ListaService } from "../../common/service/lista.service";
import { FiltroQuery } from "../../common/model/filtro-query";

@Component({
    selector: "app-filtro-query-padrao-modal",
    templateUrl: "./filtro-query-padrao-modal.component.html",
    styleUrls: ["./filtro-query-padrao-modal.component.scss"],
    providers: [
        { useClass: FiltroQueryService, provide: FiltroQueryService },
        { useClass: ListaService, provide: ListaService },
        { useClass: CustomAlertService, provide: CustomAlertService }
    ]
})
export class FiltroQueryPadraoModalComponent implements OnDestroy {

    filtroPesquisa: any = {};
    listaFiltrosQuerys: Array<FiltroQuery> = [];
    listaId: number;
    filtroQueryId: number;

    subPesquisa: Subscription;

    constructor(
        private filtroQueryService: FiltroQueryService,
        private customAlertService: CustomAlertService,
        private ref: MatDialogRef<FiltroQueryPadraoModalComponent>,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA)
        public data: { listaId: number, filtroQueryId: number, listaTemplateId: number, listaAlias: string }
    ) {
        this.listaId = this.data.listaId;
        this.filtroQueryId = this.data.filtroQueryId;
        this.pesquisar();
    }

    pesquisar() {
        this.filtroPesquisa.listaId = this.listaId;
        this.subPesquisa = this.filtroQueryService.obterFiltros(this.filtroPesquisa).subscribe((result: FiltroQuery[]) => this.listaFiltrosQuerys = result);
    }

    selecionar(filtro: FiltroQuery) {
        this.ref.close(filtro);
    }

    limpar() {
        this.filtroPesquisa.nome = null;
    }

    fechar() {
        this.ref.close();
    }

    ngOnDestroy() {
        if (this.subPesquisa) this.subPesquisa.unsubscribe();
    }
}
