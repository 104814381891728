
import { map, tap } from 'rxjs/operators';
import { Observable } from "rxjs";
import { Login } from "../../autenticacao/model/login";
import { Injectable } from "@angular/core";
import { Credential } from "../../autenticacao/model/credential";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { convertToQueryString } from "./query-string";
import { environment } from "../../../../environments/environment";
import { StorageProxy } from "./storage-proxy.service";

import * as jwt_decode from "jwt-decode";
import { Permissao } from "../model/permissoes-usuario";
import { User } from "../../autenticacao/model/user";

const Auth = {
	client_id: environment.client_id,
	grant_type: "password"
};

@Injectable()
export class AutenticacaoService {
	private headers: HttpHeaders;

	get autenticado() {
		return (
			StorageProxy.userData != undefined && (StorageProxy.userData.TokenType == "Cookie" || (StorageProxy.userData.AccessToken != undefined /*&& !isTokenExpired*/))
		);
	}

	get tokenExpired() {
		let isTokenExpired = this.isTokenExpired(StorageProxy.userData.AccessToken);
		
		return (StorageProxy.userData.AccessToken != undefined && isTokenExpired);
	}

	constructor(private httpClient: HttpClient) {
		this.headers = new HttpHeaders().set(
			"Content-Type",
			"application/x-www-form-urlencoded"
		);
	}

	realizarLogin(login: Login) {
		let loginData = Object.assign({}, Auth, {
			username: login.login,
			password: login.senha
		});

		return this.httpClient
			.post<Credential>(
				`${environment.serverUrl}/connect/token`,
				convertToQueryString(loginData, false),
				{
					headers: new HttpHeaders().set(
						"Content-Type",
						"application/x-www-form-urlencoded"
					)
				}
			).pipe(
				tap(credential => (StorageProxy.userData = credential)));
	}



	obterCredenciaisPorCookie() {
		return this.httpClient
			.get<any>(`${environment.serverUrl}/autenticacao/credenciais`, {
				withCredentials: true
			}).pipe(
				tap(result => {
					let user = new User();
					user.Id = result.user.id;
					user.UserLogin = result.user.userLogin;
					user.UserName = result.user.userName;

					StorageProxy.userData = Object.assign({}, StorageProxy.userData, {
						TokenType: result.tokenType,
						User: user
					});
				}));
	}

	obterTokenAutenticado() {
		if (!StorageProxy.userData) return undefined;

		return StorageProxy.userData.AccessToken;
	}

	isAutenticadoSemToken() {
		return StorageProxy.userData && !StorageProxy.userData.AccessToken;
	}

	logoutUser() {
		return this.httpClient
			.post(`${environment.serverUrl}/autenticacao/logout`, {}).pipe(
				tap(
					res => localStorage.removeItem("userData"),
					error => localStorage.removeItem("userData")
				));
	}

	private getTokenExpirationDate(token: string): Date {
		const decoded = jwt_decode(token);
		if (decoded.exp === undefined) return undefined;

		const date = new Date(0);
		date.setUTCMinutes(decoded.exp);
		return date;
	}

	public obterPermissoes() {
		return this.httpClient.get<Permissao>(
			`${environment.serverUrl}/api/usuarios/permissoes`
		);
	}

	private isTokenExpired(acess_token: any): boolean {
		if (!acess_token) return true;

		const date = this.getTokenExpirationDate(acess_token);
		if (date === undefined) return false;
		return !(date.valueOf() > new Date().valueOf());
	}

	refreshToken(): Observable<any> {
		let body = Object.assign({}, Auth, {
			grant_type: "refresh_token",
			refresh_token: StorageProxy.userData.RefreshToken
		});
		return this.httpClient
			.post<Credential>(
				`${environment.serverUrl}/connect/token`,
				convertToQueryString(body),
				{
					headers: this.headers
				}
			).pipe(
				tap(
					result =>
						(StorageProxy.userData = Object.assign({}, StorageProxy.userData, {
							AccessToken: result.AccessToken
							//RefreshToken: result.RefreshToken
						}))
				),
				map(res => Object.assign({}, res, { newToken: true })));
	}
}
