import { Component, OnInit } from "@angular/core";

import { Usuario } from "../../common/model/usuario";
import { UsuarioService } from "../../common/service/usuario.service";
import { SistemaRecursoAcaoService } from "../../common/service/sistema-recurso-acao.service";

@Component({
  selector: "app-tabs-usuario",
  templateUrl: "./tabs-usuario.component.html",
  styleUrls: ["../style.scss"]
})
export class TabsUsuarioComponent implements OnInit {
  constructor() {
  }
  ngOnInit() { }
}
