import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import { ListaService } from "../../../common/service/lista.service";

@Component({
	selector: "app-preview-dados-historico",
	templateUrl: "./preview-dados-historico.component.html",
	styleUrls: ["../../style.css"],
	providers: [{ useClass: ListaService, provide: ListaService }]
})
export class PreviewDadosHistoricoComponent {

	listaHistorico: any[];
	totalHistorico: any;

	header: string[] = [];
	headerInvalido: string[] = [];
	abaSelecionada: number = 0;

	constructor(
		private ref: MatDialogRef<PreviewDadosHistoricoComponent>,
		private translate: TranslateService,
		@Inject(MAT_DIALOG_DATA) public data,
		private listaService: ListaService
	) {
		//this.header = data.template.listaTemplateItem.filter(f => f.ativo).sort(compare).map(m => m.nome);
		this.abaSelecionada = data.abaSelecionada;
		this.preview(this.data.historicoIndexacaoId, 1);
	}

	preview(historicoIndexacaoId: number, pagina: number) {

		 this.listaService
			.obterPreviewHistoricoErro(historicoIndexacaoId, pagina)
			.subscribe(dados => {
				this.listaHistorico = dados.collection;
				this.totalHistorico = dados.total;

				if (dados.collection.length == 0) return;
			});
	}

	changePage(event) {
		const pagina = event.first / event.rows + 1;
		this.preview(this.data.historicoIndexacaoId, pagina);
	}

	getValorPreview(item, propriedade, index) {
		let key = Object.keys(item).find(prop => prop.toLocaleLowerCase() == propriedade.toLocaleLowerCase());

		if (key == undefined)
			key = `item${index}`;

		if (item[key] != undefined)
			return item[key].valor;

		return "";
	}

	// getErroPreview(item, propriedade, index) {
	// 	let key = Object.keys(item).find(prop => prop.toLocaleLowerCase() == propriedade.toLocaleLowerCase());

	// 	if (key == undefined)
	// 		key = `item${index}`;

	// 	if (item[key] != undefined)
	// 		return item[key].erro;

	// 	return false;
	// }

	fechar() {
		this.ref.close();
	}
}

// function compare(a, b) {
// 	if (a.ordem < b.ordem)
// 		return -1;
// 	if (a.ordem > b.ordem)
// 		return 1;
// 	return 0;
// }