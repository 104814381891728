import { Component, Input, EventEmitter, Output, ViewEncapsulation, ElementRef, ViewChild } from "@angular/core";
import { isNull } from "../../utils";

@Component({
  selector: "app-select",
  templateUrl: "./select.component.html",
  styleUrls: ["./select.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class SelectComponent {
  _data: Array<any> = [];
  @Input()
  label: string;
  @Input()
  id: string;
  @Input()
  value: any;
  @Input()
  multiple: any;
  _selected: any;
  @Input()
  tabindexVal: number;

  @Input()
  set selected(selected) {
    this._selected = selected ? selected.toString() : null;
  }

  get selected() {
    return this._selected;
  }

  @Input()
  disabled: boolean;

  @Input()
  allowClear: boolean = true;

  //@Input()
  //noFilter: number = 0;

  @Input()
  public set noFilter(data: number) {
    if (data || data === 0)
      this._noFilter = (data <= 0) ? parseInt('Infinity') : data
  }

  _noFilter: number = 0;


  //Infinity

  @Input()
  cssClass: string = '';
  // public set cssClass(data: string) {
  //   if(data){
  //     $(this.customSelect).css(data);
  //   }
  // }


  @Input()
  placeholder;

  @Input()
  refresh: boolean = false;

  @Output()
  selectedChange: EventEmitter<any>;

  @Output()
  deSelectedChange: EventEmitter<any>;

  @Output()
  changed: EventEmitter<any>;

  public get data() {
    return this._data;
  }

  @Input()
  public set data(data: Array<any>) {
    this._data = data;
    this.setOptions();
  }

  setOptions() {
    if (!this.data) return;
    if (this.options.length == this.data.length && !this.refresh) {
      var newValues = this.data.map(item => item[this.value]);
      var oldValues = this.options.map(item => item.value);

      if (newValues.every((value, index) => value == oldValues[index])) return;
    }

    this.options = this.data.map(item => {
      return {
        label: (!isNull(item[this.label]) && typeof (item[this.label]) != "string") ? item[this.label].toString() : item[this.label],
        value: (!isNull(item[this.value]) && typeof (item[this.value]) != "string") ? item[this.value].toString() : item[this.value],
        disabled: item.disabled == true ? true : false
      };
    });
  }

  options = [];


  @ViewChild('customSelect', { static: false, read: ElementRef }) customSelect: ElementRef;

  constructor() {
    this.selectedChange = new EventEmitter();
    this.deSelectedChange = new EventEmitter();
    this.changed = new EventEmitter();
  }

  change(item) {
    this.selectedChange.emit(item.value);
    this.changed.emit(item.value);
  }

  deselect() {
    this.selectedChange.emit(undefined);
    this.deSelectedChange.emit({});
    this.changed.emit(undefined);
  }

  focus() {
    if (!this.id) return;
    //TODO: Verificar
    // setTimeout(() => {
    //   //app-select > ng-select > div
    //   var element =document.getElementById(this.id).children[0].children[0];
    //   if(!element.classList.contains("open")){
    //       this.eventFire(element, 'click');
    //   }
    // }, 200);
  }

  private eventFire(el, etype) {
    if (el.fireEvent) {
      el.fireEvent("on" + etype);
    } else {
      var evObj = document.createEvent("Events");
      evObj.initEvent(etype, true, false);
      el.dispatchEvent(evObj);
    }
  }
}
