export enum ImportacaoAutomaticaHistoricoStatus {
    uploadArquivos = 1,
    processandoArquivos = 2,
    concluidoComErros = 3,
    concluido = 4,
    erro = 5
}

export class ImportacaoAutomaticaHistorico {

    importacaoAutomaticaHistoricoId: number;
    importacaoAutomaticaConfiguracaoId: number;
    status: ImportacaoAutomaticaHistoricoStatus;
    execucaoInicio: Date;
    execucaoFim: Date;
    usuarioSolicitante: number;

    static fromRaw(raw: any) {
        return Object.assign(new ImportacaoAutomaticaHistorico(), raw);
    }
}

