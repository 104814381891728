import { Component, Input, Output, EventEmitter } from "@angular/core";

import { TemplateService } from "../../../common/service/template.service";

import { Template } from "../../../common/model/template";
import { FonteDado } from "../../../common/model/fonte-dado";
import { FonteDadoTipo } from "../../../common/model/fonte-dado-tipo";

@Component({
  selector: "app-fonte-dados",
  templateUrl: "./fonte-dados.component.html",
  styleUrls: ["../lista-cadastro.css"],
  providers: [{ useClass: TemplateService, provide: TemplateService }]
})
export class FonteDadosComponent {
  public initialConfig = { hour: 7, minute: 15, meriden: "PM", format: 12 };
  _fonteDado: FonteDado;

  @Input()
  erros;

  @Output()
  fonteDadoChange: EventEmitter<FonteDado>;

  listaFonteDadoTipo: Array<any>;
  listaTemplate: Array<Template>;

  @Input()
  public set fonteDado(f: FonteDado) {
    this._fonteDado = f;
  }

  public get fonteDado() {
    return this._fonteDado;
  }

  constructor(private templateService: TemplateService) {
    this.fonteDadoChange = new EventEmitter<FonteDado>();

    this.templateService
      .obterTemplates()
      .subscribe(listaTemplate => (this.listaTemplate = listaTemplate));

    this.listaFonteDadoTipo = Object.keys(FonteDadoTipo)
      .filter(key => !isNaN(parseInt(key)))
      .map(key => ({ key: parseInt(key), value: FonteDadoTipo[key] }));
  }

  ngOnInit() {}

  excluir() {
    this.fonteDado.ativo = false;
  }
}
