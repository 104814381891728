import { Pipe, PipeTransform } from "@angular/core";
import { ArquivoStatus } from "../../common/model/arquivo-status";
import { ItemTipo } from "../../common/model/lista-template-item";

/* TODO: A descrição do status vai vir do back-end nao precisando mais desse pipe */

@Pipe({
	name: "listaTemplateItemTipoDescricao"
})
export class ListaTemplateItemTipoDescricaoPipe implements PipeTransform {
	transform(value: ItemTipo, args?: any): any {
		switch (value) {
			case ItemTipo.Padrao:
				return "Padrão";
			case ItemTipo.Linha:
				return "Linha";
			case ItemTipo.Coluna:
				return "Coluna";
			case ItemTipo.Score:
				return "Score";
			case ItemTipo.Sistema:
				return "Sistema";
			case ItemTipo.Retorno:
				return "Retorno";
			case ItemTipo.Validacao:
				return "Validacao";
			case ItemTipo.Tracking:
				return "Tracking";
			case ItemTipo.Conversao:
				return "Conversao";
		}
		return "";
	}
}
