import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";

@Pipe({
  name: "dateFormat"
})
export class DateFormatPipe implements PipeTransform {
  transform(value: Date, args?: any): any {
    if (!value) return "";
    if (!args) return moment(value).format("DD/MM/YYYY");
    return moment(value).format("DD/MM/YYYY HH:mm:ss");
  }
}
