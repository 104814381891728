import { ListaStatus } from "./listaStatus";
import { FonteDado } from "./fonte-dado";
//import { validate } from "validate.js";
import { FonteDadoTipo } from "./fonte-dado-tipo";
import { ConfiguracaoListaProvedor } from "./configuracao-lista-provedor";
import { isNull } from "../utils";
const validate = require("validate.js");

export enum FiltroStatusRegistroLista {
	naoUtilizado = 0,
	total = 1,
	utilizado = 2
}

export class Lista {
	listaId: number;
	nome: string;
	listaStatusId: ListaStatus;
	porcentagemIndexacao: number; //todo: ver maneira de obter
	porcentagemTamanho: number; //todo: obtido através do elasticsearch
	tamanho: number; //todo: obtido através do elasticsearch
	possuiRegistros: boolean; //todo: ver maneira de obter    
	providerId: number;
	listaTemplateId: number;
	alias: string;
	configuracaoListaProvedor: Array<ConfiguracaoListaProvedor>;

	campoCard: string;
	operacaoCampoCard: number;
	campoAgrupadorSomaUnicosCard: string;
	formatacaoCard: string;

	somaUnicosCardCampo: string;
	somaUnicosCardAgrupador: string;
	somaUnicosCardFormatacao: string;
	somaUnicosCardValor: string;
	somaUnicosCardProcessamento: Date;
	somaUnicosCardAlterado: boolean;

	templatePossuiAcionamento: boolean;

	processando: boolean;
	moverParaNaoUtilizado: boolean;
	moverParaNaoUtilizadoTrackingRetorno: boolean;
	sobrescreverRegistroAtualizacao: boolean;

	carteiraId: number;

	public get inativa(): boolean {
		return this.listaStatusId == ListaStatus.Inativa;
	}

	public get ativa(): boolean {
		return !this.inativa && !this.cancelada;
	}

	public get cancelada(): boolean {
		return this.listaStatusId == ListaStatus.Cancelada;
	}

	constructor(nome?: string, id?: number, status?: ListaStatus) {
		this.nome = nome;
		this.listaId = id;
		this.listaStatusId = status;
		this.possuiRegistros = true;
		this.porcentagemIndexacao = 0;
		this.porcentagemTamanho = 0;
		this.tamanho = 0;
		this.providerId = 0;
		this.moverParaNaoUtilizado = true;
		this.moverParaNaoUtilizadoTrackingRetorno = true;

		validate.validators.configuracaoListaProvedorRetornoAtivaArray = (items: Array<any>, validationRules: any) => {

			const arrayItemErrors = items.filter(f => f.ativo).reduce((errors, item, index) => {

				let error = validate(item, validationRules);
				if (!error) return;

				if (error) errors[index] = { 'providerId': item.providerId, 'error': error };
				return errors;
			}, {})

			if (arrayItemErrors === null || arrayItemErrors === undefined) return null;
			return (Object.keys(arrayItemErrors).length === 0) ? null : { errors: arrayItemErrors };        // ES5
			//return (Object.entries(arrayItemErrors).length === 0) ? null : { errors: arrayItemErrors };   // ES6 diferente do keys
			//return this.isNull(arrayItemErrors) ? null : { errors: arrayItemErrors };
		};

	}

	limparLista(): any {
		this.tamanho = 0;
		this.possuiRegistros = false;
		this.porcentagemTamanho = 0;
	}

	static fromRaw(rawLista): Lista {
		let lista = new Lista();
		lista = Object.assign(lista, rawLista);
		if (lista.listaStatusId == ListaStatus.Online)
			lista.porcentagemIndexacao = 100;
		return lista;
	}

	ativarLista() {
		this.listaStatusId = ListaStatus.AgIndexacao;
	}

	cancelarLista() {
		this.listaStatusId = ListaStatus.Cancelada;
	}

	pausarLista(): any {
		this.listaStatusId = ListaStatus.Pausado;
	}

	pararLista(): any {
		this.listaStatusId = ListaStatus.Offline;
		this.porcentagemIndexacao = 0;
		this.porcentagemTamanho = 0;
	}

	private get configuracaoListaProvedorRetornoAtiva(): ConfiguracaoListaProvedor[] {
		return this.configuracaoListaProvedor.filter((f: ConfiguracaoListaProvedor) => (f.ativo && f.habilitaRetorno));
	}

	validar() {
		let regras: any = {
			nome: {
				presence: { allowEmpty: false }
			},
			providerId: {
				presence: { allowEmpty: false }
			}
		};

		if (this.listaId > 0 && !isNull(this.configuracaoListaProvedorRetornoAtiva)) {
			regras.configuracaoListaProvedorRetornoAtiva = {
				configuracaoListaProvedorRetornoAtivaArray: {
					configuracaoCampoProviderId: { presence: { allowEmpty: false, message: "^obrigatorio" } },
					listaTemplateItemId: { presence: { allowEmpty: false, message: "^obrigatorio" } }
				}
			};
		}

		let validacaoListaResult = validate.validate(this, regras);

		if (!validacaoListaResult) return;
		return validacaoListaResult;
	}

	static Mock(nome?: string, id?: number, status?: ListaStatus) {
		let lista = new Lista(nome, id, status);
		lista.porcentagemIndexacao = Math.random() * 100;
		lista.porcentagemTamanho = Math.random() * 100;
		lista.tamanho = Math.random() * 3;
		lista.possuiRegistros = true;
		return lista;
	}
}
