import { ElementRef, HostBinding, Component, Input, OnChanges, ViewChild, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-ftn-selecao-estrategia',
    //template: `<div class="strategySelectionNodeCard {{node.data.cssClass}}" (click)="selectNodeGraph(node.id)">
    //               <label class="sub-title">
    //                   {{ 'telaFluxo.estrategia' | translate }}
    //               </label>
    //               <label class="value">{{node.data.estrategiaNome}}</label>
    //           </div>`,
    template: `<div class="strategySelectionNodeCard {{node.data.cssClass}}">
                   <div class="contentErrors" *ngIf="node.data.erros">
                      <mat-icon>info</mat-icon>
                   </div>
                   <div class="contentInfo" (click)="selectNodeGraph(node.id)">
                       <label class="sub-title">
                           {{ 'telaFluxo.estrategia' | translate }} 
                       </label>
                       <label class="value">
                           {{node.data.estrategiaNome}}
                       </label>
                       <div class="p provedores">
                           <div class="tb th text-left">
                               {{ 'telaFluxo.provedor' | translate }}
                           </div>
                           <div class="provedores-conteudo">
                               <div class="item">
                                   <div class="tb td text-left">
                                       <span class="ellipsis" matTooltip="{{ node.data.provedorNome }} {{ node.data.estrategiaQtdRegistros | numberFormat }}">
                                           {{node.data.provedorNome}} {{ node.data.estrategiaQtdRegistros | suffixNumber : 1 }}
                                       </span>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </div>
                   <div class="contentIcons">
                       <mat-icon class="actions clickable" [matTooltip]="'telaFluxo.visualizarFiltros' | translate" 
                           (click)="visualizarFiltros(node.id, node.data.listaEstrategiaId)">
                           filter_list
                       </mat-icon>
                       <mat-icon class="actions clickable" [matTooltip]="'telaFluxo.visualizarRegistros' | translate" (click)="mostrarPreview(node.id)" >
                           search
                       </mat-icon>
                       <mat-icon class="actions" [matTooltip]="node.data.descricaoExecucao">
                           timer
                       </mat-icon>
                       <mat-icon class="actions clickable"  [matTooltip]="'telaFluxo.ativarPendenteExecucao' | translate"
                            appPermissao recurso="Estrategia" acao="Escrita" *ngIf="node.data.visualizarPendenteExecucao"
                           (click)="ativarPendenteExecucao(node.id, node.data.listaEstrategiaId)">
                           send
                       </mat-icon>
                       <mat-icon class="actions clickable" [matTooltip]="'telaFluxo.forcarExecucao' | translate"
                           appPermissao recurso="Estrategia" acao="Escrita" *ngIf="node.data.visualizarForcarExecucao"
                           (click)="forcarExecucao(node.id, node.data.listaEstrategiaId)">
                           send
                       </mat-icon>
                   </div>
                   <div class="contentQuantity" (click)="selectNodeGraph(node.id)">
                      {{node.data.estrategiaQtdRegistros | suffixNumber : 1}}
                   </div>
               </div>`,
    styles: []
})
export class FluxoTemplateNoSelecaoEstrategiaComponent {

    @Input()
    guidSelecionado: string;

    @Input()
    node: any;

    @Output()
    selectNode = new EventEmitter<string>();

    @Output()
    visualizaFiltros = new EventEmitter<any>();

    @Output()
    mostraPreview = new EventEmitter<string>();

    @Output()
    ativaExecucao = new EventEmitter<any>();

    @Output()
    forcaExecucao = new EventEmitter<any>();

    constructor() {
        this.selectNode = new EventEmitter<string>();
        this.visualizaFiltros = new EventEmitter<any>();
        this.mostraPreview = new EventEmitter<string>();
        this.ativaExecucao = new EventEmitter<any>();
        this.forcaExecucao = new EventEmitter<any>();
    }

    selectNodeGraph(guidId: string) {
        this.selectNode.emit(guidId);
    }

    visualizarFiltros(guidId: string, listaestrategiaId: number) {
        this.visualizaFiltros.emit({ 'guidId': guidId, 'listaEstrategiaId': listaestrategiaId });
    }

    mostrarPreview(guidId: string) {
        this.mostraPreview.emit(guidId);
    }

    ativarPendenteExecucao(guidId: string, listaestrategiaId: number) {
        this.ativaExecucao.emit({ 'guidId': guidId, 'listaEstrategiaId': listaestrategiaId });
    }

    forcarExecucao(guidId: string, listaestrategiaId: number) {
        this.forcaExecucao.emit({ 'guidId': guidId, 'listaEstrategiaId': listaestrategiaId });
    }
}