const handler = {
  get: function(target, name) {
    if (!target.hasOwnProperty(name)) return undefined;

    try {
      return JSON.parse(target[name]);
    } catch {
      return target[name];
    }
  },

  set: function(obj, prop, value) {
    obj[prop] = typeof value === "object" ? JSON.stringify(value) : value;
    return true;
  }
};

export const StorageProxy = new Proxy(localStorage, handler);
