import { ESBuilderRules } from "./rules.model";
import { ESBuilderRulesChangeType } from "./rules-change-type.model"

export class ESBuilderRulesChange {

    public type: ESBuilderRulesChangeType;
    public rule: ESBuilderRules;

    constructor(type: ESBuilderRulesChangeType, rule: ESBuilderRules = null) {
        this.type = type;
        this.rule = rule;
    }
}
