import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LoginComponent } from "./login/login.component";
import { EsqueciSenhaComponent } from "./esqueci-senha/esqueci-senha.component";
import { AlterarSenhaComponent } from "./alterar-senha/alterar-senha.component";


import {
  MatInputModule,
  MatButtonModule,
  MatIconModule,

  MAT_DIALOG_DEFAULT_OPTIONS
} from "@angular/material";
import { AutenticacaoService } from "../common/service/autenticacao.service";
import { AppCommonModule } from "../common/common.module";
import { AcessoNegadoComponent } from "./acesso-negado/acesso-negado.component";
import { environment } from "../../../environments/environment";
import { AlterarSenhaExpiradaComponent } from "./alterar-senha-expirada/alterar-senha-expirada.component";
import { PasswordModule  } from "primeng/password";


@NgModule({
  imports: [
    CommonModule,
    MatInputModule,
    MatButtonModule,
    BrowserModule,
    BrowserAnimationsModule,
    PasswordModule,
    FormsModule,
    RouterModule,

    MatIconModule,
    AppCommonModule
  ],
  entryComponents: [
    EsqueciSenhaComponent,
    AlterarSenhaComponent
  ],
  providers: [
    { useClass: AutenticacaoService, provide: AutenticacaoService },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { panelClass: `theme-${environment.theme}` }}
  ],
  declarations: [LoginComponent, AcessoNegadoComponent,EsqueciSenhaComponent,AlterarSenhaComponent, AlterarSenhaExpiradaComponent]
})
export class AutenticacaoModule {}
