export class Notificacao {
    notificacaoId: number;
    notificacaoTipoId: NotificacaoTipo;
    notificacaoEventoId: NotificacaoEvento;
    mensagem: string;
    detalhe: string;
    dataEvento: Date;
    redirecionaClique: boolean;
    rotaClique: string;
    carteiraId: number;
    listaId: number;
    novo: boolean;

    constructor(notificacaoId: number, notificacaoTipoId: NotificacaoTipo, notificacaoEventoId: NotificacaoEvento, mensagem: string, detalhe: string, dataEvento: Date) {
        this.notificacaoId = notificacaoId;
        this.notificacaoTipoId = notificacaoTipoId;
        this.notificacaoEventoId = notificacaoEventoId;
        this.mensagem = mensagem;
        this.detalhe = detalhe;
        this.dataEvento = dataEvento;
    }
}

export enum NotificacaoTipo {
	Warning = 1,
	Error = 2,
    Info = 3
}

export enum NotificacaoEvento {
    ExportacaoEstrategia = 1,
    IndexacaoLista = 2,
    DownloadRegistro = 3,
    EnvioSemRetorno = 4,
    ProcessamentoRetorno = 5
}