import { Component, Inject, ViewChild, OnDestroy } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";

import { ElasticsearchFilterComponent } from "../../../elasticsearch/filter/elasticsearch-filter/elasticsearch-filter.component";
import { PreviewDadosEstrategiaComponent } from "../../../estrategia/form-estrategia/preview-dados-estrategia/preview-dados-estrategia.component";

import { CustomAlertService } from "../../../common/service/custom-alert.service";
import { EstrategiaService } from "../../../common/service/estrategia.service";
import { ListaService } from "../../../common/service/lista.service";
import { Estrategia } from "../../../common/model/estrategia";
import { isNull, isNullOrEmpty } from '../../../common/utils';
import { Subscription } from "rxjs";
import { FluxoService } from "../../../common/service/fluxo.service";


@Component({
    selector: "app-fluxo-visualizar-filtro-simples-modal",
    templateUrl: "./fluxo-visualizar-filtro-simples-modal.component.html",
    styleUrls: ["./fluxo-visualizar-filtro-simples-modal.component.scss"],
    providers: [
        { useClass: EstrategiaService, provide: EstrategiaService },
        { useClass: FluxoService, provide: FluxoService },
        { useClass: ListaService, provide: ListaService },
        { useClass: CustomAlertService, provide: CustomAlertService }
    ]
})
export class FluxoVisualizarFiltroSimplesModalComponent implements OnDestroy {

    submited: boolean;
    estrategiaId: number;
    finishedLoading: boolean;
    estrategia: Estrategia = new Estrategia();
    query: any;
    listas: Array<{
        listaId: number;
        providerId: number;
        templateId: number;
        nome: string;
        alias: string;
    }>;
    quantidade: number;

    public get nomeLista(): string {
        if (!this.estrategia.listaId || !this.listas) return "";
        let lista = this.listas.find(l => l.listaId == this.estrategia.listaId);
        return (lista) ? lista.alias : "";
    }

    public get templateId(): number {
        if (!this.estrategia.listaId || !this.listas) return null;
        let lista = this.listas.find(l => l.listaId == this.estrategia.listaId);
        return (lista) ? lista.templateId : null;
    }

    subListas: Subscription;
    subEstrategia: Subscription;

    @ViewChild("elasticsearchFilter", { static: false })
    elasticsearchFilter: ElasticsearchFilterComponent;
    constructor(
        private estrategiaService: EstrategiaService,
        private listaService: ListaService,
        private fluxoService: FluxoService,
        private ref: MatDialogRef<FluxoVisualizarFiltroSimplesModalComponent>,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA)
        public data: { estrategiaId: number }
    ) {
        this.subListas = this.listaService.obterListaComTemplate({}).subscribe((result: any) => (this.listas = result));

        this.subEstrategia = this.estrategiaService.obterEstrategiaPorId(this.data.estrategiaId).subscribe(estrategia => {
            this.estrategia = estrategia;
            this.updateQuery(JSON.parse(this.estrategia.filtro));
            this.finishedLoading = true;
        });
    }

    updateQuery(query: any) {
        this.query = query;


    }

    updateQuantidades(event: any) {
        
        let qtd: Array<number> = event.quantidade;

        if (!isNull(qtd) && qtd.length > 0) {
            this.quantidade = qtd[qtd.length - 1];
        } else {
            this.fluxoService
                .obterQueryQuantidadeRegistrosPost(this.estrategia.listaId, JSON.stringify(this.query))
                .subscribe((result: any) => {
                    this.quantidade = (result) ? result : 0;
                });
        }
    }

    mostrarPreview(query: any = null) {
        if (query == undefined) {
            if (this.query == undefined) {
                query = {
                    size: this.elasticsearchFilter.pageSize,
                    _source: { includes: [] },
                    query: { bool: { must_not: this.elasticsearchFilter.mustNot } }
                }
            } else {
                query = this.query;
            }
        }

        let lista = this.listas.find(l => l.listaId == this.estrategia.listaId);

        if (isNullOrEmpty(lista) && (isNullOrEmpty(lista.templateId) || isNullOrEmpty(lista.alias)))
            return;

        this.dialog.open(PreviewDadosEstrategiaComponent, {
            height: "700px",
            width: "80%",
            hasBackdrop: true,
            closeOnNavigation: true,
            data: { 'templateId': lista.templateId, 'lista': lista.alias, 'listaId': lista.listaId, query }
        });
    }

    close() {
        this.ref.close();
    }

    fechar() {
        this.ref.close();
    }

    ngOnDestroy() {
        if (this.subListas)
            this.subListas.unsubscribe();

        if (this.subEstrategia)
            this.subEstrategia.unsubscribe();
    }
}