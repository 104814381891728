import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
    selector: "app-detalhe-erro-processamento",
    templateUrl: "./detalhe-erro-processamento.component.html",
    styleUrls: ["../../style.scss"],
    providers: [
    ]
})
export class DetalheErroProcessamentoComponent {
    public erro: any;

    constructor(
        private ref: MatDialogRef<DetalheErroProcessamentoComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: { erro: any }
    ) {
        this.erro = data.erro;
    }

    close() {
        this.ref.close();
    }

    fechar() {
        this.ref.close();
    }
}