import { Component, Input } from "@angular/core";
import { ListaTemplateItemExportacao } from "../../../common/model/lista-template-item-exportacao";
import { CampoJson } from "../model/campoJson";
import { MatDialog } from "@angular/material";
import { CampoRawModalComponent } from "./campo-raw-modal/campo-raw-modal.component";
import { ListaTemplateItem } from "../../../common/model/lista-template-item";
import { trigger, transition, style, animate } from "@angular/animations";

@Component({
	selector: "app-campo-exportacao-raw",
	templateUrl: "./campo-exportacao-raw.component.html",
	styleUrls: ["../../style.css", "../form-template-exportacao.component.scss", "./campo-exportacao-raw.component.scss"],
	providers: [],
	animations: [
		trigger('slideInOut', [
			transition(':enter', [
				style({ transform: 'translateX(-100%)' }),
				animate('500ms ease-in-out', style({ transform: 'translateX(0%)' }))
			]),
			transition(':leave', [
				animate('500ms ease-in-out', style({ transform: 'translateX(-100%)' }))
			])
		])
	]
})

export class CampoExportacaoRawComponent {
	_listaTemplateItemExportacao: Array<ListaTemplateItemExportacao>;
	_camposIntegracao: Array<ListaTemplateItem>;
	valorJson: any;
	campoValorModel: string = "${valor}"
	campoIntegracaoModel: string = "$(valor)&T=tipo"
	exibeJson: boolean = false;

	//#region [ ListaTemplateItemExportacao - Valor JSON no campo Conteudo]

	public get listaTemplateItemExportacao() {
		return this._listaTemplateItemExportacao;
	}

	@Input()
	public set listaTemplateItemExportacao(listaTemplateItemExportacao: Array<ListaTemplateItemExportacao>) {
		if (!listaTemplateItemExportacao[0].conteudo)
			return;

		this._listaTemplateItemExportacao = listaTemplateItemExportacao;
	}

	////#endregion

	//#region [ CamposIntegracao ]

	public get camposIntegracao() {
		return this._camposIntegracao;
	}

	@Input()
	public set camposIntegracao(camposIntegracao: Array<ListaTemplateItem>) {
		if (!camposIntegracao)
			return;

		this._camposIntegracao = camposIntegracao;
		this.formatarCampoJson()
	}

	////#endregion

	camposJson: Array<CampoJson> = new Array<CampoJson>();
	campo: CampoJson = new CampoJson();

	constructor(public dialog: MatDialog) {
	}

	formatarCampoJson() {
		this.valorJson = this.listaTemplateItemExportacao[0].conteudo;
		this.valorJson = typeof (this.valorJson) == 'object' ? this.valorJson : JSON.parse(this.valorJson);

		this.transformarCamposJsonEmArray(this.valorJson);
	}

	transformarCamposJsonEmArray(valorJson: any, chaves: Array<string> = []) {
		let chavesAnteriores: Array<string> = [];

		for (var key in valorJson) {
			if (valorJson.hasOwnProperty(key)) {
				chaves = chavesAnteriores.length == 0 ? chaves : [...chavesAnteriores];
				chavesAnteriores = [...chaves];

				if (typeof valorJson[key] == "object") {
					chaves.push(key);
					this.transformarCamposJsonEmArray(valorJson[key], chaves);
				} else {
					this.campo.caminho = chaves.filter(c => c != "0").join(" > ");
					chaves.push(key);
					this.obterValorETipoExportacaoChave(valorJson, key);
					this.campo.chaves = [...chaves];
					this.camposJson.push({ ...this.campo });
					this.campo = new CampoJson();
				}

				chaves = new Array<string>();
			}
		}

		chavesAnteriores = new Array<string>();
	}

	obterValorETipoExportacaoChave(valorJson: any, key: string) {
		let value: string = valorJson[key];
		this.campo.chave = key;
		this.campo.tipo = value.match(/(?<=\)\&T\=).+?(?=$)/g)[0]; //Retorna o tipo do campo no json conforme definido na criação do provedor

		let arrCampoValor = value.match(/(?<=\$\().+?(?=\)&)/g); //Retorna o valor do(s) campo(s)
		let campoValor = arrCampoValor == null ? '' : arrCampoValor[0];
		let campos = campoValor == '' ? null : campoValor.match(/(?<=\${).+?(?=\})/g);

		let arrCamposFormatacao = [];
		let isCampoCustomizado = (campos != null && campos.length > 1) ||
			(arrCampoValor != null && arrCampoValor[0].match(/(?<=\}).+?().+?(?=$)/g) != null) || //Verifica se possui campo integração com texto depois
			(arrCampoValor != null && arrCampoValor[0].match(/(?=^).+?(?=\$\{)/g) != null) || //Verifica se possui campo integração com texto antes
			(arrCampoValor != null && arrCampoValor[0].match(/(?<=\$\{).+?(\.).+?(?=\()/g) != null); //Verifica se possui função

		//Verifica se campo de integração possui formatação ou não
		if (campos != null && campos.length == 1 && !isCampoCustomizado) {
			arrCamposFormatacao = arrCampoValor[0].split("|");
			this.campo.formatacao = arrCamposFormatacao.length > 1 ? arrCamposFormatacao[1].substring(0, arrCamposFormatacao[1].length - 1) : '';
		}

		//Se variável campos == null, é porque pode ser novo template ou campo vazio
		if (campos == null) {
			if (this.listaTemplateItemExportacao[0].listaTemplateItemExportacaoId == null) {
				valorJson[key] = value.replace(campoValor, "");
				this.campo.valor = '';
				this.campo.tipoCampoExportacao = 0;
			} else {
				this.campo.valor = campoValor;
				this.campo.tipoCampoExportacao = campoValor == '' ? 2 : 1;
			}
		} else {
			if (isCampoCustomizado) { //Variável campos indica que é customizado, senão é campo integração
				this.campo.tipoCampoExportacao = 1;
				this.campo.valor = campoValor;
			} else {
				this.campo.tipoCampoExportacao = 0;
				this.campo.valor = this.campo.formatacao ? arrCamposFormatacao[0].substring(2, arrCamposFormatacao[0].length) : campos[0];

				var campoIntegracao = this.camposIntegracao.find((item) => (item.nome == this.campo.valor));
				this.campo.permiteFormatacao = campoIntegracao['permiteFormatacao'];
				this.campo.formatos = campoIntegracao['formatos'];
			}
		}
	}

	obterValorJson(obj: any, path: Array<any>) {
		var valorJson = obj;

		for (var i = 0; i < path.length; i++) {
			if (!valorJson[path[i]])
				return null;

			valorJson = valorJson[path[i]];
		}

		return valorJson;
	};

	atualizarJson(obj, value, propPath) {
		const [head, ...rest] = propPath;

		!rest.length ? obj[head] = value : this.atualizarJson(obj[head], value, rest);

		this.listaTemplateItemExportacao[0].conteudo = JSON.stringify(obj);
	}

	isTipoBasico(value: any): boolean {
		var tipo = typeof (value);
		return typeof (value) == "string" || typeof (value) == "number" || typeof (value) == "bigint" || typeof (value) == "boolean";
	}

	isArray(value: any): boolean {
		return Array.isArray(value);
	}

	editarCampoRaw(campo: CampoJson) {
		let dialogRef = this.dialog.open(CampoRawModalComponent, {
			width: "750px",
			data: {
				campoJson: { ...campo },
				camposIntegracao: this.camposIntegracao
			}
		});

		dialogRef.afterClosed().subscribe((result: CampoJson) => {
			if (!result) return;

			if (campo.valor != result.valor || campo.formatacao != result.formatacao || campo.tipoCampoExportacao != result.tipoCampoExportacao) {
				campo.valor = result.valor;
				campo.formatacao = result.formatacao;
				campo.tipoCampoExportacao = result.tipoCampoExportacao;

				if (campo.tipoCampoExportacao == 0) {
					this.campoIntegracaoChange(campo);
					this.formatacaoChange(campo);
				} else {
					this.atualizarJson(this.valorJson, this.campoIntegracaoModel.replace("valor", campo.valor).replace("tipo", campo.tipo), campo.chaves);
				}
			}
		});
	}

	campoIntegracaoChange(campo: CampoJson) {
		let campoFormatado = '';

		if (campo.valor) {
			var campoIntegracao = this.camposIntegracao.find((item) => (item.nome == campo.valor));
			campo.permiteFormatacao = campoIntegracao['permiteFormatacao'];
			campo.formatos = campoIntegracao['formatos'];
			let valorFormatado = this.campoValorModel.replace("valor", campo.valor);
			campoFormatado = this.campoIntegracaoModel.replace("valor", valorFormatado).replace("tipo", campo.tipo);
		}
		else {
			campo.permiteFormatacao = false;
			campo.formatacao = '';
			campo.valor = '';
			campoFormatado = this.campoIntegracaoModel.replace("valor", campo.valor).replace("tipo", campo.tipo);
		}

		this.atualizarJson(this.valorJson, campoFormatado, campo.chaves);
	}

	formatacaoChange(campo: CampoJson) {
		let valorFormatado = '';

		if (campo.formatacao) {
			valorFormatado = this.campoValorModel.replace('valor', campo.valor + "|" + campo.formatacao);
		} else {
			valorFormatado = this.campoValorModel.replace('valor', campo.valor);
		}
		valorFormatado = this.campoIntegracaoModel.replace("valor", valorFormatado).replace("tipo", campo.tipo);

		this.atualizarJson(this.valorJson, valorFormatado, campo.chaves);
	}

	strTooltipExemplo(campo: CampoJson) {
		return 'Exemplo: ' + campo.formatos.find((f: any) => f.formato == campo.formatacao).exemplo;
	}
}