import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { LimpezaFiltroArquivoStatus } from "../../common/model/limpeza-filtro-arquivo";
import { isNullOrEmpty, isNullOrZero } from "../../common/utils";

@Pipe({ name: "vazioTraco" })
export class VazioTracoPipe implements PipeTransform {

	constructor() { }

	transform(value: any, args?: any): any {

        if(isNullOrEmpty(value) || isNullOrZero(value))
            return "---";

        return value;
	}
}
