import { Routes } from "@angular/router";
import { FormUsuarioComponent } from "./usuario/form-usuario/form-usuario.component";

import { FormTemplateImportacaoComponent } from "./template/form-template-importacao/form-template-importacao.component";
import { FormTemplateExportacaoComponent } from "./template/form-template-exportacao/form-template-exportacao.component";

import { ListaTemplateComponent } from "./template/lista-template/lista-template.component";
import { ListaComponent } from "./lista/lista/lista.component";
import { FormListaComponent } from "./lista/form-lista/form-lista.component";
import { ListaCadastroComponent } from "./lista/lista-cadastro/lista-cadastro.component";
import { ListaEstrategiaComponent } from "./estrategia/lista-estrategia/lista-estrategia.component";
import { FormEstrategiaComponent } from "./estrategia/form-estrategia/form-estrategia.component";
import { AuthGuard } from "../modules/common/auth.guard";
import { HistoricoEstrategiaComponent } from "./estrategia/historico-estrategia/historico-estrategia.component";
import { UploadArquivosComponent } from "./lista/upload-arquivos/upload-arquivos.component";
import { TabsUsuarioComponent } from "./usuario/tabs-usuario/tabs-usuario.component";
import { FormApiKeyComponent } from "./usuario/form-apikey/form-apikey.component";
import { AcessoNegadoComponent } from "./autenticacao/acesso-negado/acesso-negado.component";
import { DashPrincipalComponent } from "./dash/dash-principal/dash-principal.component";
import { FormProviderComponent } from "./provider/form-provider/form-provider.component";
import { ListaProviderComponent } from "./provider/lista-provider/lista-provider.component";
import { ListaTarefaComponent } from "./tarefa/lista-tarefa/lista-tarefa.component"
import { HistoricoIndexacaoComponent } from "./lista/historico-indexacao/historico-indexacao.component";
import { PesquisaEstrategiaComponent } from "./pesquisa/pesquisa-estrategia/pesquisa-estrategia.component";

import { ListaEstrategiaRemocaoComponent } from "./estrategia-remocao/lista-estrategia-remocao/lista-estrategia-remocao.component";
import { FormEstrategiaRemocaoComponent } from "./estrategia-remocao/form-estrategia-remocao/form-estrategia-remocao.component";
import { ListaDashboardComponent } from "./lista/lista-dashboard/lista-dashboard.component";
import { ListaReguaEstrategiaComponent } from "./regua-estrategia/lista-regua-estrategia/lista-reguaestrategia.component";
import { FormReguaEstrategiaComponent } from './regua-estrategia/form-regua-estrategia/form-regua-estrategia.component';
import { DashListaProvedorComponent } from "./dash/dash-lista-provedor/dash-lista-provedor.component";

import { DashEstrategiasComponent } from "./dash/dash-estrategias/dash-estrategias.component";
import { ListaFluxoEstrategiaComponent } from "./fluxo-estrategia/lista-fluxo-estrategia/lista-fluxoestrategia.component";
import { FormFluxoEstrategiaComponent } from "./fluxo-estrategia/form-fluxo-estrategia/form-fluxo-estrategia.component";
import { ListaDocumentacaoComponent } from "./documentacao/lista-documentacao/lista-documentacao.component";
import { HistoricoRetornoComponent } from "./retorno/historico-retorno/historico-retorno.component";
import { DashIndividualComponent } from "./dash/dash-individual/dash-individual.component";
import { ListaNotificacaoComponent } from "./notificacao/lista-notificacao/lista-notificacao.component";
import { DownloadRegistroComponent } from "./download-registro/download-registro.component";
import { PesquisaListaComponent } from "./pesquisa/pesquisa-lista/pesquisa-lista.component";
import { MonitorRetornoComponent } from "./retorno/monitor-retorno/monitor-retorno.component";
import { ListaFiltroQueryPadraoComponent } from "./filtro-query-padrao/lista-filtro-query-padrao/lista-filtro-query-padrao.component";
import { FormFiltroQueryPadraoComponent } from "./filtro-query-padrao/form-filtro-query-padrao/form-filtro-query-padrao.component";
import { ListaConfiguracaoComponent } from "./configuracao/lista-configuracao/lista-configuracao.component";
import { ListaCarteiraComponent } from "./carteira/lista-carteira/lista-carteira.component";
import { FormCarteiraComponent } from "./carteira/form-carteira/form-carteira.component";
import { FormPlanoTarifaProvedorComponent } from "./plano-tarifa-provedor/form-plano-tarifa-provedor/form-plano-tarifa-provedor.component";
import { ListaIndicadorProvedorComponent } from "./indicador-provedor/lista-indicador-provedor/lista-indicador-provedor.component";
import { AlterarSenhaExpiradaComponent } from "./autenticacao/alterar-senha-expirada/alterar-senha-expirada.component";

export const routes: Routes = [
	{
		path: "",
		redirectTo: "/app/home",
		pathMatch: "full",
		canActivate: [AuthGuard]
	},
	{
		path: "acesso-negado",
		component: AcessoNegadoComponent,
		canActivate: [AuthGuard]
	},
	{
		path: "home",
		component: DashPrincipalComponent,
		canActivate: [AuthGuard],
		data: {
			label: "Home",
			permissao: { modulo: "Dashboard", acao: "Leitura" }
		}
	},
	{
		data: {
			label: "Dashboard Lista Provedor",
			parent: "home",
			permissao: { modulo: "DashboardListaProvedor", acao: "Leitura" }
		},
		path: "dashboard/lista-provedor",
		component: DashListaProvedorComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "Dashboard Individual",
			parent: "home",
			permissao: { modulo: "DashboardIndividual", acao: "Leitura" }
		},
		path: "dashboard/individual",
		component: DashIndividualComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "Dashboard Estratégias",
			parent: "home",
			permissao: { modulo: "DashboardEstrategias", acao: "Leitura" }
		},
		path: "dashboard/estrategias",
		component: DashEstrategiasComponent,
		canActivate: [AuthGuard]
	},
	////////////////////////////////////////////////////////////////////
	/// TEMPLATE ///////////////////////////////////////////////////////
	{
		data: {
			label: "Template",
			permissao: { modulo: "Template", acao: "Leitura" }
		},
		path: "template",
		component: ListaTemplateComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "Criação de template",
			parent: "template",
			permissao: { modulo: "Template", acao: "Escrita" }
		},
		path: "template/create/:duplicar",
		component: FormTemplateImportacaoComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "Criação de template",
			parent: "template",
			permissao: { modulo: "Template", acao: "Escrita" }
		},
		path: "template/create",
		component: FormTemplateImportacaoComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "Edição de template",
			parent: "template",
			permissao: { modulo: "Template", acao: "Escrita" }
		},
		path: "template/:id",
		component: FormTemplateImportacaoComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "Criação do template de exportação",
			parent: "template/:id",
			permissao: { modulo: "Template", acao: "Escrita" }
		},
		path: "template/:id/create/:duplicar",
		component: FormTemplateExportacaoComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "Criação do template de exportação",
			parent: "template/:id",
			permissao: { modulo: "Template", acao: "Escrita" }
		},
		path: "template/:id/create",
		component: FormTemplateExportacaoComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "Edição do template de exportação",
			parent: "template/:id",
			permissao: { modulo: "Template", acao: "Escrita" }
		},
		path: "template/:id/:idExportacao",
		component: FormTemplateExportacaoComponent,
		canActivate: [AuthGuard]
	},
	////////////////////////////////////////////////////////////////////
	/// LISTA //////////////////////////////////////////////////////////
	{
		data: { label: "lista", permissao: { modulo: "Lista", acao: "Leitura" } },
		path: "lista",
		component: ListaComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "Visualização de Indexação",
			parent: "lista",
			permissao: { modulo: "Lista", acao: "Leitura" }
		},
		path: "lista/dashboard",
		component: ListaDashboardComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "Criação de lista",
			parent: "lista",
			permissao: { modulo: "Lista", acao: "Escrita" }
		},
		path: "lista/create",
		component: ListaCadastroComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "Editar itens da lista",
			parent: "lista",
			permissao: { modulo: "Lista", acao: "Escrita" }
		},
		path: "lista/form-lista/:id",
		component: FormListaComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "Visualização de Indexação",
			parent: "lista",
			permissao: { modulo: "Lista", acao: "Leitura" }
		},
		path: "lista/dashboard/:id",
		component: ListaDashboardComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "Importação de lista",
			parent: "lista",
			permissao: { modulo: "Lista", acao: "Escrita" }
		},
		path: "lista/:id/importacao",
		component: UploadArquivosComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "Edição de lista",
			parent: "lista",
			permissao: { modulo: "Lista", acao: "Escrita" }
		},
		path: "lista/:id",
		component: ListaCadastroComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "Edição de lista",
			parent: "lista",
			permissao: { modulo: "Lista", acao: "Escrita" }
		},
		path: "lista/:id/alteracao/:type",
		component: ListaCadastroComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "Estratégia",
			permissao: { modulo: "Estrategia", acao: "Leitura" }
		},
		path: "lista/:listaId/estrategia",
		component: ListaEstrategiaComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "lista",
			permissao: { modulo: "Estrategia", acao: "Escrita" }
		},
		path: "lista/:listaId/estrategia/create",
		component: FormEstrategiaComponent,
		canActivate: [AuthGuard]
	},
	////////////////////////////////////////////////////////////////////
	/// ESTRATEGIA /////////////////////////////////////////////////////
	{
		data: {
			label: "estrategia",
			permissao: { modulo: "Estrategia", acao: "Leitura" }
		},
		path: "estrategia",
		component: ListaEstrategiaComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "Criação de estrategia",
			parent: "estrategia",
			permissao: { modulo: "Estrategia", acao: "Escrita" }
		},
		path: "estrategia/create",
		component: FormEstrategiaComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "Edição de estrategia",
			parent: "estrategia",
			permissao: { modulo: "Estrategia", acao: "Escrita" }
		},
		path: "estrategia/:id",
		component: FormEstrategiaComponent,
		canActivate: [AuthGuard]
	},
	////////////////////////////////////////////////////////////////////
	/// CARTEIRA ///////////////////////////////////////////////////////
	{
		data: {
			label: "carteira",
			permissao: { modulo: "Carteira", acao: "Leitura" }
		},
		path: "carteira",
		component: ListaCarteiraComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "Criação da carteira",
			parent: "carteira",
			permissao: { modulo: "Carteira", acao: "Escrita" }
		},
		path: "carteira/create",
		component: FormCarteiraComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "Edição da carteira",
			parent: "carteira",
			permissao: { modulo: "Carteira", acao: "Escrita" }
		},
		path: "carteira/:id",
		component: FormCarteiraComponent,
		canActivate: [AuthGuard]
	},
	////////////////////////////////////////////////////////////////////
	/// REGUA //////////////////////////////////////////////////////////
	{
		data: {
			label: "regua",
			permissao: { modulo: "Regua", acao: "Leitura" }
		},
		path: "regua",
		component: ListaReguaEstrategiaComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "Criação da régua",
			parent: "regua",
			permissao: { modulo: "Regua", acao: "Escrita" }
		},
		path: "regua/create",
		component: FormReguaEstrategiaComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "Edição da régua",
			parent: "regua",
			permissao: { modulo: "Regua", acao: "Escrita" }
		},
		path: "regua/:id",
		component: FormReguaEstrategiaComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "regua",
			permissao: { modulo: "Estrategia", acao: "Escrita" }
		},
		path: "regua/:reguaId/:listaId/estrategia/create",
		component: FormEstrategiaComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "regua",
			permissao: { modulo: "Estrategia", acao: "Escrita" }
		},
		path: "regua/:reguaId/:reguaEstrategiaId/:listaId/estrategia/create",
		component: FormEstrategiaComponent,
		canActivate: [AuthGuard]
	},
	////////////////////////////////////////////////////////////////////
	/// FLUXO //////////////////////////////////////////////////////////
	{
		data: {
			label: "fluxo",
			permissao: { modulo: "Fluxo", acao: "Leitura" }
		},
		path: "fluxo",
		component: ListaFluxoEstrategiaComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "Criação do fluxo",
			parent: "fluxo",
			permissao: { modulo: "Fluxo", acao: "Escrita" }
		},
		path: "fluxo/create",
		component: FormFluxoEstrategiaComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "Edição do fluxo",
			parent: "fluxo",
			permissao: { modulo: "Fluxo", acao: "Escrita" }
		},
		path: "fluxo/:id",
		component: FormFluxoEstrategiaComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "fluxo",
			permissao: { modulo: "Fluxo", acao: "Escrita" }
		},
		path: "fluxo/:fluxoId/:fluxoProcessoId/:listaId/estrategia/create",
		component: FormEstrategiaComponent,
		canActivate: [AuthGuard]
	},
	////////////////////////////////////////////////////////////////////
	/// ESTRATEGIA REMOÇÃO /////////////////////////////////////////////
	{
		data: {
			label: "Estratégias de remoção",
			permissao: { modulo: "EstrategiaRemocao", acao: "Leitura" }
		},
		path: "estrategiaremocao",
		component: ListaEstrategiaRemocaoComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "Criação de estrategia de remoção",
			parent: "estrategiaremocao",
			permissao: { modulo: "EstrategiaRemocao", acao: "Escrita" }
		},
		path: "estrategiaremocao/create",
		component: FormEstrategiaRemocaoComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "Edição de estrategia de remoção",
			parent: "estrategiaremocao",
			permissao: { modulo: "EstrategiaRemocao", acao: "Escrita" }
		},
		path: "estrategiaremocao/:id",
		component: FormEstrategiaRemocaoComponent,
		canActivate: [AuthGuard]
	},
	////////////////////////////////////////////////////////////////////
	/// HISTÓRICO DE ESTRATEGIA ////////////////////////////////////////
	{
		data: {
			label: "Histórico de estrategia",
			parent: "estrategia",
			permissao: { modulo: "Estrategia", acao: "Leitura" }
		},
		path: "estrategia/:id/historico",
		component: HistoricoEstrategiaComponent,
		canActivate: [AuthGuard]
	},
	{
		path: "",
		redirectTo: "/app/usuario",
		pathMatch: "full",
		canActivate: [AuthGuard]
	},
	{
		data: { label: "Usuário" },
		path: "usuario",
		component: TabsUsuarioComponent,
		canActivate: [AuthGuard]
	},
	{
		data: { label: "Criação de usuário", parent: "usuario" },
		path: "usuario/create",
		component: FormUsuarioComponent,
		canActivate: [AuthGuard]
	},
	{
		data: { label: "Criação de apikey", parent: "usuario" },
		path: "apikey/create",
		component: FormApiKeyComponent,
		canActivate: [AuthGuard]
	},
	{
		data: { label: "Edição de usuário", parent: "usuario" },
		path: "usuario/:id",
		component: FormUsuarioComponent,
		canActivate: [AuthGuard]
	},
	{
		data: { label: "Edição de apikey", parent: "usuario" },
		path: "apikey/:id",
		component: FormApiKeyComponent,
		canActivate: [AuthGuard]
	},
	{
		data: { label: "Provedor" },
		path: "provedor",
		component: ListaProviderComponent,
		canActivate: [AuthGuard]
	},
	{
		data: { label: "Criação provedor", parent: "provedor" },
		path: "provedor/create",
		component: FormProviderComponent,
		canActivate: [AuthGuard]
	},
	{
		data: { label: "Editar provedor", parent: "provedor" },
		path: "provedor/:id",
		component: FormProviderComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "Execução de Estratégias", permissao: { modulo: "Tarefa", acao: "Leitura" }
		},
		path: "tarefa",
		component: ListaTarefaComponent,
		canActivate: [AuthGuard]
	},
	////////////////////////////////////////////////////////////////////
	/// DOCUMENTAÇÃO ///////////////////////////////////////////////////
	{
		data: {
			label: "documentacao",
			permissao: { modulo: "Documentacao", acao: "Leitura" }
		},
		path: "documentacao",
		component: ListaDocumentacaoComponent,
		canActivate: [AuthGuard]
	},
	////////////////////////////////////////////////////////////////////
	/// HISTORICO RETORNO//////////////////////////////////////////////
	{
		data: {
			label: "Histórico Retorno",
			permissao: { modulo: "HistoricoRetorno", acao: "Leitura" }
		},
		path: "historicoretorno",
		component: HistoricoRetornoComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "Histórico Retorno",
			permissao: { modulo: "HistoricoRetorno", acao: "Leitura" }
		},
		path: "historicoretorno/:id",
		component: HistoricoRetornoComponent,
		canActivate: [AuthGuard]
	},
	////////////////////////////////////////////////////////////////////
	/// MONITOR RETORNO/////////////////////////////////////////////////
	{
		data: {
			label: "Monitor Retorno",
			permissao: { modulo: "MonitorRetorno", acao: "Leitura" }
		},
		path: "monitorretorno",
		component: MonitorRetornoComponent,
		canActivate: [AuthGuard]
	},
	////////////////////////////////////////////////////////////////////
	/// NOTIFICACAO////////////////////////////////////////////////////
	{
		data: {
			label: "Notificação",
			permissao: { modulo: "Notificacao", acao: "Leitura" }
		},
		path: "notificacao",
		component: ListaNotificacaoComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "Notificação",
			permissao: { modulo: "Notificacao", acao: "Leitura" }
		},
		path: "notificacao/:id",
		component: ListaNotificacaoComponent,
		canActivate: [AuthGuard]
	},
	////////////////////////////////////////////////////////////////////
	/// DOWNLOADREGISTRO////////////////////////////////////////////////
	{
		data: {
			label: "Download Registro",
			permissao: { modulo: "DownloadRegistro", acao: "Leitura" }
		},
		path: "downloadregistro",
		component: DownloadRegistroComponent,
		canActivate: [AuthGuard]
	},
	////////////////////////////////////////////////////////////////////
	/// PESQUISA////////////////////////////////////////////////////////
	{
		data: {
			label: "Pesquisa Estratégias", permissao:
				{ modulo: "PesquisaEstrategia", acao: "Leitura" }
		},
		path: "pesquisaestrategia",
		component: PesquisaEstrategiaComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "Pesquisa Listas", permissao:
				{ modulo: "PesquisaLista", acao: "Leitura" }
		},
		path: "pesquisalista",
		component: PesquisaListaComponent,
		canActivate: [AuthGuard]
	},
	////////////////////////////////////////////////////////////////////
	/// FILTROQUERY ////////////////////////////////////////////////////
	{
		data: {
			label: "Filtro query",
			permissao: { modulo: "FiltroQuery", acao: "Leitura" }
		},
		path: "filtroquery",
		component: ListaFiltroQueryPadraoComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "Criação do filtro query",
			parent: "filtroquery",
			permissao: { modulo: "FiltroQuery", acao: "Escrita" }
		},
		path: "filtroquery/create",
		component: FormFiltroQueryPadraoComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "Edição do filtro query",
			parent: "filtroquery",
			permissao: { modulo: "FiltroQuery", acao: "Escrita" }
		},
		path: "filtroquery/:id",
		component: FormFiltroQueryPadraoComponent,
		canActivate: [AuthGuard]
	},
	////////////////////////////////////////////////////////////////////
	/// CONFIGURAÇÃO ///////////////////////////////////////////////////
	{
		data: {
			label: "Configuração",
			permissao: { modulo: "Configuracao", acao: "Leitura" }
		},
		path: "configuracao",
		component: ListaConfiguracaoComponent,
		canActivate: [AuthGuard]
	},
	////////////////////////////////////////////////////////////////////
	/// PLANO TARIFA PROVEDOR //////////////////////////////////////////
	{
		data: {
			label: "Editar plano tarifa provedor",
			permissao: { modulo: "PlanoTarifaProvedor", acao: "Leitura" }
		},
		path: "planotarifaprovedor/:id",
		component: FormPlanoTarifaProvedorComponent,
		canActivate: [AuthGuard]
	},
	////////////////////////////////////////////////////////////////////
	/// INDICADOR PROVEDOR /////////////////////////////////////////////
	{
		data: {
			label: "Indicador provedor",
			permissao: { modulo: "IndicadorProvedor", acao: "Leitura" }
		},
		path: "indicadorprovedor",
		component: ListaIndicadorProvedorComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "Indicador provedor",
			parent: "indicadorprovedor",
			permissao: { modulo: "IndicadorProvedor", acao: "Leitura" }
		},
		path: "indicadorprovedor/:id",
		component: ListaIndicadorProvedorComponent,
		canActivate: [AuthGuard]
	},
	////////////////////////////////////////////////////////////////////
	{ path: "**", redirectTo: "/app/usuario" }

];