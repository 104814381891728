import { Router, ActivatedRoute } from "@angular/router";
import { Component, OnInit,Inject } from "@angular/core";
import { UsuarioService } from "../../common/service/usuario.service";
import { CustomAlertService } from "../../common/service/custom-alert.service";
import * as validate from "validate.js";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Senha } from "../model/senha";
import {mostrarSenha,impedirEspaco, onKey } from "../../common/utils/validacaoSenha";
import { ValidacaoSenhaModel } from "../../common/model/validacao-senha";

@Component({
  selector: "app-alterar-senha",
  templateUrl: "./alterar-senha.component.html",
  styleUrls: ["./alterar-senha.component.scss"],
  providers: [
    { useClass: CustomAlertService, provide: CustomAlertService },
    { useClass: UsuarioService, provide: UsuarioService }
  ]
})
export class AlterarSenhaComponent implements OnInit {
  public login: string;
  public email: string;
  public error:any;
  public objAlteracao: Senha;
  public filtro: ValidacaoSenhaModel;
    
  
  constructor(
    private customAlertService: CustomAlertService,
    private usuarioService: UsuarioService,
    private router: Router,
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<AlterarSenhaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) 
  {
    this.objAlteracao =new Senha();
    this.login = data.data.login;
    this.objAlteracao.chave = data.chave;
    this.objAlteracao.senha = '';
		this.filtro = new ValidacaoSenhaModel();
  }

  ngOnInit() {
    
  }

  alterarSenha() {    

      this.usuarioService.alterarSenha(this.objAlteracao).subscribe(s=>
        {
          if(s.isValid){
            this.dialogRef.close();
            this.customAlertService.show(
              "Login",
              "login.senhaAlterada",
              "success"
            );
          }
          else{
            if (s.errors.lenght !== null)
            {
              this.customAlertService.show(
                "Atenção",
                s.errors[0].arguments[0] +"."+ s.errors[0].message,
                "error"
                );
              
            }
            else{
              this.customAlertService.show(
              "Atenção",
              "telaPadrao.alteracaoErro",
              "error"
              );
            }
          }
        }
      );

  }

  validaRegraSenha(event: any): void
	{
		this.filtro = onKey(this.filtro,this.objAlteracao.senha);
	}

	get mostrarSenha()
	{
		return mostrarSenha;
	}

	get impedirEspaco()
	{
		return impedirEspaco;
	}

}
