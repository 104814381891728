import * as _ from 'lodash';
import { toElasticSearchName } from '../../common/utils/toElasticSearchName';

export function montarQueryPesquisaCampo(nomeCampo: string, valorCampo: string, listaCampoTipoId: number) {
    let jsonQuery = _.cloneDeep(obterQueryBasePesquisaCampo());
    let campo = toElasticSearchName(nomeCampo);
    let condicao = { "term": {} };
    let campoPesquisa = listaCampoTipoId == 8 || listaCampoTipoId == 11 ? `${campo}.keyword` : campo;
    condicao.term[campoPesquisa] = valorCampo;

    jsonQuery.query.bool.must.push(condicao);

    return jsonQuery;
}

export function obterQueryBasePesquisaCampo(): any {
    let queryBaseDisponivel: any = {
        size: 10,
        _source: {
            includes: []
        },
        query: {
            bool: {
                must: [],
                must_not: []
            }
        }
    };

    return queryBaseDisponivel;
}