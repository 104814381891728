import { Component, Inject, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material";
import { CustomAlertService } from "../../common/service/custom-alert.service";
import * as validate from "validate.js";
import { TranslateService } from "@ngx-translate/core";
import { ListaReguaEstrategiaComponent } from "../lista-regua-estrategia/lista-reguaestrategia.component";
import { EstrategiaService } from "../../common/service/estrategia.service";
import { ListaService } from "../../common/service/lista.service";
import { Estrategia } from "../../common/model/estrategia";
import { ElasticsearchFilterComponent } from "../../elasticsearch/filter/elasticsearch-filter/elasticsearch-filter.component";
import { PreviewDadosEstrategiaComponent } from "../../estrategia/form-estrategia/preview-dados-estrategia/preview-dados-estrategia.component";

@Component({
    selector: "app-visualizar-filtros-estrategia-modal",
    templateUrl: "./visualizar-filtros-estrategia-modal.component.html",
    styleUrls: ["./visualizar-filtros-estrategia-modal.component.scss"],
    providers: [
        { useClass: EstrategiaService, provide: EstrategiaService },
        { useClass: ListaService, provide: ListaService },
        { useClass: CustomAlertService, provide: CustomAlertService }
    ]
})
export class VisualizarFiltrosEstrategiaModalComponent {


    submited: boolean;
    estrategiaId: number;

    finishedLoading: boolean;
    estrategia: Estrategia;
    query: any;
    lista: Array<{
        listaId: number;
        providerId: number;
        templateId: number;
        nome: string;
        alias: string;
    }>;

    @ViewChild("elasticsearchFilter", { static: false })
    elasticsearchFilter: ElasticsearchFilterComponent;

    constructor(
        private estrategiaService: EstrategiaService,
        private listaService: ListaService,
        private ref: MatDialogRef<VisualizarFiltrosEstrategiaModalComponent>,
        private customAlertService: CustomAlertService,
        private translate: TranslateService,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA)
        public data: { estrategiaId: number }
    ) {

        this.estrategia = new Estrategia();

        this.listaService
            .obterListaComTemplate({})
            .subscribe(lista => (this.lista = lista));

        this.estrategiaService
            .obterEstrategiaPorId(this.data.estrategiaId)
            .subscribe(estrategia => {
                this.estrategia = estrategia;
                this.updateQuery(JSON.parse(this.estrategia.filtro));
                this.finishedLoading = true;
            });

        // this.estrategiaService
        // .obterEstrategias({})
        // .subscribe(data => {
        //     this.estrategias = data.map((m:Estrategia) => { return { 'estrategiaId' : m.listaEstrategiaId, 'nome' : m.nome } });
        // });
    }

    public get nomeLista(): string {
        if (!this.estrategia.listaId || !this.lista) return "";
        let lista = this.lista.find(l => l.listaId == this.estrategia.listaId);
        return lista ? lista.alias : "";
    }

    public get templateId(): number {
        if (!this.estrategia.listaId || !this.lista) return null;
        let lista = this.lista.find(l => l.listaId == this.estrategia.listaId);
        return lista ? lista.templateId : null;
    }

    updateQuery(query) {
        this.query = query;
    }

    save() {
        this.submited = true;

        if (this.validadeDisable()) {
            this.customAlertService.show("telaTemplate.template", "telaPadrao.camposInvalidos", "error");
            return;
        };

        this.data.estrategiaId = this.estrategiaId;
        return this.ref.close(this.data);
    }

    public get error() {
        if (this.submited) {
            return this.validate();
        }
    }

    public liveError(property: string) {

        if (this.submited) {
            var validationResult = this.validate();
            if (!validationResult) return null;
            return validationResult[property] ? validationResult[property][0] : null;
        }
    }

    validadeDisable() {
        var validationResult = this.validate();

        if (!validationResult)
            return false;

        return true;
    }

    validate() {

        let regras = {
            estrategiaId: {
                presence: { allowEmpty: false, message: "^obrigatorio" }
            }
        };

        let validationResult = validate.validate(this, regras);

        return validationResult;
    }

    updateQuantidades(event: any) {

        let quantidades: Array<number> = event.quantidade;

    }

    close() {
        this.ref.close();
    }

    fechar() {
        this.ref.close();
    }

    isNullOrEmpty(valor: string) {
        return (valor == undefined || valor == null || valor == '');
    }

    isNull(valor: any) {
        return (valor == undefined || valor == null || valor == '');
    }

    mostrarPreview(query: any = null) {

        if (query == undefined) {
            if (this.query == undefined) {
                query = {
                    size: this.elasticsearchFilter.pageSize,
                    _source: { includes: [] },
                    query: { bool: { must_not: this.elasticsearchFilter.mustNot } }
                }
            } else {
                query = this.query;
            }
        }

        this.listaService
            .obterListaComTemplate({ 'listaId': this.estrategia.listaId })
            .subscribe(lista => {

                if (this.isNullOrEmpty(lista)) {
                    if (this.isNullOrEmpty(lista[0].templateId) || this.isNullOrEmpty(lista[0].alias))
                        return;
                }

                this.dialog.open(PreviewDadosEstrategiaComponent, {
                    height: "700px",
                    width: "80%",
                    hasBackdrop: true,
                    closeOnNavigation: true,
                    data: {
                        templateId: lista[0].templateId, //this.templateId,
                        lista: lista[0].alias, //this.nomeLista,
                        listaId: lista[0].listaId,
                        query
                    }
                });

            });


    }
}
