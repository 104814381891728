import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

import { Template } from "../../../common/model/template";
import { TemplateService } from "../../../common/service/template.service";
import { ListaTemplateItem, ItemTipo } from "../../../common/model/lista-template-item";

@Component({
  selector: "app-display-template",
  templateUrl: "./display-template.component.html",
  providers: [{ useClass: TemplateService, provide: TemplateService }]
})
export class DisplayTemplateComponent implements OnInit {
  template: Template;
  listaTemplateItemAtiva: any[];
  constructor(
    private ref: MatDialogRef<DisplayTemplateComponent>,
    private templateService: TemplateService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.template = data.template;
    this.listaTemplateItemAtiva = this.template.listaTemplateItemAtiva.filter((f: ListaTemplateItem) => f.itemTipo != ItemTipo.Coluna);
  }

  ngOnInit() {}

  baixar() {
    this.templateService.baixarExemplo(this.template);
  }

  fechar() {
    this.ref.close();
  }
}
