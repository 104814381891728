import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

import { EstrategiaService } from "../../common/service/estrategia.service";
import { TemplateService } from "../../common/service/template.service";
import { ProviderService } from "../../common/service/provider.service";

import { Provider } from "../../common/model/provider";
import { Estrategia, EstrategiasFilhas } from "../../common/model/estrategia";

@Component({
    selector: "app-visualizar-provedor-modal",
    templateUrl: "./visualizar-provedor-modal.component.html",
    styleUrls: ["./visualizar-provedor-modal.component.scss"],
    providers: [
        { useClass: EstrategiaService, provide: EstrategiaService },
        { useClass: ProviderService, provide: ProviderService },
        { useClass: TemplateService, provide: TemplateService }
    ]
})
export class VisualizarProvedorModalComponent {
    estrategiaFilhas: Array<EstrategiasFilhas>;
    totalDistribuidos: number;
    totalQuantidade: number;
    totalQuantidadePerc: number;
    provider: Provider[];
    listaTemplateExportacao: Array<any>;

    constructor(
        public dialogRef: MatDialogRef<VisualizarProvedorModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { listaEstrategiaId: number },
        private estrategiaService: EstrategiaService,
    ) {
        this.estrategiaFilhas = new Array<EstrategiasFilhas>();
        this.carregar(data.listaEstrategiaId);
    }

    carregar(listaEstrategiaId: number) {
        this.estrategiaService
            .obterEstrategiaDetalheCampanha(listaEstrategiaId)
            .subscribe(estrategia => {
                this.estrategiaFilhas = estrategia.estrategiasFilhas.filter((f: EstrategiasFilhas) => f.ativo);
                this.totalDistribuidos = this.estrategiaFilhas.reduce((total, filha) => (total + filha.quantidadeDistribuido), 0);
                this.totalQuantidade = this.estrategiaFilhas.reduce((total, filha) => (total + filha.quantidadeMaximaExportacao), 0);
                this.totalQuantidadePerc = this.estrategiaFilhas.reduce((total, filha) => (total + filha.quantidadeMaximaExportacaoPct), 0);
            });
    }

    fechar() {
        this.dialogRef.close();
    }
}