import { Directive, ElementRef, Input, HostListener } from "@angular/core";
import { UsuarioService } from "../../common/service/usuario.service";
import { Usuario } from "../model/usuario";
@Directive({
  selector: "[uniqueLogin]"
})
export class UniqueLoginValidatorDirective {
  _usuario;
  public get usuario(): Usuario {
    return this._usuario;
  }

  @Input()
  public set usuario(usuario: Usuario) {
    this._usuario = usuario;
  }

  @HostListener('blur', ['$event']) onBlur(e) {
    if(this._usuario.login && this._usuario.login!=''){
      
      this._userService.verificaUsuarioExiste(this._usuario.login).subscribe(user=>{
        let loginUnico = true;
        
        if(user.length>0)
          loginUnico= user.find(f=>f.usuarioId== this._usuario.usuarioId) ? true : false;
        
        this._usuario.loginUnico=loginUnico;
      })
    }
  }

  constructor(private el: ElementRef,private _userService: UsuarioService) {}
}
