import { validate } from "validate.js";

import { OrderOperation } from "./ordertypes";

export class Order {
	ordem: number;
	ativo: boolean;

	constructor(
		public listaTemplateItemId?: string,
		public operador?: OrderOperation,
		public valor?
	) { }

	validate(validarValor: boolean = true) {

		let validacao: any = {
			listaTemplateItemId: { presence: { allowEmpty: false } },
			operador: { presence: { allowEmpty: false } }
		};

		if (validarValor)
			validacao.valor = { presence: { allowEmpty: false } };

		return validate(this, validacao);
	}
}
