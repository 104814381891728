import { Component, Inject, ViewEncapsulation } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { CustomAlertService } from "../../../common/service/custom-alert.service";
import { FormTemplateImportacaoComponent } from "../form-template-importacao.component";
import { GuidService } from "../../../common/service/guid.service";
import { ListaTemplateItem, ItemTipo } from "../../../common/model/lista-template-item";
import * as validate from "validate.js";
import { TranslateService } from "@ngx-translate/core";
import { ListaCampoValidacao } from "../../../common/model/lista-campo-validacao";
import { isNull, isNullOrEmpty, isNullOrZero } from "../../../common/utils";
import { CampoIntegracao } from "../../../common/model/campo-integracao";

@Component({
    selector: "app-quebra-linha-modal",
    templateUrl: "./quebra-linha-modal.component.html",
    styleUrls: ["../../style.css", "./quebra-linha-modal.component.css"],
    providers: [{ useClass: CustomAlertService, provide: CustomAlertService }]
})
export class QuebraLinhaModalComponent {

    submited: boolean;
    quebraQuantidadeLinhas: number;

    camposIntegracao: Array<any>;
    colunas: Array<ListaTemplateItem> = [];
    colunasValidacao: Array<ListaTemplateItem> = [];

    constructor(
        private ref: MatDialogRef<FormTemplateImportacaoComponent>,
        private customAlertService: CustomAlertService,
        private translate: TranslateService,
        @Inject(MAT_DIALOG_DATA)
        public data: { listaTemplateTipoId: number, camposIntegracao: Array<CampoIntegracao>, listaCamposValidacao: Array<ListaCampoValidacao>, quebraQuantidadeLinhas: number, colunas: Array<ListaTemplateItem> }
    ) {

        if (data.camposIntegracao)
            this.camposIntegracao = data.camposIntegracao.map((c: any) => ({ value: c.listaCampoTipoId, label: c.nome }));

        if (data.colunas)
            this.colunas = data.colunas;
        else
            this.addColunas()

        this.quebraQuantidadeLinhas = (data.quebraQuantidadeLinhas) ? data.quebraQuantidadeLinhas : 2;
    }

    obterPosicaoCampo(campo: any) {
        return this.colunas.indexOf(campo);
    }

    addColunas() {
        let guid = this.novoGuid();
        let c = new ListaTemplateItem('', this.camposIntegracao[0], this.colunas.length, 0);
        c.guidQuebraColuna = guid;
        c.itemTipo = ItemTipo.Coluna;

        if (!isNullOrZero(c.listaCampoTipoId)) {
            let validacoes: Array<ListaCampoValidacao> = this.data.listaCamposValidacao.filter((f: ListaCampoValidacao) => f.listaCampoTipoId.toString() == c.listaCampoTipoId.toString());
            c.listaCamposValidacao = validacoes;
        }

        this.colunas.push(c);
    }

    listaCampoValidacaoChange(campo: ListaTemplateItem, $event: any) {

        if (!isNullOrEmpty(campo.listaCampoValidacaoId)) {
            if (isNullOrEmpty(campo.chaveValidacao))
                campo.chaveValidacao = this.novaChaveValidacao();

            let existeCampoValidacao = this.colunas.some((f: ListaTemplateItem) => f.itemTipo == ItemTipo.Validacao && f.chaveValidacao == campo.chaveValidacao);
            let campoIntegracaoBoolean = this.data.camposIntegracao.find((c: any) => c.tipoDado == "Boolean");
            let nome = `${this.toElasticSearchName(campo.nome)}_Valido`;

            //let campoValidaca: ListaTemplateItem;

            if (!existeCampoValidacao) {
                // CRIA
                let lti = new ListaTemplateItem(nome, campoIntegracaoBoolean, this.colunas.length, 0);
                lti.alias = "Boolean";
                lti.tipoCampoImportacao = 0;
                lti.conteudo = null;
                lti.listaCampoTipoFormatacao = campoIntegracaoBoolean.listaCampoTipoFormatacao;
                lti.listaCampoTipoId = campoIntegracaoBoolean.listaCampoTipoId;

                lti.itemTipo = ItemTipo.Validacao;
                lti.chaveValidacao = campo.chaveValidacao;

                this.colunas.push(lti);
            } else {
                // EDITA
                this.colunas.forEach((lti: ListaTemplateItem) => {
                    if (lti.itemTipo == ItemTipo.Validacao && lti.chaveValidacao == campo.chaveValidacao) {
                        lti.alias = "Boolean";
                        lti.tipoCampoImportacao = 0;
                        lti.conteudo = null;
                        lti.listaCampoTipoFormatacao = campoIntegracaoBoolean.listaCampoTipoFormatacao;
                        lti.listaCampoTipoId = campoIntegracaoBoolean.listaCampoTipoId;
                        lti.ativo = true;
                    }
                });
            }

            this.colunas.forEach((lti: ListaTemplateItem) => {
                if (lti.itemTipo == ItemTipo.Linha && lti.guidQuebraColuna == campo.guidQuebraColuna) {
                    lti.listaCampoValidacaoId = campo.listaCampoValidacaoId;
                    lti.chaveValidacao = campo.chaveValidacao;
                    lti.listaCamposValidacao = campo.listaCamposValidacao;
                }
            });
        } else {

            this.colunas.forEach((lti: ListaTemplateItem) => {
                if (lti.itemTipo == ItemTipo.Linha && lti.chaveValidacao == campo.chaveValidacao) {
                    lti.listaCampoValidacaoId = null;
                    lti.chaveValidacao = null;

                    if (!isNullOrZero(lti.listaCampoTipoId)) {
                        let validacoes: Array<ListaCampoValidacao> = this.data.listaCamposValidacao.filter((f: ListaCampoValidacao) => f.listaCampoTipoId.toString() == lti.listaCampoTipoId.toString());
                        lti.listaCamposValidacao = validacoes;
                    }

                }

                if (lti.itemTipo == ItemTipo.Validacao && lti.chaveValidacao == campo.chaveValidacao)
                    lti.ativo = false;
            });
        }


        //this.reordernarItens();
    }

    novaChaveValidacao(): string {
        let guid = GuidService.newGuid();

        if (this.data.colunas.length <= 0)
            return guid;

        while (this.data.colunas.some((s: ListaTemplateItem) => (s.chaveValidacao == guid)))
            guid = GuidService.newGuid();

        return guid;
    }

    novoGuid(): string {
        let guid = GuidService.newGuid();
        if (this.data.colunas.length <= 0)
            return guid;

        while (this.data.colunas.some((s: ListaTemplateItem) => (s.guidQuebraColuna == guid)))
            guid = GuidService.newGuid();

        return guid;
    }

    excluirColuna(coluna: ListaTemplateItem) {
        //if (this.possuiLista && this.isEdit) return;

        let col = new Array<any>();

        this.colunas.forEach((f: ListaTemplateItem) => {
            if (!(f.nome == coluna.nome && f.tamanho == coluna.tamanho && f.ordem == coluna.ordem))
                col.push(f);
        });

        this.colunas = col;
    }

    listaCampoTipoChange(campo: ListaTemplateItem, $event: any) {

        if (!isNullOrZero(campo.listaCampoTipoId)) {
            let campoIntegracao = this.camposIntegracao.find((item) => (item.value == campo.listaCampoTipoId));
            campo.alias = campoIntegracao.label;

            let validacoes: Array<ListaCampoValidacao> = this.data.listaCamposValidacao.filter((f: ListaCampoValidacao) => f.listaCampoTipoId.toString() == campo.listaCampoTipoId.toString());
            campo.listaCamposValidacao = validacoes;
        } else {
            campo.alias = null;
            campo.listaCamposValidacao = [];
        }
    }

    toElasticSearchName(field: string) {
        if (isNullOrEmpty(field)) return "";

        var upperCaseField = field.toUpperCase();
        if (upperCaseField == field) return field.toLowerCase().trimLeft().trimRight();
        return `${field.substring(0, 1).toLowerCase()}${field.substring(1)}`.trimLeft().trimRight();
    }

    save() {

        this.submited = true;

        if (this.validadeDisable()) {
            this.customAlertService.show("telaTemplate.template", "telaPadrao.camposInvalidos", "error");
            return;
        };

        let invalidNome: Array<string> = ['id', '_id', 'id_', '_id_'];

        let colunas = this.colunas.filter((f: ListaTemplateItem) => f.ativo).map((item: ListaTemplateItem) => item.nome.toLowerCase());

        if (invalidNome.some(i => colunas.find(f => f == i))) {
            this.customAlertService.show("telaPadrao.validacao", "telaTemplate.nomeCampoInvalidoId", "error");
            return;
        }


        let duplicadas = colunas.filter((item: string, idx: number) => colunas.indexOf(item) != idx);
        let ehDuplicada = colunas.some((item: string, idx: number) => colunas.indexOf(item) != idx);

        if (ehDuplicada) {
            let msg = (duplicadas.length == 1)
                ? this.translate.instant("telaTemplate.campoQuebraRepetido", { 'campo': duplicadas[0] })
                : this.translate.instant("telaTemplate.camposQuebraRepetidos", { 'campo': duplicadas.join(', ').replace(/,\s([^,]+)$/, ' e $1') });

            this.customAlertService.show("telaPadrao.validacao", msg, "error");
            return;
        }

        this.data.quebraQuantidadeLinhas = this.quebraQuantidadeLinhas;
        this.data.colunas = this.colunas;

        return this.ref.close(this.data);
    }

    public get error() {
        if (this.submited) {
            return this.validate();
        }
    }

    public liveError(property: string) {
        if (this.submited) {
            var validationResult = this.validate();
            if (!validationResult) return null;
            return validationResult[property] ? validationResult[property][0] : null;
        }
    }

    public liveErrorColuna(coluna: ListaTemplateItem, property: string) {
        var validationResult = this.validate();
        if (!validationResult) return null;

        let index = this.colunasValidacao.indexOf(coluna);

        if (validationResult["colunasValidacao"][0]["errors"][index] === undefined) return null;
        return validationResult["colunasValidacao"][0]["errors"][index]["error"][property] ? validationResult["colunasValidacao"][0]["errors"][index]["error"][property] : null;
    }

    public strTooltipErroColunas(coluna: ListaTemplateItem, property: string) {
        //let index = this.colunas.indexOf(coluna);
        let erro = this.liveErrorColuna(coluna, property);
        if (erro == 'obrigatorio') {
            return this.translate.instant("validacao.campoObrigatorio")
        }
        else if (erro == 'menorque1') {
            return this.translate.instant("validacao.tamanhoMinimo", { value: '1' })
        }
        else {
            return "";
        }
    }

    validadeDisable() {
        var validationResult = this.validate();
        if (!validationResult)
            return false;

        var ok = false;

        Object.keys(validationResult["colunasValidacao"][0]["errors"]).forEach((f: any) => {
            if (validationResult["colunasValidacao"][0]["errors"][f] !== undefined)
                ok = true;
        });

        return ok;
    }

    validate() {

        validate.validators.array = (arrayItems: Array<any>, itemConstraints: any) => {
            const arrayItemErrors = arrayItems.reduce((errors, item, index) => {
                const error = validate(item, itemConstraints)
                if (error) errors[index] = { error: error }
                return errors
            }, {});

            return isNull(arrayItemErrors) ? null : { errors: arrayItemErrors };
        };

        let regras = {};

        if (this.data.listaTemplateTipoId == 1) {
            regras = {
                quebraQuantidadeLinhas: {
                    presence: { allowEmpty: false, message: "^obrigatorio" },
                    numericality: { onlyInteger: true, greaterThanOrEqualTo: 2, message: "^menorque2" }
                },
                colunasValidacao: {
                    array: {
                        nome: {
                            presence: { allowEmpty: false, message: "^obrigatorio" }
                        },
                        tamanho: {
                            presence: { allowEmpty: false, message: "^obrigatorio" },
                            numericality: { onlyInteger: true, greaterThanOrEqualTo: 1, message: "^menorque1" }
                        },
                        listaCampoTipoId: {
                            presence: { allowEmpty: false, message: "^obrigatorio" }
                        }
                    }
                }
            };
        } else {
            regras = {
                quebraQuantidadeLinhas: {
                    presence: { allowEmpty: false, message: "^obrigatorio" },
                    numericality: { onlyInteger: true, greaterThanOrEqualTo: 2, message: "^menorque2" }
                },
                colunasValidacao: {
                    array: {
                        nome: {
                            presence: { allowEmpty: false, message: "^obrigatorio" }
                        },
                        listaCampoTipoId: {
                            presence: { allowEmpty: false, message: "^obrigatorio" }
                        }
                    }
                }
            };
        }

        this.colunasValidacao = this.colunas.filter((f: ListaTemplateItem) => f.itemTipo == ItemTipo.Coluna);

        let validationResult = validate.validate(this, regras);

        return validationResult;
    }

    close() {
        this.ref.close();
    }
}
