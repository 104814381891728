import { BaseElasticsearchFilter } from "./base";

export class RegexElasticsearchFilter extends BaseElasticsearchFilter {
  protected getInstance(
    queryType: string,
    propertyName: string,
    parameters: any,
    extra?: any,
    keyword?: boolean
  ): BaseElasticsearchFilter {
    return new RegexElasticsearchFilter(propertyName, queryType, parameters, extra, keyword);
  }

  public friendlyParameters(dataType:string, queryTypes) {
    return `Aplicar validação`;
  }

  getConvertedInlinedContains() {
    return {
      [this.queryType]: {
        [this.property]: this.parameters.query === true
      }
    };
  }

  convertFilter(): any {
    if (this.parameters.query != undefined)
      return this.getConvertedInlinedContains();
    return super.convertFilter();
  }
  static fromRaw(raw): BaseElasticsearchFilter {
    return Object.assign(new BaseElasticsearchFilter(), raw);
  }
}
