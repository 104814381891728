import { TipoValidacao } from "../../common/model/lista-campo-validacao";

export class DashListaProvedorValidacaoCard {
    constructor() {
        this.qtdTotalLista = 0;
        this.qtdClienteUnico = 0;
        this.pctClienteUnico = 0;
        this.validacoesQuantidadeDetalhe = new Array<DashListaProvedorValidacaoDetalheCard>();
    }

    qtdTotalLista: number;
    qtdClienteUnico: number;
    pctClienteUnico: number;
    nomeCampoCodigoCliente: string;
    validacoesQuantidadeDetalhe: Array<DashListaProvedorValidacaoDetalheCard>;
}

export class DashListaProvedorValidacaoDetalheCard {
    tipoValidacao: TipoValidacao;
    possuiValidacao: boolean;
    qtdValido: number;
    pctValido: number;
    camposValidacao: Array<DashListaProvedorCampoValidacaoCard>;
}

export class DashListaProvedorCampoValidacaoCard {
    nome: string;
    qtdValido: string;
    qtdInvalido: string;
}