import * as validate from "validate.js";
import { Carteira } from "../../common/model/carteira";

export class FiltroDashPrincipal {
    dataInicio: Date;
    dataFim: Date;
    listaPossivel: Array<any>;
    listaSelecionada: string;
    refreshAutomatico: boolean;
    carteira: Array<Carteira>;
    carteiraId: number

    constructor() {
        this.dataFim = new Date();
        this.dataInicio = new Date(
            this.dataFim.getFullYear(),
            this.dataFim.getMonth(),
            this.dataFim.getDate()
            
        );
        this.listaPossivel = [];
        this.refreshAutomatico = false;
        this.carteiraId = 0;
        this.carteira = [];
    }

    validar() {
        var validacao: any = {
            dataInicio: {
                presence: {
                    allowEmpty: false,
                    message: "campoObrigatorio"
                },
                dataMenorQue: {
                    campo: "dataFim"
                }
            },
            dataFim: {
                presence: {
                    allowEmpty: false,
                    message: "campoObrigatorio"
                }
            }
        };
        return validate(this, validacao, { fullMessages: false })
    }
}
