import { LOCALE_ID, NgModule } from "@angular/core";
import { MatButtonModule, 
         MatCardModule,
         MatCheckboxModule,
         MatDatepickerModule,
         MatDialogModule,
         MatDividerModule,
         MatExpansionModule,
         MatIconModule,
         MatInputModule,
         MatListModule,
         MatPaginatorModule,
         MatProgressBarModule,
         MatRadioModule,
         MatSelectModule,
         MatSlideToggleModule,
         MatTooltipModule } from "@angular/material";
import { AppCommonModule } from "../common/common.module";
import { CommonModule } from "@angular/common";
import { FormBuilder, FormsModule } from "@angular/forms";
import { TableModule } from "primeng/table";
import { DownloadRegistroComponent } from "./download-registro.component";
import { NotificacaoService } from "../common/service/notificacao.service";
import { SelectModule } from "ng-select";
import { StatusDownloadRegistroPipe } from "./libs/pipes/status-download-registro.pipe";
import { FileUploadModule } from "primeng/fileupload";

@NgModule({
    imports: [
        AppCommonModule,
        CommonModule,        
        MatButtonModule,
        MatInputModule,
        MatIconModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatCardModule,
        MatRadioModule,
        MatDividerModule,
        MatSlideToggleModule,
        MatExpansionModule,
        MatListModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatTooltipModule,
        MatDialogModule,
        MatSelectModule,
        SelectModule,
        FormsModule,
        TableModule,
        FileUploadModule
    ],
    providers: [
        { provide: FormBuilder, useClass: FormBuilder },
        { provide: LOCALE_ID, useValue: "pt-BR" },        
        { useClass: NotificacaoService, provide: NotificacaoService }
    ],
    declarations: [
        DownloadRegistroComponent,
        StatusDownloadRegistroPipe
    ],
    entryComponents: [
    ],
     exports: [
     ]
})

export class DownloadRegistroModule { }