import { NgModule, LOCALE_ID } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, FormBuilder, ReactiveFormsModule } from "@angular/forms";
import {
  MatButtonModule,
  MatInputModule,
  MatIconModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatStepperModule,
  MatSelectModule,
  MatCardModule,
  MatRadioModule,
  MatDividerModule,
  MatSlideToggleModule,
  MatExpansionModule,
  MatListModule,
  MatPaginatorModule,
  MatTooltipModule,
  MatDialogModule,
  MAT_DIALOG_DEFAULT_OPTIONS
} from "@angular/material";
import { TableModule } from "primeng/table";

import { AppCommonModule } from "../common/common.module";
import { AmazingTimePickerModule } from "amazing-time-picker";
import { ElasticsearchModule } from "../elasticsearch/elasticsearch.module";
import { ListaEstrategiaRemocaoComponent } from "./lista-estrategia-remocao/lista-estrategia-remocao.component";
import { FormEstrategiaRemocaoComponent } from "./form-estrategia-remocao/form-estrategia-remocao.component";
import { environment } from "../../../environments/environment";

@NgModule({
  imports: [
    CommonModule,
    AppCommonModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatTooltipModule,
    FormsModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatStepperModule,
    MatSlideToggleModule,
    ElasticsearchModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatCardModule,
    MatDividerModule,
    MatListModule,
    TableModule,
    AmazingTimePickerModule,
    MatRadioModule,
    MatExpansionModule,
    MatCardModule,
    MatPaginatorModule,
    MatDialogModule
  ],
  providers: [
    { provide: FormBuilder, useClass: FormBuilder },
    { provide: LOCALE_ID, useValue: "pt-BR" },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { panelClass: `theme-${environment.theme}` }}    
  ],
  entryComponents: [],
  declarations: [
    ListaEstrategiaRemocaoComponent,
    FormEstrategiaRemocaoComponent
  ]
})
export class EstrategiaRemocaoModule { }
