import { Component, OnInit, Input } from "@angular/core";

@Component({
    selector: "app-card",
    templateUrl: "./card.component.html"
})
export class CardComponent implements OnInit {
    @Input()
    title: string;
    @Input()
    titleValue: string;
    @Input()
    details: { value: string; label: string }[];
    @Input()
    icon: string;
    constructor() { }

    ngOnInit() { }

    cssClass(): string {
        if (this.details.length == 3)
            return 'col-4';
        else if (this.details.length == 2)
            return 'col-6';
        else
            return 'col';
    }


}
