import { TranslateService } from "@ngx-translate/core";
import { Estrategia } from "../../../common/model/estrategia";
import { isNullOrEmpty } from "../../../common/utils";
import * as moment from "moment";

export function execucaoEstrategiaDescricao(estrategia: Estrategia, translate: TranslateService) {

    let acertaOrdemDiasSemana = (estrategia: Estrategia) => {
        let dias = estrategia.diaSemana.split(',');
        let diasOrdenados = [];

        if (dias.some((i) => { return i == 'SUN'; })) diasOrdenados.push('SUN');
        if (dias.some((i) => { return i == 'MON'; })) diasOrdenados.push('MON');
        if (dias.some((i) => { return i == 'TUE'; })) diasOrdenados.push('TUE');
        if (dias.some((i) => { return i == 'WED'; })) diasOrdenados.push('WED');
        if (dias.some((i) => { return i == 'THU'; })) diasOrdenados.push('THU');
        if (dias.some((i) => { return i == 'FRI'; })) diasOrdenados.push('FRI');
        if (dias.some((i) => { return i == 'SAT'; })) diasOrdenados.push('SAT');

        estrategia.diaSemana = diasOrdenados.join(',');

        return diasOrdenados;
    };

    let replaceUltimoDia = (str: string) => {
        return str.split(' ').reverse().join(' ').replace(new RegExp(/,/), '*e').split(' ').reverse().join(' ').replace('*e', ' e');
    };

    let descricao: string = '';

    if (isNullOrEmpty(estrategia))
        return descricao;

    let diaSemana: any = {
        'SUN': translate.instant("telaRegua.execucao.domingo"),
        'MON': translate.instant("telaRegua.execucao.segunda"),
        'TUE': translate.instant("telaRegua.execucao.terça"),
        'WED': translate.instant("telaRegua.execucao.quarta"),
        'THU': translate.instant("telaRegua.execucao.quinta"),
        'FRI': translate.instant("telaRegua.execucao.sexta"),
        'SAT': translate.instant("telaRegua.execucao.sabado")
    };

    if (!isNullOrEmpty(estrategia.dataInicio)) {
        let dataInicio = moment(estrategia.dataInicio).format("DD/MM/YYYY");
        let horaInicio: any;
        let valores: any = { 'dataInicio': dataInicio };

        if (!isNullOrEmpty(estrategia.horaInicio))
            horaInicio = (typeof (estrategia.horaInicio) == 'string') ? estrategia.horaInicio : moment(estrategia.horaInicio).format("HH:mm");

        if (!estrategia.execucaoRecorrente) {
            if (!isNullOrEmpty(horaInicio))
                descricao = translate.instant("telaRegua.execucao.imediata", { ...valores, 'horaInicio': horaInicio });
        }
        else {
            let s = (estrategia.intervalo > 1) ? 's' : '';
            let aPartir = (!isNullOrEmpty(horaInicio)) ? `, a partir de ${horaInicio}` : '';

            if (estrategia.frequencia == '1') {
                descricao = translate.instant("telaRegua.execucao.recorrenteFrequencia1", { ...valores, 'intervalo': estrategia.intervalo, 's': s });

            } else if (estrategia.frequencia == '2') {
                descricao = translate.instant("telaRegua.execucao.recorrenteFrequencia2", { ...valores, 'intervalo': estrategia.intervalo, 's': s, 'aPartir': aPartir });

            } else if (estrategia.frequencia == '3') {
                if (!isNullOrEmpty(horaInicio))
                    descricao = translate.instant("telaRegua.execucao.recorrenteFrequencia3", { ...valores, 'horaInicio': horaInicio });

            } else if (estrategia.frequencia == '4') {
                if (!isNullOrEmpty(horaInicio)) {

                    valores = { ...valores, 'horaInicio': horaInicio };

                    if (isNullOrEmpty(estrategia.diaSemana)) {
                        descricao = translate.instant("telaRegua.execucao.recorrenteFrequencia4TodoDia", valores);

                    } else {

                        let dias = [];
                        let diasOrdenados = acertaOrdemDiasSemana(estrategia);
                        diasOrdenados.forEach(function (v: string) { diaSemana.push(diaSemana[v]); }.bind(this));

                        if (diasOrdenados.length == 1)
                            descricao = translate.instant("telaRegua.execucao.recorrenteFrequencia4TodaSemana", { ...valores, 'diaSemana': diaSemana[estrategia.diaSemana] });
                        else if (diasOrdenados.length == 7)
                            descricao = translate.instant("telaRegua.execucao.recorrenteFrequencia4TodoDia", valores);
                        else
                            descricao = translate.instant("telaRegua.execucao.recorrenteFrequencia4TodaSemana", { ...valores, 'diaSemana': replaceUltimoDia(dias.join(', ')) });
                    }
                }
            }
        }

        if (estrategia.comDataFim && !isNullOrEmpty(estrategia.dataTermino) && !isNullOrEmpty(estrategia.horaTermino)) {
            descricao = (!isNullOrEmpty(descricao)) ? `${descricao.substring(0, descricao.length - 1)} até ${moment(estrategia.dataTermino).format("DD/MM/YYYY")} 23:59:59.` : '';
        }

        return descricao;
    }

    return descricao;
}