import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

import { CampoIntegracao } from "../model/campo-integracao";
import { environment } from "../../../../environments/environment";

@Injectable()
export class CamposIntegracaoService {
  constructor(private httpClient: HttpClient) {}

  obterTodosCamposIntegracao(): Observable<Array<CampoIntegracao>> {
    return this.httpClient.get<Array<CampoIntegracao>>(
      `${environment.serverUrl}/api/listaCampoTipo/`
    );
  }
}