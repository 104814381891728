import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { NgJsonEditorModule } from 'ang-jsoneditor'
import { SelectModule } from "ng-select";

import {
  MatFormFieldModule,
  MatButtonModule,
  MatCheckboxModule,
  MatIconModule,
  MatInputModule,
  MatCardModule,
  MatRadioModule,
  MatTabsModule,
  MatButtonToggleModule,
  MatSlideToggleModule,
  MatTooltipModule,
  MatDatepickerModule,
} from "@angular/material";

import { TableModule } from "primeng/table";

import { AppCommonModule } from "../common/common.module";
import { FormProviderComponent } from "./form-provider/form-provider.component";
import { FormProviderEnvioComponent } from "./form-provider/form-provider-envio/form-provider-envio.component";
import { FormProviderRetornoComponent } from "./form-provider/form-provider-retorno/form-provider-retorno.component";
import { CampoModalComponent } from "./form-provider/campo-modal/campo-modal.component";
import { ListaProviderComponent } from "./lista-provider/lista-provider.component";
import { CampanhaDisplayComponent } from "./form-provider/campanha-display/campanha-display.component";
import { ProvedorAgendamentoComponent } from "./provedor-agendamento/provedor-agendamento.component";
import { ProviderService } from "../common/service/provider.service";
import { TesteEnvioModalComponent } from "./form-provider/form-provider-envio/teste-envio-modal/teste-envio-modal.component";
import { NgSelectModule } from '@ng-select/ng-select';
import { ConfigConversaoProvedorModalComponent } from "./form-provider/config-conversao-provedor-modal/config-conversao-provedor-modal.component";

@NgModule({
  imports: [
    CommonModule,
    AppCommonModule,
    FormsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatButtonModule,
    MatCheckboxModule,
    TableModule,
    MatIconModule,
    MatInputModule,
    MatCardModule,
    MatRadioModule,
    MatTabsModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    MatTooltipModule,
    NgJsonEditorModule,
    MatDatepickerModule,
    // SelectModule
    NgSelectModule
  ],
  providers: [
    ProviderService
  ],
  declarations: [
    FormProviderComponent,
    FormProviderEnvioComponent,
    FormProviderRetornoComponent,
    CampoModalComponent,
    ListaProviderComponent,
    CampanhaDisplayComponent,
    ProvedorAgendamentoComponent,
    TesteEnvioModalComponent,
    ConfigConversaoProvedorModalComponent
  ],
  entryComponents: [
    CampoModalComponent, 
    CampanhaDisplayComponent, 
    ProvedorAgendamentoComponent,
    TesteEnvioModalComponent,
    ConfigConversaoProvedorModalComponent
  ]
})
export class ProviderModule {}
