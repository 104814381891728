import { Component, Inject, ElementRef, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { CustomAlertService } from "../../../common/service/custom-alert.service";
import { FormatacaoCampo } from "../../../common/model/formatacao-campo";
import { TranslateService } from "@ngx-translate/core";
import { FormTemplateExportacaoComponent } from "../../form-template-exportacao/form-template-exportacao.component";
import { ListaTemplateItemExportacao } from "../../../common/model/lista-template-item-exportacao";
import { isNull } from "../../../common/utils";

@Component({
	selector: "app-campo-exportacao-modal",
	templateUrl: "./campo-exportacao-modal.component.html",
	styleUrls: ["./campo-exportacao-modal.component.scss", "../../style.css",],
	providers: [{ useClass: CustomAlertService, provide: CustomAlertService }]
})
export class CampoExportacaoModalComponent {
	camposIntegracao: Array<any>;
	listaTemplateItem: Array<any>;
	exibirCampoFormatacao: boolean;
	formatacao: string;
	templateItemCustomizado: number;

	templateItemCustomizadoFuncao: number;
	funcoesCollection: Array<any>;


	listaFormatacaoCampo: Array<any>;
	formatacaoCampo = FormatacaoCampo;

	@ViewChild("conteudo", { static: false }) conteudoRef: ElementRef;

	constructor(
		private ref: MatDialogRef<FormTemplateExportacaoComponent>,
		private customAlertService: CustomAlertService,
		private translate: TranslateService,
		@Inject(MAT_DIALOG_DATA) public data
	) {
		if (data.camposIntegracao) {
			this.camposIntegracao = data.camposIntegracao.map((c: any) => ({
				value: c.listaTemplateItemId,
				label: c.nome
			}));
		}

		if (data.listaTemplateItem) {
			this.listaTemplateItem = data.listaTemplateItem;
		}

		data.nome = data.campoSelecionado.alias;
		data.conteudo = data.campoSelecionado.conteudo;

		if (data.campoSelecionado.listaTemplateItemId) {
			data.tamanho = data.campoSelecionado.tamanho;
			data.listaTemplateItemId = data.campoSelecionado.listaTemplateItemId;
			data.tipoCampoExportacao = data.campoSelecionado.tipoCampoExportacao;
		}

		this.funcoesCollection = [
			{ 'id': '3', restricao: 'Data', 'descricao': this.translate.instant("telaTemplate.fncAddDaysTitulo"), 'funcao': 'AddDays(0)', 'tooltip': 'fncAddDays' },
			{ 'id': '2', restricao: null, 'descricao': this.translate.instant("telaTemplate.fncReplaceTitulo"), 'funcao': 'Replace("A","B")', 'tooltip': 'fncReplace' },
			{ 'id': '1', restricao: null, 'descricao': this.translate.instant("telaTemplate.fncSubStringTitulo"), 'funcao': 'Substring(0,2)', 'tooltip': 'fncSubString' },
			{ 'id': '5', restricao: null, 'descricao': this.translate.instant("telaTemplate.fncSubStringTerceiroTitulo"), 'funcao': 'Substring(2)', 'tooltip': 'fncSubStringTerceiro' }
		];
	}

	public get FncCollection() {

		if (this.templateItemCustomizado !== undefined) {
			let campo = this.data.camposIntegracao.find((f: any) => f.nome == this.templateItemCustomizado);

			if (campo.listaCampoTipoId == 10)
				return this.funcoesCollection.filter((f: any) => f.restricao == null || f.restricao == 'Data');
			else
				return this.funcoesCollection.filter((f: any) => f.restricao == null);
		}

		return this.funcoesCollection.filter((f: any) => f.restricao == null);
	}

	exibirFormatacao() {
		this.templateItemCustomizadoFuncao = null;
		
		if (!this.templateItemCustomizado) return;

		let item = this.data.camposIntegracao.find((f: any) => f.nome == this.templateItemCustomizado);
		let tipo: any;

		switch (item.listaCampoTipoId) {
			case 9:
				tipo = "Numerico";
				break;
			case 10:
				tipo = "Data";
				break;
			default:
				this.exibirCampoFormatacao = false;
				return;
		}

		this.listaFormatacaoCampo = this.formatacaoCampo
			.filter((f: any) => f.tipo == tipo)
			.map((m: any) => ({ value: m.valor, label: m.nome }));

		this.exibirCampoFormatacao = true;
	}

	validarCampo() {
		if (!this.data.nome || this.data.nome.trim().length == 0)
			return true;

		if (this.data.tipoCampoExportacao == 1 && (!this.data.conteudo || this.data.conteudo.trim().length == 0))
			return true;

		if (this.data.tipoCampoExportacao == 0 && !this.data.listaTemplateItemId)
			return true;

		return false;
	}

	strTooltipFuncoes() {
		if (this.templateItemCustomizadoFuncao) {
			let fnc = this.funcoesCollection.find((f: any) => (f.id == this.templateItemCustomizadoFuncao));
			return this.translate.instant("telaTemplate." + fnc.tooltip)
		} else {
			return "";
		}
	}

	adicionarCampoConteudo() {

		if (this.templateItemCustomizado) {

			if (this.templateItemCustomizadoFuncao) {
				let fnc = this.funcoesCollection.find((f: any) => (f.id == this.templateItemCustomizadoFuncao));
				this.data.conteudo = this.data.conteudo + "${" + this.templateItemCustomizado + "." + fnc.funcao + (this.formatacao ? "|" + this.formatacao : "") + "}";
			} else {
				this.data.conteudo = this.data.conteudo + "${" + this.templateItemCustomizado + (this.formatacao ? "|" + this.formatacao : "") + "}";
			}

			this.templateItemCustomizado = undefined;
			this.templateItemCustomizadoFuncao = undefined;
			this.formatacao = undefined;
			this.exibirCampoFormatacao = false;
			this.conteudoRef.nativeElement.focus();
		}
	}

	save() {
		let existe = this.listaTemplateItem.some((c: ListaTemplateItemExportacao) => c.alias.toLowerCase() == this.data.nome.toLowerCase()
														                          && c.ativo == true 
														                          && c.ordem != this.data.campoSelecionado.ordem
														                          && c.tamanho != this.data.campoSelecionado.tamanho);

		if (!existe)
			return this.ref.close(this.data);

		this.customAlertService.show("telaPadrao.validacao", "telaEstrategia.camposRepetidos", "error");
	}

	close() {
		this.ref.close();
	}

	fechar() {
		this.ref.close();
	}

	tipoCampoExportacaoChanged() {
		this.data.conteudo = '';

		if (this.data.tipoCampoExportacao == 1 || this.data.tipoCampoExportacao == 2)
			this.data.listaTemplateItemId = null;
	}	

	listaTemplateItemChanged(data: any) {
		if (data.listaTemplateItemId) {
			let ci = this.data.camposIntegracao.find((f: any) => String(f.listaTemplateItemId) == String(data.listaTemplateItemId));
			data.campoSelecionado.listaCampoTipoId = ci.listaCampoTipoId;
			data.campoSelecionado.permiteFormatacao = ci.permiteFormatacao;
			data.campoSelecionado.formatos = ci.formatos;
		}
		else {
			data.campoSelecionado.permiteFormatacao = false;
			data.campoSelecionado.listaCampoTipoId = null;
			data.conteudo = '';
		}
	}

	strTooltipExemplo(data) {
		let formato = data.campoSelecionado.formatos.find((f: any) => f.formato == data.conteudo);
		return (isNull(formato)) ? '' : 'Exemplo: ' + formato.exemplo;
	}		
}
