import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from "../../../../environments/environment";
import { convertToQueryString } from "../../common/service/query-string";
import { Fluxo, FluxoTipo } from "../model/fluxo";
//import { FluxoProcesso } from '../model/fluxo-processo';
import { FluxoQuery } from "../model/fluxo-query";
import { FluxoProcessoTipo, FluxoProcesso } from "../model/fluxo-processo";
import { Estrategia } from "../model/estrategia";
import { isNull } from "../utils/";

@Injectable()
export class FluxoService {
    constructor(private httpClient: HttpClient) { }

    public obterFluxos(filtro: any) {
        return this.httpClient.get<Array<Fluxo>>(
            `${environment.serverUrl}/api/fluxos${convertToQueryString(filtro)}`
        );
    }

    public obterPorId(id) {
        return this.httpClient.get<Fluxo>(`${environment.serverUrl}/api/fluxos/${id}`)
            .pipe(map(f => {
                let fluxo = Fluxo.fromRaw(f);
                if (!isNull(fluxo.fluxoProcesso)) {
                    fluxo.fluxoProcesso = fluxo.fluxoProcesso.map((i: FluxoProcesso) => {
                        let fp = FluxoProcesso.fromRaw(i);

                        if (!isNull(fp.estrategia))
                            fp.estrategia = Estrategia.fromRaw(fp.estrategia);

                        return fp;
                    });
                }
                return fluxo;
            }));
    }

    public criar(fluxo: Fluxo) {
        return this.httpClient.post<number>(`${environment.serverUrl}/api/fluxos/`, fluxo);
    }

    public atualizar(fluxo: Fluxo) {
        return this.httpClient.put<number>(`${environment.serverUrl}/api/fluxos/${fluxo.fluxoId}`, fluxo);
    }

    public desativarFluxo(fluxoId: number) {
        return this.httpClient.patch<any>(`${environment.serverUrl}/api/fluxos/${fluxoId}/desativar-fluxo`, null);
    }

    public obterEstrategias(fluxoId: number, listaId: number) {
        return this.httpClient.get<Array<any>>(`${environment.serverUrl}/api/fluxos/estrategias-sem-fluxo/${fluxoId}/${listaId}`);
    }

    public obterFluxosTipos() {
        const tipos = of<Array<any>>([
            { 'fluxoTipoId': FluxoTipo.processo, 'nome': 'Fluxo de Processo' },
            { 'fluxoTipoId': FluxoTipo.cascata, 'nome': 'Fluxo de Cascata' },
            { 'fluxoTipoId': FluxoTipo.circular, 'nome': 'Fluxo Circular' }
        ]);
        return tipos;
    }

    public obterFluxosProcessosTipos(fluxoTipoId: FluxoTipo) {

        let tipos: Observable<Array<any>>;

        if (fluxoTipoId == FluxoTipo.cascata) {
            tipos = of<Array<any>>([
                //{ 'tipoId': FluxoProcessoTipo.filtro, 'nome': 'Nó de filtro' },
                { 'tipoId': FluxoProcessoTipo.criacaoEstrategia, 'nome': 'Nó de estratégia' },
                { 'tipoId': FluxoProcessoTipo.informativoEstrategia, 'nome': 'Nó sem estratégia' }
            ]);
        } else if (fluxoTipoId == FluxoTipo.circular) {
            tipos = of<Array<any>>([
                { 'tipoId': FluxoProcessoTipo.decisao, 'nome': 'Nó de decisão' },
                { 'tipoId': FluxoProcessoTipo.selecaoEstrategia, 'nome': 'Nó de estratégia' },
                { 'tipoId': FluxoProcessoTipo.quantidade, 'nome': 'Nó de quantidade' }
            ]);
        } else {
            tipos = of<Array<any>>([
                { 'tipoId': FluxoProcessoTipo.decisao, 'nome': 'Nó de decisão' },
                { 'tipoId': FluxoProcessoTipo.selecaoEstrategia, 'nome': 'Nó de estratégia' },
                { 'tipoId': FluxoProcessoTipo.quantidade, 'nome': 'Nó de quantidade' },
                { 'tipoId': FluxoProcessoTipo.informativo, 'nome': 'Nó Informativo' }
            ]);
        }

        return tipos;
    }

    //#region [ Querys ]

    public obterQueryQuantidadeRegistros(listaId: number, query: string) {
        return this.httpClient.get<number>(`${environment.serverUrl}/api/fluxos/query-registros/${listaId}?query=${query}`);
    }

    public obterQueryQuantidadeRegistrosPost(listaId: number, query: string) {
        return this.httpClient.post<number>(`${environment.serverUrl}/api/fluxos/query-registros/`, { 'listaId': listaId, 'query': query });
    }

    public obterQueryQuantidadeRegistrosEstrategiasFiltros(listaId: number, filtroGuid: string, query: string) {
        return this.httpClient.post<number>(`${environment.serverUrl}/api/fluxos/query-registros/`, { 'listaId': listaId, 'filtroGuid': filtroGuid, 'query': query });
    }

    public obterFluxosQuerys(filtro: any) {
        return this.httpClient.get<Array<FluxoQuery>>(`${environment.serverUrl}/api/fluxos/query/${convertToQueryString(filtro)}`);
    }

    public obterQueryPorId(id) {
        return this.httpClient.get<FluxoQuery>(`${environment.serverUrl}/api/fluxos/query/${id}`)
            .pipe(map(query => FluxoQuery.fromRaw(query)));
    }

    public criarQuery(query: FluxoQuery) {
        return this.httpClient.post<number>(`${environment.serverUrl}/api/fluxos/query/`, query);
    }

    public atualizarQuery(query: FluxoQuery) {
        return this.httpClient.put<number>(`${environment.serverUrl}/api/fluxos/query/${query.fluxoQueryId}`, query);
    }

    //#endregion 	
}
