const validate = require("validate.js");

export class CarteiraUsuario {

    carteiraUsuarioId: number;
    carteiraId: number;
    usuarioId: number;
    dataCadastro: Date;
    ativo: boolean;
    
    nome: string;

    constructor() {

    }

    validar() {
        let validacao: any = {
            carteiraId: { presence: { allowEmpty: false, message: '^campoObrigatorio' } },
            usuarioId: { presence: { allowEmpty: false, message: '^campoObrigatorio' } }
        };

        return validate.validate(this, validacao);
    }

    static fromRaw(raw: any): CarteiraUsuario {
        return Object.assign(new CarteiraUsuario(), raw);
    }
}