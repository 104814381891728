import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'filterList',
    pure: false
})
export class FilterListPipe implements PipeTransform {
    transform(value: any[], args?: any): any {

        if (!value || !args) {
            return value;
        }
        // filter items array, items which match and return true will be kept, false will be filtered out
        return value.filter((item: any) => this.applyFilter(item, args));
    }

    applyFilter(value: any[], filter: any): boolean {
        for (let field in filter) {
            if (filter[field]) {
                if (typeof filter[field] === 'string') {
                    if (value[field].toLowerCase().indexOf(filter[field].toLowerCase()) === -1)
                        return false;

                } else if (typeof filter[field] === 'number') {
                    if (value[field] !== filter[field])
                        return false;

                } else if (typeof filter[field] === 'boolean') {
                    if (value[field] !== filter[field])
                        return false;

                }
            }
        }
        return true;
    }
}