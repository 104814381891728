import { Component, ViewChild, OnInit } from "@angular/core";
import { MatDialog } from '@angular/material';
import { EstrategiaService } from "../../common/service/estrategia.service";
import { CustomAlertService } from "../../common/service/custom-alert.service";
import { PreviewDadosEstrategiaComponent } from "../../estrategia/form-estrategia/preview-dados-estrategia/preview-dados-estrategia.component";

import { Table } from 'primeng/table';
import { Lista } from "../../common/model/lista";
import { FiltroEstrategiaRemocao } from "../model/filtro-estrategia-remocao";
import { ListaService } from "../../common/service/lista.service";
import { FiltroLista } from "../../common/model/filtro-lista";
import * as validate from "validate.js";

import { CarteiraService } from "../../common/service/carteira.service";
import { Carteira } from "../../common/model/carteira";

@Component({
    selector: "app-lista-estrategia-remocao",
    templateUrl: "./lista-estrategia-remocao.component.html",
    providers: [
        { useClass: CarteiraService, provide: CarteiraService },
        { useClass: ListaService, provide: ListaService },
        { useClass: EstrategiaService, provide: EstrategiaService },
        { useClass: CustomAlertService, provide: CustomAlertService }
    ]
})
export class ListaEstrategiaRemocaoComponent implements OnInit {
    filtro: FiltroEstrategiaRemocao;
    listas: Lista[];
    carteiras: Array<Carteira> = [];
    listaEstrategias: Array<any>;
    estrategias: Array<any> = [];
    dirty: boolean;

    public get disabledCarteira(): boolean {
        return (this.carteiras.length == 1);
    }

    @ViewChild(Table, { static: false }) dataTable: Table;
    constructor(
        private carteiraService: CarteiraService,
        private listaService: ListaService,
        private estrategiaService: EstrategiaService,
        private customAlertService: CustomAlertService,
        public dialog: MatDialog
    ) {
        this.filtro = new FiltroEstrategiaRemocao();

        this.carteiraService.obterCarteiras().subscribe(carteiras => {
                this.carteiras = carteiras;

                let padrao = this.carteiras.find(f => f.padrao);
				this.filtro.carteiraId = padrao.carteiraId;

                this.obterListas();
                this.obterEstrategias();
            });


    }

    ngOnInit() {
        //this.obterListas();
        //this.obterEstrategias();
        this.filtrarEstrategias();
    }

    public get erros() {
        if (!this.dirty) return;
        return this.validar();
    }

    carteiraChange() {
		this.obterListas();
	}

    listaChange(){
        this.obterEstrategias();
    }

    validar() {
        var validacao: any = {

            dataInicio: {
                presence: {
                    allowEmpty: false,
                    message: "campoObrigatorio"
                },
                dataMenorQue: {
                    campo: "dataFim"
                }
            },
            dataFim: {
                presence: {
                    allowEmpty: false,
                    message: "campoObrigatorio"
                }
            }
        };
        return validate(this, validacao, { fullMessages: false })
    }

    obterListas() {
        let filtroLista = new FiltroLista();
        filtroLista.ignorarErroPesquisa = true;
        filtroLista.ignorarIndisponivel = true;
        filtroLista.carteiraId = this.filtro.carteiraId;

        this.listaService
            .obterLista(filtroLista)
            .subscribe(lista => (this.listas = lista));
    }

    obterEstrategias() {
        let filtroEstrategia = new FiltroEstrategiaRemocao();
        filtroEstrategia.carteiraId = this.filtro.carteiraId;
        filtroEstrategia.listaId = this.filtro.listaId;
        
        this.estrategiaService
            .obterExecucoesTrackingEstrategias(filtroEstrategia)
            .subscribe(data => (this.listaEstrategias = data));
    }

    limpar() {
        this.filtro = new FiltroEstrategiaRemocao();
        let padrao = this.carteiras.find(f => f.padrao);
        this.filtro.carteiraId = padrao.carteiraId;
    }

    keyDownFunction($event: any) {
        this.filtrarEstrategias();
    }

    filtrarEstrategias() {

        this.estrategiaService
            .obterExecucoesTrackingEstrategias(this.filtro)
            .subscribe(data => {
                this.estrategias = data;
                this.setCurrentPage(0);
            });
    }

    getTotalEncotrado() {
        let total = 0;

        this.estrategias.forEach(f => {
            total = total + f.quantidadeRegistros;
        });
        return total;
    }

    mostrarPreview(estrategia: any) {
        this.dialog.open(PreviewDadosEstrategiaComponent, {
            height: "700px",
            width: "80%",
            hasBackdrop: true,
            data: {
                templateId: estrategia.listaTemplateImportacaoId,
                lista: estrategia.listaNome,
                listaId: estrategia.listaId,
                query: JSON.parse(estrategia.filtro)
            }
        });
    }

    baixarArquivo(estrategia: any) {
        if (estrategia.arquivoNome)
            this.estrategiaService.baixarHistorico(estrategia.listaEstrategiaId, estrategia.listaEstrategiaTrackingId, estrategia.estrategiaNome);
        else
            this.customAlertService.show("telaEstrategia.download", "telaEstrategia.expurgoArquivo", "info");
    }

    setCurrentPage(n: number) {
        if (this.dataTable)
            this.dataTable.first = n;
    }
}
