import { Component, ChangeDetectorRef } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "loading",
  template: `
    <h1 mat-dialog-title>{{ title }}</h1>
    <div mat-dialog-content>{{ text }}</div>
    <div mat-dialog-actions style='justify-content: center;'>
       <mat-progress-spinner style="height: 15px; width: 20px; margin:0 auto;" mode="indeterminate"></mat-progress-spinner>
    </div>`
})
export class LoadingComponent { 
    
    public text: string;
    public title: string;

    constructor(private translate: TranslateService, private cd: ChangeDetectorRef) {
        this.title = this.translate.instant('telaPadrao.carregando');
        this.text = this.translate.instant('telaPadrao.carregandoTitulo');
    }

    ngAfterViewInit() {
        
        this.cd.detectChanges();
    }
}