import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "simNaoPipe"
})
export class SimNaoPipePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return value ? "telaPadrao.sim" : "telaPadrao.nao";
  }
}
