import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { AutenticacaoService } from "../../../common/service/autenticacao.service";
import { ResponseContentType, Http, Headers } from "@angular/http";
import { environment } from "../../../../../environments/environment";
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ESBuilderData } from "../../models";


@Injectable()
export class EsBuilderTestService {
    constructor(private httpClient: HttpClient) { }

    //#region [ DataRules ]

    dataRuleCondicao02() {

        // QUERY:
        // {
        // 	"size":100,
        // 	"_source":{ "includes":[ ] },
        // 	"query":{
        // 		"bool":{
        // 			"must":[
        // 				{ "range":{ "inteiro":{ "gte":"70" } } }
        // 			],
        // 			"must_not":[
        // 				{ "exists":{ "field":"_integrado" } },
        // 				{ "bool": {
        // 					"should":[
        // 						{ "term":{ "texto.keyword":{ "value":"A" } } },
        // 						{ "term":{ "texto.keyword":{ "value":"B" } } }
        // 					]
        // 				}  }				
        // 			]
        // 		}
        // 	},
        // 	"sort": [ { "inteiro": { "order": "asc" } } ]
        // }	
        // --------------------------------------------
        // --- inteiro maior igual 70
        // AND 
        // ( 
        // 	--- texto não seja "A"
        // 	OR  texto não seja "B"
        // )

        const dataRule = of<ESBuilderData>(ESBuilderData.fromRaw({
            "type": "bool",
            "rules": [
                {
                    "condition": "---",
                    "field": "Inteiro",
                    "fieldType": "Inteiro",
                    "filterType": "maior_ou_igual",
                    "filterValues": { "gte": "70" },
                    "order": 0,
                    "filterParameters": [{ "name": "gte", "alias": "Maior ou igual" }]
                },
                {
                    "condition": "and",
                    "field": "",
                    "fieldType": "",
                    "filterType": "",
                    "filterValues": {},
                    "order": 1,
                    "filterParameters": [],
                    "rules": [
                        {
                            "condition": "---",
                            "field": "Texto",
                            "fieldType": "Texto",
                            "filterType": "nao_contem",
                            "filterValues": { "query": "A" },
                            "order": 0,
                            "filterParameters": [{ name: "query", alias: "Não contém", negation: true }]
                        },
                        {
                            "condition": "or",
                            "field": "Texto",
                            "fieldType": "Texto",
                            "filterType": "nao_contem",
                            "filterValues": { "query": "B" },
                            "order": 1,
                            "filterParameters": [{ name: "query", alias: "Não contém", negation: true }]
                        }
                    ]
                }
            ]
        }));

        return dataRule;
    }

    dataRuleCondicao03() {

        // QUERY:                                                                                                 	
        // {
        // 	"size":100,
        // 	"_source":{ "includes":[ ] },
        // 	"query":{
        // 		"bool":{
        // 			"should":[
        // 				{
        // 					"bool":{
        // 						"must":[ { "range":{ "inteiro":{ "gt":"80" } } } ]
        // 				}   },
        // 				{
        // 					"bool":{
        // 						"must":[ { "term":{ "texto.keyword":{ "value":"A" } } } ]
        // 				}   },
        // 			],
        // 			"must_not":[
        // 				{ "exists":{ "field":"_integrado" } }
        // 			]
        // 		}
        // 	},
        // 	"sort": [ { "inteiro": { "order": "asc" } } ]
        // }
        // QUERY 02 - Mais Simples
        // {
        // 	"size":100,
        // 	"_source":{ "includes":[ ] },
        // 	"query":{
        // 		"bool":{
        // 			"should":[
        // 				{ "range":{ "inteiro":{ "gt":"80" } } },
        // 				{ "term":{ "texto.keyword":{ "value":"A" } } }
        // 			],
        // 			"must_not":[
        // 				{ "exists":{ "field":"_integrado" } }
        // 			]
        // 		}
        // 	},
        // 	"sort": [ { "inteiro": { "order": "asc" } } ]
        // }
        // --------------------------------------------
        // --- inteiro maior 80
        // OR  texto não seja "A"


        const dataRule = of<ESBuilderData>(ESBuilderData.fromRaw({
            "type": "bool",
            "rules": [
                {
                    "condition": "---",
                    "field": "Inteiro",
                    "fieldType": "Inteiro",
                    "filterType": "maior_que",
                    "filterValues": { "gt": "80" },
                    "order": 0,
                    "filterParameters": [{ "name": "gt", "alias": "Maior que" }]
                },
                {
                    "condition": "or",
                    "field": "Texto",
                    "fieldType": "Texto",
                    "filterType": "nao_contem",
                    "filterValues": { "query": "A" },
                    "order": 1,
                    "filterParameters": [{ name: "query", alias: "Não contém", negation: true }]
                }
            ]
        }));

        return dataRule;
    }

    dataRuleCondicao04() {

        // QUERY
        // {
        // 	"size":100,
        // 	"_source":{ "includes":[ ] },
        // 	"query":{
        // 		"bool":{
        // 			"should":[
        // 				{
        // 					"bool":{
        // 						"must":[                                                                                   	
        // 							{ "range":{ "inteiro":{ "lt":"70" } } },
        // 							{ "range":{ "inteiro":{ "gte":"30" } } }
        // 						]
        // 				}	},
        // 				{
        // 					"bool":{
        // 						"must":[
        // 								{ "term":{ "texto.keyword":{ "value":"A" } } }
        // 						]
        // 				}	}			
        // 			],
        // 			"must_not":[
        // 				{ "term":{ "texto.keyword":{ "value":"C" } } },
        // 				{ "exists":{ "field":"_integrado" } }
        // 			]                                                                                          	
        // 		}
        // 	},
        // 	"sort": [ { "inteiro": { "order": "asc" } } ]
        // }
        // QUERY 02 - Mais Simples
        // {
        // 	"size":100,
        // 	"_source":{ "includes":[ ] },
        // 	"query":{
        // 		"bool":{
        // 			"should":[
        // 				{
        // 					"bool":{
        // 						"must":[                                                                                   	
        // 							{ "range":{ "inteiro":{ "lt":"70" } } },
        // 							{ "range":{ "inteiro":{ "gte":"30" } } }
        // 						]
        // 				}	},
        // 				{ "term":{ "texto.keyword":{ "value":"A" } } }			
        // 			],
        // 			"must_not":[
        // 				{ "term":{ "texto.keyword":{ "value":"C" } } },
        // 				{ "exists":{ "field":"_integrado" } }
        // 			]                                                                                          	
        // 		}
        // 	},
        // 	"sort": [ { "inteiro": { "order": "asc" } } ]
        // }
        // --------------------------------------------
        // --- (
        // 	--- inteiro menor 70
        // 	AND inteiro maior igual 30
        // 	OR  texto seja "A"
        // )
        // AND texto não seja "C"

        const dataRule = of<ESBuilderData>(ESBuilderData.fromRaw({
            "type": "bool",
            "rules": [
                {
                    "condition": "---",
                    "field": "",
                    "fieldType": "",
                    "filterType": "",
                    "filterValues": {},
                    "order": 0,
                    "rules": [
                        {
                            "condition": "and",
                            "field": "Inteiro",
                            "fieldType": "Inteiro",
                            "filterType": "menor_que",
                            "filterValues": { "lt": "70" },
                            "order": 0,
                            "filterParameters": [{ "name": "lt", "alias": "Menor que" }]
                        },
                        {
                            "condition": "and",
                            "field": "Inteiro",
                            "fieldType": "Inteiro",
                            "filterType": "maior_ou_igual",
                            "filterValues": { "gte": "30" },
                            "order": 1,
                            "filterParameters": [{ "name": "gte", "alias": "Maior ou igual" }]
                        },
                        {
                            "condition": "or",
                            "field": "Texto",
                            "fieldType": "Texto",
                            "filterType": "contem",
                            "filterValues": { "query": "A" },
                            "order": 2,
                            "filterParameters": [{ "name": "query", "alias": "Contém" }]
                        }
                    ]
                },
                {
                    "condition": "and",
                    "field": "Texto",
                    "fieldType": "Texto",
                    "filterType": "nao_contem",
                    "filterValues": { "query": "C" },
                    "order": 1,
                    "filterParameters": [{ "name": "query", "alias": "Não contém", "negation": true }]
                }
            ]
        }));

        return dataRule;
    }

    dataRuleCondicao05() {

        // QUERY:
        // {
        //    "size":100,
        //    "_source":{ "includes":[ ] },
        //    "query":{
        //       "bool":{
        //          "must":[
        //             { "range":{ "inteiro":{ "gte":"0" } } },
        //             { "range":{ "decimal":{ "lte":"10" } } },
        //             { "bool":{
        //                   "should":[
        //                      { "term":{ "texto.keyword":{ "value":"A" } } },
        //                      { "term":{ "texto.keyword":{ "value":"B" } } }
        //                   ]
        //             }  }
        //          ],
        //          "must_not":[
        //             { "exists":{ "field":"_integrado" } }
        //          ]
        //       }
        //    },
        //    "sort":[ { "inteiro":{ "order":"desc" } } ]
        // }
        // --------------------------------------------
        // {
        //    "size": 100,
        //    "_source":{ "includes":[ ] },
        //    "query":{
        //       "bool":{
        //          "must":[
        //             { "range":{ "inteiro": { "gte": "0" } } },
        //             { "range":{ "decimal": { "lte": "10" } } },
        //             { "bool":{
        //                  "should":[
        //                     {
        //                       "bool": { // <= boolShould
        //                           "must":[ { "term":{ "texto.keyword":{ "value": "A" } } } ]
        //                       }
        //                     },
        //                     {
        //                       "bool": {
        //                           "must":[ { "term":{ "texto.keyword":{ "value": "B" } } } ]
        //                       }
        //                     }
        //                  ]
        //             } }
        //          ],
        //          "must_not":[
        //             { "exists":{ "field":"_integrado" } }
        //          ]
        //       }
        //    },
        //    "sort":[ { "inteiro":{ "order":"desc" } } ]
        // }
        //-----------------------------------------

        // --- inteiro maior igual 0
        // and decimal menor igual 10
        // and 
        // ( 
        //     --- texto igual "A"
        //     or  texto igual "B"
        // )

        const dataRule = of<ESBuilderData>(ESBuilderData.fromRaw({
            "type": "bool",
            "rules": [
                {
                    "condition": "---",
                    "field": "Inteiro",
                    "fieldType": "Inteiro",
                    "filterType": "maior_ou_igual",
                    "filterValues": { "gte": "0" },
                    "order": 0,
                    "filterParameters": [{ "name": "gte", "alias": "Maior ou igual" }]
                },
                {
                    "condition": "and",
                    "field": "Inteiro",
                    "fieldType": "Inteiro",
                    "filterType": "menor_ou_igual",
                    "filterValues": { "lte": "10" },
                    "order": 1,
                    "filterParameters": [{ "name": "lte", "alias": "Menor ou igual" }]
                },
                {
                    "condition": "and",
                    "field": "",
                    "fieldType": "",
                    "filterType": "",
                    "filterValues": {},
                    "order": 2,
                    "rules": [
                        {
                            "condition": "---",
                            "field": "Texto",
                            "fieldType": "Texto",
                            "filterType": "contem",
                            "filterValues": { "query": "A" },
                            "order": 0,
                            "filterParameters": [{ "name": "query", "alias": "Contém" }]
                        },
                        {
                            "condition": "or",
                            "field": "Texto",
                            "fieldType": "Texto",
                            "filterType": "nao_contem",
                            "filterValues": { "query": "B" },
                            "order": 1,
                            "filterParameters": [{ name: "query", alias: "Não contém", negation: true }]
                        }
                    ]
                }
            ]
        }));

        return dataRule;
    }

    dataRuleCondicao06() {
        const dataRule = of<ESBuilderData>(ESBuilderData.fromRaw({
            "type": "bool",
            "rules": [
                {
                    "condition": "---",
                    "field": "Inteiro",
                    "fieldType": "Inteiro",
                    "filterType": "maior_ou_igual",
                    "filterValues": { "gte": "30" },
                    "order": 0,
                    "filterParameters": [{ "name": "gte", "alias": "Maior ou igual" }]
                },
                {
                    "condition": "and",
                    "field": "Inteiro",
                    "fieldType": "Inteiro",
                    "filterType": "menor_ou_igual",
                    "filterValues": { "lte": "70" },
                    "order": 1,
                    "filterParameters": [{ "name": "lte", "alias": "Menor ou igual" }]
                },
                {
                    "condition": "or",
                    "field": "Texto",
                    "fieldType": "Texto",
                    "filterType": "contem",
                    "filterValues": { "query": "A" },
                    "order": 2,
                    "filterParameters": [{ "name": "query", "alias": "Contém" }]
                },
                {
                    "condition": "or",
                    "field": "Texto",
                    "fieldType": "Texto",
                    "filterType": "contem",
                    "filterValues": { "query": "C" },
                    "order": 3,
                    "filterParameters": [{ "name": "query", "alias": "Contém" }]
                }
            ]
        }));

        return dataRule;
    }

    dataRuleCondicao07() {

        // QUERY:                                                                                                 	
        // {
        //     "size":100,
        //     "_source":{ "includes":[ ] },
        //     "query":{
        //         "bool":{
        //             "should":[
        //                 { "bool":{
        //                     "must":[
        //                         { "range":{ "inteiro":{ "gte":"70" } } },
        //                         { "range":{ "inteiro":{ "lte":"85" } } }
        //                     ]
        //                 }	},
        //                 { "bool":{
        //                     "must":[
        //                         { "term":{ "texto.keyword":{ "value":"A" } } },
        //                         { "match":{ "inteiro":{ "query":"1" } } }
        //                     ]
        //                 }	},
        //                 { "bool":{
        //                     "must":[
        //                         { "term":{ "texto.keyword":{ "value":"B" } } },
        //                         { "match":{ "inteiro":{ "query":"2" } } }
        //                     ]
        //                 }	}				
        //             ],
        //             "must_not":[
        //                 { "exists":{ "field":"_integrado" } }
        //             ]

        //         }
        //     },
        //     "sort": [ { "inteiro": { "order": "asc" } } ]
        // }
        // --------------------------------------------
        // --- inteiro maior igual 70
        // AND inteiro menor igual 85
        // OR 
        // ( 
        // 	--- texto seja "A"
        // 	AND inteiro igual 1
        // )
        // OR 
        // ( 
        // 	--- texto seja "B"
        // 	AND inteiro igual 2
        // )

        const dataRule = of<ESBuilderData>(ESBuilderData.fromRaw({
            "type": "bool",
            "rules": [
                {
                    "condition": "---",
                    "field": "Inteiro",
                    "fieldType": "Inteiro",
                    "filterType": "maior_ou_igual",
                    "filterValues": { "gte": "70" },
                    "order": 0,
                    "filterParameters": [{ "name": "gte", "alias": "Maior ou igual" }]
                },
                {
                    "condition": "and",
                    "field": "Inteiro",
                    "fieldType": "Inteiro",
                    "filterType": "menor_ou_igual",
                    "filterValues": { "lte": "85" },
                    "order": 1,
                    "filterParameters": [{ "name": "lte", "alias": "Menor ou igual" }]
                },
                {
                    "condition": "or",
                    "field": "",
                    "fieldType": "",
                    "filterType": "",
                    "filterValues": {},
                    "order": 2,
                    "filterParameters": [],
                    "rules": [
                        {
                            "condition": "---",
                            "field": "Texto",
                            "fieldType": "Texto",
                            "filterType": "contem",
                            "filterValues": { "query": "A" },
                            "order": 0,
                            "filterParameters": [{ "name": "query", "alias": "Contém" }]
                        },
                        {
                            "condition": "and",
                            "field": "Inteiro",
                            "fieldType": "Inteiro",
                            "filterType": "igual",
                            "filterValues": { "query": "1" },
                            "order": 1,
                            "filterParameters": [{ "name": "query", "alias": "Igual a" }]
                        }
                    ]
                },
                {
                    "condition": "or",
                    "field": "",
                    "fieldType": "",
                    "filterType": "",
                    "filterValues": {},
                    "order": 3,
                    "filterParameters": [],
                    "rules": [
                        {
                            "condition": "---",
                            "field": "Texto",
                            "fieldType": "Texto",
                            "filterType": "contem",
                            "filterValues": { "query": "B" },
                            "order": 0,
                            "filterParameters": [{ "name": "query", "alias": "Contém" }]
                        },
                        {
                            "condition": "and",
                            "field": "Inteiro",
                            "fieldType": "Inteiro",
                            "filterType": "igual",
                            "filterValues": { "query": "2" },
                            "order": 1,
                            "filterParameters": [{ "name": "query", "alias": "Igual a" }]
                        }
                    ]
                }
            ]
        }));

        return dataRule;
    }

    //#endregion

    //#region [ Querys ]

    queryCondicao02() {
        // {
        //     "type": "bool",
        //     "rules": [
        //         {
        //             "condition": "---",
        //             "field": "Inteiro",
        //             "fieldType": "Inteiro",
        //             "filterType": "maior_ou_igual",
        //             "filterValues": { "gte": "70" },
        //             "order": 0,
        //             "filterParameters": [{ "name": "gte", "alias": "Maior ou igual" }]
        //         },
        //         {
        //             "condition": "and",
        //             "field": "",
        //             "fieldType": "",
        //             "filterType": "",
        //             "filterValues": {},
        //             "order": 1,
        //             "filterParameters": [],
        //             "rules": [
        //                 {
        //                     "condition": "---",
        //                     "field": "Texto",
        //                     "fieldType": "Texto",
        //                     "filterType": "nao_contem",
        //                     "filterValues": { "query": "A" },
        //                     "order": 0,
        //                     "filterParameters": [{ name: "query", alias: "Não contém", negation: true }]
        //                 },
        //                 {
        //                     "condition": "or",
        //                     "field": "Texto",
        //                     "fieldType": "Texto",
        //                     "filterType": "nao_contem",
        //                     "filterValues": { "query": "B" },
        //                     "order": 1,
        //                     "filterParameters": [{ name: "query", alias: "Não contém", negation: true }]
        //                 }
        //             ]
        //         }
        //     ]
        // }
        // --------------------------------------------
        // --- inteiro maior igual 70
        // AND 
        // ( 
        // 	--- texto não seja "A"
        // 	OR  texto não seja "B"
        // )

        const query = of<string>('');

        return query;
    }

    queryCondicao03() {

        // {
        //     "type": "bool",
        //     "rules": [
        //         {
        //             "condition": "---",
        //             "field": "Inteiro",
        //             "fieldType": "Inteiro",
        //             "filterType": "maior_que",
        //             "filterValues": { "gt": "80" },
        //             "order": 0,
        //             "filterParameters": [{ "name": "gt", "alias": "Maior que" }]
        //         },
        //         {
        //             "condition": "or",
        //             "field": "Texto",
        //             "fieldType": "Texto",
        //             "filterType": "nao_contem",
        //             "filterValues": { "query": "A" },
        //             "order": 1,
        //             "filterParameters": [{ name: "query", alias: "Não contém", negation: true }]
        //         }
        //     ]
        // }
        // --------------------------------------------
        // --- inteiro maior 80
        // OR  texto não seja "A"


        const query = of<string>('');

        return query;
    }

    queryCondicao04() {

        // {
        //     "type": "bool",
        //     "rules": [
        //         {
        //             "condition": "---",
        //             "field": "",
        //             "fieldType": "",
        //             "filterType": "",
        //             "filterValues": {},
        //             "order": 0,
        //             "rules": [
        //                 {
        //                     "condition": "and",
        //                     "field": "Inteiro",
        //                     "fieldType": "Inteiro",
        //                     "filterType": "menor_que",
        //                     "filterValues": { "lt": "70" },
        //                     "order": 0,
        //                     "filterParameters": [{ "name": "lt", "alias": "Menor que" }]
        //                 },
        //                 {
        //                     "condition": "and",
        //                     "field": "Inteiro",
        //                     "fieldType": "Inteiro",
        //                     "filterType": "maior_ou_igual",
        //                     "filterValues": { "gte": "30" },
        //                     "order": 1,
        //                     "filterParameters": [{ "name": "gte", "alias": "Maior ou igual" }]
        //                 },
        //                 {
        //                     "condition": "or",
        //                     "field": "Texto",
        //                     "fieldType": "Texto",
        //                     "filterType": "contem",
        //                     "filterValues": { "query": "A" },
        //                     "order": 2,
        //                     "filterParameters": [{ "name": "query", "alias": "Contém" }]
        //                 }
        //             ]
        //         },
        //         {
        //             "condition": "and",
        //             "field": "Texto",
        //             "fieldType": "Texto",
        //             "filterType": "nao_contem",
        //             "filterValues": { "query": "C" },
        //             "order": 1,
        //             "filterParameters": [{ "name": "query", "alias": "Não contém", "negation": true }]
        //         }
        //     ]
        // }
        // --------------------------------------------
        // --- (
        // 	--- inteiro menor 70
        // 	AND inteiro maior igual 30
        // 	OR  texto seja "A"
        // )
        // AND texto não seja "C"

        const query = of<string>('');
        return query;
    }

    queryCondicao05() {

        // {
        //     "type": "bool",
        //     "rules": [
        //         {
        //             "condition": "---",
        //             "field": "Inteiro",
        //             "fieldType": "Inteiro",
        //             "filterType": "maior_ou_igual",
        //             "filterValues": { "gte": "0" },
        //             "order": 0,
        //             "filterParameters": [{ "name": "gte", "alias": "Maior ou igual" }]
        //         },
        //         {
        //             "condition": "and",
        //             "field": "Inteiro",
        //             "fieldType": "Inteiro",
        //             "filterType": "menor_ou_igual",
        //             "filterValues": { "lte": "10" },
        //             "order": 1,
        //             "filterParameters": [{ "name": "lte", "alias": "Menor ou igual" }]
        //         },
        //         {
        //             "condition": "and",
        //             "field": "",
        //             "fieldType": "",
        //             "filterType": "",
        //             "filterValues": {},
        //             "order": 2,
        //             "rules": [
        //                 {
        //                     "condition": "---",
        //                     "field": "Texto",
        //                     "fieldType": "Texto",
        //                     "filterType": "contem",
        //                     "filterValues": { "query": "A" },
        //                     "order": 0,
        //                     "filterParameters": [{ "name": "query", "alias": "Contém" }]
        //                 },
        //                 {
        //                     "condition": "or",
        //                     "field": "Texto",
        //                     "fieldType": "Texto",
        //                     "filterType": "nao_contem",
        //                     "filterValues": { "query": "B" },
        //                     "order": 1,
        //                     "filterParameters": [{ name: "query", alias: "Não contém", negation: true }]
        //                 }
        //             ]
        //         }
        //     ]
        // }
        //-----------------------------------------
        // --- inteiro maior igual 0
        // and decimal menor igual 10
        // and 
        // ( 
        //     --- texto igual "A"
        //     or  texto igual "B"
        // )

        const query = of<string>('');
        return query;
    }

    queryCondicao06() {

        // {
        //     "type": "bool",
        //     "rules": [
        //         {
        //             "condition": "---",
        //             "field": "Inteiro",
        //             "fieldType": "Inteiro",
        //             "filterType": "maior_ou_igual",
        //             "filterValues": { "gte": "30" },
        //             "order": 0,
        //             "filterParameters": [{ "name": "gte", "alias": "Maior ou igual" }]
        //         },
        //         {
        //             "condition": "and",
        //             "field": "Inteiro",
        //             "fieldType": "Inteiro",
        //             "filterType": "menor_ou_igual",
        //             "filterValues": { "lte": "70" },
        //             "order": 1,
        //             "filterParameters": [{ "name": "lte", "alias": "Menor ou igual" }]
        //         },
        //         {
        //             "condition": "or",
        //             "field": "Texto",
        //             "fieldType": "Texto",
        //             "filterType": "contem",
        //             "filterValues": { "query": "A" },
        //             "order": 2,
        //             "filterParameters": [{ "name": "query", "alias": "Contém" }]
        //         },
        //         {
        //             "condition": "or",
        //             "field": "Texto",
        //             "fieldType": "Texto",
        //             "filterType": "contem",
        //             "filterValues": { "query": "C" },
        //             "order": 3,
        //             "filterParameters": [{ "name": "query", "alias": "Contém" }]
        //         }
        //     ]
        // }

        const query = of<string>('');
        return query;
    }

    queryCondicao07() {

        // {
        //     "type": "bool",
        //     "rules": [
        //         {
        //             "condition": "---",
        //             "field": "Inteiro",
        //             "fieldType": "Inteiro",
        //             "filterType": "maior_ou_igual",
        //             "filterValues": { "gte": "70" },
        //             "order": 0,
        //             "filterParameters": [{ "name": "gte", "alias": "Maior ou igual" }]
        //         },
        //         {
        //             "condition": "and",
        //             "field": "Inteiro",
        //             "fieldType": "Inteiro",
        //             "filterType": "menor_ou_igual",
        //             "filterValues": { "lte": "85" },
        //             "order": 1,
        //             "filterParameters": [{ "name": "lte", "alias": "Menor ou igual" }]
        //         },
        //         {
        //             "condition": "or",
        //             "field": "",
        //             "fieldType": "",
        //             "filterType": "",
        //             "filterValues": {},
        //             "order": 2,
        //             "filterParameters": [],
        //             "rules": [
        //                 {
        //                     "condition": "---",
        //                     "field": "Texto",
        //                     "fieldType": "Texto",
        //                     "filterType": "contem",
        //                     "filterValues": { "query": "A" },
        //                     "order": 0,
        //                     "filterParameters": [{ "name": "query", "alias": "Contém" }]
        //                 },
        //                 {
        //                     "condition": "and",
        //                     "field": "Inteiro",
        //                     "fieldType": "Inteiro",
        //                     "filterType": "igual",
        //                     "filterValues": { "query": "1" },
        //                     "order": 1,
        //                     "filterParameters": [{ "name": "query", "alias": "Igual a" }]
        //                 }
        //             ]
        //         },
        //         {
        //             "condition": "or",
        //             "field": "",
        //             "fieldType": "",
        //             "filterType": "",
        //             "filterValues": {},
        //             "order": 3,
        //             "filterParameters": [],
        //             "rules": [
        //                 {
        //                     "condition": "---",
        //                     "field": "Texto",
        //                     "fieldType": "Texto",
        //                     "filterType": "contem",
        //                     "filterValues": { "query": "B" },
        //                     "order": 0,
        //                     "filterParameters": [{ "name": "query", "alias": "Contém" }]
        //                 },
        //                 {
        //                     "condition": "and",
        //                     "field": "Inteiro",
        //                     "fieldType": "Inteiro",
        //                     "filterType": "igual",
        //                     "filterValues": { "query": "2" },
        //                     "order": 1,
        //                     "filterParameters": [{ "name": "query", "alias": "Igual a" }]
        //                 }
        //             ]
        //         }
        //     ]
        // }
        // --------------------------------------------
        // --- inteiro maior igual 70
        // AND inteiro menor igual 85
        // OR 
        // ( 
        // 	--- texto seja "A"
        // 	AND inteiro igual 1
        // )
        // OR 
        // ( 
        // 	--- texto seja "B"
        // 	AND inteiro igual 2
        // )

        const query = of<string>('{"size":5,"_source":{"includes":[]},"query":{"bool":{"should":[{"bool":{"must":[{"range":{"inteiro":{"gte":"70"}}},{"range":{"inteiro":{"lte":"85"}}}]}},{"bool":{"must":[{"query_string":{"default_field":"texto","query":"*A*"}},{"match":{"inteiro":{"query":"1"}}}]}},{"bool":{"must":[{"query_string":{"default_field":"texto","query":"*B*"}},{"match":{"inteiro":{"query":"2"}}}]}}]}}}');
        return query;
    }

    //#endregion

}
