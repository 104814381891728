import { Component, Output, EventEmitter } from "@angular/core";
import { DefaultValuesService } from "../../../common/service/default-values.service";
import { CarteiraService } from "../../../common/service/carteira.service";
import { Carteira } from "../../../common/model/carteira";
import { PreferenciasUsuarioService } from "../../../common/service/preferencias-usuario.service";
import { PreferenciasUsuario } from "../../../common/model/preferencias-usuario";

@Component({
	selector: "app-filtro-template",
	templateUrl: "./filtro-template.component.html",
	providers: [
		{ useClass: CarteiraService, provide: CarteiraService },
		{ useClass: PreferenciasUsuarioService, provide: PreferenciasUsuarioService },
		{ useClass: DefaultValuesService, provide: DefaultValuesService }
	]
})
export class FiltroTemplateComponent {
	preferenciasUsuario: PreferenciasUsuario;
	filtro: any = {};
	carteiras: Array<Carteira> = [];

	listaBoolean: { label: any; value: boolean }[] = [];
	listaTipoTemplate: { label: any; value: number }[] = [];

	@Output()
	filtrar: EventEmitter<any> = new EventEmitter<any>();

	public get disabledCarteira(): boolean {
		return (this.carteiras.length == 1);
	}

	constructor(private carteiraService: CarteiraService, private preferenciasService: PreferenciasUsuarioService, private defaultValuesService: DefaultValuesService) {

		// Preferencias do usuário
		this.preferenciasUsuario = this.preferenciasService.localStorageObterPreferenciasUsuario();

		this.carteiraService.obterCarteiras().subscribe(carteiras => {
			this.carteiras = carteiras;

			if (this.preferenciasUsuario.existePreferenciaCarteira) {
				this.filtro.carteiraId = this.preferenciasUsuario.carteiraId;
			} else {
				let padrao = this.carteiras.find(f => f.padrao) as Carteira;
				this.filtro.carteiraId = padrao.carteiraId;
			}

			this.filtrarTemplate();
		});

		this.defaultValuesService
			.obterBoolean()
			.then(lista => (this.listaBoolean = lista));

		this.defaultValuesService
			.obterTipoTemplate()
			.then(listaTipoTemplate => (this.listaTipoTemplate = listaTipoTemplate));
	}

	carteiraChange() {
		this.filtrarTemplate();
	}

	filtrarTemplate() {
		this.filtrar.emit(this.filtro);
	}

	limpar() {
		this.filtro = {};
		let padrao = this.carteiras.find(f => f.padrao);
		this.filtro.carteiraId = padrao.carteiraId;
	}
}
