import { Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Subscription } from "rxjs";
import { TipoValidacao } from "../../../common/model/lista-campo-validacao";
import { DashListaProvedorService } from "../../../common/service/dash-lista-provedor.service";
import { generateCanvas } from "../../libs/utils/canvas";
import { DashListaProvedorValidacaoCard } from "../../model/dash-lista-provedor-validacao-card";

@Component({
    selector: "app-dash-lista-provedor-validacao",
    templateUrl: "./dash-lista-provedor-validacao.component.html",
    styleUrls: ["../dash-lista-provedor.component.scss", "./dash-lista-provedor-validacao.component.scss"],
    providers: [
        // { useClass: DashListaProvedorService, provide: DashListaProvedorService }
    ]
})
export class DashListaProvedorValidacaoComponent implements OnInit, OnDestroy {

    subValidacao: Subscription;

    listaNome: string;
    dados: DashListaProvedorValidacaoCard;

//#region  [ Get Validação ]

    public get validacaoTelefone() {
        let validacao = this.dados.validacoesQuantidadeDetalhe.find(c => c.tipoValidacao == TipoValidacao.Telefone);
        return validacao;
    }

    public get validacaoCpf() {
        let validacao = this.dados.validacoesQuantidadeDetalhe.find(c => c.tipoValidacao == TipoValidacao.CPF);
        return validacao;
    }

    public get validacaoEmail() {
        let validacao = this.dados.validacoesQuantidadeDetalhe.find(c => c.tipoValidacao == TipoValidacao.Email);
        return validacao;
    }

    public get validacaoCep() {
        let validacao = this.dados.validacoesQuantidadeDetalhe.find(c => c.tipoValidacao == TipoValidacao.CEP);
        return validacao;
    }

//#endregion

//#region [ Possui Validacação ? ]

    public get possuiValidacaoTelefone() {
        return this.validacaoTelefone != null && this.validacaoTelefone.possuiValidacao;
    }

    public get possuiValidacaoCpf() {
        return this.validacaoCpf != null && this.validacaoCpf.possuiValidacao;
    }

    public get possuiValidacaoEmail() {
        return this.validacaoEmail != null && this.validacaoEmail.possuiValidacao;
    }

    public get possuiValidacaoCep() {
        return this.validacaoCep != null && this.validacaoCep.possuiValidacao;
    }

//#endregion    

    private ctxTelefoneValido: CanvasRenderingContext2D;
    private ctxEmailValido: CanvasRenderingContext2D;
    private ctxCepValido: CanvasRenderingContext2D;
    private ctxCpfValido: CanvasRenderingContext2D;
    private ctxClienteUnico: CanvasRenderingContext2D;

    @ViewChild('canvasTelefoneValido', { static: false }) public canvasTelefoneValido: ElementRef<HTMLCanvasElement>;
    @ViewChild('canvasEmailValido', { static: false }) public canvasEmailValido: ElementRef<HTMLCanvasElement>;
    @ViewChild('canvasCepValido', { static: false }) public canvasCepValido: ElementRef<HTMLCanvasElement>;
    @ViewChild('canvasCpfValido', { static: false }) public canvasCpfValido: ElementRef<HTMLCanvasElement>;
    @ViewChild('canvasCodigoCliente', { static: false }) public canvasCodigoCliente: ElementRef<HTMLCanvasElement>;

    constructor(
        private dashListaProvedorService: DashListaProvedorService,
        private zone: NgZone) {
        this.dados = new DashListaProvedorValidacaoCard();
    }

    ngOnInit() {
        this.subValidacao = this.dashListaProvedorService.onValidacaoAtualizar.subscribe(data => {
            this.dashListaProvedorService.obterValidacao(data.filtro).subscribe(result => {
                this.listaNome = data.filtro.listaNome;
                this.dados = result;

                this.ctxTelefoneValido = this.possuiValidacaoTelefone ? generateCanvas(this.canvasTelefoneValido, this.validacaoTelefone.pctValido, '#6f9fea') : null;
                this.ctxCpfValido = this.possuiValidacaoCpf ? generateCanvas(this.canvasCpfValido, this.validacaoCpf.pctValido, '#ffa129') : null;
                this.ctxEmailValido = this.possuiValidacaoEmail ? generateCanvas(this.canvasEmailValido, this.validacaoEmail.pctValido, '#f7d50b') : null;
                this.ctxCepValido = this.possuiValidacaoCep ? generateCanvas(this.canvasCepValido, this.validacaoCep.pctValido, '#06a06c') : null;
                this.ctxClienteUnico = generateCanvas(this.canvasCodigoCliente, this.dados.pctClienteUnico, '#1976d2');
            });
        });
    }

    ngOnDestroy() {
        if (this.subValidacao)
            this.subValidacao.unsubscribe();
    }
}