import { ProvedorConfiguracaoTipo, CampoFormato } from "./configuracao-campo-provedor";
import { validate } from "validate.js";
import { TipoTemplate } from "./template";
import { Object } from "core-js";

export enum TipoAutenticacao {
  ApiKey = 1,
  CorpoMensagem = 2,
  Basic = 3,
  Oauth = 4,
  UrlToken = 5,
  AllowAnonymous = 6
}

export enum IntegracaoTipo {
  API = 1,
  Arquivo = 2,
  BancoDeDados = 3,
  FTP = 4,
  Webhook = 5,
  Response = 6,
  RabbitMQ = 7
}

export enum EnvioRetornoTipo {
  Individual = 1,
  Lista = 2
}

export class ProvedorConfiguracao {
  public provedorConfiguracaoId: number;
  public provedorConfiguracaoTipoId: ProvedorConfiguracaoTipo;
  public providerId: number;
  public integracaoTipoId: IntegracaoTipo;
  public campoFormatoId: CampoFormato;
  public caminhoIntegracao: string;
  public metodoIntegracao: string;
  public tipoAutenticacao: TipoAutenticacao;
  public caminhoLogin: string;
  public metodoLogin: string;
  public username: string;
  public password: string;
  public clientId: string;
  public clientSecret: string;
  public scope: string;
  public grantType: string;
  public apiKey: string;
  public tokenProperty: string;
  public token: string;
  public bancoDados: string;
  public objetoDados: string;
  public parametros: string;
  public campoValorCampanha: string;
  public campoExibicaoCampanha: string;
  public layoutPorCampanha: boolean;
  public nomeCampoCampanha: string;
  public urlConfiguracaoPorCampanha: string;
  public metodoConfiguracaoPorCampanha: string;
  public possuiHeader: boolean;
  public delimitador: string;
  public tipoArquivo: TipoTemplate;
  public campoUsername: string;
  public campoPassword: string;
  public campoToken: string;
  public codigoCampanhaExportacao: boolean;
  public provedorConfiguracaoGuid: string;
  public envioRetornoTipoId: EnvioRetornoTipo;
  public ativo: boolean;
  public parametrosUrl: boolean;

	public get tipoArquivoString(){
		return this.tipoArquivo.toString();
	}

	public set tipoArquivoString(value: any){
		this.tipoArquivo = value;
  }
  
  
	public get envioRetornoTipoString(){
		return this.envioRetornoTipoId.toString();
	}

	public set envioRetornoTipoString(value: any){
		this.envioRetornoTipoId = value;
	}

  constructor(provedorConfiguracaoTipoId?: ProvedorConfiguracaoTipo, ativo?: boolean, campoFormatoId?: CampoFormato) {
    this.provedorConfiguracaoTipoId = provedorConfiguracaoTipoId;
    this.campoFormatoId = campoFormatoId;
    this.ativo = ativo;
  }

  validate() {
    let validacaoCaminho = {
      caminhoIntegracao: { presence: { allowEmpty: false } }
    };
    let validacaoMetodo = {
      metodoIntegracao: { presence: { allowEmpty: false } }
    };
    let validacaoUserPass = {
      username: { presence: { allowEmpty: false } },
      password: { presence: { allowEmpty: false } }
    };
    let validacaoCaminhoLogin = {
      caminhoLogin: { presence: { allowEmpty: false } },
      metodoLogin: { presence: { allowEmpty: false } }
    };
    let validacaoGrant = {
      clientId: { presence: { allowEmpty: false } },
      scope: { presence: { allowEmpty: false } },
      grantType: { presence: { allowEmpty: false } },
      campoUsername: { presence: { allowEmpty: false } },
      campoPassword: { presence: { allowEmpty: false } },
      campoToken: { presence: { allowEmpty: false } }
    };
    let validacaoCampanha = {
      campoValorCampanha: { presence: { allowEmpty: false } },
      campoExibicaoCampanha: { presence: { allowEmpty: false } }
    };
    let validacaoLayoutPorCampanha = {
      nomeCampoCampanha: { presence: { allowEmpty: false } },
      urlConfiguracaoPorCampanha: { presence: { allowEmpty: false } },
      metodoConfiguracaoPorCampanha: { presence: { allowEmpty: false } }
    };
    let validacaoRabbit = {
      objetoDados: { presence: { allowEmpty: false } }
    };

    if (this.provedorConfiguracaoTipoId == ProvedorConfiguracaoTipo.Campanha) {
      Object.assign(validacaoCaminho, validacaoMetodo, validacaoCampanha);

      if (this.layoutPorCampanha) {
        Object.assign(validacaoCaminho, validacaoLayoutPorCampanha);
      }
    }

    if (this.integracaoTipoId == IntegracaoTipo.FTP) {
      return validate(this, Object.assign(validacaoCaminho, validacaoUserPass));
    }

    if (this.integracaoTipoId == IntegracaoTipo.BancoDeDados) {
      return validate(this, Object.assign(validacaoCaminho, validacaoUserPass, {
        bancoDados: { presence: { allowEmpty: false } },
        objetoDados: { presence: { allowEmpty: false } }
      }));
    }

    if (this.integracaoTipoId == IntegracaoTipo.RabbitMQ) {
      return validate(this, Object.assign(validacaoCaminho, validacaoUserPass, validacaoRabbit));
    }    

    if (this.integracaoTipoId == IntegracaoTipo.API || this.provedorConfiguracaoTipoId == ProvedorConfiguracaoTipo.Campanha) {
      if (this.tipoAutenticacao == TipoAutenticacao.ApiKey)
        return validate(this, Object.assign(validacaoCaminho, validacaoMetodo, {
          apiKey: { presence: { allowEmpty: false } }
        }));

      if (this.tipoAutenticacao == TipoAutenticacao.Basic)
        return validate(this, Object.assign(validacaoCaminho, validacaoMetodo, validacaoUserPass));

      if (this.tipoAutenticacao == TipoAutenticacao.UrlToken)
        return validate(this, Object.assign(validacaoCaminho, validacaoMetodo, {
          token: { presence: { allowEmpty: false } },
          tokenProperty: { presence: { allowEmpty: false } }
        }));

      if (this.tipoAutenticacao == TipoAutenticacao.Oauth && this.grantType == "password")
        return validate(this, Object.assign(validacaoCaminho, validacaoMetodo, validacaoUserPass, validacaoGrant));

      if (this.tipoAutenticacao == TipoAutenticacao.Oauth)
        return validate(this, Object.assign(validacaoCaminho, validacaoMetodo, validacaoCaminhoLogin, validacaoGrant, {
          clientSecret: { presence: { allowEmpty: false } }
        }));

      return validate(this, Object.assign(validacaoCaminho, validacaoMetodo, {
        tipoAutenticacao: { presence: { allowEmpty: false } }
      }));
    }

    if (this.provedorConfiguracaoTipoId == ProvedorConfiguracaoTipo.Envio) {
      return validate(this, {
        integracaoTipoId: { presence: { allowEmpty: false } }
      });
    }

    if (this.integracaoTipoId == IntegracaoTipo.Arquivo && this.provedorConfiguracaoTipoId == ProvedorConfiguracaoTipo.Retorno) {
      return validate(this, Object.assign(validacaoCaminho, {
        delimitador: {
          presence: { allowEmpty: false },
          length: { minimum: 1 }
        }
      }));
    }

    return false;
  }

  //#region [ Limpar Campos ]

  limparCamposConfiguracao() {
    this.caminhoIntegracao = null;
    this.metodoIntegracao = null;
    this.tipoAutenticacao = this.integracaoTipoId == IntegracaoTipo.Webhook ? TipoAutenticacao.ApiKey : null;
    this.bancoDados = null;
    this.objetoDados = null;
    this.parametros = null;
    this.parametrosUrl = false;
    this.tipoArquivoString = this.integracaoTipoId == IntegracaoTipo.Arquivo || this.integracaoTipoId == IntegracaoTipo.FTP ? 2 : null;
    this.possuiHeader = this.integracaoTipoId == IntegracaoTipo.Arquivo || this.integracaoTipoId == IntegracaoTipo.FTP ? true : null;
    this.delimitador = this.integracaoTipoId == IntegracaoTipo.Arquivo || this.integracaoTipoId == IntegracaoTipo.FTP ? ';' : null;
    this.envioRetornoTipoString = this.integracaoTipoId == IntegracaoTipo.API ? EnvioRetornoTipo.Lista : null;
    this.limparCamposAutenticacao();
  }

  limparCamposAutenticacao() {
    this.caminhoLogin = null;
    this.metodoLogin = null;
    this.username = null;
    this.password = null;
    this.clientId = null;
    this.clientSecret = null;
    this.scope = null;
    this.grantType = null;
    this.apiKey = null;
    this.tokenProperty = null;
    this.token = null;
    this.campoUsername = null;
    this.campoPassword = null;
    this.campoToken = null;
  }

  //#endregion

  //#region [ Validações de exibição dos campos ]

  isIntegracaoTipoAPI(): boolean {
    return this.integracaoTipoId == IntegracaoTipo.API;
  }

  isIntegracaoTipoArquivo(): boolean {
    return this.integracaoTipoId == IntegracaoTipo.Arquivo;
  }

  isIntegracaoTipoBancoDeDados(): boolean {
    return this.integracaoTipoId == IntegracaoTipo.BancoDeDados;
  }

  isIntegracaoTipoFtp(): boolean {
    return this.integracaoTipoId == IntegracaoTipo.FTP;
  }

  isIntegracaoTipoWebhook(): boolean {
    return this.integracaoTipoId == IntegracaoTipo.Webhook;
  }

  isIntegracaoTipoResponse(): boolean {
    return this.integracaoTipoId == IntegracaoTipo.Response;
  } 

  isIntegracaoTipoRabbitMQ(): boolean {
    return this.integracaoTipoId == IntegracaoTipo.RabbitMQ;
  } 

  isTipoAutenticacaoBasic(): boolean {
    return this.tipoAutenticacao == TipoAutenticacao.Basic;
  }

  isTipoAutenticacaoOauth(): boolean {
    return this.tipoAutenticacao == TipoAutenticacao.Oauth;
  }

  isTipoAutenticacaoApiKey(): boolean {
    return this.tipoAutenticacao == TipoAutenticacao.ApiKey;
  }

  isTipoAutenticacaoUrlToken(): boolean {
    return this.tipoAutenticacao == TipoAutenticacao.UrlToken;
  }

  exibeUsuarioSenha(): boolean {
    return this.tipoAutenticacao == TipoAutenticacao.Basic ||
      this.tipoAutenticacao == TipoAutenticacao.Oauth ||
      (this.integracaoTipoId == IntegracaoTipo.Arquivo ||
        this.integracaoTipoId == IntegracaoTipo.BancoDeDados ||
        this.integracaoTipoId == IntegracaoTipo.FTP ||
        this.integracaoTipoId == IntegracaoTipo.RabbitMQ)
  }

  exibeDominio(): boolean {
    return this.integracaoTipoId == IntegracaoTipo.Arquivo;
  }

  //#endregion  
}