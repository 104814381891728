import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import { ListaTemplateItem } from "../../common/model/lista-template-item";
import { TemplateItemListaValor } from "../../common/model/template-item-lista-valor";
import { FormTemplateImportacaoComponent } from "../form-template-importacao/form-template-importacao.component";
import * as validate from "validate.js";
import { CustomAlertService } from "../../common/service/custom-alert.service";

@Component({
    selector: "app-campo-lista-valor-modal",
    templateUrl: "./campo-lista-valor-modal.component.html",
    styleUrls: ["../style.css", "./campo-lista-valor-modal.component.scss"],
    providers: []
})

export class CampoListaValorModalComponent {
    campo: any;
    possuiLookup: boolean = false;
    desabilitaLookup: boolean = false;
    valores: Array<TemplateItemListaValor>;

    constructor(
        private ref: MatDialogRef<FormTemplateImportacaoComponent>,
        private translate: TranslateService,
        private customAlertService: CustomAlertService,
        @Inject(MAT_DIALOG_DATA) public data: { campo: any, desabilitaLookup: boolean }
    ) {
        this.campo = data.campo;
        this.desabilitaLookup = data.desabilitaLookup && this.campo.configuracaoCampoProviderId > 0;
        this.possuiLookup = this.campo.possuiLookup;
        this.valores = new Array<TemplateItemListaValor>();

        if (this.campo.templateItemListaValor.length > 0) {
            this.campo.templateItemListaValor.forEach(val => this.valores.push(Object.assign({}, val)));
        }
    }

    public get getValores() {
        return this.valores.filter((f: TemplateItemListaValor) => f.ativo);
    }

    adicionarValor() {
        this.valores.push(new TemplateItemListaValor(this.campo.listaTemplateItemId, this.campo.configuracaoCampoProviderId));
    }

    excluirValor(valor: TemplateItemListaValor) {
        if (valor.templateItemListaValorId > 0)
            this.customAlertService
                .confirmationMessage("telaTemplate.desejaExcluirValor")
                .then(() =>
                    valor.ativo = false
                );
        else
            this.valores.forEach((item, index) => {
                if (item === valor) this.valores.splice(index, 1);
            });
    }

    confirmar() {
        return this.ref.close({ templateItemListaValor: this.valores, possuiLookup: this.possuiLookup });
    }

    fechar() {
        this.ref.close();
    }

    possuiLookupChange() {
        if (!this.possuiLookup) {
            this.valores.forEach(v => v.valorEntrada = null);
        }
    }

    //#region  [ Validação ]

    public liveErrorValor(valor: TemplateItemListaValor, property: string) {
        var validationResult = this.validate();
        if (!validationResult) return null;

        let index = this.getValores.indexOf(valor);

        if (validationResult["getValores"][0]["errors"][index] === undefined) return null;
        return validationResult["getValores"][0]["errors"][index]["error"][property] ? validationResult["getValores"][0]["errors"][index]["error"][property] : null;
    }

    validate() {
        validate.validators.array = (arrayItems: Array<any>, itemConstraints: any) => {
            const arrayItemErrors = arrayItems.reduce((errors, item, index) => {
                const error = validate(item, itemConstraints)
                if (error) errors[index] = { error: error }
                return errors
            }, {})

            return this.isNull(arrayItemErrors) ? null : { errors: arrayItemErrors };
        }

        let regras = {
            getValores: {
                array: {
                    valorSaida: {
                        presence: { allowEmpty: false, message: "^obrigatorio" }
                    }
                }
            }
        };

        if (this.possuiLookup) {
            Object.assign(regras, {
                getValores: {
                    array: {
                        valorEntrada: {
                            presence: { allowEmpty: false, message: "^obrigatorio" }
                        }
                    }
                }
            });
          }

        let validationResult = validate.validate(this, regras);

        return validationResult;
    }

    isNull(valor: any) {
        return (valor == undefined || valor == null || valor == '');
    }

    desabilitaConfirmar() {
        var validationResult = this.validate();
        if (!validationResult)
            return false;

        var ok = false;

        Object.keys(validationResult["getValores"][0]["errors"]).forEach((f: any) => {
            if (validationResult["getValores"][0]["errors"][f] !== undefined)
                ok = true;
        });

        return ok;
    }

    ////#endregion
}