import { Directive, Input, ElementRef, Renderer } from '@angular/core';

@Directive({
  selector: '[appFocus]'
})
export class FocusDirective {
  constructor(private element: ElementRef, private renderer: Renderer) {
  }

  protected ngAfterViewInit(){    
    if(this.element.nativeElement.id){
      const element = this.renderer.selectRootElement("#"+this.element.nativeElement.id);
      element.focus();
    }
  }
}
