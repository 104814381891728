import { AppCommonModule } from "./common/common.module";
import { ElasticsearchModule } from "./elasticsearch/elasticsearch.module";
import { AutenticacaoModule } from "./autenticacao/autenticacao.module";
import { TemplateModule } from "./template/template.module";
import { ListaModule } from "./lista/lista.module";
import { TarefaModule} from "./tarefa/tarefa.module"
import { EstrategiaModule } from "./estrategia/estrategia.module";
import { EstrategiaRemocaoModule } from "./estrategia-remocao/estrategia-remocao.module";
import { ReguaEstrategiaModule } from "./regua-estrategia/regua-estrategia.module";
import { FiltroQueryPadraoModule } from "./filtro-query-padrao/filtro-query-padrao.module";
import { FluxoEstrategiaModule } from "./fluxo-estrategia/fluxo-estrategia.module";
import { UsuarioModule } from "./usuario/usuario.module";
import { DashModule } from "./dash/dash.module";
import { ProviderModule } from "./provider/provider.module";
import { PesquisaModule } from "./pesquisa/pequisa.module";
import { EsBuilderModule } from "./esbuilder/esbuilder.module"
import { DocumentacaoModule } from "./documentacao/documentacao.module";
import { RetornoModule } from "./retorno/retorno.module";
import { NotificacaoModule } from "./notificacao/notificacao.module";
import { DownloadRegistroModule } from "./download-registro/download-registro.module";
import { ConfiguracaoModule } from "./configuracao/configuracao.module";
import { CarteiraModule } from "./carteira/carteira.module";
import { PlanoTarifaProvedorModule } from "./plano-tarifa-provedor/plano-tarifa-provedor.module";
import { IndicadorProvedorModule } from "./indicador-provedor/indicador-provedor.module";

export const CustomModuleCollection = [
  ElasticsearchModule,
  EsBuilderModule,
  AutenticacaoModule,
  TemplateModule,
  ListaModule,
  EstrategiaModule,
  EstrategiaRemocaoModule,
  ReguaEstrategiaModule,
  FiltroQueryPadraoModule,
  FluxoEstrategiaModule,
  UsuarioModule,
  AppCommonModule,
  DashModule,
  ProviderModule,
  PesquisaModule,
  TarefaModule,
  DocumentacaoModule,
  RetornoModule,
  NotificacaoModule,
  DownloadRegistroModule,
  ConfiguracaoModule,
  CarteiraModule,
  PlanoTarifaProvedorModule,
  IndicadorProvedorModule
];