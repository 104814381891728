export class TemplateItemListaValor {
  templateItemListaValorId: number;
  listaTemplateItemId: number;
  configuracaoCampoProviderId: number;
  valorEntrada: string;
  valorSaida: string;
  dataCadastro: Date;
  ativo: boolean;

  constructor(listaTemplateItemId: number, configuracaoCampoProviderId: number) {
    this.listaTemplateItemId = listaTemplateItemId;
    this.configuracaoCampoProviderId = configuracaoCampoProviderId;
    this.valorEntrada = null;
    this.valorSaida = null;
    this.ativo = true;
  }
}