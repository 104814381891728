import { RouterModule, Routes } from "@angular/router";

import { LayoutComponent } from "./layout.component";
import { routes as children } from "../modules/routing";

const routes: Routes = [
  {
    path: "app",
    component: LayoutComponent,
    children
  }
];

export const LayoutRoutingModule = RouterModule.forChild(routes);
