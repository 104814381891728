import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
  selector: "app-display-messagem-erro-estrategia",
  templateUrl: "./display-messagem-erro-estrategia.component.html"
})
export class DisplayMessagemErroEstrategiaComponent implements OnInit {
  mensagem: any;
  titulo: any;
  data: any;
  constructor(
    public dialogRef: MatDialogRef<DisplayMessagemErroEstrategiaComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    let object;

    try{
      object =JSON.stringify(JSON.parse(data.mensagemErro),undefined,4);
    }
    catch{
      object =data.mensagemErro;
    }

    this.mensagem = object;
    this.titulo= data.mensagem;
    this.data = data.dataCadastro;
  }

  ngOnInit() {}

  close() {
    this.dialogRef.close();
  }
}
