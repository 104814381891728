
import { map } from 'rxjs/operators';
import "rxjs/add/operator/map";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from 'rxjs';

import { Template } from "../model/template";
import { ListaCampoValidacao } from "../model/lista-campo-validacao";
import { AutenticacaoService } from "./autenticacao.service";
import { ResponseContentType, Http, Headers } from "@angular/http";
import { environment } from "../../../../environments/environment";
import { convertToQueryString } from "../../common/service/query-string";

import { downloadFile } from "./download-file";
import { CampoTemplateAgrupadoNomeTipo } from '../model/pesquisa-lista';

@Injectable()
export class TemplateService {
	constructor(
		private httpClient: HttpClient,
		private http: Http,
		private authService: AutenticacaoService
	) { }

	obterTemplate(id) {
		return this.httpClient
			.get<Template>(`${environment.serverUrl}/api/templates/${id}`).pipe(
				map(template => Template.fromRaw(template)));
	}

	obterTemplateComLista(templateId, listaId) {
		return this.httpClient
			.get<Template>(`${environment.serverUrl}/api/templates/${templateId}/lista/${listaId}`).pipe(
				map(template => Template.fromRaw(template)));
	}

	obterSeTemplatePossuiLista(id) {
		return this.httpClient.get<boolean>(
			`${environment.serverUrl}/api/templates/${id}/possui-lista`
		);
	}

	obterCamposMapeados(templateId, listaId) {
		return this.httpClient.get<Array<any>>(
			`${environment.serverUrl}/api/templates/${templateId}/lista/${listaId}/campos-mapeados`
		);
	}

	obterTodosCamposMapeados(filtro?: any) {
		var queryString = convertToQueryString(filtro);
		return this.httpClient.get<Array<any>>(
			`${environment.serverUrl}/api/templates/todos-campos-mapeados${queryString}`
		);
	}

	obterCamposAgrupadoNomeTipo(filtro?: any) {
		var queryString = convertToQueryString(filtro);
		return this.httpClient.get<Array<CampoTemplateAgrupadoNomeTipo>>(
			`${environment.serverUrl}/api/templates/campos-agrupados-nome-tipo${queryString}`
		);
	}

	obterTemplates(filtro?) {
		return this.httpClient.get<Array<Template>>(
			`${environment.serverUrl}/api/templates${convertToQueryString(filtro)}`
		);
	}

	desativar(listaTemplateId) {
		return this.httpClient.patch(
			`${environment.serverUrl}/api/templates/${listaTemplateId}/desativar`,
			null
		);
	}

	criar(template: Template) {
		return this.httpClient.post(
			`${environment.serverUrl}/api/templates`,
			template
		);
	}

	baixarExemplo(template: Template) {
		this.http
			.get(
				`${environment.serverUrl}/api/templates/${template.listaTemplateId
				}/exemplo`,
				{
					responseType: ResponseContentType.Blob,
					headers: new Headers({
						Authorization: `Bearer ${this.authService.obterTokenAutenticado()}`
					})
				}
			)
			.subscribe(res => downloadFile(res, `${template.nome}.txt`));
	}

	atualizar(template: Template) {
		return this.httpClient.put(
			`${environment.serverUrl}/api/templates`,
			template
		);
	}

	validarDiretorio(diretorio: any) {
		return this.httpClient.post(
			`${environment.serverUrl}/api/templates/validar-diretorio`,
			diretorio
		);
	}

	obterListaCamposValidacao() {
		return this.httpClient.get<Array<ListaCampoValidacao>>(`${environment.serverUrl}/api/templates/campos-validacao`);
	}

	obterFuncoesTemplateImportacao(translate) {
		const funcoes = of<Array<any>>([
			{ 'id': '4', 'restricao': ['Data'], 'descricao': translate.instant("telaTemplate.fncAddDaysTitulo"), 'funcao': 'AddDays(0)', 'funcaoNome': 'AddDays', 'tooltip': 'fncAddDays' },
			{ 'id': '2', 'restricao': ['Texto', 'Decimal', 'Lista', 'Boolean', 'Inteiro', 'Long'], 'descricao': translate.instant("telaTemplate.fncReplaceTitulo"), 'funcao': 'Replace("A","B")', 'funcaoNome': 'Replace', 'tooltip': 'fncReplace' },
			{ 'id': '1', 'restricao': ['Texto', 'Decimal', 'Lista', 'Boolean', 'Inteiro', 'Long'], 'descricao': translate.instant("telaTemplate.fncSubStringTitulo"), 'funcao': 'Substring(0,2)', 'funcaoNome': 'Substring', 'tooltip': 'fncSubString' },
			{ 'id': '3', 'restricao': ['Texto', 'Decimal', 'Lista', 'Boolean', 'Inteiro', 'Long'], 'descricao': translate.instant("telaTemplate.fncTrimTitulo"), 'funcao': 'Trim()', 'funcaoNome': 'Trim', 'tooltip': 'fncTrim' }
		]);

		return funcoes;
	}
}
