import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { convertToQueryString } from "./query-string";
import { FiltroNotificacao } from "../model/filtro-notificacao";
import { Notificacao } from "../model/notificacao";

@Injectable()
export class NotificacaoService {
	constructor(private httpClient: HttpClient) {

	}

	public obterNotificacao(filtro: FiltroNotificacao) {
		return this.httpClient.get<Array<Notificacao>>(
			`${environment.serverUrl}/api/notificacao${convertToQueryString(filtro)}`
		);
	}

	public obterNotificacaoPorId(id: number) {
		return this.httpClient.get<Notificacao>(
			`${environment.serverUrl}/api/notificacao/${id}`
		);
	}
}