import { ElementRef, HostBinding, Component, Input, OnChanges, ViewChild } from '@angular/core';
import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';
import { NONAME } from 'dns';

@Component({
    selector: 'app-esbuilder-collapse',
    template: `<ng-content></ng-content>`,
    styles: [`
    :host {
      display: block;
      /*overflow: hidden;*/
    }
  `],
    animations: [

        // trigger('grow', [
        //     state('mais', style({ transform: 'translateY(0)'} )),
        //     state('menos', style({ transform: 'translateY(-100%)' })),
        //     transition('menos => mais', [
        //         style({ transform: 'translateY(-100%)' }),
        //         animate(3000)
        //     ]),
        //     transition('mais => menos', [
        //         animate(1000, style({ transform: 'translateY(-100%)' }))
        //     ])
        // ])

        trigger('grow', [
            state('menos', style({ height: '0px', opacity: 0, display: 'none' })),
            state('mais', style({ height: 'auto', opacity: 100, display: 'block'})),
            transition('mais <=> menos', animate('3000ms ease-in-out')),
        ])
    ]
})
export class EsBuilderCollapseComponent implements OnChanges {
    @Input()
    trigger: string;

    @Input()
    state: string = 'mais';

    startHeight: number;

    constructor(private element: ElementRef) { }

    @HostBinding('@grow') get grow() {

        //console.log("STATE: " + this.state);
        return { value: this.state };
        //return { value: this.trigger, params: { startHeight: this.startHeight } };
    }

    setStartHeight() {
        this.startHeight = this.element.nativeElement.clientHeight;
    }

    ngOnChanges() {
        this.setStartHeight();
    }
}
