import { Component, Input, EventEmitter, Output } from "@angular/core";
import { SistemaRecursoAcaoService } from "../../common/service/sistema-recurso-acao.service";
import { SistemaRecursoAcao } from "../../common/model/sistema-recurso-acao";
import { UsuarioPermissao } from "../../common/model/usuario-permissao";

@Component({
  selector: "app-form-permissoes",
  templateUrl: "./form-permissoes.component.html",
  styleUrls: [ "../style.scss", "./form-permissoes.component.scss" ],
  providers: [
    { useClass: SistemaRecursoAcaoService, provide: SistemaRecursoAcaoService }
  ]
})
export class FormPermissoesComponent {
  recursos: Array<any>;
  recursosOriginal: Array<SistemaRecursoAcao>;
  _recursosSelecionados: Array<UsuarioPermissao>;
  recursosAtualizados: boolean = false;

  constructor(private recursoService: SistemaRecursoAcaoService) {
    this._recursosSelecionados = [];
    this.recursosOriginal = [];
    this.recursos = [];
  }

  @Input()
  set recursosSelecionados(value: Array<UsuarioPermissao>) {
    this._recursosSelecionados = value;
    this.atualizarSelecionados();
  }
  @Output()
  recursosSelecionadosChange = new EventEmitter();

  @Input()
  disabled: boolean;

  ngOnInit() {
    this.recursoService
      .obterRecursos()
      .subscribe((response: Array<SistemaRecursoAcao>) => {

        let recursos = response.filter(f => f.sistemaRecursoId != 7);

        this.recursosOriginal = recursos;
        const todosRecursos = recursos.map(data => data.sistemaRecurso);
        const uniqRecursos = todosRecursos.filter(
          (x, i, arr) => x && arr.indexOf(x) === i
        );

        uniqRecursos.forEach(recurso => {
          var acoes = recursos.filter(data => data.sistemaRecurso == recurso);
          this.recursos.push({
            sistemaRecurso: recurso,
            acoes: acoes
          });
        });
        this.atualizarSelecionados();
      });
  }

  atualizarSelecionados() {
    if (
      !this.recursosAtualizados &&
      this.recursos.length > 0 &&
      this._recursosSelecionados.length > 0
    ) {
      this.recursosAtualizados = true;
      this.recursos.forEach(recurso => {
        recurso.acoes.forEach(acao => {
          const index = this._recursosSelecionados.findIndex(
            data =>
              data.sistemaRecursoId == acao.sistemaRecursoId &&
              data.recursoAcaoId == acao.recursoAcaoId
          );
          acao.ativo = index >= 0;
        });
      });
    }
  }

  selecionarTodos() {
    this._recursosSelecionados.length = 0;
    this.recursosOriginal.forEach(recursoAcao => {
      this._recursosSelecionados.push(
        Object.assign(new UsuarioPermissao(), recursoAcao)
      );
      recursoAcao.ativo = true;
    });
    this.recursosSelecionadosChange.emit(this._recursosSelecionados);
  }

  removerTodos() {
    this._recursosSelecionados.length = 0;
    this.recursosOriginal.forEach(recursoAcao => {
      recursoAcao.ativo = false;
    });
    this.recursosSelecionadosChange.emit(this._recursosSelecionados);
  }

  onAcaoSelecionada(acao: SistemaRecursoAcao) {
    if (acao.ativo) {
      this._recursosSelecionados.push(
        Object.assign(new UsuarioPermissao(), acao)
      );
    } else {
      const index = this._recursosSelecionados.findIndex(
        data =>
          data.sistemaRecursoId == acao.sistemaRecursoId &&
          data.recursoAcaoId == acao.recursoAcaoId
      );
      this._recursosSelecionados.splice(index, 1);
    }
    this.recursosSelecionadosChange.emit(this._recursosSelecionados);
  }

  validarAcaoObrigatoria(acao: SistemaRecursoAcao) {
    if (acao.sistemaRecurso == 'Lista' && acao.recursoAcao == 'Visualizar') {
      acao.ativo = true;
      return true;
    }
    if (acao.sistemaRecurso == 'Carteira' && acao.recursoAcao == 'Visualizar') {
      acao.ativo = true;
      return true;
    }
    if (acao.sistemaRecurso == 'Estrategia' && acao.recursoAcao == 'Visualizar') {
      acao.ativo = true;
      return true;
    }
    if (acao.sistemaRecurso == 'FiltroQuery' && acao.recursoAcao == 'Visualizar') {
      acao.ativo = true;
      return true;
    }
  }
}
