/* TODO: Remover Duplicidades no json de tradução */

import { BrowserModule } from "@angular/platform-browser";
import { HttpModule } from "@angular/http";
import { FormsModule } from "@angular/forms";
import { NgModule, ApplicationRef, APP_INITIALIZER } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  MatIconModule,
  MatSliderModule,
  MatListModule,
  MatSlideToggleModule,
  MatSelectModule,
  MatNativeDateModule,
  MatMenuModule,
  MatTooltipModule
} from "@angular/material";

import { HTTP_INTERCEPTORS } from "@angular/common/http";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { BaseAppComponents as TemplateComponents } from "./dependencies/template-components";

// Sub modules
import { LayoutModule } from "./layout/layout.module";
import { SharedModule } from "./shared/shared.module";

// hmr
import { removeNgStyles, createNewHosts } from "@angularclass/hmr";

import { CustomModuleCollection as CustomModules } from "./modules";
import { SlimScroll } from "./shared/slimscroll";
import { directives as DirectiveDependencies } from "./dependencies/directives";
import { RequestInterceptorService } from "./modules/common/interceptors/request-interceptor";
import { TranslateService } from "@ngx-translate/core";
import { BlockUIModule } from "ng-block-ui";
import { FabMenuComponent } from "./layout/fab-menu/fab-menu.component";
import { OverlayContainer, Overlay } from '@angular/cdk/overlay';
import { environment } from "../environments/environment";

import { NgxGraphModule } from "@swimlane/ngx-graph";
import { SimpleNotificationsModule } from "angular2-notifications";
import { ConfigProvider, APPCONFIG, ConfigProviderFactory } from "./common/providers/config.provider";
import { WINDOW_PROVIDERS } from "./common/providers/window.provider";

@NgModule({
  imports: [
    SimpleNotificationsModule.forRoot(),
    AppRoutingModule,
    LayoutModule,
    SharedModule,
    BrowserModule,
    HttpModule,
    FormsModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatSliderModule,
    MatListModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatNativeDateModule,
    MatMenuModule,
    MatTooltipModule,
    ...CustomModules,
    NgxGraphModule,
    BlockUIModule.forRoot()
  ],
  declarations: [
    AppComponent,
    //custom
    SlimScroll,
    ...DirectiveDependencies,
    ...TemplateComponents
  ],
  providers: [
    ConfigProvider,
    WINDOW_PROVIDERS, // <- add WINDOW_PROVIDERS here
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptorService, multi: true },
    { provide: APP_INITIALIZER, useFactory: ConfigProviderFactory, deps: [ConfigProvider], multi: true },
    { provide: APPCONFIG, useFactory: (provider: ConfigProvider) => provider.getConfig(), deps: [ConfigProvider] }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  public AppConfig: any;

  constructor(
    public appRef: ApplicationRef,
    private translateService: TranslateService/*,
    private overlayContainer: OverlayContainer*/) {
    this.AppConfig = environment;

    //this.overlayContainer.getContainerElement().classList.add('app-dark-theme');
    this.translateService.use("pt-BR");

    // switch (this.AppConfig.theme) {
    //   case 'gray':
    //     this.overlayContainer.getContainerElement().classList.add('theme-gray');
    //     break;
    //   case 'dark':
    //     this.overlayContainer.getContainerElement().classList.add('theme-dark');
    //     break;
    //   case 'light':
    //     this.overlayContainer.getContainerElement().classList.add('theme-light');
    //     break;
    //   case 'bradesco':
    //     this.overlayContainer.getContainerElement().classList.add('theme-bradesco');
    //     break;
    // }

  }
  hmrOnInit(store) {
    console.log("HMR store", store);
  }
  hmrOnDestroy(store) {
    const cmpLocation = this.appRef.components.map(
      cmp => cmp.location.nativeElement
    );
    // recreate elements
    store.disposeOldHosts = createNewHosts(cmpLocation);
    // remove styles
    removeNgStyles();
  }
  hmrAfterDestroy(store) {
    // display new elements
    store.disposeOldHosts();
    delete store.disposeOldHosts;
  }
}
