import { Component, OnInit } from "@angular/core";
import swal from "sweetalert2";
import { Usuario } from "../../common/model/usuario";
import { UsuarioService } from "../../common/service/usuario.service";
import { TranslateService } from "@ngx-translate/core";
import { DefaultValuesService } from "../../common/service/default-values.service";
import { Carteira } from "../../common/model/carteira";
import { CarteiraService } from "../../common/service/carteira.service";

@Component({
	selector: "app-lista-usuario",
	templateUrl: "./lista-usuario.component.html",
	styleUrls: ["../style.scss"],
	providers: [
		{ useClass: UsuarioService, provide: UsuarioService },
		{ useClass: DefaultValuesService, provide: DefaultValuesService },
        { useClass: CarteiraService, provide: CarteiraService }
	]
})
export class ListaUsuarioComponent implements OnInit {
	usuarios: Array<Usuario>;
	tipoUsuario: { label: any; value: number }[];
    carteiras: Array<Carteira> = [];
    filtro = { 'carteiraId': null };

	constructor(
		private usuarioService: UsuarioService,
		private translate: TranslateService,
		private defaultValuesServices: DefaultValuesService
	) {
		this.usuarios = [];

		this.defaultValuesServices
			.obterTipoUsuario()
			.then(tipoUsuario => (this.tipoUsuario = tipoUsuario));
	}

	ngOnInit() {
		this.usuarioService
			.obterUsuarios()
			.subscribe(usuarios => {
                (this.usuarios = usuarios)
            } );
	}

	obterTipoUsuario(tipo) {
		var tipoUsuario = this.tipoUsuario.find(c => c.value == tipo);
		if (tipoUsuario == undefined) return "";
		return tipoUsuario.label;
	}

	filtrarUsuario(filtro) {
		this.usuarioService
			.obterUsuarios(filtro)
			.subscribe(usuarios => (this.usuarios = usuarios));
	}

	excluir(usuarioId) {
		this.translate
			.get([
				"telaPadrao.perguntaConfirmacao",
				"telaPadrao.descricaoConfirmacao",
				"telaPadrao.confirmar",
				"telaPadrao.cancelar"
			])
			.subscribe((res: Object) => {
				swal.fire({
					title: res["telaPadrao.perguntaConfirmacao"],
					text: res["telaPadrao.descricaoConfirmacao"],
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: res["telaPadrao.confirmar"],
					cancelButtonText: res["telaPadrao.cancelar"]
				}).then(result => {
					if (result.value) {
						this.usuarioService
							.desativar(usuarioId)
							.subscribe(
								() =>
									(this.usuarios = this.usuarios.filter(
										t => t.usuarioId != usuarioId
									))
							);
					}
				});
			});
	}
}
