import { Component, OnInit, ViewChild } from "@angular/core";
import { CustomAlertService } from "../../common/service/custom-alert.service";
import { ProviderService } from "../../common/service/provider.service";
import { Table } from "primeng/table";
import { Router, NavigationEnd, NavigationStart, RouterEvent } from "@angular/router";
import { ok } from "assert";

@Component({
    selector: "app-lista-provider",
    templateUrl: "./lista-provider.component.html",
    providers: [
        { useClass: ProviderService, provide: ProviderService },
        { useClass: CustomAlertService, provide: CustomAlertService }
    ]
})
export class ListaProviderComponent {
    public filtro = { nome: "", alias: "" };
    public providerCollection = [];

    @ViewChild(Table, { static: false }) dataTable: Table;
    constructor(
        private providerService: ProviderService,
        private customAlertService: CustomAlertService,
        private router: Router
    ) {
        this.atualizarLista();
    }

    limpar() {
        this.filtro = { nome: "", alias: "" };
    }

    atualizarLista() {
    
        this.providerService
            .obterListaProvedor(this.filtro)
            .subscribe(
                providerCollection => (this.providerCollection = providerCollection)

            );

        this.setCurrentPage(0);    
    }

    excluir(id: number) {
        this.customAlertService
            .confirmationMessage("telaProvider.confirmarExclusao")
            .then(() =>
                this.providerService.excluir(id).subscribe(() => this.atualizarLista())
            );
    }

    duplicar(id: number) {
        this.customAlertService
            .confirmationMessage("telaProvider.confirmarDuplicarProvedor")
            .then(() =>
                this.providerService.duplicar(id).subscribe((p:any) => {
                    this.router.navigate(["/app/provedor/", p.data])
                })
            );               
    }

    setCurrentPage(n: number) {
         if (this.dataTable)
             this.dataTable.first = n;
    }

}