import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'my-app-search-overlay',
  styles: [],
  templateUrl: './search-overlay.component.html'
})

export class AppSearchOverlayComponent {

}
