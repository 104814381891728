import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { Component, OnInit, Input, Inject } from "@angular/core";

import { ElasticsearchRepositoryService } from "../../elasticsearch/repository/elasticsearch-repository.service";
import { Template } from "../../common/model/template";
import { ListaService } from "../../common/service/lista.service";
import { toElasticSearchName } from '../../common/utils/toElasticSearchName';
import { CustomAlertService } from "../../common/service/custom-alert.service";
import { ItemTipo } from "../../common/model/lista-template-item";
import { isNullOrEmpty } from "../../common/utils";
import { deepCopy } from "../../common/utils/deepCopy";
import { TranslateService } from "@ngx-translate/core";

@Component({
	selector: "app-elasticsearch-edit",
	templateUrl: "./elasticsearch-edit.component.html",
	providers: [
		{ useClass: ElasticsearchRepositoryService, provide: ElasticsearchRepositoryService },
		{ useClass: ListaService, provide: ListaService },
		{ useClass: CustomAlertService, provide: CustomAlertService }
	]
})
export class ElasticsearchEditComponent implements OnInit {
	template: Template;
	dados: any;
	lista: string;
	listaId: number;
	filtro: string;
	tipo: number;
	isEdit: boolean = false;

	public get colunas(): string[] {
		return this.template.listaTemplateItem.map(m => m.nome);
	}

	public get listaCamposEditaveis() {
		let listaItem = this.template.listaTemplateItem.filter(f => f.ativo && (f.itemTipo == ItemTipo.Padrao || f.itemTipo == ItemTipo.Coluna) && (!this.filtro || f.nome.toLowerCase().indexOf(this.filtro.toLowerCase()) != -1));

		if (this.dados["id"] && this.tipo != 3) {
			let identificadores: Array<string> = this.obterCamposIdentificadores();
			listaItem = listaItem.filter(f => f.ativo && !identificadores.some((s: string) => s == f.nome));
		}

		return listaItem;
	}

	public get descricaoIdentificador(): string {

		let descricao = '';

		this.obterCamposIdentificadores().forEach((campo: string) => {
			let valor = (isNullOrEmpty(this.dados[this.getPropriedade(campo)])) ? '---' : this.dados[this.getPropriedade(campo)];
			descricao += (isNullOrEmpty(descricao)) ? `${campo}: ${valor}` : ` - ${campo}: ${valor}`;
		});

		return descricao;
	}

	constructor(
		private elasticsearchRepositoryService: ElasticsearchRepositoryService,
		private listaService: ListaService,
		private translate: TranslateService,
		private customAlertService: CustomAlertService,
		public dialogRef: MatDialogRef<ElasticsearchEditComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.template = data.template;
		this.lista = data.lista;
		this.dados = deepCopy(data.item);
		this.listaId = data.listaId;
		this.tipo = data.tipo;
		this.isEdit = (this.dados != null && this.dados["id"] != null);
	}

	obterCamposIdentificadores(): Array<string> {
		let identificadores: Array<string> = (!isNullOrEmpty(this.template.identificadorTelefone))
			? [this.template.identificadorAlias, ...this.template.identificadorTelefone.split(',')]
			: [this.template.identificadorAlias];

		return identificadores;
	}

	ngOnInit() { }

	alterarValor(retorno) {
		if (retorno) {
			let prop = this.getPropriedade(retorno.campo.toLowerCase());
			this.dados[prop] = retorno.valor;
		}
	}

	getPropriedade(nome: string): string {
		let item = this.colunas.find(f => f.toLowerCase() == nome.toLowerCase());
		return item.toLowerCase();
	}

	salvar() {

		let valido: boolean = true;
		let identificadores: Array<string> = this.obterCamposIdentificadores();

		identificadores.forEach((campo: string) => {
			if (isNullOrEmpty(this.dados[this.getPropriedade(campo)]))
				valido = false;
		});

		if (!valido) {
			this.customAlertService.show("telaPadrao.validacao", "telaLista.identificadoresNaoInformados", "error");
			return;
		}

		if (this.isEdit && this.tipo != 3)
			this.listaService.alterarItemLista(this.listaId, this.dados["_listaItemId"], this.dados).subscribe(
				(result: any) => { this.fechar(result); },
				(error: any) => {
					this.customAlertService.show("telaPadrao.alteracao", error.error[0], "error");
				});
		else
			this.listaService.incluirItemLista(this.listaId, this.dados).subscribe(
				(result: any) => { this.fechar(result); },
				(error: any) => {
					this.customAlertService.show("telaPadrao.inclusao", error.error[0], "error");
				});
	}

	formatElasticField(alias) {
		if (this.isUpperCase(alias)) return alias.toLowerCase();
		return `${alias[0].toLowerCase()}${alias.substring(1)}`;
	}

	isUpperCase(text) {
		return text.toUpperCase() == text;
	}

	fechar(s: any = null) {
		this.dialogRef.close(s);
	}
}
