import { ListaTipoCampoFormatacao } from "./lista-tipo-campo-formatacao";

export class CampoIntegracao {
  nome: string;
  listaCampoTipoId: number;
  permiteMultiplo: boolean;
  ativo: boolean;
  valorFixoCampo: string;
  tipoPrimario: boolean;
  ordem: number;
  permiteFormatacao: boolean;
  listaCampoTipoFormatacao: Array<ListaTipoCampoFormatacao>;

  constructor(nome?: string, id?: number) {
    this.listaCampoTipoId = id;
    this.nome = nome;
  }
}