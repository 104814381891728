import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from 'rxjs/operators';

import { Regua } from "../model/regua";
import { environment } from "../../../../environments/environment";
import { convertToQueryString } from "../../common/service/query-string";
import { ReguaEstrategia } from '../model/ReguaEstrategia';

@Injectable()
export class ReguaService {
	constructor(private httpClient: HttpClient) { }

	public obterReguas(filtro: any) {
		return this.httpClient.get<Array<Regua>>(
			`${environment.serverUrl}/api/reguas${convertToQueryString(filtro)}`
		);
	}

	public obterPorId(id) {
		return this.httpClient.get<Regua>(`${environment.serverUrl}/api/reguas/${id}`)
			.pipe(map(regua => Regua.fromRaw(regua)));
	}

	public reordernarRegua(ordem: Array<any>) {
		return this.httpClient.post<number>(
			`${environment.serverUrl}/api/reguas/reordenar-regua`,
			ordem
		);
	}

	public criar(regua: Regua) {
		return this.httpClient.post<number>(
			`${environment.serverUrl}/api/reguas/`,
			regua
		);
	}

	public atualizar(regua: Regua) {
		return this.httpClient.put<number>(
			`${environment.serverUrl}/api/reguas/${regua.reguaId}`,
			regua
		);
	}

	public gravarReguaEstrategia(reguaEstrategia: ReguaEstrategia) {
		return this.httpClient.post<number>(
			`${environment.serverUrl}/api/reguas/gravar-reguaestrategia`,
			reguaEstrategia
		);
	}

	public desativarRegua(reguaId: number) {
		return this.httpClient.patch<any>(
			`${environment.serverUrl}/api/reguas/${reguaId}/desativar-regua`,
			null
		);
	}

	public desativarReguaEstrategia(reguaEstrategiaId: number) {
		return this.httpClient.patch<Array<any>>(
			`${environment.serverUrl}/api/reguas/${reguaEstrategiaId}/desativar-reguaestrategia`,
			null
		);
	}

	public obterEstrategias(listaId: number) {
		return this.httpClient.get<Array<any>>(
			`${environment.serverUrl}/api/reguas/estrategias-sem-regua/${listaId}`
		);
	}
}
