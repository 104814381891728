import { Component, OnDestroy, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material";
//import "rxjs/add/operator/combineLatest";
import { Subscription, combineLatest as observableCombineLatest } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import swal from "sweetalert2";
import { isNullOrEmpty, isNullOrZero } from "../../common/utils";

import { CustomAlertService } from "../../common/service/custom-alert.service";
import { ListaService } from "../../common/service/lista.service";
import { FiltroQueryService } from "../../common/service/filtro-query.service";

import { FiltroLista } from "../../common/model/filtro-lista";
import { FiltroStatusRegistroLista, Lista } from "../../common/model/lista";
import { Estrategia } from "../../common/model/estrategia";
import { FiltroQuery } from "../../common/model/filtro-query";

import { ElasticsearchFilterComponent } from "../../elasticsearch/filter/elasticsearch-filter/elasticsearch-filter.component";
import { PreviewDadosEstrategiaComponent } from "../../estrategia/form-estrategia/preview-dados-estrategia/preview-dados-estrategia.component";

@Component({
    selector: "app-form-filtro-query-padrao",
    templateUrl: "./form-filtro-query-padrao.component.html",
    styleUrls: ["./form-filtro-query-padrao.component.scss"],
    providers: [
        { useClass: FiltroQueryService, provide: FiltroQueryService },
        { useClass: ListaService, provide: ListaService },
        { useClass: CustomAlertService, provide: CustomAlertService }
    ]
})
export class FormFiltroQueryPadraoComponent implements OnDestroy {

    //#region [ Properts ] 	

    dirty: boolean;
    filtroQuery: FiltroQuery;
    listas: Lista[];

    listaId: number;
    listaNome: string;
    listaTemplateId: number;
    reenviarDistribuido: FiltroStatusRegistroLista;
    estrategia: Estrategia
    finishedLoading: boolean = false;

    subFiltroQuery: Subscription;
    subListas: Subscription;

    //#endregion

    //#region [ GETS/SETS ]

    public get filtroDefault(): string {
        return '{"size":5,"_source":{"includes":[]},"query":{"bool":{"must":[],"must_not":[]}}}';
    }

    public get filtroRegraDefault(): string {
        return '[]';
    }

    public get erros() {
        if (!this.dirty) return;
        return this.filtroQuery.validar();
    }

    public get isEdit(): boolean {
        return (this.filtroQuery != undefined && this.filtroQuery.filtroQueryId != undefined);
    }

    //#endregion

    @ViewChild("elasticsearchFilter", { static: false })
    elasticsearchFilter: ElasticsearchFilterComponent;
    constructor(
        private route: ActivatedRoute,
        private filtroQueryService: FiltroQueryService,
        private listaService: ListaService,
        private router: Router,
        private customAlertService: CustomAlertService,
        private translateService: TranslateService,
        private dialog: MatDialog
    ) {
        this.filtroQuery = new FiltroQuery();
        this.route.params.subscribe(params => this.carregar(params));
    }

    //#region [ Eventos do componente ]

    ngOnDestroy() {
        if (this.subFiltroQuery) this.subFiltroQuery.unsubscribe();
        if (this.subListas) this.subListas.unsubscribe();
    }

    //#endregion

    //#region [ Dados ]	

    carregar(params: any) {
        if (!params.id) {
            this.obterListas();
            return;
        }

        this.subFiltroQuery = this.filtroQueryService.obterPorId(params.id).subscribe((filtro: FiltroQuery) => {
            this.filtroQuery = filtro;
            this.listaId = filtro.listaId;
            this.listaNome = filtro.listaNome;
            this.listaTemplateId = filtro.listaTemplateId;

            this.reenviarDistribuido = FiltroStatusRegistroLista.total;
            this.estrategia = new Estrategia();
            this.estrategia.listaEstrategiaId = 0;

            this.estrategia.filtro = this.filtroQuery.filtro;
            this.estrategia.filtroRegras = this.filtroQuery.filtroRegras;

            this.finishedLoading = true;
        });
    }

    obterListas(listaId: number = null) {
        let filtro: FiltroLista = { ignorarErroPesquisa: true } as FiltroLista;
        if (!isNullOrZero(listaId))
            filtro.lista = listaId;

        this.subListas = this.listaService.obterLista(filtro).subscribe((listas: Lista[]) => (this.listas = listas));
    }

    //#endregion

    //#region [ Eventos ]	

    listaChange(event: any) {
        if (isNullOrZero(this.filtroQuery.listaId))
            return;

        let listaSelecionada = this.listas.find((f: Lista) => f.listaId.toString() == this.filtroQuery.listaId.toString())

        this.filtroQuery.listaId = listaSelecionada.listaId;
        this.listaId = listaSelecionada.listaId
        this.listaNome = listaSelecionada.nome;
        this.listaTemplateId = listaSelecionada.listaTemplateId;

        this.reenviarDistribuido = FiltroStatusRegistroLista.total;
        this.estrategia = new Estrategia();
        this.estrategia.listaEstrategiaId = 0;

        this.filtroQuery.filtro = this.filtroDefault;
        this.filtroQuery.filtroRegras = this.filtroRegraDefault;

        this.finishedLoading = true;
    }

    mostrarPreview(query: any = null) {
        if (isNullOrZero(this.listaTemplateId) || isNullOrEmpty(this.listaNome))
            return;

        if (query == undefined) {
            query = JSON.parse(this.filtroDefault);
            query.size = this.elasticsearchFilter.pageSize;
        }

        this.dialog.open(PreviewDadosEstrategiaComponent, {
            height: "700px",
            width: "80%",
            hasBackdrop: true,
            data: {
                templateId: this.listaTemplateId,
                lista: this.listaNome,
                listaId: this.listaId,
                query
            }
        });
    }

    updateQuantidades(event: any) { }

    updateQuery(event: any) {
        this.filtroQuery.filtro = JSON.stringify(event);
        this.filtroQuery.filtroRegras = this.estrategia.filtroRegras;
    }

    //#endregion

    //#region [ Salvar ]

    // confirmationMessageWithThreeButton(configuration) {
    // 	return observableCombineLatest([
    // 		this.translateService.get(configuration.title),
    // 		this.translateService.get(configuration.text),
    // 		this.translateService.get(configuration.confirmButtonText),
    // 		this.translateService.get(configuration.cancelButtonText),
    // 		this.translateService.get(configuration.denyButtonText)
    // 	])
    // 		.toPromise()
    // 		.then(resultCollection =>
    // 			swal.fire(
    // 				Object.assign({}, configuration, {
    // 					showDenyButton: true,
    // 					showCloseButton: true,
    // 					showCancelButton: true,
    // 					html: resultCollection[1].replace(new RegExp('\n', 'g'), '<br>'),
    // 					title: resultCollection[0],
    // 					text: resultCollection[1].replace(new RegExp('\n', 'g'), '<br>'),
    // 					confirmButtonText: resultCollection[2] || undefined,
    // 					cancelButtonText: resultCollection[3] || undefined,
    // 					denyButtonText: resultCollection[4] || undefined
    // 				})
    // 			)
    // 		);
    // }

    salvar() {
        this.dirty = true;

        if (this.filtroQuery.validar()) {
            this.customAlertService.show("telaFiltrosPadroes.filtrosPadroes", "telaPadrao.camposInvalidos", "error");
            return;
        }

        let sucesso = (response: any) => {
            this.customAlertService.show("telaFiltrosPadroes.filtrosPadroes", "telaPadrao.sucessoSalvar", "success");
            this.router.navigate(["/app/filtroquery/"]);
        };

        let erro = () => {
            this.customAlertService.show("telaFiltrosPadroes.filtrosPadroes", "telaPadrao.erroSalvar", "error");
        };

        let gravar = (aplicarEstrategias: boolean = false) => {
            if (!this.filtroQuery.filtroQueryId)
                return this.filtroQueryService.criar(this.filtroQuery).subscribe(sucesso, erro);

            this.filtroQueryService.atualizar(this.filtroQuery, aplicarEstrategias).subscribe(sucesso, erro);
        }

        if (this.filtroQuery.estrategiasVinculadas) {
            this.customAlertService
                .confirmationMessageWithThreeButton({
                    title: "telaFiltrosPadroes.filtrosPadroes",
                    text: "telaFiltrosPadroes.confirmacaoAlteracao",
                    icon: "success",
                    showCancelButton: true,
                    confirmButtonText: "telaFiltrosPadroes.aplicar",
                    denyButtonText: "telaFiltrosPadroes.naoAplicar",
                    cancelButtonText: "telaFiltrosPadroes.cancelar",
                })
                .then((data: any) => {
                    if (data.isConfirmed) // Aplicar
                        gravar(true);

                    else if (data.isDenied) //  Não aplicar
                        gravar(false);

                    else //Cancelar
                        return;
                });
        } else {
            gravar();
        }
    }

    //#endregion
}