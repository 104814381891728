import { Component, ViewChild, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from '@angular/material';
import { DataView } from 'primeng/dataview';

import { Carteira } from "../../common/model/carteira";

import { CarteiraService } from "../../common/service/carteira.service";
import { CustomAlertService } from "../../common/service/custom-alert.service";


@Component({
	selector: "app-lista-carteira",
	templateUrl: "./lista-carteira.component.html",
	styleUrls: ["./lista-carteira.component.scss"],
	providers: [
		{ useClass: CarteiraService, provide: CarteiraService },
		{ useClass: CustomAlertService, provide: CustomAlertService }
	]
})
export class ListaCarteiraComponent implements OnInit {
	carteiras: Array<Carteira> = [];
	tipos: Array<any>;
	filtro = { 'nome': '' };

	@ViewChild(DataView, { static: false }) dataTable: DataView;
	constructor(
		private carteiraService: CarteiraService,
		private route: ActivatedRoute,
		private customAlertService: CustomAlertService,
		public dialog: MatDialog
	) { }

	ngOnInit() {
		this.filtrarCarteiras();
	}

	filtrarCarteiras() {
		this.carteiraService.obterCarteiras(this.filtro).subscribe(data => {
			this.carteiras = data;
			this.setCurrentPage(0);
		});
	}


	setCurrentPage(n: number) {
		if (this.dataTable)
			this.dataTable.first = n;
	}
	
	limpar() {
		this.filtro = { 'nome': '' };
	}

	excluir(carteiraId: number) {
		this.customAlertService
			.confirmationMessage("telaCarteira.confirmacaoDesativarCarteira")
			.then(() => {
				this.carteiraService.desativarCarteira(carteiraId).subscribe((retorno: any) => this.filtrarCarteiras());
			});
	}
}