import { Component, Input } from "@angular/core";
import { ProviderService } from "../../../common/service/provider.service";
import { MatDialog } from "@angular/material";
import { CampoRetornoModalComponent } from "./campo-retorno-modal/campo-retorno-modal.component";
import { Lista } from "../../../common/model/lista";
import { ConfiguracaoListaProvedor } from "../../../common/model/configuracao-lista-provedor";
import { Provider } from "../../../common/model/provider";
import { isNullOrEmpty } from "../../../common/utils";
import { deepCopy } from "../../../common/utils/deepCopy";

@Component({
	selector: "app-lista-provedor",
	templateUrl: "./lista-provedor.component.html",
	styleUrls: ["../../style.css", "./lista-provedor.component.scss"],
	providers: [{ useClass: ProviderService, provide: ProviderService }]
})

export class ListaProvedorComponent {

	_dirty: boolean;

	public get dirty() {
		return this._dirty;
	}

	@Input()
	public set dirty(dirty: boolean) {
		this._dirty = dirty;
	}

	_lista: Lista;

	public get lista() {
		return this._lista;
	}

	@Input()
	public set lista(lista: Lista) {
		if (!lista.listaId)
			return;

		this._lista = lista;
		this.obterListaProvedorConfigurado()
	}

	public liveErrorArray(id: string, property: string = null, value: string = null) {
		if (this.dirty) {
			var validationResult = this.lista.validar();

			if (!validationResult)
				return null;

			let configRetorno = validationResult['configuracaoListaProvedorRetornoAtiva'];

			if (!configRetorno)
				return null;

			let validateErrors = validationResult["configuracaoListaProvedorRetornoAtiva"][0].errors;
			let isError: any;

			if (isNullOrEmpty(property) && isNullOrEmpty(value)) {
				isError = Object.keys(validateErrors)
					.map(f => ({ 'id': validateErrors[f].providerId, 'error': validateErrors[f].error }))
					.find(f => f.id == id);
			} else {

				isError = Object.keys(validateErrors)
					.map(f => ({ 'id': validateErrors[f].providerId, 'error': validateErrors[f].error }))
					.find((f: any) => {
						if (property.indexOf('.') === -1) {
							return f.id == id && f.error[property] == value;
						} else {
							let p = property.split('.');
							return f.id == id && f.error[p[0]][p[1]] == value;
						}
					});
			}

			if (isError)
				return true;

			return null;
		}
	}

	constructor(private providerService: ProviderService, private dialog: MatDialog) { }

	obterListaProvedorConfigurado() {
		this.providerService
			.obterProvedorConfiguradoPorLista(this.lista.listaId)
			.subscribe(provedores => {
				this.lista.configuracaoListaProvedor = provedores;
			});
	}

	exibirCamposRetorno(provedor: Provider) {

		let configuracao = this.lista.configuracaoListaProvedor.find((c: ConfiguracaoListaProvedor) => (c.providerId == provedor.providerId && c.ativo));

		const modalRef = this.dialog.open(CampoRetornoModalComponent, {
			hasBackdrop: true,
			height: "725px",
			width: "900px",
			data: {
				listaId: this.lista.listaId,
				listaTemplateId: this.lista.listaTemplateId,
				configuracaoListaProvedor: deepCopy(configuracao),
				provedor: provedor
			}
		});

		modalRef.afterClosed().subscribe((result: any) => {
			if (!result) return;

			this.lista.configuracaoListaProvedor.forEach((c: ConfiguracaoListaProvedor) => {
				if (c.providerId == provedor.providerId && c.ativo) {
					c.configuracaoCampoProviderId = result.camposChave.configuracaoCampoProviderId;
					c.listaTemplateItemId = result.camposChave.listaTemplateItemId;
					c.configuracaoListaProvedorItem = result.configuracaoListaProvedorItem;
				}
			});
		});

	}

	habilitaRetornoChange(event: any, provedor: Provider) {
		if (event.checked)
			this.exibirCamposRetorno(provedor);
	}
}