import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams, } from "@angular/common/http";
import { Tarefa } from "../model/tarefa";
import { environment } from "../../../../environments/environment";
import { convertToQueryString } from "../../common/service/query-string";

@Injectable()
export class TarefaService {
	constructor(private httpClient: HttpClient) { }

	obterTarefas(filtro: any, blockLoading: boolean = false) {

		if (blockLoading) {
			let headers = new HttpHeaders().set('blockLoading', 'true');
			return this.httpClient.get<Array<Tarefa>>(`${environment.serverUrl}/api/tarefas/estrategias-executadas${convertToQueryString(filtro)}`, { headers: headers });
		}
		else {
			return this.httpClient.get<Array<Tarefa>>(`${environment.serverUrl}/api/tarefas/estrategias-executadas${convertToQueryString(filtro)}`);
		}
	}

	obterEstrategiasParaExecutar(filtro: any, blockLoading: boolean = false) {
		if (blockLoading) {
			let headers = new HttpHeaders().set('blockLoading', 'true');
			return this.httpClient.get<Array<any>>(`${environment.serverUrl}/api/tarefas/estrategias-para-executar${convertToQueryString(filtro)}`, { headers: headers });
		}
		else {
			return this.httpClient.get<Array<any>>(`${environment.serverUrl}/api/tarefas/estrategias-para-executar${convertToQueryString(filtro)}`);
		}
	}

	limparTarefasNaoExecutadas() {
		return this.httpClient.get<any>(`${environment.serverUrl}/api/tarefas/limpar-tarefas-nao-executadas`);
	}

}
