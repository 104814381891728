import { Injectable, EventEmitter } from "@angular/core";

@Injectable()
export class EsBuilderService {

    onDadosListaCarregados: EventEmitter<any> = new EventEmitter();
    onDadosListaExpressaoCarregados: EventEmitter<any> = new EventEmitter();

    constructor() {
        this.onDadosListaCarregados = new EventEmitter();
        this.onDadosListaExpressaoCarregados = new EventEmitter();
    }

    emitDadosListaCarregados(listaNome: string, templateId: number) {
        this.onDadosListaCarregados.emit({ 'listaNome': listaNome, 'templateId': templateId });
    }

    emitDadosListaExpressaoCarregados(listaExpressao: any) {
        this.onDadosListaExpressaoCarregados.emit(listaExpressao);
    }
}
