import { BaseElasticsearchFilter } from "./base";

export class FieldQuery extends BaseElasticsearchFilter {
  protected getInstance(
    queryType: string,
    propertyName: string,
    parameters: any,
    extra?: any,
    keyword?: boolean
  ): BaseElasticsearchFilter {
    return new FieldQuery(propertyName, queryType, parameters, extra, keyword);
  }

  protected getQueryType(filter) {
    return Object.keys(filter)[0];
  }

  protected getPropertyName(filter, queryType) {
    var propName = Object.keys(filter[queryType])["field"];

    if (propName == undefined || propName == null)
      propName = filter[queryType]["field"]

    return propName;
  }

  public friendlyParameters(dataType: string, queryTypes: Array<any>) {
    return `Existe`;
  }

  protected convertFilter(): any {
    return { [this.queryType]: { field: this.property } };
  }
  static fromRaw(raw): FieldQuery {
    return Object.assign(new FieldQuery(), raw);
  }
}
