import { Component, Output, EventEmitter } from "@angular/core";
import { FiltroMonitorRetorno } from "../../../common/model/filtro-monitor-retorno";
import { Provider } from "../../../common/model/provider";
import { CustomAlertService } from "../../../common/service/custom-alert.service";
import { HistoricoRetornoService } from "../../../common/service/historico-retorno.service";

@Component({
    selector: "app-filtro-erro-processamento",
    templateUrl: "./filtro-erro-processamento.component.html",
    styleUrls: ["../style.scss"],
    providers: [
        { useClass: CustomAlertService, provide: CustomAlertService },
        { useClass: HistoricoRetornoService, provide: HistoricoRetornoService }
    ]
})

export class FiltroErroProcessamentoComponent {
    public filtro: FiltroMonitorRetorno = new FiltroMonitorRetorno();
    public isDirty: boolean = false;
    public provedoresRetorno: Array<Provider> = [];

    public get erros() {
        if (!this.isDirty)
            return false;

        return this.filtro.validarPesquisaErroProcessamento();
    }

    @Output()
    pesquisar: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private customAlertService: CustomAlertService,
        private historicoRetornoService: HistoricoRetornoService) {

        this.historicoRetornoService.obterProvedoresRetorno(0).subscribe(provedores => {
            this.provedoresRetorno = provedores;
        });
    }

    pesquisarFiltro() {
        this.isDirty = true;

        if (this.erros)
            return this.customAlertService.show(
                "menu.monitorRetorno",
                'telaPadrao.camposInvalidos',
                "error"
            );

        this.pesquisar.emit(this.filtro);
    }

    limpar() {
        this.filtro = new FiltroMonitorRetorno();
    }
}