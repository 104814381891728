import { Component, ElementRef } from "@angular/core";
import { ActivatedRoute, Router, Params } from "@angular/router";
import { MatDialog } from "@angular/material";
import * as XLSX from "xlsx";
import { CampoExportacaoModalComponent } from "./campo-exportacao-modal/campo-exportacao-modal.component";

import { CamposIntegracaoService } from "../../common/service/campos-integracao.service";
import { TemplateService } from "../../common/service/template.service";
import { ProviderService } from "../../common/service/provider.service";

import { Provider } from "../../common/model/provider";
import { CampoIntegracao } from "../../common/model/campo-integracao";
import { ListaTemplateItem, ItemTipo } from "../../common/model/lista-template-item";
import { ListaTemplateItemExportacao } from "../../common/model/lista-template-item-exportacao";
import { Template, TipoTemplate, TipoCategoria } from "../../common/model/template";
import { CustomAlertService } from "../../common/service/custom-alert.service";
import { changeOrder } from "../../common/model/change-collection-item-order";
import { IntegracaoTipo } from "../../common/model/provedor-configuracao";
import { MapeamentoCampanha } from "../../common/model/mapeamento-campanha";
import { ProvedorConfiguracaoTipo, CampoFormato, ConfiguracaoCampoProvider } from "../../common/model/configuracao-campo-provedor";
import { CampoCampanhaInfoModalComponent } from "./campo-campanha-info-modal/campo-campanha-info-modal.component";
import { isNull, isNullOrZero } from "../../common/utils";
import { CamposParametrosUrlModalComponent } from "./campos-parametros-url-modal/campos-parametros-url-modal.component";
import { ListaTemplateExportacaoParametrosUrl } from "../../common/model/lista-template-exportacao-parametros-url";

@Component({
	selector: "app-form-template-exportacao",
	templateUrl: "./form-template-exportacao.component.html",
	styleUrls: ["../style.css", "./form-template-exportacao.component.scss"],
	providers: [
		{ useClass: CamposIntegracaoService, provide: CamposIntegracaoService },
		{ useClass: TemplateService, provide: TemplateService },
		{ useClass: ProviderService, provide: ProviderService },
		{ useClass: CustomAlertService, provide: CustomAlertService }
	]
})

export class FormTemplateExportacaoComponent {

	static campoArrastado: ListaTemplateItemExportacao;
	templateImportacao: Template;
	template: Template;
	listaProvider: Array<Provider>;
	camposIntegracao: Array<CampoIntegracao>;
	camposIntegracaoExportacao: Array<ListaTemplateItem>;
	trackById: any;

	listaTemplateItemExportacao: Array<ListaTemplateItemExportacao>;
	isEdit: boolean = false;
	possuiLista: boolean = true;
	submited: boolean;
	provedor: Provider = new Provider();
	listaTemplatesExportacao: Array<Template>;

	validacaoCaminhoDestino: any;
	IntegracaoTipo: any = IntegracaoTipo;
	campoFormatoEnum = CampoFormato;

	_errosAsync: any;

	//#region [ Campanha]

	listaCampanha = new Array<MapeamentoCampanha>();
	listaCamposCampanha: Array<any>;
	temCamposDiferenca: boolean = false;
	camposCampanha: Array<string> = [];
	camposDiferenca: Array<any> = [];
	camposEnvioProvedor: Array<ConfiguracaoCampoProvider> = [];

	////#endregion

	constructor(
		public dialog: MatDialog,
		private camposIntegracaoService: CamposIntegracaoService,
		private templateService: TemplateService,
		private providerService: ProviderService,
		private router: Router,
		private route: ActivatedRoute,
		private customAlertService: CustomAlertService,
		private el: ElementRef
		
	) {

		this.templateImportacao = new Template();
		this.template = new Template();

		this.route.params.subscribe(params => this.carregar(params));
	}

	//#region [ Carregamento da página ]

	carregar(params: any) {

		if (!params.id) return;

		this.template.listaTemplateImportacaoId = params.id;
		this.template.listaTemplateCategoriaId = TipoCategoria.exportacao;

		this.providerService
			.obterProviders()
			.subscribe(listaProvider => (this.listaProvider = listaProvider));

		this.camposIntegracaoService
			.obterTodosCamposIntegracao()
			.subscribe(camposIntegracao => {
				this.camposIntegracao = camposIntegracao;
				this.camposIntegracao.forEach((i: any) => { i.value = i.listaCampoTipoId; i.label = i.nome });

				this.templateService
					.obterTemplate(params.id)
					.subscribe((template: Template) => {
						this.templateImportacao = template;
						this.camposIntegracaoExportacao = template.listaTemplateItem.filter((f: ListaTemplateItem) => f.ativo && (f.itemTipo == ItemTipo.Padrao || f.itemTipo == ItemTipo.Coluna || f.itemTipo == ItemTipo.Retorno));
						this.camposIntegracaoExportacao.forEach((i: any) => {
							i.value = i.listaTemplateItemId.toString();
							i.label = i.nome;
							var campoTipo = this.camposIntegracao.find((f: any) => f.listaCampoTipoId == i.listaCampoTipoId);
							i.permiteFormatacao = campoTipo.permiteFormatacao;
							i.formatos = campoTipo.listaCampoTipoFormatacao;
						});
					});


				if (params.duplicar) {
					this.templateService.obterTemplate(params.duplicar).subscribe(template => {
						this.formatarCarregamentoTemplate(template, params);
					});
				} else {
					if (!params.idExportacao) return;
					this.setIfEditable(params.idExportacao);
					this.isEdit = true;

					this.templateService.obterTemplate(params.idExportacao).subscribe(template => {
						this.formatarCarregamentoTemplate(template, params);
						this.sortExportacao();
					});
				}
			});
	}

	setIfEditable(id: number) {
		this.templateService
			.obterSeTemplatePossuiLista(id)
			.subscribe(possuiLista => (this.possuiLista = possuiLista));
	}



	formatarCarregamentoTemplate(template: Template, params: Params) {


		this.template = template;
		this.provedor = this.template.provider;
		this.template.listaTemplateImportacaoId = params.id;
		this.template.listaTemplateCategoriaId = TipoCategoria.exportacao;

		this.template.nome = params.duplicar ? `${template.nome} - Copy` : this.template.nome;
		this.template.listaTemplateId = params.duplicar ? "" : this.template.listaTemplateId;

		if (this.provedor.envio.campoFormatoId != this.campoFormatoEnum.Raw) {
			this.obterCamposEnvioProvedor();
			this.obterCampanhasConfiguradas();
			this.compararCamposCampanha();
		}

		this.listaTemplateItemExportacao = this.template.listaTemplateItemExportacao;
		this.template.listaTemplateItemExportacao.forEach((i: any) => {
			i.listaTemplateItemExportacaoId = params.duplicar ? 0 : i.listaTemplateItemExportacaoId;
			i.listaTemplateId = params.duplicar ? 0 : i.listaTemplateId;
			var campoTipo = this.camposIntegracao.find((f: any) => f.listaCampoTipoId == i.listaCampoTipoId);
			i.permiteFormatacao = campoTipo == undefined ? false : campoTipo.permiteFormatacao;
			i.formatos = campoTipo == undefined ? null : campoTipo.listaCampoTipoFormatacao;
		});
	}

	onProvedorSelecionado() {
		this.template.listaTemplateItemExportacao = [];
		this.template.ativarCaminhoDestino = false;
		this.template.caminhoDestino = null;
		this.template.campanhaId = null;
		this.provedor = new Provider();
		this.template.provider = this.provedor;

		if (this.template.providerId != null) {
			this.providerService.obterProviderPorId(this.template.providerId)
				.subscribe(provider => {
					this.provedor = provider;
					this.template.provider = provider;
					this.obterCamposEnvioProvedor();

					if (this.provedor.envio.campoFormatoId != this.campoFormatoEnum.Raw) {
						this.obterCampanhasConfiguradas();
						this.verificarCamposProvedor();
					} else {
						this.template.listaTemplateItemExportacao = this.camposEnvioProvedor.map((c: ConfiguracaoCampoProvider, index: number) => {
							return new ListaTemplateItemExportacao("raw", null, index, 1, c.campoJson, null, 0, c.configuracaoCampoProviderId);
						});
					}
				});
		}
	}

	onCampanhaSelecionada() {
		if (this.template.campanhaId != null) {
			this.providerService
				.obterListaCampo(this.template.providerId, this.template.campanhaId)
				.subscribe(campos => {
					this.formatarCamposCampanha(campos);
				});
		}
	}

	compararCamposCampanha() {
		if (this.provedor.configuracaoPorCampanha && this.provedor.campanha.layoutPorCampanha) {
			this.providerService
				.obterListaCampo(this.template.providerId, this.template.campanhaId)
				.subscribe(campos => {
					this.camposCampanha = campos;
					let camposTemplate = this.template.listaTemplateItemExportacao.map(l => l.alias);
					let r = [...new Set([...this.camposCampanha, ...camposTemplate])];
					this.camposDiferenca = r.map(c => ({ campo: c, template: camposTemplate.some(cc => cc == c), campanha: this.camposCampanha.some(cc => cc == c) }));
					this.temCamposDiferenca = this.camposDiferenca.some(c => c.template == false || c.campanha == false);
				});
		}
	}

	formatarCamposCampanha(campos: Array<string>) {
		let ordem = 0;
		this.template.listaTemplateItemExportacao = [];
		this.template.listaTemplateItemExportacao = campos.map(c => <ListaTemplateItemExportacao>{ alias: c, ativo: true, ordem: ordem++ });
	}

	obterCampanhasConfiguradas() {
		if (this.provedor.configuracaoPorCampanha && this.provedor.campanha.layoutPorCampanha) {
			this.providerService.obterCampanhasConfiguradasProvedor(this.template.providerId, this.template.listaTemplateImportacaoId)
				.subscribe(campanhas => {
					this.listaCampanha = campanhas;
				});
		}
	}

	exibirCamposCampanhaInfo() {
		this.dialog.open(CampoCampanhaInfoModalComponent, {
			width: "650px",
			height: "600px",
			data: {
				campos: this.camposDiferenca
			}
		});
	}

	atualizarCamposCampanha() {
		this.customAlertService.confirmationMessage("telaTemplate.confirmarAtualizacaoCampo")
			.then(() => {
				this.formatarCamposCampanha(this.camposCampanha);
			});
	}

	obterCamposEnvioProvedor() {
		this.camposEnvioProvedor = this.provedor.configuracaoCampoProvider.filter(c => c.ativo && c.provedorConfiguracaoTipoId == ProvedorConfiguracaoTipo.Envio);

		if (!isNull(this.camposEnvioProvedor))
			this.camposEnvioProvedor = this.camposEnvioProvedor.sort((a: any, b: any) => a.ordem - b.ordem);
	}

	verificarCamposProvedor() {
		if ((!this.provedor.configuracaoPorCampanha || (this.provedor.campanha && !this.provedor.campanha.layoutPorCampanha)) && this.camposEnvioProvedor.length > 0) {
			if (this.camposEnvioProvedor[0].campoFormatoId == CampoFormato.Form) {
				this.template.listaTemplateItemExportacao = [];
				this.template.listaTemplateItemExportacao = this.camposEnvioProvedor.map((c: ConfiguracaoCampoProvider, index: number) => {
					let tipoCampoExportacao = (c.obrigatorio) ? 0 : 2;
					return new ListaTemplateItemExportacao(c.nome, null, index, tipoCampoExportacao, "", c.listaCampoTipoId, 0, c.configuracaoCampoProviderId);
				});
			}
		}
	}

	ehCampoSistemico(configuracaoCampoProviderId: number): boolean {
		let ccp = this.camposEnvioProvedor.find((f: ConfiguracaoCampoProvider) => f.configuracaoCampoProviderId == configuracaoCampoProviderId);
		return (isNull(ccp)) ? false : ccp.campoSistemico;
	}

	verificaObrigatorio(configuracaoCampoProviderId: number) {

		if (configuracaoCampoProviderId > 0)
			return this.camposEnvioProvedor.find((f: ConfiguracaoCampoProvider) => f.configuracaoCampoProviderId == configuracaoCampoProviderId).obrigatorio;
		else
			return false;
	}

	//#endregion

	//#region [ CRUD parametros URL ]

	public get exibirParametrosUrl(): boolean {
		if (isNullOrZero(this.provedor.providerId))
			return false;

		return this.provedor.envio.parametrosUrl;
	}

	modalCamposParametrosUrl() {
		let dialogRef = this.dialog.open(CamposParametrosUrlModalComponent, {
			width: "900px",
			hasBackdrop: true,
			data: {
				'listaTemplateId': this.template.listaTemplateId,
				'caminhoIntegracao': this.provedor.envio.caminhoIntegracao,
				'parametrosUrl': this.template.listaTemplateExportacaoParametrosUrl,
				'camposIntegracao': this.templateImportacao.listaTemplateItem.filter((f: ListaTemplateItem) => f.ativo && (f.itemTipo == ItemTipo.Padrao || f.itemTipo == ItemTipo.Coluna) && f.listaCampoTipoId != 11 && f.listaCampoTipoId != 18)
			}
		});

		dialogRef.afterClosed().subscribe((result: Array<ListaTemplateExportacaoParametrosUrl>) => {

			if (!result) return;
			this.template.listaTemplateExportacaoParametrosUrl = result;
		});
	}

	//#endregion

	//#region [ CRUD campos exportação ]

	modalCampoExportacao(campoSelecionado: any = null) {

		let dialogRef = this.dialog.open(CampoExportacaoModalComponent, {
			width: "750px",
			hasBackdrop: true,
			data: {
				'camposIntegracao': this.camposIntegracaoExportacao,
				'listaTemplateItem': this.template.listaTemplateItemExportacao,
				'campoSelecionado': campoSelecionado ? campoSelecionado : { permiteFormatacao: false },
				'exibirQuantidade': this.template.listaTemplateTipoId == TipoTemplate.posicional,
				'conteudo': "",
				'tipoCampoExportacao': campoSelecionado ? campoSelecionado.tipoCampoExportacao : 0
			}
		});

		dialogRef.afterClosed().subscribe(result => {
			if (!result) return;
			this.atualizarCampoExportacao(result, campoSelecionado);
		});
	}

	adicionarTodosCamposImportacao() {

		let quantidadeCampo = 0;

		this.camposIntegracaoExportacao.sort((a: any, b: any) => a.ordem - b.ordem).forEach((f: ListaTemplateItem) => {
			if (!this.template.listaTemplateItemExportacao.some((s: ListaTemplateItemExportacao) => s.alias.toLowerCase() == f.nome.toLowerCase())) {
				let campo = {
					'listaTemplateItemId': f.listaTemplateItemId,
					'nome': f.nome,
					'tipoCampoExportacao': 0,
					'conteudo': '',
					'tamanho': f.tamanho,
				};
				this.atualizarCampoExportacao(campo, null);
				quantidadeCampo++;
			}
		});

		let mensagem = (quantidadeCampo == 0) ? 'Nenhum campo adicionado' : ((quantidadeCampo == 1) ? `${quantidadeCampo} campo adicionado` : `${quantidadeCampo} campos adicionados`);
		this.customAlertService.show("telaTemplate.template", mensagem, "success");
	}

	atualizarCampoExportacao(result: any, campoEditado: any) {
		let campo = this.camposIntegracaoExportacao.find(c => c.listaTemplateItemId == result.listaTemplateItemId);

		if (campoEditado) {
			this.template.listaTemplateItemExportacao = this.template.listaTemplateItemExportacao.map(
				(m: any) => {
					if (m.alias == campoEditado.alias && m.ordem == campoEditado.ordem) {
						m.alias = result.nome;
						m.tamanho = result.tamanho;
						m.conteudo = result.conteudo;
						m.tipoCampoExportacao = result.tipoCampoExportacao;
						m.listaTemplateItemId = (campo) ? campo.listaTemplateItemId : null;

						if (this.template.providerId == 2) {
							m.listaCampoTipoId = null;
							if (campo) {
								if (campo.listaCampoTipoId > 0)
									m.listaCampoTipoId = campo.listaCampoTipoId;
							}
						}

					}
					return m;
				}
			);
		} else {

			let item = new ListaTemplateItemExportacao(
				result.nome,
				((campo) ? campo.listaTemplateItemId : null),
				this.template.listaTemplateItemExportacao.length + 1,
				result.tipoCampoExportacao,
				result.conteudo,
				null,
				result.tamanho
			);
			if (this.template.providerId == 2) {
				if (campo) {
					if (campo.listaCampoTipoId > 0)
						item.listaCampoTipoId = campo.listaCampoTipoId;
				} else {
					item.listaCampoTipoId = 8 //Campo Vazio colocar como o tipo texto
				}
			}
			this.template.listaTemplateItemExportacao.push(item);
			this.template.listaTemplateItemExportacao = [...this.template.listaTemplateItemExportacao];

			if (campo) {
				item['permiteFormatacao'] = campo['permiteFormatacao'];
				item['formatos'] = campo['formatos'];
			}
		}
	}

	listaCampoTipoChange(campo: any, $event: any) {
		if (campo.listaTemplateItemId) {
			var campoIntegracao = this.camposIntegracaoExportacao.find((item) => (item.listaTemplateItemId == campo.listaTemplateItemId));
			campo.permiteFormatacao = campoIntegracao['permiteFormatacao'];
			campo.formatos = campoIntegracao['formatos'];
			if (this.template.providerId != 1)
				campo.listaCampoTipoId = campoIntegracao.listaCampoTipoId;
		}
		else {
			campo.permiteFormatacao = false;
			campo.conteudo = '';
			campo.listaCampoTipoId = null;
		}
	}

	obterCampoIntegracao(templateItem: ListaTemplateItemExportacao) {
		if (!this.camposIntegracaoExportacao)
			return "";

		let campo = this.camposIntegracaoExportacao.find((c: any) => c.listaTemplateItemId == templateItem.listaTemplateItemId);

		return (campo) ? campo.nome : ""
	}

	editarCampoExportacao(campo: any) {
		if (this.possuiLista && this.isEdit) return;

		let campoEdit = this.template.listaTemplateItemExportacao.find((f: any) => f.alias == campo.alias && f.tamanho == campo.tamanho && f.ordem == campo.ordem);
		this.modalCampoExportacao(campoEdit);
	}

	excluirCampoExportacao(campo: any) {
		if (this.possuiLista && this.isEdit) return;
		let li = new Array<any>();

		this.template.listaTemplateItemExportacao.forEach((f: any) => {
			if (!(f.alias == campo.alias && f.tamanho == campo.tamanho && f.ordem == campo.ordem))
				li.push(f);
		});

		this.template.listaTemplateItemExportacao = li;
	}

	//#endregion

	//#region [ Ordenação dos campos exportação ]

	obterPosicaoCampo(campo: any) {
		return this.template.listaTemplateItemExportacao.indexOf(campo);
	}

	sortExportacao() {
		this.template.listaTemplateItemExportacao = this.template.listaTemplateItemExportacao
			.filter((listaTemplate: any) => listaTemplate.ativo)
			.sort((a: any, b: any) => a.ordem - b.ordem)
			.map((templateItem: any, index: number) => {
				templateItem.ordem = index;
				return templateItem;
			});
	}

	mudarCampoOrdem(campo: any, mudanca: number) {
		let posicao = campo.ordem + mudanca;
		this.template.listaTemplateItemExportacao.forEach((campoLista: any) => {
			if (mudanca > 0 && campoLista.ordem >= posicao) campoLista.ordem--;
			if (mudanca < 0 && campoLista.ordem <= posicao) campoLista.ordem++;
		});
		campo.ordem = posicao;
		this.sortExportacao();
	}

	handleDragStart(campo: ListaTemplateItemExportacao) {
		FormTemplateExportacaoComponent.campoArrastado = campo;
	}

	handleDragOver(event: Event) {
		event.preventDefault();
		(<Element>event.target).parentElement.classList.add("droppable");
	}

	handleDragLeave(event: Event) {
		event.preventDefault();
		(<Element>event.target).parentElement.classList.remove("droppable");
	}

	handleDrop(event: Event, campo: ListaTemplateItemExportacao) {
		(<Element>event.target).parentElement.classList.remove("droppable");
		var newOrderCollection = changeOrder(this.template.listaTemplateItemExportacao, FormTemplateExportacaoComponent.campoArrastado, campo);
		this.template.listaTemplateItemExportacao.forEach((ordem: any) => ordem.ordem = newOrderCollection.indexOf(ordem));
		this.sortExportacao();
	}


	//#endregion

	//#region [ Envio modelo ]

	incluirTemplateModelo(event: any) {

		let input = event.target;
		let extensaoPermitida = this.validarExtensao(event);

		if (extensaoPermitida && input.files.length > 0) {
			let reader = new FileReader();
			reader.onload = (e: any) => {
				let result: string = (<any>e.target).result;
				let possuiDelimitador = result.indexOf(this.template.delimitador);

				if (possuiDelimitador == -1)
					this.customAlertService.show("Arquivo modelo", "O arquivo enviado não possui o delimitador", "error");
				else {
					let colunas = [];
					if (extensaoPermitida == ".xls" || extensaoPermitida == ".xlsx")
						colunas = this.getColunasExcel(result);
					else
						colunas = result.split("\n")[0].split(this.template.delimitador);

					const findDuplicates = (arr: any) => {
						let sorted_arr = arr.slice().sort();
						let results: Array<string> = [];
						for (let i = 0; i < sorted_arr.length - 1; i++) {
							if ((sorted_arr[i + 1] as string).trim().toLowerCase() == (sorted_arr[i] as string).trim().toLowerCase())
								results.push(sorted_arr[i]);
						}
						return results;
					}

					let duplicados = findDuplicates(colunas);

					if (duplicados.length == 1)
						this.customAlertService.show("Arquivo modelo", `A coluna ${duplicados[0]} foi removida por estar duplicada no arquivo.`, "warning");
					else if (duplicados.length > 1)
						this.customAlertService.show("Arquivo modelo", `As colunas ${duplicados.join(', ').replace(/,\s([^,]+)$/, ' e $1')} foram removidas por estarem duplicadas no arquivo.`, "warning");

					colunas = colunas.map((item: string) => item.replace(/[^a-zA-Z0-9|_]/g, '').replace(/\s/g, ''));

					this.adicionarListaTemplateItem(colunas);
				}
			};
			reader.readAsBinaryString(input.files[0]);
		}
	}

	adicionarListaTemplateItem(colunas: any) {
		for (var i = 0; i < colunas.length; i++) {
			if (this.template.listaTemplateItemExportacao.filter(f => f.alias.trim().toLowerCase() == colunas[i].trim().toLowerCase()).length == 0 && colunas[i] != '')
				this.template.listaTemplateItemExportacao.push(
					new ListaTemplateItemExportacao(
						colunas[i].replace("\r", "").replace("\n", ""),
						null,
						(this.template.listaTemplateItemExportacao.length + 1),
						0,
						"",
						null,
						0
					)
				);
		}
	}

	validarExtensao(event: any) {
		var validExts = new Array(".txt", ".xlsx", ".xls", ".csv");
		var fileExt = event.target.files[0].name;
		fileExt = fileExt.substring(fileExt.lastIndexOf("."));
		if (validExts.indexOf(fileExt) < 0) {
			this.customAlertService.show("Arquivo modelo", "As únicas extensões aceitas são: .txt, .csv, .xls e .xlsx", "error");
			return null;
		} else return fileExt;
	}

	getColunasExcel(result: any) {
		let colunas = [];
		const wb: XLSX.WorkBook = XLSX.read(result, { type: "binary" });
		wb.SheetNames.forEach(element => {
			let sheet = wb.Sheets[element];
			var range = XLSX.utils.decode_range(sheet["!ref"]);
			var C,
				R = range.s.r; /* start in the first row */
			/* walk every column in the range */
			for (C = range.s.c; C <= range.e.c; ++C) {
				var cell =
					sheet[
					XLSX.utils.encode_cell({ c: C, r: R })
					]; /* find the cell in the first row */
				var hdr = "UNKNOWN " + C; // <-- replace with your desired default
				if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);
				colunas.push(hdr);
			}
		});
		return colunas;
	}

	//#endregion

	//#region [ Salvar ]

	public get error() {
		if (this.submited) {
			let campos = this.camposIntegracao.filter((f: any) => (
				this.template.listaTemplateItem.some((s: any) => f.listaCampoTipoId == s.listaCampoTipoId)
			));
			return this.template.validar(campos);
		}
	}

	public get errosAsync() {
		return this._errosAsync;
	}

	validarDiretorioExiste() {
		this.template
			.validarDiretorioExisteAsync(this.templateService)
			.then((res: any) => { this._errosAsync = undefined; }, (err: any) => { this._errosAsync = err; });
	}

	salvar() {

		this.submited = true;

		let templateAlteracao = this.template;

		// if (this.template.listaTemplateId && this.provedor.envio.campoFormatoId != this.campoFormatoEnum.Raw) {
		// 	let itensExcluidos = new Array<ListaTemplateItemExportacao>();
		// 	let ordem = this.template.listaTemplateItemExportacao.length;
		// 	this.listaTemplateItemExportacao.forEach((f: ListaTemplateItemExportacao) => {
		// 		if (!this.template.listaTemplateItemExportacao.some((s: ListaTemplateItemExportacao) => s.listaTemplateItemExportacaoId == f.listaTemplateItemExportacaoId)) {
		// 			ordem++;
		// 			f.ativo = false;
		// 			f.ordem = ordem;
		// 			itensExcluidos.push(f);
		// 		}
		// 	});

		// 	templateAlteracao.listaTemplateItemExportacao = this.template.listaTemplateItemExportacao
		// 		.concat(itensExcluidos)
		// 		.sort((a: ListaTemplateItemExportacao, b: ListaTemplateItemExportacao) => a.ordem - b.ordem)
		// 		.map((templateItem: ListaTemplateItemExportacao, index: number) => {
		// 			templateItem.listaTemplateId = parseInt(this.templateImportacao.listaTemplateId);
		// 			templateItem.ordem = index;
		// 			return templateItem;
		// 		});
		// }

		let campos = this.camposIntegracao.filter((f: any) => (
			templateAlteracao.listaTemplateItemExportacao.some((s: any) => f.listaCampoTipoId == s.listaCampoTipoId)
		));

		if (templateAlteracao.validar(campos)) {
			this.customAlertService.show("telaTemplate.template", "telaPadrao.camposInvalidos", "error");
			return;
		}

		templateAlteracao
			.validarDiretorioExisteAsync(this.templateService)
			.then((res: any) => {
				this._errosAsync = undefined;
				let sucesso = () => {
					this.customAlertService.show("telaTemplate.template", "telaPadrao.sucessoSalvar", "success");
					localStorage.setItem("abaTemplate", '1');
					this.router.navigate(["/app/template/", this.templateImportacao.listaTemplateId]);
				};

				let erro = () => { this.customAlertService.show("telaTemplate.template", "telaPadrao.erroSalvar", "error") };

				if (!templateAlteracao.listaTemplateId)
					return this.templateService.criar(templateAlteracao).subscribe(sucesso, erro);

				this.templateService.atualizar(templateAlteracao).subscribe(sucesso, erro);
			},
				(err: any) => {
					this._errosAsync = err;
					this.customAlertService.show("telaTemplate.template", "telaPadrao.camposInvalidos", "error");
					return;
				});
	}

	voltar() {
		localStorage.setItem("abaTemplate", '1');
		this.router.navigate(["/app/template/", this.templateImportacao.listaTemplateId]);
	}

	strTooltipExemplo(campo) {
		let formato = campo.formatos.find((f: any) => f.formato == campo.conteudo);
		return (isNull(formato)) ? '' : 'Exemplo: ' + formato.exemplo;
	}

	//#endregion

	//#region [ Customização Select Campanhas ]

	customSearchFn(term: string, item: any) {
		term = term.toLocaleLowerCase();
		return item.value.toString().toLocaleLowerCase().indexOf(term) > -1 || item.label.toString().toLocaleLowerCase().indexOf(term) > -1;
	}

	////#endregion
}