import { Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Subscription } from "rxjs";
import { DashListaProvedorService } from "../../../common/service/dash-lista-provedor.service";
import { generateCanvas } from "../../libs/utils/canvas";

@Component({
    selector: "app-dash-lista-provedor-registro",
    templateUrl: "./dash-lista-provedor-registro.component.html",
    styleUrls: ["../dash-lista-provedor.component.scss", "./dash-lista-provedor-registro.component.scss"],
    providers: []
})

export class DashListaProvedorRegistroComponent implements OnInit, OnDestroy {

    subRegistro: Subscription;

    dados: any = {
        'qtdUtilizado': 0,
        'qtdNaoUtilizado': 0,
        'pctUtilizado': 0,
        'pctNaoUtilizado': 0,
    }

    private ctxUtilizado: CanvasRenderingContext2D;
    private ctxNaoUtilizado: CanvasRenderingContext2D;

    @ViewChild('canvasUtilizado', { static: true }) public canvasUtilizado: ElementRef<HTMLCanvasElement>;
    @ViewChild('canvasNaoUtilizado', { static: true }) public canvasNaoUtilizado: ElementRef<HTMLCanvasElement>;

    constructor(
        private dashListaProvedorService: DashListaProvedorService,
        private zone: NgZone) {

    }

    ngOnInit() {
        this.subRegistro = this.dashListaProvedorService.onRegistroAtualizar.subscribe(data => {
            this.dashListaProvedorService.obterStatusRegistro(data.filtro).subscribe(result => {
                this.dados = result;

                this.ctxUtilizado = generateCanvas(this.canvasUtilizado, this.dados.pctUtilizado, '#929292');
                this.ctxNaoUtilizado = generateCanvas(this.canvasNaoUtilizado, this.dados.pctNaoUtilizado, '#929292');
            });
        });
    }

    ngOnDestroy() {
        if (this.subRegistro)
            this.subRegistro.unsubscribe();
    }
}