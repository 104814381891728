import "rxjs/add/operator/map";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { environment } from "../../../../environments/environment";
import { SistemaRecursoAcao } from "../model/sistema-recurso-acao";

@Injectable()
export class SistemaRecursoAcaoService {
  constructor(private httpClient: HttpClient) {}

  obterRecursos() {
    return this.httpClient.get<Array<SistemaRecursoAcao>>(
      `${environment.serverUrl}/api/sistemaRecursoAcao`
    );
  }
}
