import * as validate from "validate.js";

export class FiltroDashListaProvedor {
    listaId: number;
    listaNome: string;
    carteiraId: number;

    constructor(listaId: number = 0, listaNome: string = null, carteiraId: number = 0) {
        this.listaId = listaId;
        this.listaNome = listaNome;
        this.carteiraId = carteiraId;
    }

    validar() {
        var validacao: any = {
            listaId: {
                presence: {
                    allowEmpty: false,
                    message: "campoObrigatorio"
                }
            }
        };
        return validate(this, validacao, { fullMessages: false })
    }
}