import { BaseElasticsearchFilter } from "./base";
import { FieldQuery } from "./field_query";

export class EmptyQuery extends FieldQuery {
  protected getInstance(
    queryType: string,
    propertyName: string,
    parameters: any,
    extra?: any,
    keyword?: boolean
  ): BaseElasticsearchFilter {
    return new EmptyQuery(propertyName, queryType, parameters, extra, keyword);
  }

  protected getQueryType(filter) {
    return "script";
  }

  protected getPropertyName(filter, queryType) {
    var propName = this.property;

    if (propName == undefined || propName == null)
      propName = filter[queryType]["script"].inline.match(/doc\['(.*)'\].empty/)[1];

    return propName;
  }

  public friendlyParameters(dataType: string, queryTypes: Array<any>) {
    return `Não Existe`;
  }

  protected convertFilter() {
    return {
      "script": {
        "script": {
          "inline": "doc['" + this.property + "'].empty",
          "lang": "painless"
        }
      }
    };
  }
  static fromRaw(raw): EmptyQuery {
    return Object.assign(new EmptyQuery(), raw);
}
}