import { NgModule, LOCALE_ID } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import {
  MatListModule,
  MatChipsModule,
  MatButtonModule,
  MatDividerModule,
  MatProgressBarModule,
  MatIconModule,
  MatMenuModule,
  MatSelectModule,
  MatInputModule,
  MatSlideToggleModule,
  MatDatepickerModule,
  MatExpansionModule,
  MatTooltipModule,
  MatTabsModule,
  MatCheckboxModule,
  MatCardModule,
  MatPaginatorModule,
  MatRadioModule,  
  MAT_DIALOG_DEFAULT_OPTIONS
} from "@angular/material";
import { TableModule } from "primeng/table";
import { FileUploadModule } from 'primeng/fileupload';
import { AmazingTimePickerModule } from "amazing-time-picker";
import { TextInputAutocompleteModule } from "angular-text-input-autocomplete";

import { TemplateModule } from "../template/template.module";
import { ElasticsearchModule } from "../elasticsearch/elasticsearch.module";
import { AppCommonModule } from "../common/common.module";

import { ListaStatusDisplayDirective } from "./directives/lista-status-display.directive";
import { ImportacaoAutomaticaHistoricoStatusDisplayDirective } from "./directives/importacao-automatica-historico-status-display.directive";

import { StatusArquivoPipe } from "./pipes/status-arquivo.pipe";
import { StatusLimpezaFiltroArquivoPipe } from "./pipes/status-limpeza-filtro-arquivo.pipe";

import { ListaComponent } from "./lista/lista.component";
import { ListaDashboardComponent } from "./lista-dashboard/lista-dashboard.component";

import { FormListaComponent } from "./form-lista/form-lista.component";
import { DadosListaComponent } from "./form-lista/dados-lista/dados-lista.component";
import { LimpezaFiltroArquivoComponent } from "./limpeza-filtro-arquivo/limpeza-filtro-arquivo.component";

import { FiltroListaComponent } from "./lista/filtro-lista/filtro-lista.component";

import { ListaCadastroComponent } from "./lista-cadastro/lista-cadastro.component";
import { FonteDadosComponent } from "./lista-cadastro/fonte-dados/fonte-dados.component";

import { UploadArquivosComponent } from "./upload-arquivos/upload-arquivos.component";
import { DisplayTemplateComponent } from "./upload-arquivos/display-template/display-template.component";
import { PreviewDadosHistoricoComponent } from "./historico-indexacao/preview-dados-historico/preview-dados-historico.component";
import { PreviewDadosArquivoComponent } from "./upload-arquivos/preview-dados-arquivo/preview-dados-arquivo.component";
import { ListaProvedorComponent } from "./lista-cadastro/lista-provedor/lista-provedor.component";
import { HistoricoIndexacaoComponent } from "./historico-indexacao/historico-indexacao.component";
import { ImportacaoAutomaticaComponent } from "./upload-arquivos/importacao-automatica/importacao-automatica.component";
import { ImportacaoAutomaticaVisualizacaoComponent } from "./upload-arquivos/importacao-automatica-visualizacao/importacao-automatica-visualizacao.component";
import { LimpezaAutomaticaComponent } from "./limpeza-automatica/limpeza-automatica.component";
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { environment } from "../../../environments/environment";
import { CampoRetornoModalComponent } from "./lista-cadastro/lista-provedor/campo-retorno-modal/campo-retorno-modal.component";

@NgModule({
  imports: [
    AppCommonModule,
    CommonModule,
    MatListModule,
    MatChipsModule,
    MatButtonModule,
    MatDividerModule,
    MatProgressBarModule,
    MatIconModule,
    MatMenuModule,
    MatSelectModule,
    MatInputModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatTabsModule,
    TemplateModule,
    FormsModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatButtonToggleModule,    
    AmazingTimePickerModule,
    MatCardModule,
    TableModule,
    ElasticsearchModule,
    MatPaginatorModule,
    MatRadioModule,
    TextInputAutocompleteModule,
    FileUploadModule
  ],
  entryComponents: [
    PreviewDadosHistoricoComponent,
    PreviewDadosArquivoComponent,
    DisplayTemplateComponent,
    ImportacaoAutomaticaComponent,
    ImportacaoAutomaticaVisualizacaoComponent,
    CampoRetornoModalComponent,
    LimpezaAutomaticaComponent
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "pt-BR" },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { panelClass: `theme-${environment.theme}` } }
  ],
  declarations: [
    ListaComponent,
    ListaDashboardComponent,
    FormListaComponent,
    HistoricoIndexacaoComponent,
    DadosListaComponent,
    LimpezaFiltroArquivoComponent,
    ListaStatusDisplayDirective,
    ImportacaoAutomaticaHistoricoStatusDisplayDirective,
    FiltroListaComponent,
    ListaCadastroComponent,
    FonteDadosComponent,
    StatusArquivoPipe,
    StatusLimpezaFiltroArquivoPipe,
    UploadArquivosComponent,
    PreviewDadosHistoricoComponent,
    PreviewDadosArquivoComponent,
    DisplayTemplateComponent,
    ListaProvedorComponent,
    ImportacaoAutomaticaComponent,
    ImportacaoAutomaticaVisualizacaoComponent,
    CampoRetornoModalComponent,
    LimpezaAutomaticaComponent
  ]
})
export class ListaModule {
  // static _injector: Injector;

  // constructor(private injector: Injector) {
  //   ListaModule._injector = injector;
  // }

}
