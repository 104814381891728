export class ListaTemplateItemExportacao {
  public listaTemplateItemExportacaoId: number;
  public listaTemplateId: number;
  public listaTemplateItemId: number;
  public alias: string;
  public ordem: number;
  public ativo: boolean;
  public tipoCampoExportacao: number;
  public conteudo: string;
  public tamanho: number;
  public listaCampoTipoId: number;
  public virtualId: number;
  public dataCadastro: Date;
  public configuracaoCampoProviderId: number;

  constructor(
    nome: string,
    listaTemplateItemId: number,
    ordem: number,
    tipoCampoExportacao: number,
    conteudo: string,
    listaCampoTipoId: number,
    tamanho: number = 0,
    configuracaoCampoProviderId?: number
  ) {
    this.alias = nome;
    this.listaTemplateItemId = listaTemplateItemId;
    this.tamanho = tamanho;
    this.ordem = ordem;
    this.ativo = true;
    this.tipoCampoExportacao = tipoCampoExportacao;
    this.listaCampoTipoId = listaCampoTipoId;
    this.conteudo = conteudo;
    this.configuracaoCampoProviderId = configuracaoCampoProviderId;
  }
}
