import { mergeMap, tap, first } from 'rxjs/operators';
import swal from "sweetalert2";
import "rxjs/add/operator/do";
import "rxjs/add/operator/first";
import "rxjs/add/operator/mergeMap";
import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ListaService } from "../../common/service/lista.service";
import { ProviderService } from "../../common/service/provider.service";
import { Lista } from "../../common/model/lista";
import { TemplateService } from "../../common/service/template.service";
import { Template, TipoCategoria } from "../../common/model/template";
import { CustomAlertService } from "../../common/service/custom-alert.service";
import { isNullOrEmpty } from '../../common/utils';
import { TranslateService } from '@ngx-translate/core';
import { CarteiraService } from '../../common/service/carteira.service';
import { Carteira } from '../../common/model/carteira';
import { PreferenciasUsuario } from '../../common/model/preferencias-usuario';
import { PreferenciasUsuarioService } from '../../common/service/preferencias-usuario.service';

@Component({
	selector: "app-lista-cadastro",
	templateUrl: "./lista-cadastro.component.html",
	styleUrls: ["./lista-cadastro.css"],
	providers: [
		{ useClass: ListaService, provide: ListaService },
		{ useClass: CarteiraService, provide: CarteiraService },
		{ useClass: PreferenciasUsuarioService, provide: PreferenciasUsuarioService },
		{ useClass: ProviderService, provide: ProviderService },
		{ useClass: TemplateService, provide: TemplateService },
		{ useClass: CustomAlertService, provide: CustomAlertService }
	]
})

export class ListaCadastroComponent {
	listaId: number;
	lista: Lista;
	dirty: boolean;
	listaTemplate: Array<Template>;
	listaTemplateFiltrada: Array<Template> = [];
	abaVisivel = 0;

	preferenciasUsuario: PreferenciasUsuario;
	carteiras: Array<Carteira> = [];
	carteiraId: number;

	public get erros() {
		if (!this.dirty) return;

		return this.lista.validar();
	}

	public get disabledCarteira(): boolean {
		return (this.carteiras.length == 1);
	}

	isEdit: boolean = false;
	// public get isEdit(): boolean {
	// 	return this.lista != undefined && this.lista.listaId != undefined;
	// }

	constructor(
		private route: ActivatedRoute,
		private listaService: ListaService,
		private router: Router,
		private carteiraService: CarteiraService,
		private preferenciasService: PreferenciasUsuarioService,
		private templateService: TemplateService,
		private customAlertService: CustomAlertService,
		private translate: TranslateService
	) {
		this.lista = new Lista();

		// Preferencias do usuário
		this.preferenciasUsuario = this.preferenciasService.localStorageObterPreferenciasUsuario();


		this.route.params.subscribe(params => {

			if (params.id) {
				this.isEdit = true;

				this.obterTemplates();

			} else {
				this.carteiraService.obterCarteiras().subscribe(carteiras => {
					this.carteiras = carteiras;
					if (this.preferenciasUsuario.existePreferenciaCarteira) {
						this.carteiraId = this.preferenciasUsuario.carteiraId as number;
					} else {
						let padrao = this.carteiras.find(f => f.padrao) as Carteira;
						this.carteiraId = padrao.carteiraId;
					}
					let filtro: any = { 'carteiraId': this.carteiraId, 'categoria': 1 };
					this.obterTemplates(filtro);
				});
			}
		});
	}

	ngOnInit() { }

	obterTemplates(filtro: any = null) {
		this.templateService
			.obterTemplates(filtro).pipe(
				first(),
				tap(listaTemplate => (this.listaTemplate = listaTemplate)),
				mergeMap(() => this.route.params),
				tap(params => {

					if (!params.id) return;
					if (params.type)
						this.abaVisivel = 1;

					this.listaService.obterListaPorId(params.id).subscribe(lista => {
						this.lista = lista;
						this.atualizarLista();
					});
				}))
			.subscribe(() => {
				this.atualizarLista();
			});
	}

	carteiraChange() {
		this.obterTemplates({ 'carteiraId': this.carteiraId, 'categoria': 1 });
	}

	sobrescreverRegistroAtualizacaoChange(event: any) {
		if (this.lista.sobrescreverRegistroAtualizacao) {

			this.customAlertService
				.confirmationMessageWithThreeButton({
					title: "telaLista.sobrescreverRegistroAtualizacao",
					text: "telaLista.sobrescreverRegistroAtualizacaoConfirm",
					icon: "success",
					showCancelButton: false,
					confirmButtonText: "telaPadrao.sim",
					denyButtonText: "telaPadrao.nao",
					cancelButtonText: "telaPadrao.cancelar",
				})
				.then((data: any) => {
					if (data.isDenied)
						this.lista.sobrescreverRegistroAtualizacao = false;

					return;
				});
		}
	}

	atualizarLista() {
		this.listaTemplateFiltrada = this.listaTemplate.filter((t: Template) => (t.listaTemplateCategoriaId == TipoCategoria.importacao));
	}

	public salvar() {
		this.dirty = true;

		if (this.lista.validar()) {
			this.customAlertService.show("telaPadrao.validacao", "telaPadrao.camposInvalidos", "error");
			return;
		}

		const request = this.lista.listaId
			? this.listaService.atualizarLista(this.lista)
			: this.listaService.criarLista(this.lista);

		request.subscribe(
			response => {

				if (this.isEdit) {
					this.customAlertService.show("telaLista.lista", "telaLista.listaSalvaSucesso", "success");
					return this.router.navigate(["/app/lista"]);
				}

				if (!this.lista.listaId) {
					this.customAlertService
						.showWithConfiguration({
							title: "telaLista.lista",
							text: "telaLista.listaSalvaNovoProvedor",
							icon: "success",
							showCancelButton: true,
							confirmButtonText: "telaPadrao.sim",
							cancelButtonText: "telaPadrao.nao"
						})
						.then(data => {
							if (data.dismiss == swal.DismissReason.cancel) {
								return this.router.navigate(["/app/lista"]);
							}
							return this.router.navigate(["/app/lista", response["data"], "alteracao", "provedor"]);
						});
				}

				this.customAlertService
					.showWithConfiguration({
						title: "telaLista.lista",
						text: "telaLista.listaSalva",
						icon: "success",
						showCancelButton: true,
						confirmButtonText: "telaPadrao.sim",
						cancelButtonText: "telaPadrao.nao"
					})
					.then(data => {
						if (data.dismiss == swal.DismissReason.cancel)
							return this.router.navigate(["/app/lista"]);

						this.router.navigate(["/app/lista", response["data"], "importacao"]);
					});
			},
			(error: any) => {
				this.customAlertService.show("telaLista.lista", error.error[0], "error");
			});
	}

	obterToolTipSobrescrever() {
		return this.translate.instant("telaLista.sobrescreverRegistroAtualizacaoInfo");
	}
}