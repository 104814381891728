import * as validate from "validate.js";
export class FiltroTarefa {
	dataInicio: Date;
	dataFim: Date;
	carteiraId: number;
	lista: number;
	listaEstrategia: number;
	quantidadeExportadoMaiorQue: number;
	status: number;

	constructor() {

		let atual = new Date();

		this.dataFim = new Date(
			atual.getFullYear(),
			atual.getMonth(),
			atual.getDate(),
			23,
			59
		);
		this.dataInicio = new Date(
			atual.getFullYear(),
			atual.getMonth(),
			atual.getDate()
		);
	}

	validar() {
		var validacao: any = {
			dataInicio: {
				presence: {
					allowEmpty: false,
					message: "campoObrigatorio"
				},
				dataMenorQue: {
					campo: "dataFim"
				}
			},
			dataFim: {
				presence: {
					allowEmpty: false,
					message: "campoObrigatorio"
				}
			}
		};
		return validate(this, validacao, { fullMessages: false })
	}
}

