import { LayoutComponent } from "../layout/layout.component";
import { AppHeaderComponent } from "../layout/header/header.component";
import { AppSidenavComponent } from "../layout/sidenav/sidenav.component";
import { AppSidenavMenuComponent } from "../layout/sidenav/sidenav-menu/sidenav-menu.component";
import { AppCustomizerComponent } from "../layout/customizer/customizer.component";
import { AppFooterComponent } from "../layout/footer/footer.component";
import { AppSearchOverlayComponent } from "../layout/search-overlay/search-overlay.component";
import { FabMenuComponent } from "../layout/fab-menu/fab-menu.component";

export const BaseAppComponents = [
  LayoutComponent,
  AppHeaderComponent,
  AppSidenavComponent,
  AppSidenavMenuComponent,
  AppCustomizerComponent,
  FabMenuComponent,
  AppFooterComponent,
  AppSearchOverlayComponent
];
