import { construct } from "core-js/fn/reflect";
import { ListaTipoCampoFormatacao } from "../../../common/model/lista-tipo-campo-formatacao";

export class CampoJson {
    chaves: Array<string>;
    valor: string;
    tipo: string;
    chave: string;
    caminho: string;
    tipoCampoExportacao: number;
    permiteFormatacao: boolean;
    formatacao: string;
    formatos: Array<ListaTipoCampoFormatacao>;

    constructor() {
        this.chaves = new Array<string>();
    }
}