export class GuidService {

    public static newGuid(): string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
            const r = Math.random() * 16 | 0;
            const v = (c == 'x') ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
    public static get empty(): string {
        return '00000000-0000-0000-0000-000000000000';
    }

    public static isValid(str: string): boolean {
        const validRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
        return validRegex.test(str);
    }

    public static isGuid(guid: string): boolean {
        let validator = new RegExp("^[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$", "i");
        return guid && (validator.test(guid));
    }


    constructor() {
        // if (value) {
        //     if (GuidService.isValid(value)) {
        //         this.value = value;
        //     }
        // }
    }
}

// var e = new Guid();

// e; // Guid { value: '00000000-0000-0000-0000-000000000000' }​​​​​
// e.toString(); // 00000000-0000-0000-0000-000000000000​​​​​

// console.log(e); // ​​​​​Guid { value: '00000000-0000-0000-0000-000000000000' }​​​​​

// JSON.stringify(e); // ​​​​​"00000000-0000-0000-0000-000000000000"​​​​​

// e = Guid.newGuid(); // ​​​​​Guid { value: 'bb90ef83-1a7e-42b1-90ba-39cdebb6366c' }​​​​​
// JSON.stringify(e); // ​​​​​"bb90ef83-1a7e-42b1-90ba-39cdebb6366c"​​​​​

// Guid.isValid(e.toString()); // true
// Guid.empty; // ​​​​​00000000-0000-0000-0000-000000000000​​​​​
// Guid.isValid(Guid.empty); // false