import { Component, Inject, ElementRef, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { CustomAlertService } from "../../../../common/service/custom-alert.service";
import { FormatacaoCampo } from "../../../../common/model/formatacao-campo";
import { TranslateService } from "@ngx-translate/core";
import { FormTemplateImportacaoComponent } from "../../../form-template-importacao/form-template-importacao.component";
import { CampoJson } from "../../model/campoJson";

@Component({
	selector: "app-campo-raw-modal",
	templateUrl: "./campo-raw-modal.component.html",
	styleUrls: ["./campo-raw-modal.component.scss", "../../../style.css",],
	providers: [{ useClass: CustomAlertService, provide: CustomAlertService }]
})
export class CampoRawModalComponent {
	campoJson: CampoJson;
	camposIntegracao: Array<any>;

	exibirCampoFormatacao: boolean;
	formatacao: string;
	templateItemCustomizado: number;
	templateItemCustomizadoFuncao: number;
	funcoesCollection: Array<any>;
	listaFormatacaoCampo: Array<any>;
	formatacaoCampo = FormatacaoCampo;

	@ViewChild("conteudo", { static: false }) conteudoRef: ElementRef;

	constructor(
		private ref: MatDialogRef<FormTemplateImportacaoComponent>,
		private customAlertService: CustomAlertService,
		private translate: TranslateService,
		@Inject(MAT_DIALOG_DATA) public data
	) {
		if (data.camposIntegracao) {
			this.camposIntegracao = data.camposIntegracao;	
		}

		if (data.campoJson) {
			this.campoJson = data.campoJson;
		}

		this.funcoesCollection = [
			{ 'id': '3', restricao: 'Data', 'descricao': this.translate.instant("telaTemplate.fncAddDaysTitulo"), 'funcao': 'AddDays(0)', 'tooltip': 'fncAddDays' },
			{ 'id': '2', restricao: null, 'descricao': this.translate.instant("telaTemplate.fncReplaceTitulo"), 'funcao': 'Replace("A","B")', 'tooltip': 'fncReplace' },
			{ 'id': '1', restricao: null, 'descricao': this.translate.instant("telaTemplate.fncSubStringTitulo"), 'funcao': 'Substring(0,2)', 'tooltip': 'fncSubString' },
			{ 'id': '5', restricao: null, 'descricao': this.translate.instant("telaTemplate.fncSubStringTerceiroTitulo"), 'funcao': 'Substring(2)', 'tooltip': 'fncSubStringTerceiro' }
		];
	}

	public get FncCollection() {
		if (this.templateItemCustomizado !== undefined) {
			let campo = this.camposIntegracao.find((f: any) => f.nome == this.templateItemCustomizado);

			if (campo.listaCampoTipoId == 10)
				return this.funcoesCollection.filter((f: any) => f.restricao == null || f.restricao == 'Data');
			else
				return this.funcoesCollection.filter((f: any) => f.restricao == null);
		}

		return this.funcoesCollection.filter((f: any) => f.restricao == null);
	}

	exibirFormatacao() {
		this.templateItemCustomizadoFuncao = null;

		if (!this.templateItemCustomizado) return;

		let item = this.camposIntegracao.find((f: any) => f.nome == this.templateItemCustomizado);
		let tipo: any;

		switch (item.listaCampoTipoId) {
			case 9:
				tipo = "Numerico";
				break;
			case 10:
				tipo = "Data";
				break;
			default:
				this.exibirCampoFormatacao = false;
				return;
		}

		this.listaFormatacaoCampo = this.formatacaoCampo
			.filter((f: any) => f.tipo == tipo)
			.map((m: any) => ({ value: m.valor, label: m.nome }));

		this.exibirCampoFormatacao = true;
	}

	validarCampo() {
		if (this.campoJson.tipoCampoExportacao == 1 && (!this.campoJson.valor || this.campoJson.valor.trim().length == 0))
			return true;

		if (this.campoJson.tipoCampoExportacao == 0 && !this.campoJson.valor)
			return true;

		return false;
	}

	adicionarCampoConteudo() {
		if (this.templateItemCustomizado) {

			if (this.templateItemCustomizadoFuncao) {
				let fnc = this.funcoesCollection.find((f: any) => (f.id == this.templateItemCustomizadoFuncao));
				this.campoJson.valor = this.campoJson.valor + "${" + this.templateItemCustomizado + "." + fnc.funcao + (this.formatacao ? "|" + this.formatacao : "") + "}";
			} else {
				this.campoJson.valor = this.campoJson.valor + "${" + this.templateItemCustomizado + (this.formatacao ? "|" + this.formatacao : "") + "}";
			}

			this.templateItemCustomizado = undefined;
			this.templateItemCustomizadoFuncao = undefined;
			this.formatacao = undefined;
			this.exibirCampoFormatacao = false;
			this.conteudoRef.nativeElement.focus();
		}
	}

	save() {
		return this.ref.close(this.campoJson);
	}

	close() {
		this.ref.close();
	}

	fechar() {
		this.ref.close();
	}

	tipoCampoExportacaoChanged() {
		this.campoJson.formatacao = '';
		this.campoJson.valor = '';
	}

	campoIntegracaoChange(campo: CampoJson) {
		if (campo.valor) {
			var campoIntegracao = this.camposIntegracao.find((item) => (item.nome == campo.valor));
			campo.permiteFormatacao = campoIntegracao['permiteFormatacao'];
			campo.formatos = campoIntegracao['formatos'];
		}
		else {
			campo.permiteFormatacao = false;
			campo.formatacao = '';
			campo.valor = '';
		}
	}

	strTooltipFuncoes() {
		if (this.templateItemCustomizadoFuncao) {
			let fnc = this.funcoesCollection.find((f: any) => (f.id == this.templateItemCustomizadoFuncao));
			return this.translate.instant("telaTemplate." + fnc.tooltip)
		} else {
			return "";
		}
	}

	strTooltipExemplo(campo: CampoJson) {
		return 'Exemplo: ' + campo.formatos.find((f: any) => f.formato == campo.formatacao).exemplo;
	}
}
