import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { LimpezaFiltroArquivoStatus } from "../../common/model/limpeza-filtro-arquivo";

@Pipe({ name: "statusLimpezaFiltroArquivo" })
export class StatusLimpezaFiltroArquivoPipe implements PipeTransform {

	constructor(private translate: TranslateService) { }

	transform(value: LimpezaFiltroArquivoStatus, args?: any): any {
		switch (value) {
			case LimpezaFiltroArquivoStatus.AguardandoProcessamento:
				return this.translate.instant('telaLimpezaFiltroArquivo.statusLimpezaFiltroArquivo.aguardandoProcessamento');
			case LimpezaFiltroArquivoStatus.ProcessandoBuscaItens:
				return this.translate.instant('telaLimpezaFiltroArquivo.statusLimpezaFiltroArquivo.processandoBuscaItens');
			case LimpezaFiltroArquivoStatus.AguardandoExclusaoBanco:
				return this.translate.instant('telaLimpezaFiltroArquivo.statusLimpezaFiltroArquivo.aguardandoExclusaoBanco');
			case LimpezaFiltroArquivoStatus.ProcessandoExclusaoBanco:
				return this.translate.instant('telaLimpezaFiltroArquivo.statusLimpezaFiltroArquivo.processandoExclusaoBanco');
			case LimpezaFiltroArquivoStatus.Concluido:
				return this.translate.instant('telaLimpezaFiltroArquivo.statusLimpezaFiltroArquivo.concluido');
			case LimpezaFiltroArquivoStatus.ConcluidoErros:
				return this.translate.instant('telaLimpezaFiltroArquivo.statusLimpezaFiltroArquivo.concluidoErros');
			default:
				return "";
		}
	}
}
