import { PreloaderDirective } from "../layout/preloader.directive";
import { ToggleOffcanvasNavDirective } from "../layout/sidenav/toggle-offcanvas-nav.directive";
import { AutoCloseMobileNavDirective } from "../layout/sidenav/auto-close-mobile-nav.directive";

import { AccordionNavDirective } from "../layout/sidenav/sidenav-menu/accordion-nav.directive";
import { AppendSubmenuIconDirective } from "../layout/sidenav/sidenav-menu/append-submenu-icon.directive";
import { HighlightActiveItemsDirective } from "../layout/sidenav/sidenav-menu/highlight-active-items.directive";

import { ToggleQuickviewDirective } from "../layout/customizer/toggle-quickview.directive";

import { SearchOverlayDirective } from "../layout/search-overlay/search-overlay.directive";
import { OpenSearchOverlaylDirective } from "../layout/search-overlay/open-search-overlay.directive";

export const directives = [
  PreloaderDirective,
  ToggleOffcanvasNavDirective,
  AutoCloseMobileNavDirective,
  AccordionNavDirective,
  AppendSubmenuIconDirective,
  HighlightActiveItemsDirective,
  ToggleQuickviewDirective,
  SearchOverlayDirective,
  OpenSearchOverlaylDirective
];
