import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from "../../../../environments/environment";
import { convertToQueryString } from "../../common/service/query-string";
import { Fluxo, FluxoTipo } from "../model/fluxo";
//import { FluxoProcesso } from '../model/fluxo-processo';
import { FluxoQuery } from "../model/fluxo-query";
import { FluxoProcessoTipo, FluxoProcesso } from "../model/fluxo-processo";
import { Estrategia } from "../model/estrategia";
import { isNull, isNullOrEmpty } from "../utils/";
import { PreferenciasUsuario } from "../model/preferencias-usuario";

@Injectable()
export class PreferenciasUsuarioService {
    constructor(private httpClient: HttpClient) { }

    public localStorageObterPreferenciasUsuario(): PreferenciasUsuario {
        let ls = localStorage.getItem('preferenciasUsuario');

        if (isNullOrEmpty(ls))
            return new PreferenciasUsuario();

        return PreferenciasUsuario.fromRaw(JSON.parse(ls as string));
    }

    public localStorageGravarPreferenciasUsuario(preferencias: PreferenciasUsuario) {
        localStorage.setItem('preferenciasUsuario', JSON.stringify(preferencias));
    }

    public obterPorUsuario() {
        return this.httpClient
        .get<PreferenciasUsuario>(`${environment.serverUrl}/api/usuarios/preferencias-usuario`).pipe(
            map(PreferenciasUsuario.fromRaw));
    }

    public criar(preferencias: PreferenciasUsuario) {
        return this.httpClient.post<PreferenciasUsuario>(`${environment.serverUrl}/api/usuarios/preferencias-usuario`, preferencias);
    }

    public atualizar(preferencias: PreferenciasUsuario) {
        return this.httpClient.put<PreferenciasUsuario>(`${environment.serverUrl}/api/usuarios/preferencias-usuario`, preferencias);
    }

}
