import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
    selector: "app-esbuilder-item-field-filter",
    templateUrl: "./esbuilder-item-field-filter.component.html"
})
export class EsBuilderItemFieldFilterComponent {

    @Input()
    filterParameters: any;

    @Input()
    fieldType: string;

    @Input()
    fieldNestedType: string;

    @Output()
    valuesChange = new EventEmitter<any>();

    @Input()
    readOnly: boolean = false;

    //#region [ GET/SET - FilterValues ]

    _filterValues: any;

    @Input()
    get filterValues(): any {
        return this._filterValues;
    }

    @Output()
    filterValuesChange = new EventEmitter<any>();

    set filterValues(fv: any) {
        this._filterValues = fv;
        this.filterValuesChange.emit(fv);
        //this.valuesChange.emit(fv);

        if (this._filterValues && (
            (this._filterValues.lte && this._filterValues.lte.toString().startsWith("now")) || 
            (this._filterValues.gte && this._filterValues.gte.toString().startsWith("now")) ||
            (this._filterValues.lt && this._filterValues.lt.toString().startsWith("now")) || 
            (this._filterValues.gt && this._filterValues.gt.toString().startsWith("now"))
        )) {
            this.dataType = 2;
        }
    }

    //#endregion   

    // modelChange(){
    //     this.valuesChange.emit();
    // }

    private dataType: number = 1;

    public get isDateTypeField() {
        return (this.fieldType == 'Data' || (this.fieldType == 'Nested' && this.fieldNestedType == 'Data'));
    }

    constructor() {
        this.valuesChange = new EventEmitter<any>();
    }

    valuesChanged() {
        this.valuesChange.emit();
    }
}