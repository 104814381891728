import { Component, Input, Output, EventEmitter } from "@angular/core";
import { DiasSemana } from "../../model/dias-semana";

@Component({
  selector: "app-weekday-selection",
  templateUrl: "./weekday-selection.component.html"
})
export class WeekdaySelectionComponent {
  diasSemana = DiasSemana;
  @Input()
  dias: string = "";
  @Input()
  disabled: boolean = false;
  @Output()
  diasChange: EventEmitter<string>;

  constructor() {
    this.diasChange = new EventEmitter<string>();
  }

  public get diasSelecionados(): string[] {
    if (!this.dias) return [];
    return this.dias.split(",");
  }

  diaEstaSelecionado(dia) {
    if (!this.dias) return false;
    return this.dias.indexOf(dia) >= 0;
  }

  selecionarDia(dia) {
    let contemDia = this.dias && this.dias.indexOf(dia) >= 0;
    let diasSelecionados = this.diasSelecionados;
    if (contemDia) diasSelecionados = diasSelecionados.filter(d => d != dia);
    else diasSelecionados.push(dia);
    this.diasChange.emit(diasSelecionados.filter(dia => dia).join(","));
  }
}
