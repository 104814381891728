import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { ConfiguracaoListaProvedor, ConfiguracaoListaProvedorItem } from "../../../../common/model/configuracao-lista-provedor";
import { ItemTipo, ListaTemplateItem } from "../../../../common/model/lista-template-item";
import { Provider } from "../../../../common/model/provider";
import { Template } from "../../../../common/model/template";
import { CamposIntegracaoService } from "../../../../common/service/campos-integracao.service";
import { CustomAlertService } from "../../../../common/service/custom-alert.service";
import { TemplateService } from "../../../../common/service/template.service";
import { isNull, isNullOrZero } from "../../../../common/utils";
import { deepCopy } from "../../../../common/utils/deepCopy";
const validate = require("validate.js");

@Component({
    selector: "app-campo-retorno-modal",
    templateUrl: "./campo-retorno-modal.component.html",
    styleUrls: ["./campo-retorno-modal.component.scss"],
    providers: [
        { useClass: CamposIntegracaoService, provide: CamposIntegracaoService },
        { useClass: TemplateService, provide: TemplateService },
        { useClass: CustomAlertService, provide: CustomAlertService }
    ]
})

export class CampoRetornoModalComponent {
    exibeConfiguracaoListaProvedorItem: boolean = false;
    listaCampoTipoPrimario: Array<any>;
    provedor: Provider = new Provider();
    descricao: number;
    logo: string;
    submited: boolean;
    listaId: number;
    listaTemplateId: number;
    camposChave: { configuracaoCampoProviderId: number, listaTemplateItemId: number } = { configuracaoCampoProviderId: 0, listaTemplateItemId: 0 };
    listaTemplateItens: Array<ListaTemplateItem>;
    configuracaoListaProvedor: ConfiguracaoListaProvedor;
    configuracaoListaProvedorItem: Array<ConfiguracaoListaProvedorItem>;
    campoRetornoListaSelecionado: any;
    campoRetornoListaSelecionadoTipo: string;
    isHidden: boolean = false;

    public get configuracaoListaProvedorItemAtivos() {
        return this.configuracaoListaProvedorItem
                   .filter(i => i.configuracaoCampoProviderId == this.campoRetornoListaSelecionado.configuracaoCampoProviderId
                             && i.ativo);
    }

    public get error() {
        if (this.submited)
            return this.validar();
    }

    constructor(
        private camposIntegracaoService: CamposIntegracaoService,
        private templateService: TemplateService,
        private customAlertService: CustomAlertService,
        private ref: MatDialogRef<CampoRetornoModalComponent>,
        public dialogRef: MatDialogRef<CampoRetornoModalComponent>,
        @Inject(MAT_DIALOG_DATA) 
        public data: {
            listaId: number,
            listaTemplateId: number,
            configuracaoListaProvedor: ConfiguracaoListaProvedor,
            provedor: Provider }
    ) {

        this.listaId = this.data.listaId;
        this.listaTemplateId = this.data.listaTemplateId;
        this.configuracaoListaProvedor = this.data.configuracaoListaProvedor;
        this.camposChave.configuracaoCampoProviderId = this.configuracaoListaProvedor.configuracaoCampoProviderId;
        this.camposChave.listaTemplateItemId = this.configuracaoListaProvedor.listaTemplateItemId;
        this.provedor = this.data.provedor;

        this.camposIntegracaoService.obterTodosCamposIntegracao().subscribe(result => {
            this.listaCampoTipoPrimario = result.filter(campo => campo.tipoPrimario && campo.ativo);
            this.listaCampoTipoPrimario = this.listaCampoTipoPrimario.map((c: any) => ({ value: c.listaCampoTipoId, label: c.nome }));
        });

        this.templateService.obterTemplate(this.listaTemplateId).subscribe((template: Template) => {
            this.listaTemplateItens = template.listaTemplateItem.filter((f: ListaTemplateItem) => f.ativo && (f.itemTipo == ItemTipo.Padrao || f.itemTipo == ItemTipo.Coluna));
        });
    }

    salvar() {
        this.submited = true;

        if (this.validar()) {
            this.customAlertService.show("telaLista.lista", "telaPadrao.camposInvalidos", "error");
            return;
        };

        this.ref.close({camposChave: this.camposChave, configuracaoListaProvedorItem: this.configuracaoListaProvedor.configuracaoListaProvedorItem}); 
    }

    validar() {
        var validacao: any = {
            configuracaoCampoProviderId: { presence: { allowEmpty: false, message: '^campoObrigatorio' } },
            listaTemplateItemId: { presence: { allowEmpty: false, message: '^campoObrigatorio' } }
        };

        return validate.validate(this.camposChave, validacao);
    }

    fechar() {
        this.dialogRef.close();
    }

    //#region [ Métodos da Configuração dos Campos de Retorno para Lista ]

    configurarCampoRetornoLista(campo: any) {
        this.isHidden = true;
        this.exibeConfiguracaoListaProvedorItem = true;
        this.configuracaoListaProvedorItem = deepCopy(this.configuracaoListaProvedor.configuracaoListaProvedorItem);
        this.campoRetornoListaSelecionado = campo;
        this.campoRetornoListaSelecionadoTipo = this.listaCampoTipoPrimario.find(c => c.value == campo.listaCampoTipoId).label;
    }

    adicionarCampoConfiguracao() {
        let item = new ConfiguracaoListaProvedorItem(this.campoRetornoListaSelecionado.configuracaoCampoProviderId, null);
        this.configuracaoListaProvedorItem.push(item);        
    }

    confirmarConfiguracao() {
        this.isHidden = false;
        this.exibeConfiguracaoListaProvedorItem = false;
        this.campoRetornoListaSelecionado = null;
        this.configuracaoListaProvedor.configuracaoListaProvedorItem = this.configuracaoListaProvedorItem;
    }

    cancelarConfiguracao() {
        this.isHidden = false;
        this.configuracaoListaProvedorItem = null;
        this.campoRetornoListaSelecionado = null;
        this.campoRetornoListaSelecionadoTipo = null;
        this.exibeConfiguracaoListaProvedorItem = false;
        this.campoRetornoListaSelecionado.configuracaoCampoProviderId = null;
    }

    excluirCampoConfiguracao(config: ConfiguracaoListaProvedorItem) {
        if (isNullOrZero(config.configuracaoListaProvedorItemId)) {
            this.configuracaoListaProvedorItem = this.configuracaoListaProvedorItem.filter((f: ConfiguracaoListaProvedorItem, idx: number) => this.configuracaoListaProvedorItem.indexOf(config) != idx);
        } else {
            this.configuracaoListaProvedorItem = this.configuracaoListaProvedorItem.map((f: ConfiguracaoListaProvedorItem) => {
                if (f.configuracaoListaProvedorItemId == config.configuracaoListaProvedorItemId)
                    f.ativo = false;
                return f;
            });
        }
    }

    public liveErrorConfiguracoes(item: ConfiguracaoListaProvedorItem, property: string) {
        let validationResult = this.validate();

        if (!validationResult)
            return null;

        let index = this.configuracaoListaProvedorItemAtivos.indexOf(item);

        if (validationResult["configuracaoListaProvedorItemAtivos"][0]["errors"][index] === undefined)
            return null;

        return validationResult["configuracaoListaProvedorItemAtivos"][0]["errors"][index]["error"][property] ? validationResult["configuracaoListaProvedorItemAtivos"][0]["errors"][index]["error"][property] : null;
    }

    public validadeDisable() {
        let validationResult = this.validate();

        if (!validationResult)
            return false;

        let ok = false;

        Object.keys(validationResult["configuracaoListaProvedorItemAtivos"][0]["errors"]).forEach((f: any) => {
            if (validationResult["configuracaoListaProvedorItemAtivos"][0]["errors"][f] !== undefined)
                ok = true;
        });

        return ok;
    }

    validate() {
        validate.validators.array = (arrayItems: Array<any>, itemConstraints: any) => {
            const arrayItemErrors = arrayItems.reduce((errors, item, index) => {
                const error = validate(item, itemConstraints)
                if (error) errors[index] = { error: error }
                return errors
            }, {})

            return isNull(arrayItemErrors) ? null : { errors: arrayItemErrors };
        }

        let regras = {
            configuracaoListaProvedorItemAtivos: {
                array: {
                    listaTemplateItemId: {
                        presence: { allowEmpty: false, message: "^obrigatorio" }
                    }
                }
            }
        };

        let validationResult = validate.validate(this, regras);

        return validationResult;
    }

    //#endregion
}