import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { environment } from "../../../../environments/environment";
import { convertToQueryString } from "../../common/service/query-string";
import { FiltroQuery } from "../model/filtro-query";

@Injectable()
export class FiltroQueryService {
	constructor(private httpClient: HttpClient) { }

	public obterFiltros(filtro: any) {
		return this.httpClient.get<Array<FiltroQuery>>(`${environment.serverUrl}/api/filtroquery/filtros-query${convertToQueryString(filtro)}`)
			.pipe(map(r => r.map(m => FiltroQuery.fromRaw(m))));
	}

	public obterPorId(id) {
		return this.httpClient.get<FiltroQuery>(`${environment.serverUrl}/api/filtroquery/filtro-query/${id}`)
			.pipe(map(query => FiltroQuery.fromRaw(query)));
	}

	public criar(query: FiltroQuery) {
		return this.httpClient.post<number>(`${environment.serverUrl}/api/filtroquery/filtro-query/`, query);
	}

	public atualizar(query: FiltroQuery, aplicarEstrategias: boolean = false) {
		return this.httpClient.put<number>(`${environment.serverUrl}/api/filtroquery/${query.filtroQueryId}/${aplicarEstrategias}/filtro-query?`, query);
	}

    public desativar(id: any) {
        return this.httpClient.delete<number>(`${environment.serverUrl}/api/filtroquery/${id}`)
    }
}
