import { Component, Inject, OnInit, NgZone } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatRadioChange, MatSlideToggleChange } from "@angular/material";

import { ImportacaoAutomaticaConfiguracao, ImportacaoAutomaticaTipo } from "../../../common/model/importacao-automatica";
import { ImportacaoAutomaticaNotificacao, ImportacaoAutomaticaNotificacaoPassos } from "../../../common/model/importacao-automatica-notificacao";
import { ImportacaoAutomaticaHistorico } from "../../../common/model/importacao-automatica-historico";
import * as moment from "moment";
import { ListaService } from "../../../common/service/lista.service";
import { CustomAlertService } from "../../../common/service/custom-alert.service";
import { TranslateService } from "@ngx-translate/core";

import { HubConnectionBuilder, HubConnection } from "@aspnet/signalr";
import { environment } from "../../../../../environments/environment";
import * as signalR from "@aspnet/signalr";
import { isObjectEmpty, isNull } from "../../../common/utils/isNull";

@Component({
    selector: "app-importacao-automatica-visualizacao",
    templateUrl: "./importacao-automatica-visualizacao.component.html",
    styleUrls: ["./importacao-automatica-visualizacao.component.scss"],
    providers: [
        { useClass: ListaService, provide: ListaService },
        { useClass: CustomAlertService, provide: CustomAlertService }
    ]
})
export class ImportacaoAutomaticaVisualizacaoComponent implements OnInit {
    importacaoAutomaticaConfiguracaoId: number;
    historicos: Array<ImportacaoAutomaticaHistorico>;

    private _hubConnection: HubConnection | undefined;
    signalRStatus: boolean;
    signalRReconect: boolean;
    notificacao: ImportacaoAutomaticaNotificacao;
    tempoExecucao: string = "00:00:00";

    interval: NodeJS.Timer = null;

    public get exibirNotificacao(): boolean {
        if (isNull(this.notificacao))
            return false;

        return (this.notificacao.passo != ImportacaoAutomaticaNotificacaoPassos.finalizacao);
    }

    constructor(private listaService: ListaService,
         private zone: NgZone,
        private customAlertService: CustomAlertService,
        private translate: TranslateService,
        public dialogRef: MatDialogRef<ImportacaoAutomaticaVisualizacaoComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: { importacaoAutomaticaConfiguracaoId: number }
    ) {
        this.importacaoAutomaticaConfiguracaoId = data.importacaoAutomaticaConfiguracaoId;
        //this.notificacao = ImportacaoAutomaticaNotificacao.fromRaw({});
    }

    ngOnInit() {
        this.carregarHistorico();
        this.conectarSignalR();
        this.escutarSignalR();
        this.reconectarSignalR();

        this.zone.runOutsideAngular(() => {
            this.interval = setInterval(() => {
                if (!isNull(this.notificacao) && !isNull(this.notificacao["dataInicio"]) /*&& !isNull(this.notificacao.diferencaBackFront)*/) {
                    let inicio = moment(this.notificacao["dataInicio"]).add(this.notificacao.diferencaBackFront, 'seconds');
                    this.tempoExecucao = moment.utc(moment().diff(inicio)).format('HH:mm:ss');
                }
            }, 1000);
        });
    }

    ngOnDestroy() {
        this.signalRReconect = false;
        this._hubConnection.stop();
        clearInterval(this.interval);
        this.interval = null;
    }

    carregarHistorico(blockLoading: boolean = false) {
        this.listaService.getImportacaoAutomaticaHistorico(this.importacaoAutomaticaConfiguracaoId, blockLoading).subscribe((result: Array<ImportacaoAutomaticaHistorico>) => {
            this.historicos = result;
        });

        // clearInterval(this.interval);
        // this.interval = null;
    }

    conectarSignalR() {
        this.signalRReconect = true;
        this._hubConnection = new HubConnectionBuilder()
            .withUrl(`${environment.serverUrl}/notificacao`)
            .configureLogging(signalR.LogLevel.Information)
            .build();
    }

    reconectarSignalR() {
        this._hubConnection.onclose(() => {
            if (this.signalRReconect) {
                console.log('reconectar')
                this.signalRStatus = false;
                this.conectarSignalR();
                this.escutarSignalR();
                this.reconectarSignalR();
            }
        });
    }

    escutarSignalR() {
        this._hubConnection.start().then(() => {
            this.signalRStatus = true;
            this._hubConnection.on('ImportacaoAutomaticaNotificacao', (conteudo: any) => {
                if (!conteudo) return;

                //let notificacao = ImportacaoAutomaticaNotificacao.fromRaw(conteudo);
                let notificacao = Object.assign((isNull(this.notificacao)) ? new ImportacaoAutomaticaNotificacao() : this.notificacao, conteudo)
                if (notificacao.importacaoAutomaticaConfiguracaoId == this.importacaoAutomaticaConfiguracaoId) {
                    this.notificacao = notificacao;
                    this.notificacao.calcularDiferencaDataBackFront();

                    // if (isNull(this.interval)) {
                    //     this.interval = setInterval(() => {
                    //         if (!isNull(this.notificacao) && !isNull(this.notificacao["dataInicio"]) && !isNull(this.notificacao.diferencaBackFront)) {
                    //             let inicio = moment(this.notificacao["dataInicio"]).add(this.notificacao.diferencaBackFront, 'seconds');
                    //             this.tempoExecucao = moment.utc(moment().diff(inicio)).format('HH:mm:ss');
                    //         }
                    //     }, 1000);
                    // }

                    if (notificacao.passo == ImportacaoAutomaticaNotificacaoPassos.finalizacao)
                        this.carregarHistorico(true);
                }
            });
        }).catch(err => console.log(err));
    }

    fechar() {
        this.dialogRef.close();
        this.signalRReconect = false;
        this._hubConnection.stop();
    }
}
