
import { Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit, Input, Output, EventEmitter, AfterViewInit } from "@angular/core";

import { ListaService } from "../../common/service/lista.service"
import { TemplateService } from "../../common/service/template.service";
import { ListaExpressaoService } from '../../common/service/lista-expressao.service';
import { NotificationService } from "../../common/service/notification.service";
import { ExpressaoRegex } from '../../common/model/expressao-regex';

import { ESBuilderData, ESBuilderRules, ESBuilderRulesConditions, ESBuilderRulesChange, ESBFiltroStatusRegistroLista } from "../models";
import { QueryToModel, ModelToQuery, isObjectEmpty } from '../libs/utils';
import { EsBuilderTestService } from '../libs/services/esbuilder-test.service';
import { Lista } from '../../common/model/lista';
import { EsBuilderService } from '../libs/services/esbuilder.service';
import * as jquery from 'jquery';
import { isNull, isNullOrEmpty, isNullOrZero } from '../../common/utils';
import { ElasticsearchRepositoryService } from '../../elasticsearch/repository/elasticsearch-repository.service';
const $ = jquery;

@Component({
    selector: "app-esbuilder-filter",
    templateUrl: "./esbuilder-filter.component.html",
    styleUrls: ["./esbuilder-filter.component.scss"],
    providers: [
        { useClass: ListaService, provide: ListaService },
        { useClass: TemplateService, provide: TemplateService },
        { useClass: ListaExpressaoService, provide: ListaExpressaoService },
        //{ useClass: EsBuilderService, provide: EsBuilderService },
        { useClass: ElasticsearchRepositoryService, provide: ElasticsearchRepositoryService },
        { useClass: EsBuilderTestService, provide: EsBuilderTestService }
    ]
})
export class EsBuilderFilterComponent implements OnInit, OnDestroy {

    private queryDefaultTotal: any = JSON.parse('{"size":5,"_source":{"includes":[]},"query":{"bool":{"must":[],"must_not":[]}}}');
    private queryDefaultNotIntegrated: any = JSON.parse('{"size":5,"_source":{"includes":[]},"query":{"bool":{"must":[],"must_not":[{"exists":{"field":"_integrado"}}]}}}');
    private queryDefaultIntegrated: any = JSON.parse('{"size":5,"_source":{"includes":[]},"query":{"bool":{"must":[{"exists":{"field":"_integrado"}}],"must_not":[]}}}');

    //#region [ Inputs ]

    @Input()
    enableConditionOr: boolean = true;

    @Input()
    readOnly: boolean = false;

    //#endregion

    //#region [ Subscriptions ]

    subLista: Subscription;
    subCamposTemplate: Subscription;
    subRegex: Subscription;

    //#endregion

    //#region [ GET/SET - ListaId ]  

    _listaId: number;

    @Input()
    public set listaId(l: number) {
        if (!l || isNullOrZero(l)) return;

        this._listaId = l;
        this.updateQuantityList();
    }

    public get listaId() {
        return this._listaId;
    }

    //#endregion

    //#region [ GET/SET - Query ]  

    _query: any = {};

    @Input()
    public set query(q: any) {
        if (!q || isObjectEmpty(q)) return;

        this._query = q;
    }

    public get query() {
        return this._query;
    }

    //#endregion

    //#region [ GET/SET - DataRules ]  

    _dataRules: ESBuilderData;// = new ESBuilderData();

    @Input()
    public set dataRules(dr: ESBuilderData) {
        if (!dr) return;
        this._dataRules = dr;

        if (this._dataRules.rules.length <= 0)
            this.addRule();
    }

    public get dataRules() {
        return this._dataRules;
    }

    //#endregion

    //#region [ GET/SET - Campos Mapeados ]  

    _camposMapeados: Array<any>;

    public set camposMapeados(cm: Array<any>) {
        if (!cm) return;
        this._camposMapeados = cm;
    }

    public get camposMapeados() {
        return this._camposMapeados;
    }

    //#endregion

    //#region [ GET/SET - Lista Expressao Regex ]  

    _listaExpressaoRegex = new Array<ExpressaoRegex>();

    public set listaExpressaoRegex(rg: Array<ExpressaoRegex>) {
        if (!rg) return;
        this._listaExpressaoRegex = rg;
    }

    public get listaExpressaoRegex() {
        return this._listaExpressaoRegex;
    }

    //#endregion

    //#region [ Propriedades ]   

    lista: Lista;
    listaNome: string;
    templateId: number;

    statusRegistroLista = ESBFiltroStatusRegistroLista;

    @Output('updateQuery')
    updateQuery: EventEmitter<any> = new EventEmitter<any>();

    //#endregion

    constructor(private listaService: ListaService,
        private templateService: TemplateService,
        private elasticsearchService: ElasticsearchRepositoryService,
        private listaExpressaoService: ListaExpressaoService,
        private notificationService: NotificationService,
        private esBuilderService: EsBuilderService) {

        this.updateQuery = new EventEmitter<any>();

        this._dataRules = new ESBuilderData();
        this._query = ModelToQuery.convert(this._dataRules);
    }

    //#region [ Eventos do componente ]

    ngOnInit() {
        this.obterLista();
        this.obterListaExpressao();
    }

    ngOnDestroy() {
        if (this.subLista)
            this.subLista.unsubscribe();

        if (this.subCamposTemplate)
            this.subCamposTemplate.unsubscribe();

        if (this.subRegex)
            this.subRegex.unsubscribe();
    }

    //#endregion

    //#region [ Dados ]

    obterLista() {
        this.subLista = this.listaService.obterListaPorId(this.listaId).subscribe((result: any) => {
            this.lista = result;
            this.listaNome = this.lista.nome
            this.templateId = this.lista.listaTemplateId;
            this.obterCamposMapeados();
        });
    }

    obterCamposMapeados() {
        this.subCamposTemplate = this.templateService.obterCamposMapeados(this.templateId, this.lista.listaId).subscribe((result: any) => {
            this.camposMapeados = result.filter(f => f.itemTipo != 2);
            this.esBuilderService.emitDadosListaCarregados(this.listaNome, this.templateId);
        });
    }

    obterListaExpressao(): any {
        this.subRegex = this.listaExpressaoService.obterExpressao().subscribe((result: any) => {
            this.listaExpressaoRegex = result;
            this.esBuilderService.emitDadosListaExpressaoCarregados(this.listaExpressaoRegex);
        });
    }

    //#endregion

    //#region [ Clipboard ]

    notify(): void {
        this.notificationService.success("esBuilderComponent.sucesso", "esBuilderComponent.queryCopiadaSucesso");
    }

    copyQueryClipboard(): string {
        return JSON.stringify(this.query);
    }

    //#endregion 

    //#region [ QuantityList ] 

    quantityList: any = {
        quantidadeLivre: 0,
        quantidadeDistribuida: 0
    };

    updateQuantityList() {
        this.elasticsearchService
            .getQuantidadeLista(this.listaId, 0, (this.query || {}), null, null, null, null, null)
            .subscribe((quantityList: any) => (this.quantityList = quantityList));
    }

    //#endregion

    //#region [ IncludeIntegratedRegister ]

    public get allRegisters() {
        return this.dataRules.includeIntegratedRegister == ESBFiltroStatusRegistroLista.total;
    }

    public set allRegisters(value: boolean) {
        if (this.readOnly) return;
        this.dataRules.includeIntegratedRegister = ESBFiltroStatusRegistroLista.total;
    }

    public get onlyRegistersAvailable() {
        return this.dataRules.includeIntegratedRegister == ESBFiltroStatusRegistroLista.naoUtilizado;
    }

    public set onlyRegistersAvailable(value: boolean) {
        if (this.readOnly) return;
        this.dataRules.includeIntegratedRegister = ESBFiltroStatusRegistroLista.naoUtilizado;
    }

    public get onlyUsedRegisters() {
        return this.dataRules.includeIntegratedRegister == ESBFiltroStatusRegistroLista.utilizado;
    }

    public set onlyUsedRegisters(value: boolean) {
        if (this.readOnly) return;
        this.dataRules.includeIntegratedRegister = ESBFiltroStatusRegistroLista.utilizado;
    }

    updateIntegrated(filtroStatus: ESBFiltroStatusRegistroLista) {
        if (this.readOnly) return;

        if (this.query == undefined) {
            this.query = (filtroStatus == ESBFiltroStatusRegistroLista.naoUtilizado) ?
                this.queryDefaultNotIntegrated : (filtroStatus == ESBFiltroStatusRegistroLista.utilizado) ? this.queryDefaultIntegrated : this.queryDefaultTotal;
        }
        else {
            if (!isNull(this.query.query.bool.must))
                this.query.query.bool.must = this.query.query.bool.must.filter(f => {
                    if (f.exists != undefined)
                        return f.exists.field != "_integrado";

                    return true;
                })
            if (!isNull(this.query.query.bool.must_not))
                this.query.query.bool.must_not = this.query.query.bool.must_not.filter(f => {
                    if (f.exists != undefined)
                        return f.exists.field != "_integrado";

                    return true;
                })
        }

        if (filtroStatus == ESBFiltroStatusRegistroLista.naoUtilizado) {
            if (isNull(this.query.query.bool.must_not)) {
                this.query.query.bool.must_not = [];
            }

            this.query.query.bool.must_not.push({ exists: { field: "_integrado" } });
        }
        else if (filtroStatus == ESBFiltroStatusRegistroLista.utilizado) {
            if (isNull(this.query.query.bool.must)) {
                this.query.query.bool.must = [];
            }

            this.query.query.bool.must.push({ exists: { field: "_integrado" } });
        }

        this.dataRules.includeIntegratedRegister = filtroStatus;

        this.updateQuery.emit({ 'query': this.query, 'dataRules': this.dataRules });
    }

    //#endregion

    //#region [ Rules ]

    addRule(scrollingDown: boolean = false) {
        if (this.dataRules.rules == undefined)
            this.dataRules.rules = [];

        let o = (this.dataRules.rules.length > 0) ? Math.max.apply(Math, this.dataRules.rules.map((m: ESBuilderRules) => (m.order))) + 1 : 0;

        this.dataRules.rules.push(new ESBuilderRules({
            "condition": (o == 0) ? ESBuilderRulesConditions.none : ESBuilderRulesConditions.and,
            "field": "",
            "fieldType": "",
            "filterType": "",
            "filterValues": {},
            "order": o
        }));

        if (scrollingDown) {
            let esbuilderScrollbar = $('#esbuilderScrollbar');
            let scrollHeight = document.querySelector('#esbuilderScrollbar').scrollHeight;
            esbuilderScrollbar.stop().animate({ 'scrollTop': scrollHeight }, 1000);
            //let last = $('#esbuilderScrollbar LI:last-child');
            //esbuilderScrollbar.stop().animate({ 'scrollTop': last.position().top }, 1000);
        }
    }

    //#endregion

    dataRuleChanged(event: ESBuilderRulesChange) {
        this.query = ModelToQuery.convert(this.dataRules);
        this.updateQuery.emit({ 'query': this.query, 'dataRules': this.dataRules });
    }

}