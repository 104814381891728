import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "displayAtivo"
})
export class DisplayAtivoPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return value ? "Ativo" : "Desativado";
  }
}
