
import { map, tap } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { AutenticacaoService } from "./service/autenticacao.service";
import { PermissoesUsuario } from "./model/permissoes-usuario";

@Injectable()
export class AuthGuard implements CanActivate {
	constructor(
		private router: Router,
		private authService: AutenticacaoService
	) { }

	canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
		if (this.authService.autenticado == false || this.authService.tokenExpired) {
			this.router.navigate(["/login"]);
			return false;
		}

		if (PermissoesUsuario.recursos.length > 0)
			return this.verificarAcessoUsuario(route.data.permissao, route.data.label);

		return this.authService
			.obterPermissoes().pipe(
				tap(permissoes => (PermissoesUsuario.recursos = permissoes.permissaoRecurso)),
				map(() =>
					this.verificarAcessoUsuario(route.data.permissao, route.data.label)
				));
	}

	verificarAcessoUsuario(permissao, nomeRota): boolean {
		if (undefined == permissao) return true;
		const possuiPermissao = PermissoesUsuario.possuiPermissao(
			permissao.modulo,
			permissao.acao
		);

		//todo: alterar redirect
		if (false == possuiPermissao)
			this.router.navigate(["/app/acesso-negado"], {
				queryParams: { rota: nomeRota }
			});
		return possuiPermissao;
	}
}
