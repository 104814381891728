import { MatDialog } from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit, ViewChild } from "@angular/core";
import { Estrategia, TipoEstrategia, EstrategiaAcaoCampanhaTipo } from "../../common/model/estrategia";
import { Location } from '@angular/common';

import { PreviewDadosEstrategiaComponent } from "../../estrategia/form-estrategia/preview-dados-estrategia/preview-dados-estrategia.component";
import { ElasticsearchFilterComponent } from "../../elasticsearch/filter/elasticsearch-filter/elasticsearch-filter.component";

import { EstrategiaService } from "../../common/service/estrategia.service";
import { TemplateService } from "../../common/service/template.service";
import { ListaService } from "../../common/service/lista.service";
import { CampanhaService } from "../../common/service/campanha.service";
import { CustomAlertService } from "../../common/service/custom-alert.service";
import { Template, TipoCategoria } from "../../common/model/template";
import { EstrategiaTipo } from "../../common/model/estrategia-tipo";
import { ProviderService } from "../../common/service/provider.service";
import { Provider } from "../../common/model/provider";
import { TranslateService } from "@ngx-translate/core";
import { FiltroLista } from "../../common/model/filtro-lista";
import { FiltroStatusRegistroLista } from "../../common/model/lista";

@Component({
    selector: "app-form-estrategia-remocao",
    templateUrl: "./form-estrategia-remocao.component.html",
    styleUrls: ["./form-estrategia-remocao.component.css"],
    providers: [
        { useClass: EstrategiaService, provide: EstrategiaService },
        { useClass: ListaService, provide: ListaService },
        { useClass: TemplateService, provide: TemplateService },
        { useClass: CampanhaService, provide: CampanhaService },
        { useClass: CustomAlertService, provide: CustomAlertService },
        { useClass: ProviderService, provide: ProviderService }
    ]
})
export class FormEstrategiaRemocaoComponent implements OnInit {
    estrategia: Estrategia;
    campanhaBase: any;

    lista: Array<{
        listaId: number;
        providerId: number;
        templateId: number;
        nome: string;
        alias: string;
    }> = [];

    listaTemplateExportacao: Array<Template>;
    listaTemplateExportacaoSelect: Array<Template>;
    listaEstrategiaTipo: Array<EstrategiaTipo>;
    bloqueiaEscolhaLista: boolean;
    listaEscolhida: any;
    quantidadeEstrategia: number;
    isEdit: boolean = false;
    campanhas: Array<any>;
    dirty: boolean;
    finishedLoading: boolean;
    existeTemplateExportacao: boolean = false;
    query: any;
    listaAcaoCampanhaTipoSelect: Array<any>
    listaEstrategiasCompletas: Array<any>
    listaEstrategias: Array<any>

    _envioPorLote;
    providerCollection: Provider[];

    @ViewChild("elasticsearchFilterReadOnly", { static: false })
    elasticsearchFilterReadOnly: ElasticsearchFilterComponent;

    @ViewChild("elasticsearchFilter", { static: false })
    elasticsearchFilter: ElasticsearchFilterComponent;

    constructor(
        private estrategiaService: EstrategiaService,
        private campanhaService: CampanhaService,
        private templateService: TemplateService,
        private route: ActivatedRoute,
        private listaService: ListaService,
        private router: Router,
        public dialog: MatDialog,
        private customAlertService: CustomAlertService,
        private providerService: ProviderService,
        private _location: Location,
        private translate: TranslateService
    ) {
        this.estrategia = new Estrategia();
        this.estrategia.listaEstrategiaTipoId = TipoEstrategia.provedor;
        this.estrategia.providerId = 1;
    }

    ngOnInit() {
        this.obterLista()
        this.obterListaAcaoCampanhaTipo();
    }

    public get templateId(): number {
        if (!this.estrategia.listaId || !this.lista) return null;
        let lista = this.lista.find(l => l.listaId == this.estrategia.listaId);
        return lista ? lista.templateId : null;
    }

    public get nomeLista(): string {
        if (!this.estrategia.listaId || !this.lista) return "";
        let lista = this.lista.find(l => l.listaId == this.estrategia.listaId);
        return lista ? lista.alias : "";
    }

    public get erros() {
        if (!this.dirty) return;
        return this.estrategia.validar();
    }

    public get envioPorLote(): boolean {
        return this._envioPorLote;
    }

    public set envioPorLote(valor: boolean) {
        if (!valor)
            this.estrategia.quantidadeMaximaExportacao = 0;
        if (valor && this.estrategia.quantidadeMaximaExportacao == 0)
            this.estrategia.quantidadeMaximaExportacao = this.estrategia.quantidadeLote;

        this._envioPorLote = valor;
    }

    obterLista() {
        this.listaService
            .obterListaComTemplate({})
            .subscribe((lista: any) => (this.lista = lista));
    }

    obterTemplatesExportacao() {
        this.templateService
            .obterTemplates({ listaId: this.estrategia.listaId })
            .subscribe((listaTemplate: any) => {
                this.listaTemplateExportacao = listaTemplate.filter(function (f: any) {
                    return f.listaTemplateCategoriaId == TipoCategoria.exportacao;
                });
            });
    }

    obterListaAcaoCampanhaTipo() {
        this.listaAcaoCampanhaTipoSelect = [
            { 'id': '2', 'label': this.translate.instant("telaEstrategia.removerDaCampanha") },
            { 'id': '3', 'label': this.translate.instant("telaEstrategia.removerDeTodasCampanha") }
        ];
    }

    obterEstrategiasBase() {

        this.campanhaBase = {};

        let filtro = {
            lista: this.estrategia.listaId,
            provider: this.estrategia.providerId,
            estrategiaAcaoCampanhaTipos: [EstrategiaAcaoCampanhaTipo.ImportarCampanha]
        };

        this.estrategiaService
            .obterEstrategias(filtro)
            .subscribe((estrategias: any) => {
                this.listaEstrategiasCompletas = estrategias;
                this.listaEstrategias = estrategias.map((m: any) => ({ 'id': m.listaEstrategiaId, 'nome': m.nome }))
                    .sort((a: any, b: any) => (a.nome > b.nome ? 1 : 0));
            });
    }

    obterCampanhas() {
        this.providerService
            .obterCampanhasConfiguradas(this.estrategia.listaId, this.estrategia.providerId)
            .subscribe(campanhas => {
                this.campanhas = campanhas;
            });
    }

    listaChange() {
        if (this.estrategia.listaId) {
            this.estrategia.estrategiaAcaoCampanhaTipoId = EstrategiaAcaoCampanhaTipo.RemoverCampanha;
            this.obterTemplatesExportacao();
            this.obterEstrategiasBase();
            this.obterCampanhas();
        } else {
            this.limparFiltro();
            this.limparCampanhaBase();
        }
    }

    estrategiaAcaoCampanhaTipoChange() {
        if (this.estrategia.estrategiaAcaoCampanhaTipoId == EstrategiaAcaoCampanhaTipo.RemoverTodasCampanhas) {
            this.limparFiltro();
            this.limparCampanhaBase();
        }
    }

    estrategiaChange() {

        if (this.estrategia.estrategiaBaseId) {
            let estrategiaBase = this.listaEstrategiasCompletas.find((f: any) => f.listaEstrategiaId == this.estrategia.estrategiaBaseId);
            this.estrategia.filtro = estrategiaBase.filtro;
            this.estrategia.reenviarDistribuido = FiltroStatusRegistroLista.total;
            this.estrategia.listaId = estrategiaBase.listaId;
            this.campanhaBase = this.campanhas.find((f: any) => f.value == estrategiaBase.campanhaId.toString());
            this.updateQuery(JSON.parse(this.estrategia.filtro));
            this.elasticsearchFilterReadOnly.atualizar(estrategiaBase.filtro);
            this.elasticsearchFilter.atualizar(this.estrategia.filtro, estrategiaBase.filtro);
        }
        else {
            this.limparFiltro();

        }
    }

    limparCampanhaBase() {
        this.estrategia.campanhaId = null;
        this.campanhaBase = {};
    }

    limparFiltro() {
        this.estrategia.estrategiaBaseId = null;
        this.estrategia.filtro = '';
        this.updateQuery('');
        this.elasticsearchFilterReadOnly.atualizar('');
        this.elasticsearchFilter.atualizar(this.estrategia.filtro, '');
    }

    updateQuery(query: any) {
        this.query = query;
    }

    mostrarPreview(query: any) {
        if (query == undefined) query = this.query;
        this.dialog.open(PreviewDadosEstrategiaComponent, {
            height: "700px",
            width: "80%",
            hasBackdrop: true,
            data: {
                templateId: this.templateId,
                lista: this.nomeLista,
                listaId: this.estrategia.listaId,
                query
            }
        });
    }

    isListaSelecionada() {
        return (this.estrategia.listaId == undefined || this.estrategia.listaId <= 0);
    }

    obterProvedores(listaId: number) {
        this.providerService
            .obterProvedorDisponivelPorLista(listaId)
            .subscribe(
                providerCollection => (this.providerCollection = providerCollection)
            );
    }

    setQuantidadeLista(listaId: number) {

        if (listaId === undefined) {
            let nome = this.estrategia.nome;
            this.estrategia = new Estrategia();
            this.estrategia.nome = nome;
            this.elasticsearchFilter.must = [];
            this.query = {};
            return;
        }

        this.obterProvedores(listaId);
        this.listaService
            .obterQuantidadeLista(listaId, this.estrategia.listaEstrategiaId)
            .subscribe(
                quantidades => (this.quantidadeEstrategia = quantidades.quantidadeLivre)
            );
    }

    updateQuantidades(event: any) {

        let quantidades: Array<number> = event.quantidade;
        
        if (undefined != quantidades && quantidades.length != 0) {
            this.quantidadeEstrategia = quantidades[quantidades.length - 1];
            return;
        }
        this.quantidadeEstrategia = 0;
        if (undefined == this.estrategia.listaId) return;
        this.setQuantidadeLista(this.estrategia.listaId);
    }

    verificaExisteTemplateExportacao() {
        if (this.listaTemplateExportacao == null || !this.listaTemplateExportacao.some((f: any) => (f.providerId == this.estrategia.providerId)))
            return false

        return true;
    }

    criarEstrategia() {

        this.customAlertService
            .confirmationMessage("telaEstrategiaRemocao.confirmarGravacao")
            .then(() => {

                if (this.estrategia.estrategiaBaseId) {
                    let estrategiaBase = this.listaEstrategiasCompletas.find((f: any) => f.listaEstrategiaId == this.estrategia.estrategiaBaseId);
                    this.estrategia.listaTemplateExportacaoId = estrategiaBase.listaTemplateExportacaoId;
                    this.estrategia.campanhaId = estrategiaBase.campanhaId;
                } else {
                    if (!this.verificaExisteTemplateExportacao()) {
                        this.customAlertService.show("telaEstrategiaRemocao.estrategia", "telaEstrategiaRemocao.naoExisteTemplate", "error");
                        return;
                    }

                    let te = this.listaTemplateExportacao.filter((f: any) => (f.providerId == this.estrategia.providerId))[0];
                    this.estrategia.listaTemplateExportacaoId = parseInt(te.listaTemplateId);

                    if (!this.estrategia.campanhaId && this.estrategia.estrategiaAcaoCampanhaTipoId == EstrategiaAcaoCampanhaTipo.RemoverTodasCampanhas) {
                        let campanhaId = this.campanhas.map(m => m.value)[0];
                        if (campanhaId)
                            this.estrategia.campanhaId = campanhaId;
                    }
                }

                this.dirty = true;
                this.estrategia.recorrente = false;
                this.estrategia.quantidadeMaximaExportacao = 0;
                this.estrategia.marcarDistribuido = false;

                if (this.estrategia.validar()) {
                    this.customAlertService.show("telaEstrategiaRemocao.estrategia", "telaPadrao.camposInvalidos", "error");
                    return;
                }

                if (this.estrategia.recorrente && this.estrategia.quantidadeMaximaExportacao > this.quantidadeEstrategia)
                    return this.customAlertService.show("telaEstrategiaRemocao.estrategia", "telaEstrategiaRemocao.erroLimite", "error");

                this.estrategia.filtroAlias = this.elasticsearchFilter.must
                    .filter(c => c["not_in"])
                    .map(c => c.property)
                    .join(",");

                this.estrategia.filtro = JSON.stringify(this.elasticsearchFilter.query);

                const requisicao = !this.estrategia.listaEstrategiaId
                    ? this.estrategiaService.criarEstrategia(this.estrategia)
                    : this.estrategiaService.atualizarEstrategia(this.estrategia);

                requisicao.subscribe(
                    () => {
                        this.customAlertService.show("telaEstrategiaRemocao.estrategia", "telaEstrategiaRemocao.salvarEstrategia", "success");
                        this.router.navigate(["/app/estrategiaremocao"]);
                    },
                    error =>
                        this.customAlertService.show("telaEstrategiaRemocao.estrategia", error.error[0], "error")
                );
            });
    }

    voltar() {
        this._location.back();
    }
}
