import { isNullOrZero } from "../utils";

export class PreferenciasUsuario {
    preferenciaUsuarioId: number;
    usuarioId: number;
    carteiraId: number | null;
    listaId: number | null;
    dataCadastro: Date;
    dataAlteracao: Date | null;
    ativo: boolean;

    public get existePreferenciaCarteira(): boolean {
        return (isNullOrZero(this.carteiraId) == false);
    }

    public get existePreferenciaLista(): boolean {
        return (isNullOrZero(this.listaId) == false);
    }

    constructor() {
        this.carteiraId = 0;
        this.listaId = 0;
    }

    static fromRaw(raw: any): PreferenciasUsuario {
        return Object.assign(new PreferenciasUsuario(), raw);
    }
}