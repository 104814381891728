import { EventEmitter, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { environment } from "../../../../environments/environment";
import { convertToQueryString } from "./query-string";
import { Observable } from "rxjs";
import { FiltroDashListaProvedor } from "../../dash/model/filtro-dash-lista-provedor";
import { DashListaProvedorValidacaoCard } from "../../dash/model/dash-lista-provedor-validacao-card";

@Injectable()
export class DashListaProvedorService {
    data: any = {};

    onValidacaoAtualizar: EventEmitter<any> = new EventEmitter();
    onRegistroAtualizar: EventEmitter<any> = new EventEmitter();
    onProvedorAtualizar: EventEmitter<any> = new EventEmitter();
        
    constructor(private httpClient: HttpClient) {
     }

    onPesquisar(filtro: FiltroDashListaProvedor) {
        let dados = { 'filtro': filtro };

        this.onValidacaoAtualizar.emit(dados);
        this.onRegistroAtualizar.emit(dados);
        this.onProvedorAtualizar.emit(dados);
    }

    public obterValidacao(filtro: FiltroDashListaProvedor) {
        return this.httpClient.get<DashListaProvedorValidacaoCard>(
            `${environment.serverUrl}/api/dashboardlistaprovedor/validacoes-quantidade${convertToQueryString(filtro)}`
        );
    }

    public obterStatusRegistro(filtro: FiltroDashListaProvedor) {
        return this.httpClient.get<any>(
            `${environment.serverUrl}/api/dashboardlistaprovedor/status-registro${convertToQueryString(filtro)}`
        );
    }

    public obterProvedorConfigurado(filtro: FiltroDashListaProvedor) {
        return this.httpClient.get<any>(
            `${environment.serverUrl}/api/dashboardlistaprovedor/provedor-configurado${convertToQueryString(filtro)}`
        );
    }    
}