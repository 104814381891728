import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatRadioChange, MatSlideToggleChange } from "@angular/material";
import * as moment from "moment";
import * as validate from "validate.js";

import { EstrategiaService } from "../../../common/service/estrategia.service";
import { Estrategia } from "../../../common/model/estrategia";
import { isNullOrEmpty, isNullOrZero, generateMinutes, generateHours } from "../../../common/utils";
import { environment } from "../../../../../environments/environment";
import { CustomAlertService } from "../../../common/service/custom-alert.service";

@Component({
	selector: "app-fluxo-agendamento-estrategia",
	templateUrl: "./fluxo-agendamento-estrategia.component.html",
	styleUrls: ["./fluxo-agendamento-estrategia.component.scss"],
	providers: [
		{ useClass: EstrategiaService, provide: EstrategiaService },
		{ useClass: CustomAlertService, provide: CustomAlertService }
	]
})
export class FluxoAgendamentoEstrategiaComponent {
	AppConfig: any;
	estrategia: Estrategia;
	//frequencia: string;
	horaInicioFrequencia: Date;

	descricao: string;

	tipoRecorrencia: string;
	dirty: any;

	frequenciaCollection: any = [{ 'frequencia': '1', 'descricao': 'Minuto' }, { 'frequencia': '2', 'descricao': 'Hora' }, { 'frequencia': '3', 'descricao': 'Dia' }, { 'frequencia': '4', 'descricao': 'Semana' }];
	diaSemanaCompleto: any = { 'SUN': 'domingo', 'MON': 'segunda', 'TUE': 'terça', 'WED': 'quarta', 'THU': 'quinta', 'FRI': 'sexta', 'SAT': 'sábado' };
	horariosCollection: any = [];

	horas: Array<string> = [];
	minutos: Array<string> = [];

	constructor(
		public estrategiaService: EstrategiaService,
		public dialogRef: MatDialogRef<FluxoAgendamentoEstrategiaComponent>,
		private customAlertService: CustomAlertService,
		@Inject(MAT_DIALOG_DATA)
		public data: { estrategia: Estrategia; dirty: boolean }
	) {
		this.AppConfig = environment;
		this.estrategia = data.estrategia;
		this.dirty = data.dirty;

		if (!isNullOrZero(this.AppConfig.estrategiaAgendamentoMinimoMinutos))
			this.estrategia.estrategiaAgendamentoMinimoMinutos = data.estrategia.estrategiaAgendamentoMinimoMinutos = this.AppConfig.estrategiaAgendamentoMinimoMinutos;

		this.obterHorariosDisponiveis();

		if (this.isNew() && this.dirty === undefined) {
			let dataAtual = new Date();
			this.estrategia.execucaoRecorrente = false;
			this.estrategia.dataInicio = dataAtual;
		} else {
			if (this.estrategia.horaInicio) {
				let h = this.estrategia.horaInicio.split(":");
				this.estrategia.horaInicio = `${h[0]}:${h[1]}`;
			}
		}

		this.horas = generateHours();
		this.minutos = generateMinutes(this.estrategia.estrategiaAgendamentoMinimoMinutos);

		this.gerarDescricao();
	}

	public get erros() {
		if (!this.dirty) return;
		return validate(this.estrategia, this.estrategia.regrasValidacaoAgendamento({}));
	}


	obterHorariosDisponiveis() {
		this.estrategiaService
			.obterEstrategias({})
			.subscribe((estrategias: Estrategia[]) => {

				let remover: Estrategia[] = (this.isNew())
					? estrategias.filter((item: Estrategia) => (item.ativo && item.recorrente && item.listaId == this.estrategia.listaId))
					: estrategias.filter((item: Estrategia) => (item.ativo && item.recorrente && item.listaId == this.estrategia.listaId && item.listaEstrategiaId != this.estrategia.listaEstrategiaId));

				let horarios = [];
				this.horas.forEach((hora: string) => {
					this.minutos.forEach((minuto: string) => horarios.push(`${hora}:${minuto}`));
				});

				remover.map((item: Estrategia) => item.horaInicio.substring(0, 5))
					.forEach((item: any) => {
						let index = horarios.indexOf(item);
						if (index > -1)
							horarios.splice(index, 1);
					});

				this.horariosCollection = [];
				horarios.forEach((hora: any) => this.horariosCollection.push({ 'id': `${hora}`, 'valor': `${hora}` }));
				this.obterProximaHora();
			});
	}

	obterProximaHora() {

		if (this.isNew() && this.dirty === undefined) {
			this.proximaHora();
		}
	}

	proximaHora() {
		let atual = new Date();
		let minutos = atual.getMinutes();
		let horaAtual = '';

		while (minutos % this.estrategia.estrategiaAgendamentoMinimoMinutos != 0) { minutos++; }

		if (minutos == 60) {
			atual.setMinutes(atual.getMinutes() + this.estrategia.estrategiaAgendamentoMinimoMinutos);
			horaAtual = `${atual.getHours()}:00`;
			minutos = 0
		} else {
			horaAtual = `${atual.getHours()}:${minutos}`;
		}

		if (this.horariosCollection.some(function (item) { return item.id == horaAtual; }))
			this.estrategia.horaInicio = horaAtual;
		else {
			let r = true;

			this.horas.forEach((h: string) => {
				this.minutos.forEach((m: string) => {

					let d1 = new Date(atual.getFullYear(), atual.getMonth(), atual.getDay(), parseInt(h), parseInt(m));
					let d2 = new Date(atual.getFullYear(), atual.getMonth(), atual.getDay(), atual.getHours(), minutos);
					if (d1 >= d2 && r) {
						if (this.horariosCollection.some(function (item) { return item.id == `${h}:${m}`; })) {
							r = false;
							this.estrategia.horaInicio = `${h}:${m}`;
						}
					}
				});
			});
		}

		if (isNullOrEmpty(this.estrategia.horaInicio) && this.horariosCollection.length > 0)
			this.estrategia.horaInicio = this.horariosCollection[this.horariosCollection.length - 1];
	}


	isNew() {
		return isNullOrEmpty(this.estrategia.listaEstrategiaId);
	}

	aplicarAlteracoes() {
		this.dirty = true;
		let validacao = validate(this.estrategia, this.estrategia.regrasValidacaoAgendamento({}));

		if (validacao) {
			this.customAlertService.show("telaEstrategia.estrategia", "telaPadrao.camposInvalidos", "error");
			return;
		}

		this.dialogRef.close(this.estrategia);
	}

	gerarDescricao() {
		this.descricao = '';

		if (!this.vazio(this.estrategia.dataInicio)) {
			let dataInicio = moment(this.estrategia.dataInicio).format("DD/MM/YYYY");
			let horaInicio: any;

			if (!this.vazio(this.estrategia.horaInicio))
				horaInicio = (typeof (this.estrategia.horaInicio) == 'string') ? this.estrategia.horaInicio : moment(this.estrategia.horaInicio).format("HH:mm");

			if (!this.estrategia.execucaoRecorrente) {
				this.descricao = (!this.vazio(horaInicio)) ? `A estratégia será executada no dia ${dataInicio} às ${horaInicio}.` : '';
			}
			else {
				if (this.estrategia.frequencia == '1') {
					let s = (this.estrategia.intervalo > 1) ? 's' : '';
					this.descricao = `A estratégia será executada todos os dias de ${this.estrategia.intervalo} em ${this.estrategia.intervalo} minuto${s}. Com início em ${dataInicio}.`;

				} else if (this.estrategia.frequencia == '2') {
					let s = (this.estrategia.intervalo > 1) ? 's' : '';
					let aPartir = (!this.vazio(horaInicio)) ? `, a partir de ${horaInicio}` : '';
					this.descricao = `A estratégia será executada todos os dias de ${this.estrategia.intervalo} em ${this.estrategia.intervalo} hora${s}${aPartir}. Com início em ${dataInicio}.`;

				} else if (this.estrategia.frequencia == '3') {
					this.descricao = (!this.vazio(horaInicio)) ? `A estratégia será executada todos os dias às ${horaInicio}. Com início em ${dataInicio}.` : '';

				} else if (this.estrategia.frequencia == '4') {
					if (this.vazio(this.estrategia.diaSemana)) {
						this.descricao = (!this.vazio(horaInicio)) ? `A estratégia será executada todos os dias às ${horaInicio}. Com início em ${dataInicio}.` : '';

					} else {
						let diasOrdenados = this.acertaOrdemDiasSemana();

						if (diasOrdenados.length == 1) {
							this.descricao = (!this.vazio(horaInicio)) ? `A estratégia será executada toda semana de ${this.diaSemanaCompleto[this.estrategia.diaSemana]} às ${horaInicio}. Com início em ${dataInicio}.` : '';

						} else if (diasOrdenados.length == 7) {
							this.descricao = (!this.vazio(horaInicio)) ? `A estratégia será executada todos os dias às ${horaInicio}. Com início em ${dataInicio}.` : '';

						} else {
							let diaSemana = [];
							diasOrdenados.forEach(function (v: string) { diaSemana.push(this.diaSemanaCompleto[v]); }.bind(this));
							this.descricao = (!this.vazio(horaInicio)) ? `A estratégia será executada toda semana de ${this.replaceUltimoDia(diaSemana.join(', '))} às ${horaInicio}. Com início em ${dataInicio}.` : '';
						}
					}
				}
			}

			if (this.estrategia.comDataFim && !this.vazio(this.estrategia.dataTermino) && !this.vazio(this.estrategia.horaTermino)) {
				this.descricao = (!this.vazio(this.descricao)) ? `${this.descricao.substring(0, this.descricao.length - 1)} até ${moment(this.estrategia.dataTermino).format("DD/MM/YYYY")} 23:59:59.` : '';
			}

			// Descricao Intervalo
			if (!isNullOrEmpty(this.estrategia.intervaloHoraInicio) && !isNullOrEmpty(this.estrategia.intervaloHoraTermino)){
				this.descricao = (!isNullOrEmpty(this.descricao)) ? `${this.descricao} Com intervalo diário entre ${this.estrategia.intervaloHoraInicio} e ${this.estrategia.intervaloHoraTermino}`: '';
			}
		}
	}

	acertaOrdemDiasSemana() {
		let dias = this.estrategia.diaSemana.split(',');
		let diasOrdenados = [];

		if (dias.some((i) => { return i == 'SUN'; })) diasOrdenados.push('SUN');
		if (dias.some((i) => { return i == 'MON'; })) diasOrdenados.push('MON');
		if (dias.some((i) => { return i == 'TUE'; })) diasOrdenados.push('TUE');
		if (dias.some((i) => { return i == 'WED'; })) diasOrdenados.push('WED');
		if (dias.some((i) => { return i == 'THU'; })) diasOrdenados.push('THU');
		if (dias.some((i) => { return i == 'FRI'; })) diasOrdenados.push('FRI');
		if (dias.some((i) => { return i == 'SAT'; })) diasOrdenados.push('SAT');

		this.estrategia.diaSemana = diasOrdenados.join(',');

		return diasOrdenados;
	}

	replaceUltimoDia(str: string) {
		return str.split(' ').reverse().join(' ').replace(new RegExp(/,/), '*e').split(' ').reverse().join(' ').replace('*e', ' e');
	};

	execucaoRecorrenteChange(valor: boolean) {

		this.estrategia.execucaoRecorrente = valor;

		if (this.isNew() && this.dirty === undefined) {
			let dataAtual = new Date();

			this.estrategia.dataInicio = dataAtual;
			this.obterProximaHora();

			// Execução recorrente
			if (this.estrategia.execucaoRecorrente) {
				this.estrategia.frequencia = '1';
				this.estrategia.intervalo = this.estrategia.estrategiaAgendamentoMinimoMinutos;
				this.estrategia.diaSemana = null;
				this.estrategia.comDataFim = false;
				this.estrategia.dataTermino = null;
				this.estrategia.horaTermino = null;
			}
		} else {

			if (this.estrategia.execucaoRecorrente) {
				this.estrategia.frequencia = '1';
				this.estrategia.intervalo = this.estrategia.estrategiaAgendamentoMinimoMinutos;
				this.estrategia.diaSemana = null;
				this.estrategia.comDataFim = false;
				this.estrategia.dataTermino = null;
				this.estrategia.horaTermino = null;
			} else {
				this.estrategia.intervalo = null;
			}

			this.proximaHora();
		}
	}

	frequenciaChange(event: any) {

		//if (this.isNew() && this.dirty === undefined) {

		let dataAtual = new Date();

		this.estrategia.dataInicio = dataAtual;

		if (this.estrategia.frequencia == '1') {
			this.estrategia.intervalo = this.estrategia.estrategiaAgendamentoMinimoMinutos;
			//this.obterProximaHora();
			this.proximaHora();

		} else if (this.estrategia.frequencia == '2') {
			this.estrategia.intervalo = 1;
			//this.obterProximaHora();
			this.proximaHora();

		} else if (this.estrategia.frequencia == '3') {
			//this.obterProximaHora();
			this.proximaHora();

		} else if (this.estrategia.frequencia == '4') {
			//this.obterProximaHora();
			this.proximaHora();
		}
		//}

		this.gerarDescricao();
	}

	dadosChange(event: any) {
		this.gerarDescricao();
	}

	comDataFimChange(event: MatSlideToggleChange) {

		if (event.checked) {
			let dataAtual = new Date();
			dataAtual.setDate(dataAtual.getDate() + 1);
			dataAtual.setHours(23, 59, 59, 999);
			this.estrategia.dataTermino = dataAtual;
			this.estrategia.horaTermino = moment(dataAtual).format("HH:mm");
		} else {
			this.estrategia.dataTermino = null;
			this.estrategia.horaTermino = null;
		}

		this.gerarDescricao();
	}

	vazio(valor: any) {
		return (valor == undefined || valor == null || valor == '');
	}

	checkTerminoDesabilitado() {
		if (this.estrategia.execucaoUnica)
			return true;

		if (!this.estrategia.comDataFim)
			return true;

		return false;
	}

	fechar() {
		this.estrategia.validar();
		this.dialogRef.close();
	}
}
