import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { environment } from "../../../../environments/environment";
import { convertToQueryString } from "./query-string";
import { Observable } from "rxjs";
import { FiltroDashIndividual } from "../../dash/model/filtro-dash-individual";
import { DashIndividualRegistro } from "../../dash/model/dash-individual-registro";
import { DashIndividualLinhaTempo } from "../../dash/model/dash-individual-linha-tempo";

@Injectable()
export class DashIndividualService {
    constructor(private httpClient: HttpClient) { }

    public obterRegistros(filtro: FiltroDashIndividual) {
        return this.httpClient.get<Array<DashIndividualRegistro>>(
            `${environment.serverUrl}/api/dashboardindividual/registros-por-campo${convertToQueryString(filtro)}`
        );
    }

    public obterLinhaTempo(filtro: FiltroDashIndividual) {
        return this.httpClient.get<Array<DashIndividualLinhaTempo>>(
            `${environment.serverUrl}/api/dashboardindividual/linha-tempo${convertToQueryString(filtro)}`
        );
    }
}