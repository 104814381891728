import { Directive, ElementRef, Input } from "@angular/core";
import { ListaStatus } from "../../common/model/listaStatus";

@Directive({
  selector: "[appListaStatusDisplay]"
})
export class ListaStatusDisplayDirective {
  _status;
  @Input("appListaStatusDisplay")
  public set status(status: ListaStatus) {
    this._status = status;
    this.changeDisplayName();
  }

  public get status(): ListaStatus {
    return this._status;
  }

  changeDisplayName() {
    switch (parseInt(<any>this.status)) {
      case ListaStatus.AgIndexacao:
        this.el.nativeElement.innerHTML = "Ag. Indexação";
        break;
      case ListaStatus.AgMediacao:
        this.el.nativeElement.innerHTML = "Ag. Mediacao";
        break;
      case ListaStatus.Cancelada:
        this.el.nativeElement.innerHTML = "Cancelada";
        break;
      case ListaStatus.EmProcessamento:
        this.el.nativeElement.innerHTML = "Em Processamento";
        break;
      case ListaStatus.Inativa:
        this.el.nativeElement.innerHTML = "Inativa";
        break;
      case ListaStatus.Online:
        this.el.nativeElement.innerHTML = "Online";
        break;
      case ListaStatus.Offline:
        this.el.nativeElement.innerHTML = "Offline";
        break;
      case ListaStatus.ErroIndexacao:
        this.el.nativeElement.innerHTML = "Erro";
        break;
      case ListaStatus.Pausado:
        this.el.nativeElement.innerHTML = "Pausada";
        break;
      case ListaStatus.TemplateAlterado:
        this.el.nativeElement.innerHTML = "Template Alterado";
        break;
    }
    return "";
  }

  constructor(private el: ElementRef) {}
}
