import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from "../../../../environments/environment";
import { convertToQueryString } from "../../common/service/query-string";
import { Configuracao } from "../model/configuracao";

@Injectable()
export class ConfiguracaoService {
    constructor(private httpClient: HttpClient) { }

    public obterConfiguracoes(filtro: any): Observable<Configuracao[]> {
        return this.httpClient.get<Configuracao[]>(
            `${environment.serverUrl}/api/configuracao${convertToQueryString(filtro)}`
        ).pipe(map(query => query.map(m => Configuracao.fromRaw(m))));
    }

    public loginOktaAtivo(): Observable<any> {
        return this.httpClient.get<any>(`${environment.serverUrl}/api/configuracao/loginOktaAtivo`);
    }

    public atualizar(configuracoes: Array<Configuracao>) {
        return this.httpClient.put<number>(`${environment.serverUrl}/api/configuracao/`, configuracoes);
    }
}
