import * as validate from "validate.js";
import { isNull, isNullOrEmpty } from "../utils";
import { FiltroStatusRegistroLista } from "./lista";
import { SituacaoExecucao } from "./situacao-execucao";
import { FiltroQuery } from "./filtro-query";

export enum TipoEstrategia {
	provedor = 1,
	arquivo = 2
}

export enum EstrategiaRecorrencia {
	imediata = 1,
	agendada = 2,
	recorrente = 3
}

export enum EstrategiaAcaoCampanhaTipo {
	ImportarCampanha = 1,
	RemoverCampanha = 2,
	RemoverTodasCampanhas = 3,
	Multipla = 4,
	FluxoImportarCampanha = 5
}

validate.validators.dataMenorQue = function (value, options, key, attributes) {
	if (value >= attributes[options.campo]) return "dataMaior";
};

validate.validators.dataMaiorQue = function (value, options, key, attributes) {
	if (value <= attributes[options.campo]) return "dataMenor";
};

export class Estrategia {
	listaEstrategiaId: number;
	nome: string; 		//Todo: não tem nome
	status: number; 	//todo: não tem status
	listaId: number;
	filtro: string;
	filtroRegras: string;
	filtroQueryId: number;
	filtroQuery: FiltroQuery;

	campanhaId: string;
	campanhaNome: string;
	codigoIntegracao: string;
	envioImediato: boolean;
	recorrente: boolean;
	intervalo: number;



	frequencia: string;

	horaInicio: any;
	horaTermino: any;
	dataInicio: Date;
	dataTermino: Date;
	execucaoUnica: Boolean = true;
	diretorioDestino: string;
	dataCadastro: Date;
	usuarioCadastro: string;
	nomeUsuarioCadastro: string;
	displayNomeLista: string;
	dataAlteracao: Date;
	proximaExecucao: Date;
	ultimaExecucao: Date;
	usuarioAlteracao: Date;
	porcentagemIntegracao: number; //Obtido através de
	quantidadeRegistros: number; //Obtido através de
	diaSemana: string;
	expressaoRecorrencia: string;
	filtroAlias: string;
	reenviarDistribuido: FiltroStatusRegistroLista;
	marcarDistribuido: boolean = true;
	listaEstrategiaPrioridade: any[];
	listaEstrategiaTipoId: number;
	listaTemplateExportacaoId: number;
	quantidadeMaximaExportacao: number;
	quantidadeMaximaExportacaoPct: number;
	medidaMaximaExportacaoPct: boolean = true;
	quantidadeLote: number;
	campoCard: string;
	operacaoCampoCard: number;
	campoAgrupadorSomaUnicosCard: string;
	_comDataFim: boolean;
	formatacaoCard: string;
	providerId: number;
	ativo: boolean;
	pendenteExecucao: boolean;
	agendado: boolean;
	forcarExecucao: boolean;
	estrategiaAcaoCampanhaTipoId: EstrategiaAcaoCampanhaTipo;
	notificarZerada: boolean;

	intervaloHoraInicio: any;
	intervaloHoraTermino: any;
	situacaoExecucao: SituacaoExecucao;

	// Usado nas estrategias de remoção, não existe no banco
	estrategiaBaseId: number;

	enviarEstrategiaIdExportacao: boolean;

	// Usado para criar o vinculo com a regua estrategia, não existe no banco
	reguaId: number;
	reguaEstrategiaId: number;

	// Usado para criar o vinculo com o fluxo processo, não existe no banco
	fluxoId: number;
	fluxoProcessoId: number;

	// Usado para definir o mímino de minutos que tem no agendamento da estratégia, não existe no banco nem na API
	estrategiaAgendamentoMinimoMinutos: number = 10;


	estrategiasFilhas: EstrategiasFilhas[];

	constructor() {
		this.quantidadeLote = 50000;

		this.estrategiasFilhas = [new EstrategiasFilhas(EstrategiaAcaoCampanhaTipo.ImportarCampanha, true)];

		this.situacaoExecucao = SituacaoExecucao.play;
	}

	public get execucaoRecorrente(): boolean {
		return !this.execucaoUnica;
	}

	public set execucaoRecorrente(valor: boolean) {
		this.execucaoUnica = !valor;
	}

	public get comDataFim(): boolean {
		return this._comDataFim != undefined ? this._comDataFim : this.dataTermino != undefined;
	}

	public set comDataFim(valor: boolean) {
		if (!valor) {
			this.dataTermino = undefined;
			this.horaTermino = undefined;
		}
		this._comDataFim = valor;
	}

	public get filtroNot(): string[] {
		if (!this.filtroAlias) return new Array();
		return this.filtroAlias.split(",");
	}

	static fromRaw(raw: any) {
		let estrategia = new Estrategia();
		Object.assign(estrategia, raw);
		return estrategia;
	}

	validar() {

		let regras: any = {
			nome: { presence: { allowEmpty: false, message: '^campoObrigatorio' } },
			listaId: { presence: { allowEmpty: false, message: '^campoObrigatorio' } },
			estrategiaAcaoCampanhaTipoId: { presence: { allowEmpty: false, message: '^campoObrigatorio' } }
		};

		regras = this.regrasValidacaoAgendamento(regras);

		if (this.estrategiaAcaoCampanhaTipoId == EstrategiaAcaoCampanhaTipo.RemoverCampanha)
			regras.estrategiaBaseId = { presence: { allowEmpty: false, message: '^campoObrigatorio' } };

		return validate(this, regras);
	}


	public regrasValidacaoAgendamento(regras: any): any {

		if (this.recorrente) {

			if (!this.execucaoRecorrente) {
				regras.dataInicio = { presence: { allowEmpty: false, message: '^campoObrigatorio' } };
				regras.horaInicio = { presence: { allowEmpty: false, message: '^campoObrigatorio' } };
			} else {
				regras.frequencia = { presence: { allowEmpty: false, message: '^campoObrigatorio' } };
				regras.dataInicio = { presence: { allowEmpty: false, message: '^campoObrigatorio' } };

				if (this.frequencia == '1' || this.frequencia == '2') {
					regras.intervalo = {
						presence: { allowEmpty: false, message: '^campoObrigatorio' },
						numericality: { onlyInteger: true, greaterThanOrEqualTo: ((this.frequencia == '1') ? this.estrategiaAgendamentoMinimoMinutos : 1), message: "^menorque" }
					};

					if (!isNullOrEmpty(this.intervaloHoraInicio) || !isNullOrEmpty(this.intervaloHoraTermino)) {
						regras.intervaloHoraInicio = { presence: { allowEmpty: false, message: '^campoObrigatorio' } };
						regras.intervaloHoraTermino = { presence: { allowEmpty: false, message: '^campoObrigatorio' } };
					}

				} else {
					regras.horaInicio = { presence: { allowEmpty: false, message: '^campoObrigatorio' } };
				}

				if (this.comDataFim) {
					regras.dataTermino = {
						presence: { allowEmpty: false, message: '^campoObrigatorio' },
						dataMaiorQue: { campo: "dataInicio", message: '^dataTerminoInvalida' }
					};
				}
			}
		}

		return regras;
	}


	get isDateEmpty() {
		return (
			undefined == this.dataInicio &&
			undefined == this.horaInicio &&
			undefined == this.dataTermino &&
			undefined == this.horaTermino &&
			undefined == this.intervalo &&
			undefined == this.diaSemana
		);
	}

	static Mock(id) {
		let estrategia = new Estrategia();
		estrategia.listaEstrategiaId = id;
		estrategia.nome = `Estrategia ${id}`;
		estrategia.dataInicio = new Date();
		estrategia.usuarioCadastro = "Rodrigo";
		estrategia.status = 1;
		estrategia.porcentagemIntegracao = Math.random() * 100;
		estrategia.quantidadeRegistros = parseInt(
			(Math.random() * 100000).toFixed(2)
		);
		return estrategia;
	}
}


export class EstrategiasFilhas {

	listaEstrategiaId: number = 0;
	listaEstrategiaTipoId: number;

	providerId: number;
	providerNome: string;
	listaTemplateExportacaoId: number;
	listatTemplateExportacaoNome: string;
	estrategiaAcaoCampanhaTipoId: EstrategiaAcaoCampanhaTipo;
	campanhaId: number;
	campanhaNome: string;
	quantidadeMaximaExportacaoPct: number = 0;
	quantidadeMaximaExportacaoPctSemPerda: number = 0;
	quantidadeMaximaExportacao: number = 0;
	medidaMaximaExportacaoPct: boolean = true;
	enviarEstrategiaIdExportacao: boolean;
	ativo: boolean;
	quantidadeDistribuido: number;


	existeTemplateExportacao: boolean = false;
	listaTemplateExportacaoSelect: any[] = [];
	campanhaSelect: any[] = [];
	habilitaCampoCampanha: boolean = false;

	//constructor(){
	//	
	//}

	constructor(estrategiaAcaoCampanhaTipoId?: EstrategiaAcaoCampanhaTipo, ativo?: boolean, medidaMaximaExportacaoPct?: boolean) {
		this.estrategiaAcaoCampanhaTipoId = estrategiaAcaoCampanhaTipoId;
		this.ativo = ativo;
		this.medidaMaximaExportacaoPct = medidaMaximaExportacaoPct == null ? true : medidaMaximaExportacaoPct;
	}

	validar() {

		var validacao: any = {
			providerId: {
				presence: { allowEmpty: false }
			},
			estrategiaAcaoCampanhaTipoId: {
				presence: { allowEmpty: false }
			},
			quantidadeMaximaExportacaoPct: {
				presence: { allowEmpty: false }
			}
		};

		//if (this.listaEstrategiaTipoId != TipoEstrategia.arquivo)
		if (this.habilitaCampoCampanha)
			validacao.campanhaId = { presence: { allowEmpty: false } };

		if (this.existeTemplateExportacao)
			validacao.listaTemplateExportacaoId = { presence: { allowEmpty: false } };


		if (this.estrategiaAcaoCampanhaTipoId === 1) {
			validacao.quantidadeMaximaExportacao = {
				presence: { allowEmpty: false, message: '^campoObrigatorio' },
				//numericality: { onlyInteger: true, greaterThan: 0, message: "^menorque0" }
			}
		}


		if (this.ativo)
			return validate(this, validacao);
		else
			return null;
	}

	static fromRaw(raw: any) {
		let filha = new EstrategiasFilhas();
		Object.assign(filha, raw);
		return filha;
	}

}

export class EstrategiaTotal {

	quantidadeLivre: number = 0;
	totalGeral: number = 0;
	quantidadeEstrategia: number = 0;
	totalParaUtilizar: number = 0;
	totalParaUtilizarPct: number = 100;
	reenviarDistribuido: FiltroStatusRegistroLista = 0;
}
