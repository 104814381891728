const validate = require("validate.js");

export class UsuarioPermissaoLista {

	usuarioPermissaoListaId?: number;
	usuarioId: number;
	listaId: number;
	dataCadastro: Date;
	ativo: boolean;

	nome: string;

	constructor() { }

	validar() {
		let validacao: any = {
			usuarioId: { presence: { allowEmpty: false, message: '^campoObrigatorio' } },
			listaId: { presence: { allowEmpty: false, message: '^campoObrigatorio' } }
		};

		return validate.validate(this, validacao);
	}

	static fromRaw(raw: any): UsuarioPermissaoLista {
		return Object.assign(new UsuarioPermissaoLista(), raw);
	}
}