import { IntegracaoTipo, TipoAutenticacao } from "./provedor-configuracao";

export class ProvedorTesteConexao {
    public integracaoTipoId: IntegracaoTipo;
    public caminhoIntegracao: string;
    public metodoIntegracao: string;
    public tipoAutenticacao: TipoAutenticacao;
    public caminhoLogin: string;
    public metodoLogin: string;
    public username: string;
    public password: string;
    public clientId: string;
    public clientSecret: string;
    public scope: string;
    public grantType: string;
    public apiKey: string;
    public tokenProperty: string;
    public token: string;
    public bancoDados: string;
    public objetoDados: string;
    public parametros: string;

    constructor() {

    }
}