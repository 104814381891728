import { MatDialog } from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { TranslateService } from "@ngx-translate/core";

import { Regua } from "../../common/model/regua";
import { ReguaEstrategia } from "../../common/model/ReguaEstrategia";
import { Estrategia } from "../../common/model/estrategia";

import { ReguaService } from "../../common/service/regua.service";
import { EstrategiaService } from "../../common/service/estrategia.service";
import { CustomAlertService } from "../../common/service/custom-alert.service";

import { AddEstrategiaReguaModalComponent } from "../add-estrategia-regua-modal/add-estrategia-regua-modal.component";
import { VisualizarFiltrosEstrategiaModalComponent } from "../visualizar-filtros-estrategia-modal/visualizar-filtros-estrategia-modal.component"
import { VisualizarProvedorModalComponent } from "../../estrategia/visualizar-provedor-modal/visualizar-provedor-modal.component"
import { FluxoService } from "../../common/service/fluxo.service";


import * as moment from "moment";

@Component({
    selector: "app-regua-estrategia",
    templateUrl: "./regua-estrategia.component.html",
    styleUrls: ["./regua-estrategia.component.scss"],
    providers: [
        { useClass: ReguaService, provide: ReguaService },
        { useClass: EstrategiaService, provide: EstrategiaService },
        { useClass: CustomAlertService, provide: CustomAlertService },
        { useClass: FluxoService, provide: FluxoService }
    ]
})
export class ReguaEstrategiaComponent implements OnInit {
    _regua: Regua;
    modoEdicaoPosicao: boolean;

    public get regua(): Regua {
        return this._regua;
    }

    @Input()
    public set regua(regua: Regua) {
        if (!regua) return;
        this._regua = regua;
    }

    @Input()
    public set edicaoPosicao(flag: boolean) {
        this.modoEdicaoPosicao = flag;
    }    

    @Output()
    update: EventEmitter<any>;

    constructor(

        private reguaService: ReguaService,
        private estrategiaService: EstrategiaService,
        private fluxoService: FluxoService,
        private customAlertService: CustomAlertService,
        private translate: TranslateService,
        private router: Router,
        private route: ActivatedRoute,
        public dialog: MatDialog
    ) {
        this.update = new EventEmitter<any>();
    }

    ngOnInit() { }

    filtrarReguas(reguaId: number) {
        this.update.emit({ 'reguaId': reguaId });
    }


    //#region [ Ordem da régua ]

    drop(event: CdkDragDrop<string[]>, lista: ReguaEstrategia[]) {
        let novaOrdem = lista.filter((f: ReguaEstrategia) => f.ativo == false);
        let ativos = lista.filter((f: ReguaEstrategia) => f.ativo);

        moveItemInArray(ativos, event.previousIndex, event.currentIndex);

        ativos.forEach((f: ReguaEstrategia) => novaOrdem.push(f));

        this.regua.reguaEstrategia = novaOrdem;
        this.reordernarRegua(novaOrdem);
    }

    reordernarRegua(lista: ReguaEstrategia[]) {
        let o = 1;
        let f = lista.filter((f: ReguaEstrategia) => f.ativo);
        lista = f.map((m: any) => { m.ordem = o; o++; return m; });
        let ordem = lista.map((m: ReguaEstrategia) => { return { 'reguaEstrategiaId': m.reguaEstrategiaId, 'ordem': m.ordem }; });

        this.reguaService
            .reordernarRegua(ordem)
            .subscribe(data => { });
    }

    //#endregion

    //#region [ Adicionar/Remover estratégia na régua]

    modalAddEstrategiaRegua(regua: Regua, reguaEstrategiaId: number = null) {

        let data: any = {
            reguaId: regua.reguaId,
            listaId: regua.listaId,
            estrategiasVinculadas: regua.reguaEstrategia.filter((f: ReguaEstrategia) => f.ativo).map((m: ReguaEstrategia) => m.listaEstrategiaId)
        };

        if (!this.isNullOrEmpty(reguaEstrategiaId))
            data['reguaEstrategiaId'] = reguaEstrategiaId;

        let dialogRef = this.dialog.open(AddEstrategiaReguaModalComponent, {
            width: "750px",
            data: data
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!result) return;
            this.adicionarEstrategiaRegua(result.reguaId, result.estrategiaId, result.reguaEstrategiaId);
        });
    }

    adicionarEstrategiaRegua(reguaId: number, estrategiaId: number, reguaEstrategiaId: number = null) {

        let reguaEstrategia: ReguaEstrategia;

        if (this.isNullOrEmpty(reguaEstrategiaId)) {
            let ordem = this.regua.reguaEstrategia.filter(f => f.ativo).map(o => o.ordem);
            let newOrdem = Math.max.apply(this, ordem) + 1;
            reguaEstrategia = new ReguaEstrategia(reguaId, estrategiaId, newOrdem);
        } else {
            reguaEstrategia = this.regua.reguaEstrategia.find(f => f.reguaEstrategiaId == reguaEstrategiaId);
            reguaEstrategia.listaEstrategiaId = estrategiaId;
        }

        this.reguaService
            .gravarReguaEstrategia(reguaEstrategia)
            .subscribe(
                (data: any) => {
                    this.filtrarReguas(reguaId);
                },
                (error: any) =>
                    this.customAlertService.show("telaRegua.regua", error.error[0], "error"));
    }

    desativarReguaEstrategia(reguaId: number, reguaEstrategiaId: number, mensagem: string) {
        this.customAlertService
            .confirmationMessage(mensagem)
            .then(() => {
                this.reguaService
                    .desativarReguaEstrategia(reguaEstrategiaId)
                    .subscribe((retorno: any) => {

                        let itens: Array<ReguaEstrategia> = this.regua.reguaEstrategia.filter(f => !f.ativo);
                        let item = this.regua.reguaEstrategia.find(f => f.reguaEstrategiaId == reguaEstrategiaId);
                        item.ativo = false;
                        item.ordem = 0;
                        itens.push(item);

                        let o = 1;
                        retorno.data.forEach(m => {
                            let t = this.regua.reguaEstrategia.find(f => f.reguaEstrategiaId == m);
                            t.ordem = o;
                            itens.push(t);
                            o++;
                        });
                        this.regua.reguaEstrategia = itens;
                    });
            });
    }

    //#endregion

    //#region [ Métodos da estratégia ]

    ativarPendenteExecucao(estrategia) {
        this.fluxoService
            .obterQueryQuantidadeRegistrosPost(estrategia.lista.listaId, estrategia.filtro)
            .subscribe((result: any) => {
            let quantidade = (result) ? result : 0;

            if(quantidade > 0){
                this.customAlertService
                    .confirmationMessage("telaEstrategia.confirmarReativacao")
                    .then(() =>
                        this.estrategiaService.ativarPendenteExecucao(estrategia.lista.listaId).subscribe(() => this.filtrarReguas(this.regua.reguaId))
                );
            }else{
        
                this.customAlertService
                    .confirmationMessage("telaEstrategia.confirmarReativacaoSemRegistro")
                    .then(() =>
                        this.estrategiaService.ativarPendenteExecucao(estrategia.lista.listaId).subscribe(() => this.filtrarReguas(this.regua.reguaId))
                );
            }
        });  
    }

    forcarExecucao(estrategia) {
        this.fluxoService
            .obterQueryQuantidadeRegistrosPost(estrategia.lista.listaId, estrategia.filtro)
            .subscribe((result: any) => {
            let quantidade = (result) ? result : 0;

            if(quantidade > 0){
                this.customAlertService
                .confirmationMessage("telaEstrategia.forcarExecucao")
                .then(() =>
                    this.estrategiaService.forcarExecucao(estrategia.lista.listaId).subscribe(() => this.filtrarReguas(this.regua.reguaId))
                );
            }else{
        
                this.customAlertService
                .confirmationMessage("telaEstrategia.forcarExecucaoSemRegistro")
                .then(() =>
                    this.estrategiaService.forcarExecucao(estrategia.lista.listaId).subscribe(() => this.filtrarReguas(this.regua.reguaId))
                );
            }
        });     
    }

    campo(json: any, field: string) {

        if (this.isNullOrEmpty(json))
            return null;

        if (this.isNullOrEmpty(json[field]))
            return null;

        return json[field]
    }

    visualizarFiltros(estrategiaId: number) {
        let dialogRef = this.dialog.open(VisualizarFiltrosEstrategiaModalComponent, {
            width: "850px",
            closeOnNavigation: true,
            data: {
                estrategiaId: estrategiaId
            }
        });

        dialogRef.afterClosed().subscribe(result => { });
    }

    visualizarTimer(estrategia: Estrategia) {
        if (this.isNullOrEmpty(estrategia))
            return false;

        return estrategia.recorrente;
    }

    visualizarPendenteExecucao(estrategia: Estrategia) {
        if (this.isNullOrEmpty(estrategia))
            return false;

        return estrategia.pendenteExecucao == false && estrategia.recorrente == false;
    }

    visualizarForcarExecucao(estrategia: Estrategia) {
        if (this.isNullOrEmpty(estrategia))
            return false;

        return estrategia.forcarExecucao == false && estrategia.recorrente;
    }

    atualizarQuantidade() {
        this.reguaService.obterPorId(this.regua.reguaId).subscribe(regua => {
            this.regua = regua;
        });
    }

    //#endregion


    visualizarProvedor(listaEstrategiaId: number) {
		this.dialog.open(VisualizarProvedorModalComponent, {
			height: "450px",
			width: "50%",
			data: {
				listaEstrategiaId: listaEstrategiaId
			}
		});
	}


    //#region [ Descrição execução estratégia ]

    gerarDescricao(estrategia: Estrategia) {

        let descricao: string = '';

        if (this.isNullOrEmpty(estrategia))
            return descricao;

        let diaSemana: any = {
            'SUN': this.translate.instant("telaRegua.execucao.domingo"),
            'MON': this.translate.instant("telaRegua.execucao.segunda"),
            'TUE': this.translate.instant("telaRegua.execucao.terça"),
            'WED': this.translate.instant("telaRegua.execucao.quarta"),
            'THU': this.translate.instant("telaRegua.execucao.quinta"),
            'FRI': this.translate.instant("telaRegua.execucao.sexta"),
            'SAT': this.translate.instant("telaRegua.execucao.sabado")
        };

        if (!this.isNullOrEmpty(estrategia.dataInicio)) {
            let dataInicio = moment(estrategia.dataInicio).format("DD/MM/YYYY");
            let horaInicio: any;
            let valores: any = { 'dataInicio': dataInicio };

            if (!this.isNullOrEmpty(estrategia.horaInicio))
                horaInicio = (typeof (estrategia.horaInicio) == 'string') ? estrategia.horaInicio : moment(estrategia.horaInicio).format("HH:mm");

            if (!estrategia.execucaoRecorrente) {
                if (!this.isNullOrEmpty(horaInicio))
                    descricao = this.translate.instant("telaRegua.execucao.imediata", { ...valores, 'horaInicio': horaInicio });
            }
            else {
                let s = (estrategia.intervalo > 1) ? 's' : '';
                let aPartir = (!this.isNullOrEmpty(horaInicio)) ? `, a partir de ${horaInicio}` : '';

                if (estrategia.frequencia == '1') {
                    descricao = this.translate.instant("telaRegua.execucao.recorrenteFrequencia1", { ...valores, 'intervalo': estrategia.intervalo, 's': s });

                } else if (estrategia.frequencia == '2') {
                    descricao = this.translate.instant("telaRegua.execucao.recorrenteFrequencia2", { ...valores, 'intervalo': estrategia.intervalo, 's': s, 'aPartir': aPartir });

                } else if (estrategia.frequencia == '3') {
                    if (!this.isNullOrEmpty(horaInicio))
                        descricao = this.translate.instant("telaRegua.execucao.recorrenteFrequencia3", { ...valores, 'horaInicio': horaInicio });

                } else if (estrategia.frequencia == '4') {
                    if (!this.isNullOrEmpty(horaInicio)) {

                        valores = { ...valores, 'horaInicio': horaInicio };

                        if (this.isNullOrEmpty(estrategia.diaSemana)) {
                            descricao = this.translate.instant("telaRegua.execucao.recorrenteFrequencia4TodoDia", valores);

                        } else {

                            let dias = [];
                            let diasOrdenados = this.acertaOrdemDiasSemana(estrategia);
                            diasOrdenados.forEach(function (v: string) { diaSemana.push(diaSemana[v]); }.bind(this));

                            if (diasOrdenados.length == 1)
                                descricao = this.translate.instant("telaRegua.execucao.recorrenteFrequencia4TodaSemana", { ...valores, 'diaSemana': diaSemana[estrategia.diaSemana] });
                            else if (diasOrdenados.length == 7)
                                descricao = this.translate.instant("telaRegua.execucao.recorrenteFrequencia4TodoDia", valores);
                            else
                                descricao = this.translate.instant("telaRegua.execucao.recorrenteFrequencia4TodaSemana", { ...valores, 'diaSemana': this.replaceUltimoDia(dias.join(', ')) });
                        }
                    }
                }
            }

            if (estrategia.comDataFim && !this.isNullOrEmpty(estrategia.dataTermino) && !this.isNullOrEmpty(estrategia.horaTermino)) {
                descricao = (!this.isNullOrEmpty(descricao)) ? `${descricao.substring(0, descricao.length - 1)} até ${moment(estrategia.dataTermino).format("DD/MM/YYYY")} 23:59:59.` : '';
            }

            return descricao;
        }
    }

    acertaOrdemDiasSemana(estrategia: Estrategia) {
        let dias = estrategia.diaSemana.split(',');
        let diasOrdenados = [];

        if (dias.some((i) => { return i == 'SUN'; })) diasOrdenados.push('SUN');
        if (dias.some((i) => { return i == 'MON'; })) diasOrdenados.push('MON');
        if (dias.some((i) => { return i == 'TUE'; })) diasOrdenados.push('TUE');
        if (dias.some((i) => { return i == 'WED'; })) diasOrdenados.push('WED');
        if (dias.some((i) => { return i == 'THU'; })) diasOrdenados.push('THU');
        if (dias.some((i) => { return i == 'FRI'; })) diasOrdenados.push('FRI');
        if (dias.some((i) => { return i == 'SAT'; })) diasOrdenados.push('SAT');

        estrategia.diaSemana = diasOrdenados.join(',');

        return diasOrdenados;
    }

    replaceUltimoDia(str: string) {
        return str.split(' ').reverse().join(' ').replace(new RegExp(/,/), '*e').split(' ').reverse().join(' ').replace('*e', ' e');
    };

    //#endregion

    //#region [ Úteis ]

    isNullOrEmpty(valor: any) {
        return (valor == undefined || valor == null || valor == '');
    }

    //#endregion


}
