import { Component, Inject, ViewChild, OnDestroy, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Subscription } from "rxjs";

import { CustomAlertService } from "../../../common/service/custom-alert.service";
import { FluxoService } from "../../../common/service/fluxo.service";
// import { ListaService } from "../../../common/service/lista.service";
// import { EstrategiaService } from "../../../common/service/estrategia.service";
// import { TemplateService } from "../../../common/service/template.service";
import { FluxoQuery } from "../../../common/model/fluxo-query";
import { isNullOrZero, isNullOrEmpty } from '../../../common/utils';
import { ESBFiltroStatusRegistroLista, ESBuilderData } from '../../../esbuilder/models'
import { PreviewDadosEstrategiaComponent } from "../../../estrategia/form-estrategia/preview-dados-estrategia/preview-dados-estrategia.component";
import { ModelToQuery } from "../../../esbuilder/libs/utils";
import { ElasticsearchRepositoryService } from "../../../elasticsearch/repository/elasticsearch-repository.service";
import { IAppConfig } from '../../../../common/model/app-config';
import { APPCONFIG } from '../../../../common/providers/config.provider';


@Component({
    selector: "app-fluxo-query-modal",
    templateUrl: "./fluxo-query-modal.component.html",
    styleUrls: ["./fluxo-query-modal.component.scss"],
    providers: [
        { useClass: FluxoService, provide: FluxoService },
        // { useClass: ListaService, provide: ListaService },
        // { useClass: TemplateService, provide: TemplateService },

        { useClass: CustomAlertService, provide: CustomAlertService }
    ]
})
export class FluxoQueryModalComponent implements OnInit, OnDestroy {

    fluxoQuery: FluxoQuery;
    quantidade: number;

    listaId: number;
    listaTemplateId: number;
    listaAlias: string;
    query: any = {}; //JSON.parse('{"size":5,"_source":{"includes":[]},"query":{"bool":{"must":[]}}}');
    dataRules: ESBuilderData = new ESBuilderData();

    submited: boolean;
    finishedLoading: boolean = true;

    subFluxo: Subscription;

    AppConfig;

    //#region [ GETS ]  

    public get error() {
        if (this.submited)
            return this.fluxoQuery.validar();
    }

    public get isEdit(): boolean {
        return this.fluxoQuery != undefined && this.fluxoQuery.fluxoQueryId != undefined && this.fluxoQuery.fluxoQueryId != 0;
    }

    //#endregion

    constructor(
        private fluxoService: FluxoService,

        private customAlertService: CustomAlertService,
        private ref: MatDialogRef<FluxoQueryModalComponent>,
        @Inject(APPCONFIG) public appConfig: IAppConfig,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA)
        public data: { fluxoQueryId: number; listaId: number, listaTemplateId: number, listaAlias: string }
    ) {
        this.fluxoQuery = new FluxoQuery();
        this.fluxoQuery.listaId = this.data.listaId;
        this.fluxoQuery.filtro = '';
        this.fluxoQuery.filtroRegras = '';
        this.quantidade = 0;
        this.carregarDados();
    }

    ngOnInit() {
        this.AppConfig = this.appConfig;
    }

    carregarDados() {

        this.listaId = this.data.listaId;
        this.listaTemplateId = this.data.listaTemplateId;
        this.listaAlias = this.data.listaAlias;

        if (isNullOrZero(this.data.fluxoQueryId)) {
            this.dataRules = new ESBuilderData();
            this.query = ModelToQuery.convert(this.dataRules);
            this.updateIntegrated();

            this.fluxoService
                .obterQueryQuantidadeRegistrosPost(this.listaId, JSON.stringify(this.query))
                .subscribe((result: any) => {
                    this.quantidade = (result) ? result : 0;
                });

            return;
        }

        this.subFluxo = this.fluxoService.obterQueryPorId(this.data.fluxoQueryId).subscribe((result: FluxoQuery) => {
            this.fluxoQuery = result;

            this.dataRules = (!isNullOrEmpty(this.fluxoQuery.filtroRegras)) ? ESBuilderData.fromRaw(JSON.parse(this.fluxoQuery.filtroRegras)) : new ESBuilderData();
            this.query = (!isNullOrEmpty(this.fluxoQuery.filtro)) ? JSON.parse(this.fluxoQuery.filtro) : ModelToQuery.convert(this.dataRules);
            this.updateIntegrated();

            this.fluxoService
                .obterQueryQuantidadeRegistrosPost(this.data.listaId, JSON.stringify(this.query))
                .subscribe((result: any) => {
                    this.quantidade = (result) ? result : 0;
                });
        });
    }

    updateIntegrated() {
        var resetMust = false;
        var resetMustNot = false;

        if (this.dataRules.includeIntegratedRegister == ESBFiltroStatusRegistroLista.naoUtilizado) {
            resetMust = true;

            if (isNullOrEmpty(this.query.query.bool.must_not)) {
                this.query.query.bool['must_not'] = [{ "exists": { "field": "_integrado" } }];
            } else if (!this.query.query.bool.must_not.some(f => f.exists != undefined && f.exists.field == "_integrado")) {
                this.query.query.bool.must_not.push({ exists: { field: "_integrado" } });
            }
        }

        if (this.dataRules.includeIntegratedRegister == ESBFiltroStatusRegistroLista.utilizado) {
            resetMustNot = true;

            if (isNullOrEmpty(this.query.query.bool.must)) {
                this.query.query.bool['must'] = [{ "exists": { "field": "_integrado" } }];
            } else if (!this.query.query.bool.must.some(f => f.exists != undefined && f.exists.field == "_integrado")) {
                this.query.query.bool.must.push({ exists: { field: "_integrado" } });
            }
        }

        if (this.dataRules.includeIntegratedRegister == ESBFiltroStatusRegistroLista.total) {
            resetMust = true;
            resetMustNot = true;
        }

        if (resetMustNot && !isNullOrEmpty(this.query.query.bool.must_not)) {
            this.query.query.bool.must_not = this.query.query.bool.must_not.filter((f: any) => {
                if (f.exists != undefined)
                    return (f.exists.field != "_integrado");

                return true;
            });
        }

        if (resetMust && !isNullOrEmpty(this.query.query.bool.must)) {
            this.query.query.bool.must = this.query.query.bool.must.filter((f: any) => {
                if (f.exists != undefined)
                    return (f.exists.field != "_integrado");

                return true;
            });
        }

        if (!isNullOrEmpty(this.dataRules))
            this.fluxoQuery.filtroRegras = JSON.stringify(this.dataRules);

        if (!isNullOrEmpty(this.query))
            this.fluxoQuery.filtro = JSON.stringify(this.query);
    }

    updateQuery(event: any) {
        if (!isNullOrEmpty(event.query)) {
            this.query = event.query;
            this.fluxoQuery.filtro = JSON.stringify(event.query);
        }

        if (!isNullOrEmpty(event.dataRules)) {
            this.dataRules = event.dataRules;
            this.fluxoQuery.filtroRegras = JSON.stringify(event.dataRules);
        }

        this.fluxoService
            .obterQueryQuantidadeRegistrosPost(this.data.listaId, this.fluxoQuery.filtro)
            .subscribe((result: any) => {
                this.quantidade = (result) ? result : 0;
            });
    }

    mostrarPreview() {

        let query: any = JSON.parse(this.fluxoQuery.filtro);
        query["size"] = 10;

        this.dialog.open(PreviewDadosEstrategiaComponent, {
            height: "700px",
            width: "80%",
            hasBackdrop: true,
            data: {
                templateId: this.listaTemplateId,
                lista: this.listaAlias,
                listaId: this.listaId,
                query: query
            }
        });
    }

    save() {
        this.submited = true;

        if (this.fluxoQuery.validar()) {
            this.customAlertService.show("telaFluxo.fluxo", "telaPadrao.camposInvalidos", "error");
            return;
        };

        let sucesso = (response: any) => {
            this.customAlertService.show("telaFluxo.fluxo", "telaPadrao.sucessoSalvar", "success");

            if (!this.isEdit)
                this.data.fluxoQueryId = response.data;

            this.ref.close(this.data);
        };

        let erro = () => {
            this.customAlertService.show("telaFluxo.fluxo", "telaPadrao.erroSalvar", "error");
        };

        if (!this.isEdit)
            return this.fluxoService.criarQuery(this.fluxoQuery).subscribe(sucesso, erro);

        this.fluxoService.atualizarQuery(this.fluxoQuery).subscribe(sucesso, erro);
    }

    close() {
        this.ref.close();
    }

    fechar() {
        this.ref.close();
    }

    ngOnDestroy() {
        if (this.subFluxo)
            this.subFluxo.unsubscribe();
    }
}
