import { routes } from "../../routing";
import { PermissoesUsuario } from "./permissoes-usuario";

export class PermissaoDefault {
  public static obterRotaPadraoRedirecionamento(): string {
    const rotasPossiveis = routes.filter(
      x =>
        x.data &&
        x.data.parent == undefined &&
        (x.data.permissao == undefined ||
          (x.data.permissao &&
            PermissoesUsuario.possuiPermissao(
              x.data.permissao.modulo,
              x.data.permissao.acao
            )))
    );

    if (rotasPossiveis.find(r => r.path == "dash")) return "/app/dash";

    if (rotasPossiveis.length > 0) return `/app/${rotasPossiveis[0].path}`;

    return "/app/template";
  }
}
