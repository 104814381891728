
import { map } from 'rxjs/operators';
import { Component, OnInit, Inject } from "@angular/core";
import { Order } from "../model/order";
import { OrderOperation, OrderTypes } from "../model/ordertypes";
import { TemplateService } from "../../common/service/template.service";
import { ElasticsearchRepositoryService } from "../repository/elasticsearch-repository.service";
import { formatFieldName } from "../model/elasticsearch-field-formarter";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { DefaultValuesService } from "../../common/service/default-values.service";
import { BaseElasticsearchFilter } from "../model/elasticsearch-filter/base";
import { changeOrder } from "../../common/model/change-collection-item-order";
import { FiltroStatusRegistroLista } from '../../common/model/lista';
import { isNullOrZero } from '../../common/utils';

@Component({
	selector: "app-elasticsearch-add-order",
	styleUrls: ["./elasticsearch-add-order.component.scss"],
	templateUrl: "./elasticsearch-add-order.component.html",
	providers: [
		{
			useClass: TemplateService,
			provide: TemplateService
		},
		{
			useClass: ElasticsearchRepositoryService,
			provide: ElasticsearchRepositoryService
		},
		{ useClass: DefaultValuesService, provide: DefaultValuesService }
	]
})
export class ElasticsearchAddOrderComponent implements OnInit {
	order: Order;
	static draggedOrder: Order;
	campos: any[] = [];
	opcoesPossiveis = [];
	templateId;
	listaId;
	lista;
	query;
	orderCollection = [];
	operacoes: any;
	must: Array<BaseElasticsearchFilter> = [];

	constructor(
		private ref: MatDialogRef<ElasticsearchAddOrderComponent>,
		@Inject(MAT_DIALOG_DATA) public data,
		private templateService: TemplateService,
		private elasticsearchService: ElasticsearchRepositoryService,
		private defaultValuesService: DefaultValuesService
	) {
		this.templateId = data.templateId;
		this.listaId = data.listaId;
		this.lista = data.lista;
		this.query = data.query
			? data.query.query
			: {
				size: 10,
				_source: {
					includes: []
				},
				query: {
					bool: {
						must: [...this.must.map(f => f.convertedFilter), ...this.mustUtilizado],
						must_not: this.mustNot
					}
				}
			};



		this.orderCollection = data.listaEstrategiaPrioridade
			? data.listaEstrategiaPrioridade.map(ordem =>
				Object.assign(new Order(), ordem)
			)
			: [];

		this.order = new Order(null, null, null);
		this.templateService
			.obterCamposMapeados(this.templateId, this.listaId)
			.subscribe(campos => (this.campos = campos));

		this.defaultValuesService
			.obterOperacoesOrdenacao()
			.then(operacoes => (this.operacoes = operacoes));
	}

	obterCampoSelecionado(campoSelecionado) {
		return this.campos.find(
			campo => campo.listaTemplateItemId == campoSelecionado
		);
	}

	public get activeOrderCollection() {
		return this.orderCollection
			.filter(o => o.ativo != false)
			.sort((orderA, orderB) => orderA.ordem - orderB.ordem);
	}

	public get tipoDadoLista(): boolean {
		var campoSelecionado = this.obterCampoSelecionado(
			this.order.listaTemplateItemId
		);
		if (!campoSelecionado) return false;
		return campoSelecionado.tipoDado == "Lista";
	}

	public get operadorAscDesc() {
		if (isNullOrZero(this.order.operador))
			return false;

		return (this.order.operador == OrderOperation.asc || this.order.operador == OrderOperation.desc);
	}

	public get operacoesPossiveis() {
		const campoSelecionado = this.obterCampoSelecionado(
			this.order.listaTemplateItemId
		);

		if (!campoSelecionado) return [];
		const possuiTipoNumerico = campoSelecionado.tipoDado == "Numerico";
		const possiveis = this.operacoes.filter(
			item =>
				OrderTypes[campoSelecionado.tipoDado].indexOf(parseInt(item.value)) !=
				-1
		);
		if (false == possuiTipoNumerico)
			return possiveis.filter(item => item.numeric == undefined);
		return possiveis.filter(
			item =>
				item.value != OrderOperation.equal ||
				(item.numeric == possuiTipoNumerico &&
					item.value == OrderOperation.equal)
		);
	}

	adicionar() {

		const campoSelecionado = this.obterCampoSelecionado(this.order.listaTemplateItemId);
		let validaValor = (campoSelecionado.tipoDado != "Lista" && this.operadorAscDesc == false);

		if (this.order.validate(validaValor))
			return;

		if (Array.isArray(this.order.valor))
			this.order.valor = this.order.valor.join(",");

		this.order.ordem = this.activeOrderCollection.length;
		this.orderCollection.push(this.order);
		this.order = new Order(null, null, null);
	}

	excluir(ordem: Order) {
		ordem.ativo = false;
	}

	obterOpcoesLista(campoId) {
		this.opcoesPossiveis = [];
		var campoSelecionado = this.obterCampoSelecionado(parseInt(campoId));
		if (campoSelecionado.tipoDado != "Lista") return;
		this.elasticsearchService
			.getOptionForField(
				this.lista,
				this.listaId,
				this.templateId,
				formatFieldName(campoSelecionado.nome),
				this.query
			).pipe(
				map(opcoesPossiveis =>
					Object.keys(opcoesPossiveis).map(opcao => ({
						label: opcao,
						value: opcao,
						suffix: opcoesPossiveis[opcao]
					}))
				))
			.subscribe(opcoesPossiveis => (this.opcoesPossiveis = opcoesPossiveis));
	}

	obterNomeCampo(id) {
		const campo = this.obterCampoSelecionado(id);
		if (!campo) return "";
		return campo.nome;
	}

	obterNomeOperacao(id) {
		return OrderOperation[id];
	}

	obterValor(valor) {
		if (Array.isArray(valor)) return valor.join(", ");
		return valor;
	}

	obterPosicao(order: Order) {
		return this.activeOrderCollection.indexOf(order);
	}

	private get mustNot() {
		if (this.data.reenviarDistribuido == FiltroStatusRegistroLista.naoUtilizado)
			return [
				{
					exists: {
						field: "_integrado"
					}
				}
			];

		return [];
	}

	private get mustUtilizado() {
		if (this.data.reenviarDistribuido == FiltroStatusRegistroLista.utilizado)
			return [
				{
					exists: {
						field: "_integrado"
					}
				}
			];

		return [];
	}

	subirPosicao(order: Order) {
		var index = this.obterPosicao(order);
		if (index == 0) return;
		const previousOrder = this.activeOrderCollection[index - 1];
		order.ordem--;
		previousOrder.ordem++;
	}

	descerPosicao(order: Order) {
		var index = this.obterPosicao(order);
		if (index == this.activeOrderCollection.length - 1) return;
		const nextOrder = this.activeOrderCollection[index + 1];
		order.ordem++;
		nextOrder.ordem--;
	}

	save() {
		this.ref.close(this.orderCollection);
	}

	close() {
		this.ref.close();
	}

	handleDragStart(order: Order) {
		ElasticsearchAddOrderComponent.draggedOrder = order;
	}

	handleDragOver(event: Event) {
		event.preventDefault();
		(<Element>event.target).parentElement.classList.add("droppable");
	}

	handleDragLeave(event: Event) {
		event.preventDefault();
		(<Element>event.target).parentElement.classList.remove("droppable");
	}

	handleDrop(event: Event, o: Order) {
		(<Element>event.target).parentElement.classList.remove("droppable");
		var newOrderCollection = changeOrder(
			this.activeOrderCollection,
			ElasticsearchAddOrderComponent.draggedOrder,
			o
		);

		this.activeOrderCollection.forEach(
			ordem => (ordem.ordem = newOrderCollection.indexOf(ordem))
		);
	}

	ngOnInit() { }
}
