let filterText: Array<any> = [
    {
        filterType: "igual",
        name: "Igual",
        negation: false,
        keyword: true,
        content: "term",
        parameters: [{ name: "query", alias: "Igual" }],
        reverse: { filterType: "diferente", value: false, negation: true }
    },
    {
        filterType: "diferente",
        name: "Diferente",
        negation: true,
        keyword: true,
        content: "term",
        parameters: [{ name: "query", alias: "Diferente" }],
        reverse: { filterType: "igual", value: false, negation: false }
    },
    {
        filterType: "contem",
        name: "Contém",
        negation: false,
        keyword: false,
        content: "query_string",
        parameters: [{ name: "query", alias: "Contém" }],
        reverse: { filterType: "nao_contem", value: false, negation: false }
    },
    {
        filterType: "nao_contem",
        name: "Não contém",
        negation: true,
        keyword: false,
        content: "query_string",
        parameters: [{ name: "query", alias: "Não contém", negation: true }],
        reverse: { filterType: "contem", value: false, negation: false }
    },
    {
        filterType: "contem_algum",
        name: "Correspondente a",
        negation: false,
        keyword: true,
        content: "terms",
        parameters: [{ name: "query", alias: "Contém" }],
        reverse: { filterType: "contem_algum", value: false, negation: true }
    },
    {
        filterType: "vazio",
        name: "Vazio",
        negation: true,
        keyword: false,
        content: "wildcard",
        parameters: [{ name: "field", alias: "Campo" }],
        reverse: { filterType: "nao_vazio", value: false, negation: false }
    },
    {
        filterType: "nao_vazio",
        name: "Não Vazio",
        negation: false,
        keyword: false,
        content: "wildcard",
        parameters: [{ name: "field", alias: "Campo" }],
        reverse: { filterType: "vazio", value: false, negation: true }
    },
    {
        filterType: "existe",
        name: "Existe (retorno)",
        negation: false,
        keyword: false,
        content: "exists",
        parameters: [{ name: "field", alias: "Campo" }],
        reverse: { filterType: "nao_existe", value: false, negation: false }
    },
    {
        filterType: "nao_existe",
        name: "Não Existe (retorno)",
        negation: false,
        keyword: false,
        content: "script",
        parameters: [{ name: "field", alias: "Campo" }],
        reverse: { filterType: "existe", value: false, negation: false }
    }/*,
    {
        filterType: "regexp",
        name: "Aplicar validação",
        negation: false,
        keyword: false,
        content: "regexp",
        regex: true,
        parameters: [{ name: "query", alias: "Válidos" }],
        reverse: { filterType: "regexp", value: true, negation: false }
    }*/
];

let filterNumeric: Array<any> = [
    {
        filterType: "igual",
        name: "Igual",
        negation: false,
        keyword: false,
        content: "match",
        parameters: [{ name: "query", alias: "Igual a" }],
        reverse: { filterType: "diferente", value: false, negation: false }
    },
    {
        filterType: "diferente",
        name: "Diferente",
        negation: true,
        keyword: false,
        content: "match",
        parameters: [{ name: "query", alias: "Diferente de" }],
        reverse: { filterType: "igual", value: false, negation: false }
    },
    {
        filterType: "entre",
        name: "Entre",
        negation: false,
        keyword: false,
        content: "range",
        parameters: [
            { name: "gte", alias: "Maior ou igual" },
            { name: "lte", alias: "Menor ou igual" }
        ],
        reverse: { filterType: "entre", value: false, negation: true }
    },
    {
        filterType: "maior_que",
        name: "Maior que",
        negation: false,
        keyword: false,
        content: "range",
        parameters: [{ name: "gt", alias: "Maior que" }],
        reverse: { filterType: "menor_ou_igual", value: false, negation: false }
    },
    {
        filterType: "menor_que",
        name: "Menor que",
        negation: false,
        keyword: false,
        content: "range",
        parameters: [{ name: "lt", alias: "Menor que" }],
        reverse: { filterType: "maior_ou_igual", value: false, negation: false }
    },
    {
        filterType: "maior_ou_igual",
        name: "Maior ou igual que",
        negation: false,
        keyword: false,
        content: "range",
        parameters: [{ name: "gte", alias: "Maior ou igual" }],
        reverse: { filterType: "menor_que", value: false, negation: false }
    },
    {
        filterType: "menor_ou_igual",
        name: "Menor ou igual que",
        negation: false,
        keyword: false,
        content: "range",
        parameters: [{ name: "lte", alias: "Menor ou igual" }],
        reverse: { filterType: "maior_que", value: false, negation: false }
    },
    {
        filterType: "existe",
        name: "Existe (retorno)",
        negation: false,
        keyword: false,
        content: "exists",
        parameters: [{ name: "field", alias: "Campo" }],
        reverse: { filterType: "nao_existe", value: false, negation: false }
    },
    {
        filterType: "nao_existe",
        name: "Não Existe (retorno)",
        negation: false,
        keyword: false,
        content: "script",
        parameters: [{ name: "field", alias: "Campo" }],
        reverse: { filterType: "existe", value: false, negation: false }
    }/*,
    {
        filterType: "regexp",
        name: "Aplicar validação",
        negation: false,
        keyword: true,
        content: "regexp",
        regex: true,
        parameters: [{ name: "query", alias: "Válidos" }],
        reverse: { filterType: "regexp", value: true, negation: false }
    }*/
];

let filterDate = [
    {
        filterType: "entre",
        name: "Entre",
        negation: false,
        keyword: false,
        content: "range",
        parameters: [
            { name: "gte", alias: "Maior ou igual" },
            { name: "lte", alias: "Menor ou igual" }
        ],
        reverse: { filterType: "entre", value: false, negation: true }
    },
    {
        filterType: "maior_que",
        name: "Maior que",
        negation: false,
        keyword: false,
        content: "range",
        parameters: [{ name: "gt", alias: "Maior que" }],
        reverse: { filterType: "menor_ou_igual", value: false, negation: false }
    },
    {
        filterType: "menor_que",
        name: "Menor que",
        negation: false,
        keyword: false,
        content: "range",
        parameters: [{ name: "lt", alias: "Menor que" }],
        reverse: { filterType: "maior_ou_igual", value: false, negation: false }
    },
    {
        filterType: "maior_ou_igual",
        name: "Maior ou igual que",
        negation: false,
        keyword: false,
        content: "range",
        parameters: [{ name: "gte", alias: "Maior ou igual" }],
        reverse: { filterType: "menor_que", value: false, negation: false }
    },
    {
        filterType: "menor_ou_igual",
        name: "Menor ou igual que",
        negation: false,
        keyword: false,
        content: "range",
        parameters: [{ name: "lte", alias: "Menor ou igual" }],
        reverse: { filterType: "maior_que", value: false, negation: false }
    },
    {
        filterType: "vazio",
        name: "Vazio",
        negation: false,
        keyword: false,
        content: "script",
        parameters: [{ name: "field", alias: "Campo" }],
        reverse: { filterType: "nao_vazio", value: false, negation: false }
    },
    {
        filterType: "nao_vazio",
        name: "Não Vazio",
        negation: false,
        keyword: false,
        content: "exists",
        parameters: [{ name: "field", alias: "Campo" }],
        reverse: { filterType: "vazio", value: false, negation: false }
    },
    {
        filterType: "existe",
        name: "Existe (retorno)",
        negation: false,
        keyword: false,
        content: "exists",
        parameters: [{ name: "field", alias: "Campo" }],
        reverse: { filterType: "nao_existe", value: false, negation: false }
    },
    {
        filterType: "nao_existe",
        name: "Não Existe (retorno)",
        negation: false,
        keyword: false,
        content: "script",
        parameters: [{ name: "field", alias: "Campo" }],
        reverse: { filterType: "existe", value: false, negation: false }
    }
];

let filterList = [
    {
        filterType: "contem_algum",
        name: "Contém Algum",
        negation: false,
        keyword: true,
        content: "terms",
        parameters: [{ name: "query", alias: "Contém" }],
        reverse: { filterType: "nao_contem", value: false, negation: false }
    },
    {
        filterType: "nao_contem",
        name: "Não contém",
        negation: true,
        keyword: true,
        content: "terms",
        parameters: [{ name: "query", alias: "Não contém" }],
        reverse: { filterType: "contem_algum", value: false, negation: false }
    },
    {
        filterType: "vazio",
        name: "Vazio",
        negation: true,
        keyword: false,
        content: "wildcard",
        parameters: [{ name: "field", alias: "Campo" }],
        reverse: { filterType: "nao_vazio", value: false, negation: false }
    },
    {
        filterType: "nao_vazio",
        name: "Não Vazio",
        negation: false,
        keyword: false,
        content: "wildcard",
        parameters: [{ name: "field", alias: "Campo" }],
        reverse: { filterType: "vazio", value: false, negation: true }
    },
    {
        filterType: "existe",
        name: "Existe (retorno)",
        negation: false,
        keyword: false,
        content: "exists",
        parameters: [{ name: "field", alias: "Campo" }],
        reverse: { filterType: "nao_existe", value: false, negation: false }
    },
    {
        filterType: "nao_existe",
        name: "Não Existe (retorno)",
        negation: false,
        keyword: false,
        content: "script",
        parameters: [{ name: "field", alias: "Campo" }],
        reverse: { filterType: "existe", value: false, negation: false }
    }/*,
    {
        filterType: "regexp",
        name: "Aplicar validação",
        negation: false,
        keyword: true,
        content: "regexp",
        regex: true,
        parameters: [{ name: "query", alias: "Válidos" }],
        reverse: { filterType: "regexp", value: true, negation: false }
    }*/
];

let filterBoolean = [
    // {
    //     filterType: "sim_nao",
    //     name: "Sim/Não",
    //     negation: false,
    //     keyword: false,
    //     content: "term",
    //     parameters: [{ name: "query", alias: "Texto" }],
    //     reverse: { filterType: "sim_nao", value: true, negation: false }
    // },
    {
        filterType: "true",
        name: "Sim",
        negation: false,
        keyword: false,
        content: "term",
        parameters: [{ name: "query", alias: "Texto" }],
        reverse: { filterType: "false", value: false, negation: false }
    },
    {
        filterType: "false",
        name: "Não",
        negation: false,
        keyword: false,
        content: "term",
        parameters: [{ name: "query", alias: "Texto" }],
        reverse: { filterType: "true", value: true, negation: false }
    }/*,
    {
        filterType: "nao_vazio",
        name: "Não Vazio",
        negation: false,
        keyword: false,
        content: "exists",
        parameters: [{ name: "field", alias: "Campo" }],
        reverse: { filterType: "vazio", value: false, negation: false }
    },
    {
        filterType: "vazio",
        name: "Vazio",
        negation: false,
        keyword: false,
        content: "script",
        parameters: [{ name: "field", alias: "Campo" }],
        reverse: { filterType: "nao_vazio", value: false, negation: false }
    }*/
];


// {
//     filterType: "igual",
//     name: "Igual",
//     negation: false,
//     keyword: true,
//     content: "term",
//     parameters: [{ name: "query", alias: "Igual" }],
//     reverse: { filterType: "diferente", value: false, negation: true }
// },
// {
//     filterType: "diferente",
//     name: "Diferente",
//     negation: true,
//     keyword: true,
//     content: "term",
//     parameters: [{ name: "query", alias: "Diferente" }],
//     reverse: { filterType: "igual", value: false, negation: false }
// },

let filterJson = [
	// Inteiro /////////////////////////////////////////////////////////////////////////////
	{
        filterType: "contem",
		name: "Contém",
		negation: false,
        keyword: false,
		content: "nested",
		parameters: [{ name: "query", alias: "Contém" }],
        reverse: { filterType: "nao_contem", value: false, negation: true },
		nestedType: "Inteiro",
		nestedContent: "match",
		nestedList: true
	},
	{
        filterType: "nao_contem",
		name: "Não contém",
		negation: true,
        keyword: false,
		content: "nested",
		parameters: [{ name: "query", alias: "Não contém", negation: true }],
        reverse: { filterType: "contem", value: false, negation: false },
		nestedType: "Inteiro",
		nestedContent: "match",
		nestedList: true
	},
	{
        filterType: "existe",
		name: "Existe",
		negation: false,
        keyword: false,
		content: "nested",
		parameters: [{ name: "field", alias: "Campo" }],
        reverse: { filterType: "nao_existe", value: false, negation: true },
		nestedType: "Inteiro",
		nestedContent: "exists",
		nestedList: false
	},
	{
        filterType: "nao_existe",
		name: "Não Existe",
		negation: true,
        keyword: false,
		content: "nested",
		parameters: [{ name: "field", alias: "Campo" }],
		reverse: { filterType: "existe", value: false, negation: false },
		nestedType: "Inteiro",
		nestedContent: "exists",
		nestedList: false
	},
	// Long ////////////////////////////////////////////////////////////////////////////////
	{
        filterType: "igual",
		name: "Igual",
		negation: false,
        keyword: false,
		content: "nested",
		parameters: [{ name: "query", alias: "Igual a" }],
        reverse: { filterType: "igual", value: false, negation: true },
		nestedType: "Long",
		nestedContent: "match",
		nestedList: false
	},
	{
        filterType: "existe",
		name: "Existe",
		negation: false,
        keyword: false,
		content: "nested",
		parameters: [{ name: "field", alias: "Campo" }],
        reverse: { filterType: "nao_existe", value: false, negation: true },
		nestedType: "Long",
		nestedContent: "exists",
		nestedList: false
	},
	{
        filterType: "nao_existe",
		name: "Não Existe",
		negation: true,
        keyword: false,
		content: "nested",
		parameters: [{ name: "field", alias: "Campo" }],
		reverse: { filterType: "existe", value: false, negation: false },
		nestedType: "Long",
		nestedContent: "exists",
		nestedList: false
	},
	// Data ////////////////////////////////////////////////////////////////////////////////
	{
        filterType: "entre",
		name: "Entre",
		negation: false,
        keyword: false,
		content: "nested",
        parameters: [
            { name: "gte", alias: "Maior ou igual" },
            { name: "lte", alias: "Menor ou igual" }
        ],
        reverse: { filterType: "entre", value: false, negation: true },
		nestedType: "Data",
		nestedContent: "range",
		nestedList: false
	},
	{
        filterType: "existe",
		name: "Existe",
		negation: false,
        keyword: false,
		content: "nested",
		parameters: [{ name: "field", alias: "Campo" }],
		reverse: { filterType: "nao_existe", value: false, negation: true },
		nestedType: "Data",
		nestedContent: "exists",
		nestedList: false
	},
	{
        filterType: "nao_existe",
		name: "Não Existe",
		negation: true,
        keyword: false,
		content: "nested",
		parameters: [{ name: "field", alias: "Campo" }],
		reverse: { filterType: "existe", value: false, negation: false },
		nestedType: "Data",
		nestedContent: "exists",
		nestedList: false
	}
]

export const ESBuilderFilterType: any = {
    Texto: filterText,
    Inteiro: filterNumeric,
    Long: filterNumeric,
    Decimal: filterNumeric,
    Numerico: filterNumeric,
    Data: filterDate,
    Lista: filterList,
    Boolean: filterBoolean,
	Nested: filterJson
}