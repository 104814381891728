import { StorageProxy } from "../service/storage-proxy.service";
import { isNullOrEmpty } from "../utils";
import { PreferenciasUsuario } from "./preferencias-usuario";

export class Permissao {
	permissaoRecurso: Array<PermissaoRecurso>;
	permissaoCarteira: Array<PermissaoCarteira>;
	permissaoLista: Array<PermissaoLista>;
	preferenciasUsuario: PreferenciasUsuario;
}

export class PermissaoRecurso {
	modulo: string;
	acao: string;
}

export class PermissaoCarteira {
	carteiraId: number;
	nome: string;
}

export class PermissaoLista {
	listaId: number;
}

export class PermissoesUsuario {

	//#region [ Recurso ]

	public static get recursos(): Array<PermissaoRecurso> {
		let retorno = StorageProxy.recursos;
		if (isNullOrEmpty(retorno)) return [];
		return StorageProxy.recursos;
	};

	public static set recursos(v: Array<PermissaoRecurso>) {
		StorageProxy.recursos = v;
	};

	public static possuiPermissao(modulo: string, acao: string): boolean {

		let p = PermissoesUsuario.recursos.find(
			l => l.acao == acao && l.modulo == modulo
		) != undefined;

		return p;
	}

	//#endregion

	//#region [ Carteira ]

	public static get carteiras(): Array<PermissaoCarteira> {
		let retorno = StorageProxy.carteiras;
		if (isNullOrEmpty(retorno)) return [];
		return StorageProxy.carteiras;
	};

	public static set carteiras(v: Array<PermissaoCarteira>) {
		StorageProxy.carteiras = v;
	};

	//#endregion

	//#region [ Lista ]

	public static get listas(): Array<PermissaoLista> {
		let retorno = StorageProxy.listas;
		if (isNullOrEmpty(retorno)) return [];
		return StorageProxy.listas;
	};

	public static set listas(v: Array<PermissaoLista>) {
		StorageProxy.listas = v;
	};

	//#endregion	

	//#region [ PreferenciasUsuario ]

	public static get preferenciasUsuario(): PreferenciasUsuario {
		let retorno = StorageProxy.preferenciasUsuario;
		if (isNullOrEmpty(retorno)) return new PreferenciasUsuario();
		return StorageProxy.preferenciasUsuario;
	};

	public static set preferenciasUsuario(v: PreferenciasUsuario) {
		StorageProxy.preferenciasUsuario = v;
	};

	//#endregion	
}