import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//import { NoopAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";

import {
  MatSlideToggleModule,
	MatButtonToggleModule,
	MatButtonToggleGroupMultiple,
  MatInputModule,
  MatDatepickerModule,
  MatButtonModule,
  MatGridListModule,
  MatCheckboxModule,
  MatDialogModule,
  MatSelectModule,
  MatListModule,
  MatIconModule,
  MatCardModule,
  MatProgressBarModule,
  MatDividerModule,
  MatPaginatorModule,
  MatRadioModule,
  MatTooltipModule,
  MAT_DIALOG_DEFAULT_OPTIONS
} from "@angular/material";

import { AppCommonModule } from "../common/common.module";
import { TableModule } from "primeng/table";
import { ListboxModule } from "primeng/listbox";
import { SelectModule } from "ng-select";

import { environment } from "../../../environments/environment";
import { EsBuilderFilterComponent } from "./esbuilder-filter/esbuilder-filter.component";
import { EsBuilderListComponent } from "./esbuilder-list/esbuilder-list.component";
import { EsBuilderItemComponent } from "./esbuilder-item/esbuilder-item.component";
import { EsBuilderItemFieldFilterComponent } from "./esbuilder-item-field-filter/esbuilder-item-field-filter.component";
import { EsBuilderItemDateFilterComponent } from "./esbuilder-item-date-filter/esbuilder-item-date-filter.component"
import { NotificationService } from "../common/service/notification.service";
import { DragDropModule } from '@angular/cdk/drag-drop';
import { EsBuilderCollapseComponent } from "./esbuilder-collapse/esbuilder-collapse.component";
import { EsBuilderService } from "./libs/services/esbuilder.service";

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppCommonModule,
    HttpClientModule,
    //NoopAnimationsModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatInputModule,
    MatButtonModule,
    MatGridListModule,
    FormsModule,
    MatCheckboxModule,
    MatSelectModule,
    MatPaginatorModule,
    MatListModule,
    MatIconModule,
    MatDividerModule,
    MatCardModule,
    MatProgressBarModule,
    MatDialogModule,
    TableModule,
    SelectModule,
    MatRadioModule,
    MatTooltipModule,
    ListboxModule,
    MatDatepickerModule,
    DragDropModule 
  ],
  declarations: [
    EsBuilderFilterComponent,
    EsBuilderListComponent,
    EsBuilderItemComponent,
    EsBuilderItemFieldFilterComponent,
    EsBuilderItemDateFilterComponent,
    EsBuilderCollapseComponent
  ],
  providers: [
    NotificationService,
    EsBuilderService,
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { panelClass: `theme-${environment.theme}` }}
  ],
  entryComponents: [  ],
  exports: [
    EsBuilderFilterComponent,
    EsBuilderListComponent,
    EsBuilderItemComponent,
    EsBuilderItemFieldFilterComponent,
    EsBuilderItemDateFilterComponent
  ]
})
export class EsBuilderModule {}
