import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-card-with-header",
  templateUrl: "./card-with-header.component.html"
})
export class CardWithHeaderComponent implements OnInit {
  @Input()
  cardState = "in";

  @Input()
  headerHidden:boolean = false;

  constructor() {}

  toggleCard() {
    this.cardState = this.cardState == "in" ? "out" : "in";
  }

  ngOnInit() {}
}
