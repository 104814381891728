import { Component, Inject, Input, OnInit } from '@angular/core';
import { IAppConfig } from '../../common/model/app-config';
import { APPCONFIG } from '../../common/providers/config.provider';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'my-app-sidenav',
  styles: [],
  templateUrl: './sidenav.component.html'
})

export class AppSidenavComponent implements OnInit {
  AppConfig;
  swaggerURL;

  constructor(@Inject(APPCONFIG) public appConfig: IAppConfig) { }

  ngOnInit() {
    this.AppConfig = this.appConfig;
    this.swaggerURL = `${this.appConfig.serverUrl}/docs`;
  }

  toggleCollapsedNav() {
    this.AppConfig.navCollapsed = !this.AppConfig.navCollapsed;
  }
}
