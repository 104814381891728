import { MatDialog } from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FiltroStatusRegistroLista, Lista } from "../../common/model/lista";
import { ListaStatus } from "../../common/model/listaStatus";
import { Estrategia, TipoEstrategia } from "../../common/model/estrategia";
import { PreviewDadosEstrategiaComponent } from "../../estrategia/form-estrategia/preview-dados-estrategia/preview-dados-estrategia.component";
import { ElasticsearchFilterComponent } from "../../elasticsearch/filter/elasticsearch-filter/elasticsearch-filter.component";
import { BaseElasticsearchFilter } from "../../elasticsearch/model/elasticsearch-filter/base";
import { ListaService } from "../../common/service/lista.service";
import { CustomAlertService } from "../../common/service/custom-alert.service";
import { ElasticsearchEditComponent } from "../../elasticsearch/elasticsearch-edit/elasticsearch-edit.component";
import { TemplateService } from "../../common/service/template.service";
import { Template } from "../../common/model/template";


@Component({
    selector: "app-form-lista",
    templateUrl: "./form-lista.component.html",
    providers: [
        { useClass: ListaService, provide: ListaService },
        { useClass: CustomAlertService, provide: CustomAlertService },
        { useClass: TemplateService, provide: TemplateService },
    ]
})
export class FormListaComponent implements OnInit {
    estrategia: Estrategia;
    listaId: number;
    lista: Lista;
    template: Template;
    nomeLista: string;
    quantidadeEstrategia: number;
    isEdit: boolean = false;
    campanhas: Array<any>;
    dirty: boolean;
    finishedLoading: boolean = true;
    query: any;
    must: Array<BaseElasticsearchFilter> = [];
    forcarAtualizar: boolean = false;
    @ViewChild("elasticsearchFilter", { static: false })
    elasticsearchFilter: ElasticsearchFilterComponent;

    constructor(
        private route: ActivatedRoute,
        private listaService: ListaService,
        private templateService: TemplateService,
        private customAlertService: CustomAlertService,
        private router: Router,

        public dialog: MatDialog
    ) {
        this.estrategia = new Estrategia();
        this.estrategia.reenviarDistribuido = FiltroStatusRegistroLista.total;

        this.query = this.queryBase;
        this.query.size = 5;
        this.route.params.subscribe(params => {
            if (params.id) {
                this.listaId = params.id;
                listaService.obterListaPorId(params.id).subscribe(s => {
                    this.lista = s;
                    templateService.obterTemplate(this.lista.listaTemplateId).subscribe(t => this.template = t);
                });
            }
            else {
                this.listaId = 0;
                this.finishedLoading = true;
            }
        });
    }

    public get erros() {
        if (!this.dirty) return;
        return this.estrategia.validar();
    }

    get queryBase() {
        return {
            size: 5,
            _source: {
                includes: []
            },
            query: {
                bool: {
                    must: this.must.map(f => f.convertedFilter),
                    must_not: []
                }
            }
        };
    }

    incluir() {
        const incluirItem = this.dialog.open(ElasticsearchEditComponent, {
            height: "800px",
            width: "80%",
            data: {
                template: this.template,
                lista: this.lista,
                listaId: this.lista.listaId,
                item: {}
            }
        });

		incluirItem.afterClosed().subscribe(result => {
			if (result) {
				if (result.isError)
					this.customAlertService.show("Inclusão", "Ocorreu um erro ao executar a inclusão", "error");
				else {
					setTimeout(() => {
						this.setQuantidadeLista();
						this.forcarAtualizar = true;
						this.customAlertService.show("Inclusão", "Registro incluído com sucesso", "success");
					}, 1000);
				}
			}
		});
	}


    ngOnInit() { }

    updateQuery(query) {
        this.query = query;
    }

    mostrarPreview() {

        this.query.size = 10;
        this.dialog.open(PreviewDadosEstrategiaComponent, {
            height: "700px",
            width: "80%",
            hasBackdrop: true,
            data: {
                templateId: this.template.listaTemplateId,
                lista: this.lista.nome,
                listaId: this.lista.listaId,
                query: this.query /*{
            query: this.query,
            size:10
          }*/
            }
        }
        );

    }

    setQuantidadeLista() {
        this.listaService
            .obterQuantidadeLista(this.lista.listaId, this.estrategia.listaEstrategiaId)
            .subscribe(
                quantidades => (this.quantidadeEstrategia = quantidades.quantidadeLivre)
            );
    }

    updateQuantidades(event: any) {

        let quantidades: Array<number> = event.quantidade;

        if (undefined != quantidades && quantidades.length != 0) {
            this.quantidadeEstrategia = quantidades[quantidades.length - 1];
            return;
        }
        this.quantidadeEstrategia = 0;
        if (undefined == this.estrategia.listaId) return;
        this.setQuantidadeLista();
    }

    gravarCardCustomizado(event: any) {
        let dados = {
            'id': this.lista.listaId,
            'campoCard': this.lista.campoCard,
            'operacaoCampoCard': this.lista.operacaoCampoCard,
            'campoAgrupadorSomaUnicosCard': this.lista.campoAgrupadorSomaUnicosCard,
            'formatacaoCard': this.lista.formatacaoCard
        };

        this.listaService
            .atualizarCardCustomizado(dados)
            .subscribe(retorno => {
                this.lista.campoCard = retorno.campoCard;
                this.lista.operacaoCampoCard = retorno.operacaoCampoCard;
                this.lista.campoAgrupadorSomaUnicosCard = retorno.campoAgrupadorSomaUnicosCard;
                this.lista.formatacaoCard = retorno.formatacaoCard;
            });
    }

    gravarCardSomarUnicos(event: any) {

        let dados = {
            'id': this.lista.listaId,
            'somaUnicosCardCampo': this.lista.somaUnicosCardCampo,
            'somaUnicosCardAgrupador': this.lista.somaUnicosCardAgrupador,
            'somaUnicosCardFormatacao': this.lista.somaUnicosCardFormatacao
        };

        this.listaService
            .atualizarCardSomaUnicos(dados)
            .subscribe(retorno => {
                this.lista.somaUnicosCardCampo = retorno.somaUnicosCardCampo;
                this.lista.somaUnicosCardAgrupador = retorno.somaUnicosCardAgrupador;
                this.lista.somaUnicosCardFormatacao = retorno.somaUnicosCardFormatacao;
                this.lista.somaUnicosCardAlterado = retorno.somaUnicosCardAlterado;
                this.lista.somaUnicosCardProcessamento = retorno.somaUnicosCardProcessamento;
                this.lista.somaUnicosCardValor = retorno.somaUnicosCardValor;
            });
    }
}
