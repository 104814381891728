import { ElementRef } from "@angular/core";

export function generateCanvas(canvas: ElementRef<HTMLCanvasElement>, percentage: number, color: string) {
    percentage = Math.round(percentage * 100) / 100;

    let context = canvas.nativeElement.getContext('2d');
    context.clearRect(0, 0, 70, 70);
    let x = 35;
    let y = 35;
    let radius = 30;

    var startAngle = percentToRadians(0);
    var endAngle = percentToRadians(percentage);

    // set to true so that we draw the missing percentage
    var counterClockwise = true;

    context.beginPath();

    if (percentage == 0) {
        context.arc(x, y, radius, startAngle, percentToRadians(1), counterClockwise)
    } else {
        context.arc(x, y, radius, startAngle, endAngle, counterClockwise);
    }

    context.lineWidth = 10;

    // line color
    context.strokeStyle = '#f3f2f2';
    context.stroke();

    // set to false so that we draw the actual percentage
    counterClockwise = false;

    context.beginPath();
    
    if (percentage == 0) {
        context.arc(x, y, radius, startAngle, percentToRadians(1), counterClockwise)
    } else {
        context.arc(x, y, radius, startAngle, endAngle, counterClockwise);
    }
    context.lineWidth = 10;

    // line color
    context.strokeStyle = (percentage == 0) ? '#f3f2f2' : color;
    context.stroke();

    // now draw the inner text
    context.font = radius / 2.5 + "px Helvetica";
    context.fillStyle = color;
    context.textAlign = "center";
    // baseline correction for text
    context.fillText(percentage + "%", x, y * 1.05);

    return context;
}

export function degreesToRadians(deg) {
    return (deg / 180) * Math.PI;
}

export function percentToRadians(percentage) {
    // convert the percentage into degrees
    var degrees = percentage * 360 / 100;
    // and so that arc begins at top of circle (not 90 degrees) we add 270 degrees
    return degreesToRadians(degrees + 270);
}